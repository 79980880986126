import axios from "axios";
import { REACT_APP_API_BASE_URL } from "../const/env";
import { getTenantIds } from "../utils/get-tenant-ids";

declare module "axios" {
  interface AxiosRequestConfig {
    tenantId?: string;
  }
}

export const authApiClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

authApiClient.interceptors.request.use(
  (config) => {
    const tenantIds = getTenantIds();
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...(tenantIds.length === 1
        ? { "X-Tenant": tenantIds[0] }
        : config.tenantId
        ? { "X-Tenant": config.tenantId }
        : false),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
