import React, { FC, useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import { Loader } from "../../../app/components/Loader/Loader";
import { PagesDto, VehicleDto } from "../../api/vehicles.api.dto";

interface IVehicleStatusList {
  vehicles: VehicleDto[];
  showTenantColumn?: boolean;
  isVehiclesPending: boolean;
  goToVehicleStatusScreen: (
    vehicleId: string,
    tenantId: string | undefined
  ) => void;
  pageData: PagesDto;
  handlePageChange: (page: number, rowsPerPage: number) => void;
}

export const VehicleStatusList: FC<IVehicleStatusList> = ({
  vehicles,
  showTenantColumn,
  goToVehicleStatusScreen,
  isVehiclesPending,
  pageData,
  handlePageChange,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rows = vehicles.map((vehicle) => ({
    id: vehicle.alternateId,
    name: vehicle.name || vehicle.hardwareId || "N/A",
    make: vehicle.make,
    model: vehicle.model,
    terminal: vehicle.terminal?.name,
    tenantId: vehicle.tenantId,
  }));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handlePageChange(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowPress = (vehicleId: string, tenantId: string | undefined) => {
    goToVehicleStatusScreen(vehicleId, tenantId);
  };

  useEffect(() => {
    handlePageChange(0, rowsPerPage);
  }, [rowsPerPage]);

  return (
    <TableContainer
      component={Paper}
      style={{ marginBottom: "75px !important" }}
    >
      {!isVehiclesPending ? (
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Vehicle Name</TableCell>
              <TableCell align="center">Vehicle Make</TableCell>
              <TableCell align="center">Vehicle Model</TableCell>
              <TableCell align="center">Vehicle ID</TableCell>
              <TableCell align="center">Terminal</TableCell>
              {showTenantColumn && <TableCell align="center">Tenant</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index: number) => (
              <TableRow
                key={`${row}_${index}`}
                onClick={() => handleRowPress(row.id, row.tenantId)}
              >
                <TableCell style={{ fontWeight: 600 }} align="center">
                  {index + 1}
                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.make}</TableCell>
                <TableCell align="center">{row.model}</TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.terminal}</TableCell>
                {showTenantColumn && (
                  <TableCell align="center">{row.tenantId || "N/A"}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={pageData.totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <Loader />
      )}
    </TableContainer>
  );
};
