import { Typography } from "@mui/material";
import styled from "styled-components";
import {
  ASPHALT_LIGHTER,
  TRUCKING_GREEN,
  SCHOOL_BUS_RED,
  CAUTIONARY_ORANGE,
  WHITE,
} from "../../../app/theme/palette";

export const StyledTableTire = styled.div<{ alert: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 28px;
  background-color: ${({ alert }) =>
    alert === "green"
      ? TRUCKING_GREEN
      : alert === "red"
      ? SCHOOL_BUS_RED
      : alert === "orange"
      ? CAUTIONARY_ORANGE
      : ASPHALT_LIGHTER};
  margin: auto;
`;

export const StyledTireText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${WHITE};
`;
