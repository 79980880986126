import React, { FC, useEffect } from "react";
import { debounce } from "lodash";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import {
  clearSearchTenantsState as clearSearchTenantsStateAction,
  searchTenants as searchTenantsAction,
} from "../../store/tenants.slice";
import TableSearch from "../../../app/components/Table/TableSearch";

export const TenantsSearchContainer: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSearchTenantsStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchTenantsAction(value));

  return <TableSearch onSearch={debounce(onSearch, 200)} />;
};
