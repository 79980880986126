import React, { FC, useEffect } from "react";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { TireCoordinateDto } from "../../api/vehicles.api.dto";
import {
  selectFetchAllVehiclesData,
  selectIsFetchAllVehiclesPending,
} from "../../store/vehicles.selector";
import {
  VehicleTireDiagram,
  DiagramVehicleType,
} from "../../components/VehicleTireDiagram/VehicleTireDiagram";

export interface IVehicleSetupTiresContainerProps {
  vehicleId: string | undefined;
  tenantId: string | undefined;
  selectedTireCoordinates: TireCoordinateDto[];
  setSelectedTireCoordinates: React.Dispatch<
    React.SetStateAction<TireCoordinateDto[]>
  >;
  errorMessage?: string;
  showTenantIds?: boolean;
}

export const VehicleSetupTiresContainer: FC<
  IVehicleSetupTiresContainerProps
> = ({
  vehicleId,
  tenantId,
  selectedTireCoordinates,
  setSelectedTireCoordinates,
  errorMessage,
  showTenantIds,
}) => {
  const vehicles = useAppSelector(selectFetchAllVehiclesData);
  const isVehiclesPending = useAppSelector(selectIsFetchAllVehiclesPending);
  const selectedVehicle = vehicles.find(
    (vehicle) =>
      vehicle.alternateId === vehicleId &&
      (!showTenantIds || vehicle.tenantId === tenantId)
  );

  const diagramVehicles: DiagramVehicleType[] = [
    { type: "truck", data: selectedVehicle, editable: true },
    { type: "trailer" },
  ];

  useEffect(() => {
    if (selectedVehicle) {
      setSelectedTireCoordinates(selectedVehicle.tiresMap || []);
    }
  }, [selectedVehicle]);

  return (
    <>
      <Typography sx={{ marginBottom: 5 }}>
        Select the number of axles and tires for each axle.
      </Typography>
      <Box sx={{ marginBottom: 5 }}>
        <VehicleTireDiagram
          interactive
          hideReadOnlyOnMobile
          vehicles={diagramVehicles}
          selectedTireCoordinates={selectedTireCoordinates}
          setSelectedTireCoordinates={setSelectedTireCoordinates}
          isPending={isVehiclesPending}
          isDisabled={!selectedVehicle}
        />
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Box>
    </>
  );
};
