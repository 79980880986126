import React, { FC } from "react";
import { Box, Container } from "@mui/material";

import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { ForgotPasswordFormContainer } from "../../containers/ForgotPasswordFormContainer/ForgotPasswordFormContainer";

export const ForgotPasswordView: FC = () => {
  return (
    <PageLayout hideSideNav>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <PageTitle title="Forgot Password" />
          <ForgotPasswordFormContainer />
        </Container>
      </Box>
    </PageLayout>
  );
};
