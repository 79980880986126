import React, { FC, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Box } from "@mui/material";
import { FileDownloadOutlined } from "@mui/icons-material";

import { Button, IButton } from "../Button/Button";

interface IExportCsvButton extends IButton {
  fileName: string;
  fetchAsync: () => Promise<string>;
}

export const ExportCsvButton: FC<IExportCsvButton> = ({
  fileName,
  fetchAsync,
  ...rest
}) => {
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [csvData, setCsvData] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const handleButtonClick = async () => {
    setIsFetching(true);

    const csv = await fetchAsync();

    if (csv !== csvData) {
      setCsvData(csv);
    } else {
      triggerDownload();
    }
  };

  const triggerDownload = () => {
    setIsFetching(false);

    if (csvData) {
      csvLinkRef.current?.link?.click();
    }
  };

  useEffect(() => {
    triggerDownload();
  }, [csvData]);

  return (
    <Box>
      <CSVLink
        ref={csvLinkRef}
        data={csvData}
        filename={`${fileName}.csv`}
        className="hidden"
        target="_blank"
      />
      <Button
        onClick={handleButtonClick}
        startIcon={<FileDownloadOutlined />}
        pending={isFetching}
        {...rest}
      >
        Export
      </Button>
    </Box>
  );
};
