import React, { FC, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  StyledContainer,
  StyledRow,
  StyledColumn,
  StyledHeaderText,
  StyledSubText,
  StyledText,
  StyledGpsContainer,
  StyledTextContainer,
  StyledAlertTextContainer,
} from "./VehicleInfo.style";
import {
  VehicleDto,
  VehicleMapDto,
} from "../../../vehicles/api/vehicles.api.dto";
import { TrailerDto } from "../../../trailers/api/trailers.api.dto";
import { AlertDto } from "../../../summary/api/summary.api.dto";
import { GPSMap } from "../../../map/components/GPSMap";
import { timezoneCode } from "../../../app/utils/timezone-code";
import { getTimeZone } from "../../../app/utils/get-timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface iVehicleInfoProps {
  data: AlertDto | null;
  trailer?: TrailerDto;
  tripId: number;
  vehicle?: VehicleDto;
  showTenantIds?: boolean;
}

export const VehicleInfo: FC<iVehicleInfoProps> = ({
  data,
  trailer,
  tripId,
  vehicle,
  showTenantIds,
}) => {
  const [position, setPosition] = React.useState<VehicleMapDto[]>([
    { lat: 33.9364757, lng: -118.3080658 },
  ]);
  const lat = Number(data?.gps?.lat?.toFixed(6));
  const lon = Number(data?.gps?.lon?.toFixed(6));

  useEffect(() => {
    const vehiclePosition = {
      lat: lat,
      lng: lon,
    } as VehicleMapDto;
    setPosition([vehiclePosition]);
  }, []);

  const userTimeZone = getTimeZone();

  const timestamp = dayjs(new Date(data?.timestamp || new Date()))
    .tz(userTimeZone)
    .format(`ddd MMM DD YYYY HH:mm:ss [${timezoneCode(userTimeZone)}]`);

  return (
    <StyledContainer container="vehicle">
      <StyledColumn style={{ marginBottom: "52px" }}>
        <StyledHeaderText>Vehicle Information</StyledHeaderText>
        <StyledRow>
          <StyledTextContainer>
            <StyledSubText>Vehicle Type:</StyledSubText>
            <StyledText>
              {vehicle?.name || vehicle?.hardwareId || "N/A"}
            </StyledText>
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledSubText>Vehicle ID:</StyledSubText>
            <StyledText>{vehicle?.alternateId}</StyledText>
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledSubText>Trip ID:</StyledSubText>
            <StyledText>{tripId}</StyledText>
          </StyledTextContainer>
        </StyledRow>
        <StyledRow>
          <StyledTextContainer>
            <StyledSubText>Trailer Type:</StyledSubText>
            <StyledText>{trailer?.name || trailer?.type || "N/A"}</StyledText>
          </StyledTextContainer>
          <StyledTextContainer>
            <StyledSubText>Trailer ID:</StyledSubText>
            <StyledText>{trailer?.alternateId || "N/A"}</StyledText>
          </StyledTextContainer>
        </StyledRow>
      </StyledColumn>
      <StyledColumn>
        <StyledHeaderText>Position</StyledHeaderText>
        <StyledRow>
          <StyledAlertTextContainer
            style={{ display: "block", maxWidth: "280px" }}
          >
            <StyledRow>
              <StyledTextContainer>
                <StyledSubText>Timestamp:</StyledSubText>
                <StyledText>{timestamp}</StyledText>
              </StyledTextContainer>
            </StyledRow>
            <StyledRow>
              <StyledTextContainer>
                <StyledSubText>Position Latitude:</StyledSubText>
                {!isNaN(lat) && (
                  <StyledText>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      {lat}
                    </a>
                  </StyledText>
                )}
              </StyledTextContainer>
            </StyledRow>
            <StyledRow>
              <StyledTextContainer>
                <StyledSubText>Position Longitude:</StyledSubText>
                {!isNaN(lon) && (
                  <StyledText>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      {lon}
                    </a>
                  </StyledText>
                )}
              </StyledTextContainer>
            </StyledRow>
          </StyledAlertTextContainer>
          <StyledAlertTextContainer>
            <StyledGpsContainer>
              {!(isNaN(lat) && isNaN(lon)) && (
                <GPSMap
                  positions={position}
                  fullView={false}
                  customHeight={250}
                  customWidth={250}
                  customZoom={14}
                  centerVehicle={[lat, lon]}
                  showTenantIds={showTenantIds}
                ></GPSMap>
              )}
            </StyledGpsContainer>
          </StyledAlertTextContainer>
        </StyledRow>
      </StyledColumn>
    </StyledContainer>
  );
};
