import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { fetchAllVehicles as fetchAllVehiclesAction } from "../../vehicles/store/vehicles.actions";
import { fetchAllTrailers as fetchAllTrailersAction } from "../../trailers/store/trailers.actions";
import {
  selectFetchAllVehiclesData,
  selectIsFetchAllVehiclesPending,
} from "../../vehicles/store/vehicles.selector";
import {
  selectFetchAllTrailersData,
  selectIsFetchAllTrailersPending,
} from "../../trailers/store/trailers.selector";
import { MeasurementType } from "../../vehicles/api/vehicles.api.dto";
import { DateRangeType } from "../../app/components/DateRangePicker/DateRangePicker";
import { AlertHistoryFilters } from "../components/AlertHistoryFilters/AlertHistoryFilters";
import { UserRole } from "../../app/enum/UserRole";

interface IAlertHistoryFiltersContainerProps {
  selectedAlertTypes: MeasurementType[];
  selectedTenantId: string | undefined;
  selectedVehicleId: string | null;
  selectedTrailerId: string | null;
  selectedDateRange: DateRangeType;
  setSelectedAlertTypes: React.Dispatch<
    React.SetStateAction<MeasurementType[]>
  >;
  setSelectedTenantId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedVehicleId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedTrailerId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
}

export const AlertHistoryFiltersContainer: FC<
  IAlertHistoryFiltersContainerProps
> = ({
  selectedAlertTypes,
  selectedTenantId,
  selectedVehicleId,
  selectedTrailerId,
  selectedDateRange,
  setSelectedAlertTypes,
  setSelectedTenantId,
  setSelectedVehicleId,
  setSelectedTrailerId,
  setSelectedDateRange,
}) => {
  const dispatch = useAppDispatch();
  const vehicles = useAppSelector(selectFetchAllVehiclesData);
  const trailers = useAppSelector(selectFetchAllTrailersData);
  const isFetchVehiclesPending = useAppSelector(
    selectIsFetchAllVehiclesPending
  );
  const isFetchTrailersPending = useAppSelector(
    selectIsFetchAllTrailersPending
  );
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const showVehicleAndTrailerFilter = !useShowForRoles([UserRole.Driver]);

  useEffect(() => {
    dispatch(fetchAllVehiclesAction());
    dispatch(fetchAllTrailersAction());
  }, []);

  return (
    <AlertHistoryFilters
      tenantIds={tenantIds}
      vehicles={vehicles}
      trailers={trailers}
      selectedAlertTypes={selectedAlertTypes}
      selectedTenantId={selectedTenantId}
      selectedVehicleId={selectedVehicleId}
      selectedTrailerId={selectedTrailerId}
      selectedDateRange={selectedDateRange}
      setSelectedAlertTypes={setSelectedAlertTypes}
      setSelectedTenantId={setSelectedTenantId}
      setSelectedVehicleId={setSelectedVehicleId}
      setSelectedTrailerId={setSelectedTrailerId}
      setSelectedDateRange={setSelectedDateRange}
      showTenantFilter={canSeeTenants}
      showVehicleAndTrailerFilter={showVehicleAndTrailerFilter}
      isLoading={isFetchVehiclesPending || isFetchTrailersPending}
    />
  );
};
