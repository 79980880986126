import React, { FC, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "../../app/components/Loader/Loader";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { fetchAlertEmail } from "../store/email.actions";
import {
  selectFetchAlertEmailData,
  selectIsFetchAlertEmailPending,
} from "../store/email.selector";
import { VehicleInfo } from "../components/TireAlert/VehicleInfo";
import { AlertCard } from "../components/TireAlert/AlertCard";
import { VehicleDiagram } from "../../vehicles/components/VehicleDiagram/VehicleDiagram";
import { TireTable } from "../../vehicles/components/TireTable/TireTable";
import { MeasurementDto } from "../../vehicles/api/vehicles.api.dto";
import { UserRole } from "../../app/enum/UserRole";

export const AlertEmailWebContainer: FC = () => {
  const token = useLocation().pathname.split("/")[2];
  const dispatch = useAppDispatch();
  const alertData = useAppSelector(selectFetchAlertEmailData);
  const isAlertDataPending = useAppSelector(selectIsFetchAlertEmailPending);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  useEffect(() => {
    dispatch(fetchAlertEmail({ token }));
  }, [dispatch]);

  const buildMeasurementDto = () => {
    const measurement = {
      type: alertData?.type,
      tires: alertData?.tires,
      timestamp: alertData?.timestamp,
      trailer: alertData?.trailer,
      vehicle: alertData?.vehicle,
      gps: alertData?.gps,
    } as MeasurementDto;
    return measurement;
  };

  return !isAlertDataPending ? (
    <>
      <VehicleInfo
        data={alertData}
        trailer={alertData?.trailer}
        tripId={alertData?.tripId || 1}
        vehicle={alertData?.vehicle}
        showTenantIds={canSeeTenants}
      />
      <AlertCard data={alertData} />
      {alertData?.vehicle ? (
        <>
          <VehicleDiagram vehicle={buildMeasurementDto()} interactive={false} />
          <TireTable tires={alertData?.tires} />
        </>
      ) : null}
    </>
  ) : (
    <Loader />
  );
};
