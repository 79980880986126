import { FormControl } from "@mui/material";
import { styled } from "@mui/system";
import { BLACK } from "../../../app/theme/palette";

export const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 324px;
  height: 36px;
`;

export const StyledLabel = styled("label")`
  white-space: nowrap;
  margin-top: 10px;
  margin-right: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
`;

export const StyledRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
