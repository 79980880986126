import { FC, useEffect } from "react";
import { useSnackbar, SnackbarKey } from "notistack";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { removeNotification } from "../../store/notifications/notifications.slice";
import { NotificationModel } from "../../models/Notification";
import { selectNotificationsState } from "../../store/notifications/notifications.selector";

let displayed: SnackbarKey[] = [];

export const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotificationsState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {} }: NotificationModel) => {
        if (displayed.includes(key)) return;

        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (node, myKey) => {
            dispatch(removeNotification(myKey));
            removeDisplayed(myKey);
          },
        });

        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};
