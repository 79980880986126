import styled from "styled-components";
import { ASPHALT_LIGHTER_ALT } from "../../app/theme/palette";

export const StyledContainer = styled.div`
  background-color: ${ASPHALT_LIGHTER_ALT};
  min-width: 350px;
  height: 338px;
  max-width: 100%;
  margin: 0 15px;
  position: relative;
`;
