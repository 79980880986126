import { authApiClient } from "../../app/api/authApi.client";
import { AlertHistoryDto } from "./alertHistory.api.dto";
import { PagesDto } from "../../summary/api/summary.api.dto";

export const fetchAlerts = async (
  vehicleId: string,
  trailerId: string,
  terminalId: string,
  tenantId: string | undefined,
  typesString: string,
  start: string,
  end: string,
  sortField: string,
  sortType: number,
  pageSize?: number,
  page?: number
): Promise<AlertHistoryDto[]> => {
  try {
    let url = `/stats/measurements/${vehicleId}/${trailerId}/${terminalId}/${typesString}/${start}/${end}/${sortField}/${sortType}`;
    if (page !== undefined && pageSize !== undefined) {
      url += `/${page}/${pageSize}`;
    } else if (page !== undefined) {
      url += `/${page}`;
    }
    const response = await authApiClient.get(url, {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAlertsPages = async (
  vehicleId: string,
  trailerId: string,
  terminalId: string,
  tenantId: string | undefined,
  typesString: string,
  start: string,
  end: string
): Promise<PagesDto> => {
  try {
    const response = await authApiClient.get(
      `/stats/pages/measurements/${vehicleId}/${trailerId}/${terminalId}/${typesString}/${start}/${end}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAlertsCsv = async (
  vehicleId: string,
  trailerId: string,
  terminalId: string,
  tenantId: string | undefined,
  typesString: string,
  start: string,
  end: string
): Promise<string> => {
  try {
    const response = await authApiClient.get(
      `/stats/download/measurements/${vehicleId}/${trailerId}/${terminalId}/${typesString}/${start}/${end}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const muteNotification = async (
  vehicleId: string,
  tenantId: string | undefined,
  tire: number,
  axle: number
): Promise<void> => {
  try {
    const response = await authApiClient.put(
      `/monitor/mute/${vehicleId}/${axle}/${tire}`,
      undefined,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unmuteNotification = async (
  vehicleId: string,
  tenantId: string | undefined,
  tire: number,
  axle: number
): Promise<void> => {
  try {
    const response = await authApiClient.put(
      `/monitor/unmute/${vehicleId}/${axle}/${tire}`,
      undefined,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
