import React, { FC, useState, useEffect } from "react";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import Dropzone from "react-dropzone";
import {
  Box,
  Stack,
  FormControl,
  TextField,
  TextFieldProps,
  Chip,
  Typography,
} from "@mui/material";
import { Button } from "../../../app/components/Button/Button";
import { UserRole } from "../../../app/enum/UserRole";
import { EditProfileDto } from "../../api/profile.api.dto";
import { ProfileModel } from "../../../auth/model/auth.model";
import { TerminalDto } from "../../../terminals/api/terminals.api.dto";
import { editProfileFormValidation } from "./EditProfileForm.validation";
import { StyledAvatar, DropzoneContainer } from "./EditProfileForm.style";

export interface IEditProfileFormProps {
  user?: ProfileModel | null;
  terminals: TerminalDto[];
  onSubmit: (formValues: EditProfileDto) => void;
  isPending?: boolean;
}

export const EditProfileForm: FC<IEditProfileFormProps> = ({
  user,
  terminals,
  onSubmit,
  isPending,
}) => {
  const [profileImagePreview, setProfileImagePreview] = useState(
    user?.image || ""
  );

  const getTerminalIdOptionLabel = (option: string) => {
    const match = terminals.find((terminal) => terminal.externalId === option);
    if (match) {
      return `${match.name || match.externalId}`;
    }
    return "";
  };

  useEffect(() => {
    // Revoke the image preview URL to avoid memory leaks (will run on unmount)
    return () => URL.revokeObjectURL(profileImagePreview);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        phoneNumber: user?.phoneNumber || "",
        image: undefined,
      }}
      validationSchema={editProfileFormValidation}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label>Username</label>
            <Typography sx={{ color: "text.disabled" }}>
              {user?.username}
            </Typography>
          </FormControl>
          <FormControl>
            <label htmlFor="first-name">First Name</label>
            <TextField
              id="first-name"
              name="firstName"
              variant="filled"
              fullWidth
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              required
              disabled={isPending}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="last-name">Last Name</label>
            <TextField
              id="last-name"
              name="lastName"
              variant="filled"
              fullWidth
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              disabled={isPending}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="image">Image</label>
            <Dropzone
              accept={["image/jpeg", "image/png"]}
              maxSize={2500000}
              multiple={false}
              onDrop={(acceptedFiles) => {
                // Revoke the previous preview URL
                URL.revokeObjectURL(profileImagePreview);

                if (acceptedFiles.length > 0) {
                  setProfileImagePreview(URL.createObjectURL(acceptedFiles[0]));
                  setFieldValue("image", acceptedFiles[0]);
                } else {
                  setProfileImagePreview("");
                  setFieldValue("image", undefined);
                }
              }}
              noClick
              noKeyboard
            >
              {({ getRootProps, getInputProps, open, isDragActive }) => (
                <DropzoneContainer
                  {...getRootProps({
                    className: isDragActive ? "dragging" : "",
                  })}
                >
                  <input {...getInputProps()} />
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <StyledAvatar src={profileImagePreview}>
                      {values.firstName?.charAt(0).toUpperCase()}
                      {values.lastName?.charAt(0).toUpperCase()}
                    </StyledAvatar>
                    {isDragActive ? (
                      <Typography>Drop the image here...</Typography>
                    ) : (
                      <>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={open}
                          disabled={isPending}
                        >
                          Change
                        </Button>
                        {profileImagePreview && (
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                              setProfileImagePreview("");
                              setFieldValue("image", null);
                            }}
                            disabled={isPending}
                          >
                            Remove
                          </Button>
                        )}
                      </>
                    )}
                  </Stack>
                </DropzoneContainer>
              )}
            </Dropzone>
          </FormControl>
          <FormControl>
            <label htmlFor="phone-number">Phone Number</label>
            <InputMask
              mask="(999) 999-9999"
              value={values.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isPending}
            >
              {(inputProps: TextFieldProps) => (
                <TextField
                  {...inputProps}
                  id="phone-number"
                  name="phoneNumber"
                  fullWidth
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  disabled={isPending}
                />
              )}
            </InputMask>
          </FormControl>
          <FormControl>
            <label>Role</label>
            <Typography sx={{ color: "text.disabled" }}>
              {
                (Object.entries(UserRole).find(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ([key, value]) => value === user?.role
                ) || ["", ""])[0]
              }
            </Typography>
          </FormControl>
          {(user?.role === UserRole.FleetManager ||
            user?.role === UserRole.Shop) && (
            <FormControl>
              <label>Terminal Access</label>
              <Stack direction="row" spacing={1}>
                {(user?.terminalExternalIds || []).map((terminalId, index) => (
                  <Chip
                    key={index}
                    label={getTerminalIdOptionLabel(terminalId)}
                    variant="outlined"
                    sx={{ color: "text.disabled" }}
                  />
                ))}
              </Stack>
            </FormControl>
          )}
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                pending={isPending}
                disabled={isPending || !isValid}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};
