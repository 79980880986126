import React, { FC } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  StyledContainer,
  StyledRow,
  StyledColumn,
  StyledHeaderText,
  StyledSubText,
  StyledText,
  StyledAlertTextContainer,
} from "./VehicleInfo.style";
import { AlertDto } from "../../../summary/api/summary.api.dto";
import { timezoneCode } from "../../../app/utils/timezone-code";
import { getTimeZone } from "../../../app/utils/get-timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface IAlertCardProps {
  data: AlertDto | null;
}

export const AlertCard: FC<IAlertCardProps> = ({ data }) => {
  const userTimeZone = getTimeZone();
  const timestamp = dayjs(new Date(data?.timestamp || new Date()))
    .tz(userTimeZone)
    .format(`ddd MMM DD YYYY HH:mm:ss [${timezoneCode(userTimeZone)}]`);

  return (
    <StyledContainer container="alert">
      <StyledColumn>
        <StyledHeaderText>Alert</StyledHeaderText>
        <StyledRow>
          <StyledAlertTextContainer>
            <StyledSubText>Timestamp: </StyledSubText>
            <StyledText>{timestamp}</StyledText>
          </StyledAlertTextContainer>
          <StyledAlertTextContainer>
            <StyledSubText>Axle: </StyledSubText>
            <StyledText>
              {data?.tire?.axle ? data?.tire?.axle + 1 : "N/A"}
            </StyledText>
          </StyledAlertTextContainer>
        </StyledRow>
        <StyledRow>
          <StyledAlertTextContainer>
            <StyledSubText>Reason: </StyledSubText>
            <StyledText>{data?.description}</StyledText>
          </StyledAlertTextContainer>
          <StyledAlertTextContainer>
            <StyledSubText>Tire: </StyledSubText>
            <StyledText>{data?.tire?.tireLocation}</StyledText>
          </StyledAlertTextContainer>
        </StyledRow>
        <StyledRow>
          <StyledAlertTextContainer>
            <StyledSubText>Tire ID: </StyledSubText>
            <StyledText>
              {data?.tire?.axle}
              {data?.tire?.tire}
            </StyledText>
          </StyledAlertTextContainer>
        </StyledRow>
      </StyledColumn>
    </StyledContainer>
  );
};
