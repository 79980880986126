import React, { FC } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useUnitSystem } from "../../../app/hooks/useUnitSystem";
import { StyledTableTire, StyledTireText } from "./TireTable.style";
import { TireDto } from "../../api/vehicles.api.dto";
import { PressureStatus } from "../../../app/enum/Vehicle";
import {
  getTemperatureValue,
  getTemperatureUnit,
} from "../../../app/utils/get-temperature-value";
import {
  getPressureValue,
  getPressureUnit,
} from "../../../app/utils/get-pressure-value";
import { timezoneCode } from "../../../app/utils/timezone-code";
import { getTimeZone } from "../../../app/utils/get-timezone";

dayjs.extend(localizedFormat);

interface ITireTable {
  tires: TireDto[];
}

export const TireTable: FC<ITireTable> = ({ tires }) => {
  const unitSystem = useUnitSystem();
  const temperatureUnit = getTemperatureUnit(unitSystem);
  const pressureUnit = getPressureUnit(unitSystem);
  const nowTimestamp = dayjs();
  const tz = timezoneCode(getTimeZone());

  const rows = tires
    .filter((tire) => nowTimestamp.diff(dayjs(tire.timestamp), "day") < 7)
    .map((tire) => {
      const tireTimestamp = dayjs(tire.timestamp);
      return {
        lostSignal: tire.lostSignal,
        pressureStatus: tire.pressureStatus,
        date: tireTimestamp.format("L"),
        time: tireTimestamp.format(`HH:mm:ss [${tz}]`),
        tireID: `${tire.axle}${tire.tire}`,
        axle: tire.axle,
        tireLocation: tire.tireLocation,
        temperature: getTemperatureValue(tire, unitSystem) || "System Error",
        pressure: getPressureValue(tire, unitSystem) || "System Error",
        leak: tire.leak,
        temperatureAlert: tire.temperatureAlert,
      };
    });

  return (
    <TableContainer component={Paper} style={{ marginBottom: "75px" }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Axle</TableCell>
            <TableCell align="center">Tire</TableCell>
            <TableCell align="center">
              Temperature ({temperatureUnit})
            </TableCell>
            <TableCell align="center">Pressure ({pressureUnit})</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index: number) => (
            <TableRow key={`${row}_${index}`}>
              <TableCell align="center">
                {row.lostSignal ||
                row.temperature === "System Error" ||
                row.pressure === "System Error" ? (
                  <StyledTableTire alert="error">
                    <StyledTireText>--</StyledTireText>
                  </StyledTableTire>
                ) : row.pressureStatus === PressureStatus.EUP ? (
                  <StyledTableTire alert="red">
                    <StyledTireText>EUP</StyledTireText>
                  </StyledTableTire>
                ) : row.leak ? (
                  <StyledTableTire alert="red">
                    <StyledTireText>TL</StyledTireText>
                  </StyledTableTire>
                ) : row.temperatureAlert ? (
                  <StyledTableTire alert="red">
                    <StyledTireText>HT</StyledTireText>
                  </StyledTableTire>
                ) : row.pressureStatus === PressureStatus.EOP ? (
                  <StyledTableTire alert="red">
                    <StyledTireText>EOP</StyledTireText>
                  </StyledTableTire>
                ) : row.pressureStatus === PressureStatus.UP ? (
                  <StyledTableTire alert="orange">
                    <StyledTireText>UP</StyledTireText>
                  </StyledTableTire>
                ) : row.pressureStatus === PressureStatus.OP ? (
                  <StyledTableTire alert="orange">
                    <StyledTireText>OP</StyledTireText>
                  </StyledTableTire>
                ) : row.pressureStatus === "good" ? (
                  <StyledTableTire alert="green">
                    <StyledTireText>N</StyledTireText>
                  </StyledTableTire>
                ) : (
                  <StyledTableTire alert="error">
                    <StyledTireText>--</StyledTireText>
                  </StyledTableTire>
                )}
              </TableCell>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.time}</TableCell>
              <TableCell align="center">{+row.axle + 1}</TableCell>
              <TableCell align="center">{row.tireLocation}</TableCell>
              <TableCell align="center">
                {row.lostSignal ? "Lost Signal" : row.temperature}
              </TableCell>
              <TableCell align="center">
                {row.lostSignal ? "Lost Signal" : row.pressure}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
