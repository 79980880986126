import { SnackbarKey, SnackbarMessage, OptionsObject } from "notistack";

export interface NotificationModel {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: OptionsObject;
}

export enum Notification {
  Info = "info",
  Warning = "warning",
  Success = "success",
  Error = "error",
}
