import React, { FC, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Loader } from "../../../app/components/Loader/Loader";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { authorize } from "../../store/auth.action";
import { setLoginRedirectToPreviousLocation } from "../../store/auth.slice";
import {
  selectIsAuthorizationVoid,
  selectIsAuthorizationPending,
  selectIsAuthorizationFulfilled,
  selectIsAuthorizationRejected,
  selectIsLogoutStatusPending,
  selectIsLogoutStatusFulfilled,
  selectIsLogoutStatusRejected,
  selectProfileData,
  selectIsProfileStatusPending,
  selectIsProfileStatusFulfilled,
  selectIsLoginStatusPending,
} from "../../store/auth.selector";
import { openErrorNotification } from "../../../app/store/notifications/notifications.slice";
import { UserRole } from "../../../app/enum/UserRole";
import { HOME, LOGIN } from "../../../app/const/routes";

export interface IProtectedRoute {
  roles?: UserRole[];
  element?: React.ReactElement | null;
  unauthorizedElement?: React.ReactElement | null;
}

export const ProtectedRoute: FC<IProtectedRoute> = ({
  roles,
  element,
  unauthorizedElement,
  children,
}) => {
  const dispatch = useAppDispatch();
  const isAuthorizationVoid = useAppSelector(selectIsAuthorizationVoid);
  const isAuthorizationPending = useAppSelector(selectIsAuthorizationPending);
  const isAuthorizationFulfilled = useAppSelector(
    selectIsAuthorizationFulfilled
  );
  const isAuthorizationRejected = useAppSelector(selectIsAuthorizationRejected);
  const isLoginPending = useAppSelector(selectIsLoginStatusPending);
  const isLogoutPending = useAppSelector(selectIsLogoutStatusPending);
  const isLogoutFulfilled = useAppSelector(selectIsLogoutStatusFulfilled);
  const isLogoutRejected = useAppSelector(selectIsLogoutStatusRejected);
  const profileData = useAppSelector(selectProfileData);
  const isProfilePending = useAppSelector(selectIsProfileStatusPending);
  const isProfileFulfilled = useAppSelector(selectIsProfileStatusFulfilled);
  const location = useLocation();

  useEffect(() => {
    if (isAuthorizationVoid) {
      dispatch(setLoginRedirectToPreviousLocation(true));
    }
    if (isAuthorizationVoid || isAuthorizationRejected) {
      dispatch(authorize(location.pathname === HOME));
    }
  }, [dispatch]);

  if (
    isAuthorizationPending ||
    isProfilePending ||
    isLoginPending ||
    isLogoutPending
  ) {
    return <Loader />;
  }

  if (isAuthorizationRejected || isLogoutFulfilled || isLogoutRejected) {
    return <Navigate to={LOGIN} state={{ from: location }} />;
  }

  if (isAuthorizationFulfilled && isProfileFulfilled) {
    if (roles) {
      if (profileData?.role && !roles.includes(profileData?.role)) {
        dispatch(openErrorNotification("Forbidden resource"));

        if (unauthorizedElement) {
          return <>{unauthorizedElement}</>;
        }

        return null;
      }
    }

    if (element) {
      return <>{element}</>;
    }

    if (children) {
      return <>{children}</>;
    }

    return <Outlet />;
  }

  return null;
};
