import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import {
  fetchDailySummary as fetchDailySummaryRequest,
  fetchLatestSummary as fetchLatestSummaryRequest,
  fetchSummaryPages as fetchSummaryPagesRequest,
} from "../api/summary.api";
import {
  SummaryDto,
  AlertsSummaryDto,
  PagesDto,
  PageInfoDto,
} from "../api/summary.api.dto";

export const fetchDailySummary = createAsyncThunk(
  "summary/fetchDailySummary",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<SummaryDto[]> =>
      await fetchDailySummaryRequest()
  )
);

export const fetchLatestSummary = createAsyncThunk(
  "summary/fetchLatestSummary",
  withError(
    async ({
      pageInfo,
      severity,
    }: {
      pageInfo: PageInfoDto;
      severity?: string;
    }): Promise<AlertsSummaryDto[]> =>
      await fetchLatestSummaryRequest(pageInfo, severity)
  )
);

export const fetchSummaryPages = createAsyncThunk(
  "summary/fetchSummaryPages",
  withError(
    async ({
      pageInfo,
      severity,
    }: {
      pageInfo: PageInfoDto;
      severity?: string;
    }): Promise<PagesDto> => await fetchSummaryPagesRequest(pageInfo, severity)
  )
);
