import React, { FC } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { ITireAlerts } from "../../containers/VehicleStatusContainer/VehicleStatusContainer";
import {
  ASPHALT_LIGHT,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
  BLACK,
  WHITE,
  ASPHALT_LIGHTER,
} from "../../../app/theme/palette";
import {
  StyledChartContainer,
  StyledChartTypography,
  StyledColumn,
  StyledLegendText,
  StyledRow,
  StyledTire,
  StyledTireText,
} from "./VehicleStatusPieChart.style";

type EChartsOption = echarts.EChartsOption;

interface IVehicleStatusPieChart {
  tireAlerts: ITireAlerts;
}

export const VehicleStatusPieChart: FC<IVehicleStatusPieChart> = ({
  tireAlerts,
}) => {
  const pieOption: EChartsOption = {
    tooltip: {
      trigger: "item",
      formatter: "<b>{c} tire(s)</b>",
    },
    series: [
      {
        type: "pie",
        radius: ["35%", "70%"],
        avoidLabelOverlap: false,
        label: {
          formatter: "{b}{d}%",
          color: BLACK,
          fontFamily: "Montserrat",
          bleedMargin: 0,
          position: "outside",
        },
        labelLine: {
          length: 15,
          length2: 20,
          lineStyle: { color: ASPHALT_LIGHT, width: 2 },
        },
        data: [
          tireAlerts.criticalCount > 0
            ? {
                value: tireAlerts.criticalCount,
                name: "Critical - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
          tireAlerts.cautionaryCount > 0
            ? {
                value: tireAlerts.cautionaryCount,
                name: "Cautionary - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
          tireAlerts.normalCount > 0
            ? {
                value: tireAlerts.normalCount,
                name: "Normal - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
          tireAlerts.noDataCount > 0
            ? {
                value: tireAlerts.noDataCount,
                name: "No Data - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    color: [SCHOOL_BUS_RED, CAUTIONARY_ORANGE, TRUCKING_GREEN, ASPHALT_LIGHTER],
  };

  return (
    <StyledChartContainer>
      <StyledChartTypography>Status Summary</StyledChartTypography>
      <StyledRow>
        <ReactECharts
          style={{ width: "170%", margin: 5, marginLeft: -20 }}
          option={pieOption}
        />

        <StyledColumn>
          <StyledRow>
            <StyledTire alert="green">
              <StyledTireText>N</StyledTireText>
            </StyledTire>
            <StyledLegendText>Normal (Green)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="red">
              <StyledTireText>HT</StyledTireText>
            </StyledTire>
            <StyledLegendText>High Temperature (Red)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="red">
              <StyledTireText>TL</StyledTireText>
            </StyledTire>
            <StyledLegendText>Tire Leak (Red)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="none">
              <StyledTireText>--</StyledTireText>
            </StyledTire>
            <StyledLegendText>Missing Data/Lost Signal (Gray)</StyledLegendText>
          </StyledRow>
        </StyledColumn>

        <StyledColumn>
          <StyledRow>
            <StyledTire alert="orange">
              <StyledTireText>UP</StyledTireText>
            </StyledTire>
            <StyledLegendText>Under Pressure (Orange)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="orange">
              <StyledTireText>OP</StyledTireText>
            </StyledTire>
            <StyledLegendText>Over Pressure (Orange)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="red">
              <StyledTireText>EOP</StyledTireText>
            </StyledTire>
            <StyledLegendText>Extreme Over Pressure (Red)</StyledLegendText>
          </StyledRow>
          <StyledRow>
            <StyledTire alert="red">
              <StyledTireText>EUP</StyledTireText>
            </StyledTire>
            <StyledLegendText>Extreme Under Pressure (Red)</StyledLegendText>
          </StyledRow>
        </StyledColumn>
      </StyledRow>
    </StyledChartContainer>
  );
};
