import * as yup from "yup";

const phoneRegExp = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const editProfileFormValidation = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().optional(),
  phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  terminalExternalIds: yup.array().of(yup.string()).optional(),
});
