import { authApiClient } from "../../app/api/authApi.client";
import {
  HistoryDto,
  MeasurementDto,
  PageInfoDto,
  PagesDto,
} from "../../vehicles/api/vehicles.api.dto";
import { TrailerDto, CreateEditTrailerDto } from "./trailers.api.dto";

export const fetchAllTrailers = async (
  pageInfo?: PageInfoDto,
  search?: string
): Promise<TrailerDto[]> => {
  try {
    const response = await authApiClient.get(
      `/data/trailers/${
        pageInfo
          ? `${pageInfo.page}/${pageInfo.rowsPerPage}/${search ? search : ""}`
          : ""
      }`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailer = async (
  trailerId: string,
  tenantId: string | undefined
): Promise<TrailerDto> => {
  try {
    const response = await authApiClient.get(`/data/trailer/${trailerId}`, {
      tenantId,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailerCurrentStats = async (
  trailerId: string,
  tenantId: string | undefined
): Promise<MeasurementDto> => {
  try {
    const response = await authApiClient.get(
      `/stats/current/trailer/${trailerId}`,
      {
        tenantId,
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailerHistoricalStats = async (
  trailerId: string,
  tenantId: string | undefined
): Promise<MeasurementDto> => {
  try {
    const response = await authApiClient.get(
      `/stats/historical/trailer/${trailerId}`,
      {
        tenantId,
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const editTrailer = async (
  trailerId: string,
  tenantId: string | undefined,
  data: CreateEditTrailerDto
): Promise<string> => {
  try {
    const response = await authApiClient.put(
      `/data/trailer/${trailerId}`,
      data,
      {
        tenantId,
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTrailer = async (
  trailerId: string,
  tenantId: string | undefined
): Promise<void> => {
  try {
    const response = await authApiClient.delete(`/data/trailer/${trailerId}`, {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailersPages = async (
  search?: string
): Promise<PagesDto> => {
  try {
    const response = await authApiClient.get(
      `/data/pages/trailers/${search ? search : ""}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailerPressureHistory = async (
  trailerId: string,
  tenantId: string | undefined,
  dateFrom: string,
  dateTo: string
): Promise<HistoryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/trailer/pressure/${trailerId}/${dateFrom}/${dateTo}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTrailerTemperatureHistory = async (
  trailerId: string,
  tenantId: string | undefined,
  dateFrom: string,
  dateTo: string
): Promise<HistoryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/trailer/temperature/${trailerId}/${dateFrom}/${dateTo}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
