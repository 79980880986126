import { apiClient } from "../../app/api/api.client";
import { UnsubscribeDto } from "./unsubscribe.api.dto";

export const unsubscribe = async (
  unsubscribeData: UnsubscribeDto
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      "/user/email/unsubscribe/" + unsubscribeData.email,
      unsubscribeData
    );
    return data;
  } catch (error) {
    throw error;
  }
};
