import { authApiClient } from "../../app/api/authApi.client";
import { TenantDto } from "./tenants.api.dto";

export const fetchAllTenants = async (): Promise<TenantDto[]> => {
  try {
    const response = await authApiClient.get("/tenant");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTenant = async (tenantModel: TenantDto): Promise<void> => {
  try {
    const response = await authApiClient.post("/tenant", tenantModel);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const disableTenant = async (id: string): Promise<void> => {
  try {
    const response = await authApiClient.put("/tenant/disable/" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const enableTenant = async (id: string): Promise<void> => {
  try {
    const response = await authApiClient.put("/tenant/enable/" + id);
    return response.data;
  } catch (error) {
    throw error;
  }
};
