import React, { FC } from "react";
import "leaflet/dist/leaflet.css";
import { Link as RouterLink } from "react-router-dom";
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { GPSMap } from "../components/GPSMap";
import { StyledContainer } from "./GPSMapContainer.style";
import { GPS_MAP, SUMMARY } from "../../app/const/routes";
import {
  VehicleDto,
  VehicleMapDto,
  VehicleTerminalDto,
} from "../../vehicles/api/vehicles.api.dto";
import { BLACK } from "../../app/theme/palette";
import { UserRole } from "../../app/enum/UserRole";

export interface IGPSMapContainerProps {
  fullView: boolean;
  filter?: string;
  vehicles?: VehicleDto[] | null;
  positions: VehicleMapDto[];
  terminals: VehicleTerminalDto[];
}

export const GPSMapContainer: FC<IGPSMapContainerProps> = ({
  fullView,
  positions,
  terminals,
}) => {
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  return (
    <StyledContainer
      style={{
        height: fullView ? "calc(100vh - 300px)" : "338px",
        maxHeight: fullView ? "100%" : "500px",
        margin: fullView ? "0" : "0 15px",
      }}
    >
      {!fullView ? (
        <RouterLink to={GPS_MAP}>
          <FullscreenIcon
            style={{
              top: "10px",
              right: "10px",
              position: "absolute",
              zIndex: 9999,
              color: BLACK,
            }}
            fontSize="large"
          />
        </RouterLink>
      ) : (
        <RouterLink to={SUMMARY}>
          <FullscreenExitIcon
            style={{
              top: "10px",
              right: "10px",
              position: "absolute",
              zIndex: 9999,
              color: BLACK,
            }}
            fontSize="large"
          />
        </RouterLink>
      )}
      <GPSMap
        fullView={fullView}
        terminals={terminals}
        positions={positions}
        showTenantIds={canSeeTenants}
      />
    </StyledContainer>
  );
};
