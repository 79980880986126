import { Typography, ToggleButton } from "@mui/material";
import styled from "styled-components";
import { BLACK } from "../../../app/theme/palette";
import { ReactComponent as LineKeySvg } from "../../../../assets/lineKey.svg";

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledTooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 6px;
`;

export const StyledFilterButton = styled(ToggleButton)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const StyledLineKey = styled(LineKeySvg)`
  margin: auto;
  margin-right: 12px;
`;

export const StyledTooltipText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${BLACK};
`;

export const StyledErrorText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: ${BLACK};
  position: relative;
  top: 150px;
`;
