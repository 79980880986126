import React, { FC } from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { StyledHeaderText, StyledLink } from "./SummaryEmail.style";
import {
  PagesDto,
  SummaryDto,
  VehiclesAlertsDto,
} from "../../summary/api/summary.api.dto";
import { VEHICLE_STATUS, VEHICLE_STATUS_TENANT } from "../../app/const/routes";

interface ISummaryEmailTable {
  terminalData: SummaryDto | null;
  showTenantIds?: boolean;
  pageData: PagesDto;
  handlePageChange: (page: number, rowsPerPage: number) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
}

export const SummaryEmailTable: FC<ISummaryEmailTable> = ({
  terminalData,
  showTenantIds,
  pageData,
  handlePageChange,
  page,
  setPage,
  rowsPerPage,
}) => {
  const availableAlerts = [
    "temperature_threshold_detection",
    "air_leak",
    "pressure_threshold_detection",
    "pressure_threshold_detection_4",
    "pressure_threshold_detection_3",
    "pressure_threshold_detection_1",
    "pressure_threshold_detection_0",
  ];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handlePageChange(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = () => {
    return null;
  };

  const rows = terminalData?.vehiclesAlerts
    .map((vehicleAlert: VehiclesAlertsDto) => {
      const alerts = vehicleAlert.alerts.filter(
        (item) => availableAlerts.indexOf(item.type) > -1
      );
      return {
        vehicle: vehicleAlert.name || vehicleAlert.hardwareId,
        vehicleId: vehicleAlert.id,
        vehicleTenantId: vehicleAlert.tenantId,
        trailer: vehicleAlert.trailerName || vehicleAlert.trailerType,
        trailerId: vehicleAlert.trailerId || "N/A",
        alerts: alerts.length,
      };
    })
    .sort((a, b) => b.alerts - a.alerts);

  return (
    <>
      <StyledHeaderText style={{ marginBottom: "75px" }}>
        Terminal Name: {terminalData?.terminalName}
      </StyledHeaderText>
      <br />
      <StyledHeaderText>Daily Summary:</StyledHeaderText>
      <TableContainer
        component={Paper}
        style={{ overflowX: "auto", marginBottom: "75px" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Vehicle</TableCell>
              <TableCell align="center">Vehicle ID</TableCell>
              <TableCell align="center">Trailer</TableCell>
              <TableCell align="center">Trailer ID</TableCell>
              <TableCell align="center">Alerts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index: number) => {
              const vehicleStatusLink =
                row.vehicleId &&
                (showTenantIds && row.vehicleTenantId
                  ? generatePath(VEHICLE_STATUS_TENANT, {
                      vehicleId: row.vehicleId,
                      tenantId: row.vehicleTenantId,
                    })
                  : generatePath(VEHICLE_STATUS, {
                      vehicleId: row.vehicleId,
                    }));
              return (
                <TableRow key={`${row}_${index}`}>
                  <TableCell align="center">
                    {vehicleStatusLink ? (
                      <StyledLink>
                        <RouterLink to={vehicleStatusLink}>
                          {row.vehicle}
                        </RouterLink>
                      </StyledLink>
                    ) : (
                      row.vehicle
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {vehicleStatusLink ? (
                      <StyledLink>
                        <RouterLink to={vehicleStatusLink}>
                          {row.vehicleId}
                        </RouterLink>
                      </StyledLink>
                    ) : (
                      row.vehicleId
                    )}
                  </TableCell>
                  <TableCell align="center">{row.trailer}</TableCell>
                  <TableCell align="center">{row.trailerId}</TableCell>
                  <TableCell align="center">{row.alerts}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={pageData.totalElements}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
