import { authApiClient } from "../../app/api/authApi.client";
import {
  VehicleDto,
  CreateEditVehicleDto,
  MeasurementDto,
  PagesDto,
  PageInfoDto,
  HistoryDto,
} from "./vehicles.api.dto";

export const fetchAllVehicles = async (
  pageInfo?: PageInfoDto,
  search?: string
): Promise<VehicleDto[]> => {
  try {
    const response = await authApiClient.get(
      `/data/vehicles/${
        pageInfo
          ? `${pageInfo.page}/${pageInfo.rowsPerPage}/${search ? search : ""}`
          : ""
      }`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllVehiclesCsv = async (): Promise<string> => {
  try {
    const response = await authApiClient.get("/data/download/vehicles");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllVehiclesStats = async (): Promise<VehicleDto[]> => {
  try {
    const response = await authApiClient.get("/stats/vehicles");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicle = async (
  vehicleId: string | undefined,
  tenantId: string | undefined
): Promise<VehicleDto> => {
  try {
    const response = await authApiClient.get(`/data/vehicle/${vehicleId}`, {
      tenantId,
    });
    return response.data.data[0];
  } catch (error) {
    throw error;
  }
};

export const fetchAllVehiclesCurrentStats = async (): Promise<VehicleDto[]> => {
  try {
    const response = await authApiClient.get("/stats/current/allvehicles");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleCurrentStats = async (
  vehicleId: string,
  tenantId: string | undefined
): Promise<MeasurementDto> => {
  try {
    const response = await authApiClient.get(
      `/stats/current/vehicle/${vehicleId}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleHistoricalStats = async (
  vehicleId: string,
  tenantId: string | undefined
): Promise<MeasurementDto> => {
  try {
    const response = await authApiClient.get(
      `/stats/historical/vehicle/${vehicleId}`,
      {
        tenantId,
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const editVehicle = async (
  vehicleId: string,
  tenantId: string | undefined,
  data: CreateEditVehicleDto
): Promise<string> => {
  try {
    const response = await authApiClient.put(
      `/data/vehicle/${vehicleId}`,
      data,
      {
        tenantId,
      }
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehiclesPages = async (
  search?: string
): Promise<PagesDto> => {
  try {
    const response = await authApiClient.get(
      `/data/pages/vehicles/${search ? search : ""}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPressureHistory = async (
  vehicleId: string,
  tenantId: string | undefined,
  dateFrom: string,
  dateTo: string
): Promise<HistoryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/vehicle/pressure/${vehicleId}/${dateFrom}/${dateTo}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTemperatureHistory = async (
  vehicleId: string,
  tenantId: string | undefined,
  dateFrom: string,
  dateTo: string
): Promise<HistoryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/vehicle/temperature/${vehicleId}/${dateFrom}/${dateTo}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
