import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import { borderRadius } from "../../../app/theme/shape";
import { ASPHALT, ASPHALT_LIGHT } from "../../../app/theme/palette";

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  fontSize: theme.typography.h2.fontSize,
  backgroundColor: ASPHALT,
}));

export const DropzoneContainer = styled("div")({
  borderWidth: 1,
  borderStyle: "dashed",
  borderColor: "transparent",
  borderRadius: borderRadius(1),

  "&.dragging": {
    borderColor: ASPHALT_LIGHT,
  },
});
