import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { isCurrentPath } from "../../utils/is-current-path";
import { logout } from "../../../auth/store/auth.action";
import {
  StyledAppBar,
  StyledToolbar,
  StyledNavLink,
  StyledLogo,
  StyledAvatar,
} from "./TopNav.style";
import { HOME, PROFILE, SETTINGS } from "../../const/routes";

interface ITopNav {
  showLeftNavToggle: boolean;
  onLeftNavToggle: () => void;
}

export const TopNav: FC<ITopNav> = ({ showLeftNavToggle, onLeftNavToggle }) => {
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleLogout = () => {
    handleProfileMenuClose();
    dispatch(logout());
  };

  return (
    <StyledAppBar position="relative">
      <StyledToolbar>
        {showLeftNavToggle && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={onLeftNavToggle}
            sx={{ marginRight: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <StyledNavLink to={HOME} sx={{ flexGrow: 1 }}>
          <StyledLogo />
        </StyledNavLink>
        {user && (
          <div>
            <IconButton size="small" edge="end" onClick={handleProfileMenuOpen}>
              <StyledAvatar src={user.image}>
                {user.firstName.charAt(0).toUpperCase()}
                {user.lastName.charAt(0).toUpperCase()}
              </StyledAvatar>
            </IconButton>
            <Menu
              anchorEl={profileMenuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(profileMenuAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem
                key="profile"
                component={NavLink}
                to={PROFILE}
                selected={isCurrentPath(PROFILE)}
              >
                Profile
              </MenuItem>
              <MenuItem
                key="settings"
                component={NavLink}
                to={SETTINGS}
                selected={isCurrentPath(SETTINGS)}
              >
                Settings
              </MenuItem>
              <MenuItem key="logout" onClick={handleLogout}>
                Log out
              </MenuItem>
            </Menu>
          </div>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
};
