import React, { useState, useEffect } from "react";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps,
  AutocompleteValue,
  Checkbox,
  ListItemText,
} from "@mui/material";

export interface IAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  selectAllText?: string;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  selectAllText,
  setFieldValue,
  value: valueProp,
  ...props
}: IAutocomplete<T, Multiple, DisableClearable, FreeSolo>) => {
  const [value, setValue] = useState(
    valueProp as T[] as AutocompleteValue<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >
  );

  const areAllOptionsSelected = () => {
    return Array.isArray(value) && props.options.length === value.length;
  };

  const handleToggleAll = () => {
    if (areAllOptionsSelected()) {
      setValue(
        [] as T[] as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
      );
    } else {
      setFieldValue("terminalExternalIds", [
        ...props.options,
      ] as T[] as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>);
      setValue([...props.options] as T[] as AutocompleteValue<
        T,
        Multiple,
        DisableClearable,
        FreeSolo
      >);
    }
  };

  const ListboxWithSelectAll = React.forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLUListElement>
  >(function ListboxWithSelectAll({ children, ...listboxProps }, ref) {
    return (
      <ul ref={ref} {...listboxProps}>
        <li
          className="MuiAutocomplete-option"
          onClick={() => handleToggleAll()}
        >
          <Checkbox
            checked={areAllOptionsSelected()}
            onChange={() => handleToggleAll()}
          />
          <ListItemText>{selectAllText}</ListItemText>
        </li>
        {children}
      </ul>
    );
  });

  useEffect(() => {
    setValue(
      valueProp as T[] as AutocompleteValue<
        T,
        Multiple,
        DisableClearable,
        FreeSolo
      >
    );
  }, [valueProp]);

  if (props.multiple && selectAllText) {
    return (
      <MuiAutocomplete
        {...props}
        value={value}
        ListboxComponent={ListboxWithSelectAll}
      />
    );
  }
  return <MuiAutocomplete {...props} value={value} />;
};
