import { Typography } from "@mui/material";
import styled from "styled-components";
import {
  ASPHALT_LIGHTER,
  ASPHALT_LIGHTER_ALT,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
  BLACK,
  WHITE,
} from "../../../app/theme/palette";

export const StyledChartContainer = styled.div`
  justify-content: center;
  align-items: center;
  background-color: ${ASPHALT_LIGHTER_ALT};
  min-width: 350px;
  width: 100%;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const StyledChartTypography = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  margin-left: 24px;
  padding-top: 24px;
`;

export const StyledTire = styled.div<{ alert: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 20.62px;
  background-color: ${({ alert }) =>
    alert === "green"
      ? TRUCKING_GREEN
      : alert === "red"
      ? SCHOOL_BUS_RED
      : alert === "orange"
      ? CAUTIONARY_ORANGE
      : ASPHALT_LIGHTER};
  margin: 5px;
  margin-right: 15px;
  margin-bottom: 30px;
`;

export const StyledTireText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${WHITE};
`;

export const StyledLegendText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${BLACK};
  margin-bottom: 30px;
  margin-right: 20px;
  white-space: nowrap;
`;
