import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { settingsState } from "./settings.store";
import {
  fetchSilencedNotifications,
  saveSettings,
  subscribeToEmailAlerts,
  unsubscribeFromEmailAlerts,
} from "./settings.actions";

const slice = createSlice({
  name: "settings",
  initialState: settingsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSilencedNotifications.pending, (state) => ({
        ...state,
        fetchSilencedNotifications: {
          ...state.fetchSilencedNotifications,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchSilencedNotifications.fulfilled, (state, { payload }) => {
        if (payload || payload === "") {
          return {
            ...state,
            fetchSilencedNotifications: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchSilencedNotifications.rejected, (state) => ({
        ...state,
        fetchSilencedNotifications: {
          ...state.fetchSilencedNotifications,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(saveSettings.pending, (state) => ({
        ...state,
        saveSettings: {
          ...state.saveSettings,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(saveSettings.fulfilled, (state) => ({
        ...state,
        saveSettings: {
          ...state.saveSettings,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(saveSettings.rejected, (state) => ({
        ...state,
        saveSettings: {
          ...state.saveSettings,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(subscribeToEmailAlerts.pending, (state) => ({
        ...state,
        subscribeToEmailAlerts: {
          ...state.subscribeToEmailAlerts,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(subscribeToEmailAlerts.fulfilled, (state) => ({
        ...state,
        subscribeToEmailAlerts: {
          ...state.subscribeToEmailAlerts,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(subscribeToEmailAlerts.rejected, (state) => ({
        ...state,
        subscribeToEmailAlerts: {
          ...state.subscribeToEmailAlerts,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(unsubscribeFromEmailAlerts.pending, (state) => ({
        ...state,
        unsubscribeFromEmailAlerts: {
          ...state.unsubscribeFromEmailAlerts,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(unsubscribeFromEmailAlerts.fulfilled, (state) => ({
        ...state,
        unsubscribeFromEmailAlerts: {
          ...state.unsubscribeFromEmailAlerts,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(unsubscribeFromEmailAlerts.rejected, (state) => ({
        ...state,
        unsubscribeFromEmailAlerts: {
          ...state.unsubscribeFromEmailAlerts,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export default slice.reducer;
