import React, { FC, Fragment, useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { Loader } from "../../app/components/Loader/Loader";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { VehiclesAlertsDto } from "../../summary/api/summary.api.dto";
import { SummaryAlertVehicle } from "../components/SummaryAlertVehicle";
import { SummaryEmailTable } from "../components/SummaryEmailTable";
import { TripDataTable } from "../components/TripDataTable";
import {
  fetchSummaryEmail,
  fetchSummaryEmailPages,
} from "../store/email.actions";
import {
  selectFetchEmailPagesData,
  selectFetchSummaryEmailData,
  selectIsFetchSummaryEmailPending,
} from "../store/email.selector";
import { UserRole } from "../../app/enum/UserRole";
import { VEHICLE_STATUS, VEHICLE_STATUS_TENANT } from "../../app/const/routes";

export const SummaryEmailWebContainer: FC = () => {
  const terminalId = useLocation().pathname.split("/")[2];
  const date = useLocation().pathname.split("/")[3];
  const dispatch = useAppDispatch();
  const pageData = useAppSelector(selectFetchEmailPagesData);
  const terminalData = useAppSelector(selectFetchSummaryEmailData);
  const isSummaryDataPending = useAppSelector(selectIsFetchSummaryEmailPending);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const tenantIds = getTenantIds();
  const handlePageChange = (page: number, rowsPerPage: number) => {
    dispatch(
      fetchSummaryEmail({ terminalId, date, pageInfo: { page, rowsPerPage } })
    );
  };

  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  useEffect(() => {
    dispatch(fetchSummaryEmail({ terminalId, date }));
    dispatch(fetchSummaryEmailPages({ terminalId }));
  }, [dispatch]);

  return !isSummaryDataPending ? (
    <>
      <SummaryEmailTable
        terminalData={terminalData}
        showTenantIds={canSeeTenants}
        handlePageChange={handlePageChange}
        pageData={pageData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
      />
      {terminalData?.vehiclesAlerts.map(
        (vehicle: VehiclesAlertsDto, index: number) => {
          const vehicleStatusLink =
            vehicle.id &&
            (canSeeTenants && vehicle.tenantId
              ? generatePath(VEHICLE_STATUS_TENANT, {
                  vehicleId: vehicle.id,
                  tenantId: vehicle.tenantId,
                })
              : generatePath(VEHICLE_STATUS, {
                  vehicleId: vehicle.id,
                }));
          return (
            <Fragment key={index}>
              <SummaryAlertVehicle
                vehicleData={vehicle}
                vehicleStatusLink={vehicleStatusLink}
              />
              <TripDataTable alerts={vehicle.alerts} />
            </Fragment>
          );
        }
      )}
    </>
  ) : (
    <Loader />
  );
};
