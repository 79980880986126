import React, { FC } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  StyledTire,
  StyledTireText,
  StyledBlockHeaderText,
} from "./SummaryEmail.style";
import { AlertDto } from "../../summary/api/summary.api.dto";
import { MeasurementEvent } from "../../app/enum/Vehicle";
import { MeasurementEventTable } from "../enum/email.enum";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyledTableTire } from "../../vehicles/components/TireTable/TireTable.style";
import { timezoneCode } from "../../app/utils/timezone-code";
import { getTimeZone } from "../../app/utils/get-timezone";
import { BLACK } from "../../app/theme/palette";

dayjs.extend(localizedFormat);

interface ITripDataTable {
  alerts: AlertDto[];
}

export const TripDataTable: FC<ITripDataTable> = ({ alerts }) => {
  const tz = timezoneCode(getTimeZone());

  const sortingType = [
    { type: "temperature_threshold_detection", sortValue: 1 },
    { type: "air_leak", sortValue: 2 },
    { type: "pressure_threshold_detection", sortValue: 3 },
    { type: "pressure_threshold_detection_4", sortValue: 4 },
    { type: "pressure_threshold_detection_3", sortValue: 5 },
    { type: "pressure_threshold_detection_1", sortValue: 6 },
    { type: "pressure_threshold_detection_0", sortValue: 7 },
  ];

  const sortingPressure = [
    { type: "extreme under pressure", sortValue: 1 },
    { type: "extreme over pressure", sortValue: 2 },
    { type: "under pressure", sortValue: 3 },
    { type: "over pressure", sortValue: 4 },
  ];

  const sortedData = [...alerts].sort((a, b) => {
    const typeA = sortingType.filter((c) => {
      return c.type === a.type;
    });
    const typeB = sortingType.filter((d) => {
      return d.type === b.type;
    });
    const opt1 = typeA.length > 0 ? typeA[0].sortValue : 4;
    const opt2 = typeB.length > 0 ? typeB[0].sortValue : 4;

    return opt1 - opt2;
  });

  const pressureSortedData = [...sortedData].sort((a, b) => {
    const sortA = sortingPressure.filter((c) => {
      return c.type === a.tire.pressureStatus;
    });
    const sortB = sortingPressure.filter((d) => {
      return d.type === b.tire.pressureStatus;
    });
    const opt1 = sortA.length > 0 ? sortA[0].sortValue : 4;
    const opt2 = sortB.length > 0 ? sortB[0].sortValue : 4;
    if (
      a.type.startsWith("pressure_threshold_detection") &&
      b.type.startsWith("pressure_threshold_detection")
    ) {
      return opt1 - opt2;
    }
    return 0;
  });

  const rows = pressureSortedData.map((alert: AlertDto) => ({
    trip: alert.tire.pressureStatus,
    date: dayjs(alert.timestampPDT).format("L"),
    time: dayjs(alert.timestampPDT).format(`LTS [${tz}]`),
    axle: alert.tire.axle,
    tire: alert.tire.tireLocation,
    event: alert.type,
    location: {
      lat: alert.gps.lat,
      lon: alert.gps.lon,
    },
    leak: alert.tire.leak,
    tempAlert: alert.tire.temperatureAlert,
    tripID: alert.tripId,
  }));

  if (!rows.length) {
    return (
      <StyledBlockHeaderText style={{ marginBottom: "85px" }}>
        No daily trip data for {dayjs().subtract(1, "day").format("L")}
      </StyledBlockHeaderText>
    );
  }

  return (
    <TableContainer
      component={Paper}
      style={{ overflowX: "auto", marginBottom: "50px" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Trip</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Axle</TableCell>
            <TableCell align="center">Tire</TableCell>
            <TableCell align="center">Event</TableCell>
            <TableCell align="center">Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index: number) => (
            <TableRow key={`${row}_${index}`}>
              <TableCell align="center" component="th" scope="row">
                {row.event === MeasurementEvent.IGNITION_ON ||
                row.event === MeasurementEvent.IGNITION_OFF ? (
                  row.tripID
                ) : row.trip === "extreme under pressure" ? (
                  <StyledTire alert="red">
                    <StyledTireText>EUP</StyledTireText>
                  </StyledTire>
                ) : row.leak ? (
                  <StyledTire alert="red">
                    <StyledTireText>TL</StyledTireText>
                  </StyledTire>
                ) : row.tempAlert ? (
                  <StyledTire alert="red">
                    <StyledTireText>HT</StyledTireText>
                  </StyledTire>
                ) : row.trip === "extreme over pressure" ? (
                  <StyledTire alert="red">
                    <StyledTireText>EOP</StyledTireText>
                  </StyledTire>
                ) : row.trip === "under pressure" ? (
                  <StyledTire alert="orange">
                    <StyledTireText>UP</StyledTireText>
                  </StyledTire>
                ) : row.trip === "over pressure" ? (
                  <StyledTire alert="orange">
                    <StyledTireText>OP</StyledTireText>
                  </StyledTire>
                ) : row.trip === "good" ? (
                  <StyledTableTire alert="green">
                    <StyledTireText>N</StyledTireText>
                  </StyledTableTire>
                ) : (
                  <StyledTableTire alert="error">
                    <StyledTireText>--</StyledTireText>
                  </StyledTableTire>
                )}
              </TableCell>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.time}</TableCell>
              <TableCell align="center">{+row.axle + 1}</TableCell>
              <TableCell align="center">{row.tire}</TableCell>
              <TableCell align="center">
                {MeasurementEventTable[
                  Object.keys(MeasurementEvent).find(
                    (key) =>
                      MeasurementEvent[key as keyof typeof MeasurementEvent] ===
                      row.event
                  ) as keyof typeof MeasurementEventTable
                ] || ""}
              </TableCell>
              <TableCell align="center">
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.location.lat},${row.location.lon}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <LocationOnIcon sx={{ color: BLACK }}></LocationOnIcon>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
