import * as yup from "yup";

export const terminalEditFormValidation = yup.object().shape({
  address: yup.string().optional(),
  city: yup.string().optional(),
  zip: yup.string().optional(),
  country: yup.string().optional(),
  state: yup.string().optional(),
  //additionalEmailList: [],
  latitude: yup
    .number()
    .min(-90)
    .max(90)
    .typeError("you must specify a number")
    .optional(),
  longitude: yup
    .number()
    .min(-180)
    .max(180)
    .typeError("you must specify a number")
    .optional(),
  timeZone: yup.string().required("Timezone is required"),
  tenantId: yup.string().required("Tenant Id is required"),
});

export const terminalAddFormValidation = (showTenantField: boolean) =>
  yup.object().shape({
    id: yup.string().required("ID is required"),
    address: yup.string().optional(),
    city: yup.string().optional(),
    zip: yup.string().optional(),
    country: yup.string().optional(),
    name: yup.string().required("Name is required"),
    state: yup.string().optional(),
    //additionalEmailList: [],
    latitude: yup
      .number()
      .min(-90)
      .max(90)
      .typeError("you must specify a number")
      .optional(),
    longitude: yup
      .number()
      .min(-180)
      .max(180)
      .typeError("you must specify a number")
      .optional(),
    timeZone: yup.string().required("Timezone is required"),
    tenantId: showTenantField
      ? yup.string().required("Tenant Id is required")
      : yup.string().optional(),
  });
