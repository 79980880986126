import React, { FC } from "react";
import { push } from "redux-first-history";
import { generatePath } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { AlertHistoryTable } from "../components/AlertHistoryTable/AlertHistoryTable";
import { UserRole } from "../../app/enum/UserRole";
import { VEHICLE_STATUS, VEHICLE_STATUS_TENANT } from "../../app/const/routes";

interface IAlertHistoryTableContainerProps {
  types: string[];
  tenantId: string | undefined;
  terminalId: string | null;
  vehicleId: string | null;
  trailerId: string | null;
  start: string | null;
  end: string | null;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  sortField: string;
  setSortType: React.Dispatch<React.SetStateAction<number>>;
  sortType: number;
}

export const AlertHistoryTableContainer: FC<
  IAlertHistoryTableContainerProps
> = ({
  types,
  tenantId,
  terminalId,
  vehicleId,
  trailerId,
  start,
  end,
  setSortField,
  sortField,
  setSortType,
  sortType,
}) => {
  const dispatch = useAppDispatch();
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const goToVehicleStatusScreen = (
    vehicleId: string,
    tenantId: string | undefined
  ) =>
    vehicleId &&
    dispatch(
      push(
        canSeeTenants && tenantId
          ? generatePath(VEHICLE_STATUS_TENANT, {
              vehicleId,
              tenantId,
            })
          : generatePath(VEHICLE_STATUS, {
              vehicleId,
            })
      )
    );

  return (
    <>
      <AlertHistoryTable
        vehicleId={vehicleId}
        trailerId={trailerId}
        terminalId={terminalId}
        tenantId={tenantId}
        types={types}
        start={start}
        end={end}
        goToVehicleStatusScreen={goToVehicleStatusScreen}
        showTenantColumn={canSeeTenants}
        setSortField={setSortField}
        sortField={sortField}
        setSortType={setSortType}
        sortType={sortType}
      />
    </>
  );
};
