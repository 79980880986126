import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TrailerDto } from "../api/trailers.api.dto";
import {
  HistoryDto,
  MeasurementDto,
  PagesDto,
} from "../../vehicles/api/vehicles.api.dto";

export interface TrailersState {
  fetchAllTrailers: AsyncState<TrailerDto[]>;
  fetchTrailer: AsyncState<TrailerDto | null>;
  fetchTrailerCurrentStats: AsyncState<MeasurementDto | null>;
  fetchTrailerHistoricalStats: AsyncState<MeasurementDto | null>;
  editTrailer: AsyncState<null>;
  fetchTrailersPages: AsyncState<PagesDto>;
  fetchTrailerPressureHistory: AsyncState<HistoryDto[]>;
  fetchTrailerTemperatureHistory: AsyncState<HistoryDto[]>;
}

export const trailersState: TrailersState = {
  fetchAllTrailers: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchTrailer: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchTrailerCurrentStats: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchTrailerHistoricalStats: {
    status: AsyncStatus.Void,
    data: null,
  },
  editTrailer: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchTrailersPages: {
    status: AsyncStatus.Void,
    data: { pageSize: 0, totalElements: 0, totalPages: 0 },
  },
  fetchTrailerPressureHistory: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchTrailerTemperatureHistory: {
    status: AsyncStatus.Void,
    data: [],
  },
};
