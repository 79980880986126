import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../app/store/notifications/notifications.slice";
import withError from "../../app/utils/with-thunk-error";
import {
  SummaryDto,
  AlertDto,
  PageInfoDto,
} from "../../summary/api/summary.api.dto";
import {
  fetchSummaryEmail as fetchSummaryEmailRequest,
  fetchAlertEmail as fetchAlertEmailRequest,
  editTireAlertEmailBcc as editTireAlertEmailBccRequest,
} from "../api/email.api";
import { PagesDto } from "../../summary/api/summary.api.dto";
import { fetchSummaryEmailPages as fetchSummaryEmailPagesRequest } from "../api/email.api";

export const fetchSummaryEmail = createAsyncThunk(
  "email/fetchSummaryEmail",
  withError(
    async ({
      terminalId,
      date,
      pageInfo,
    }: {
      terminalId: string;
      date: string;
      pageInfo?: PageInfoDto;
    }): Promise<SummaryDto> =>
      await fetchSummaryEmailRequest(terminalId, date, pageInfo)
  )
);

export const fetchAlertEmail = createAsyncThunk(
  "email/fetchAlertEmail",
  withError(
    async ({ token }: { token: string }): Promise<AlertDto> =>
      await fetchAlertEmailRequest(token)
  )
);

export const editTireAlertEmailBcc = createAsyncThunk(
  "email/editTireAlertEmailBcc",
  withError(
    async (
      {
        vehicleId,
        tenantId,
        emails,
      }: { vehicleId: string; tenantId: string | undefined; emails: string[] },
      { dispatch }
    ): Promise<void> => {
      try {
        await editTireAlertEmailBccRequest(vehicleId, tenantId, emails);
        dispatch(
          openSuccessNotification("Email recipients successfully changed")
        );
      } catch (err) {
        dispatch(openErrorNotification("Couldn't edit email recipients"));
        throw err;
      }
    }
  )
);

export const fetchSummaryEmailPages = createAsyncThunk(
  "alerts/fetchSummaryEmailPages",
  withError(
    async ({
      terminalId,
      tenantId,
    }: {
      terminalId: string | null;
      tenantId?: string | undefined;
    }): Promise<PagesDto> =>
      await fetchSummaryEmailPagesRequest(terminalId || "*", tenantId, 5)
  )
);
