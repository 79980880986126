import { authApiClient } from "../../app/api/authApi.client";
import { CreateEditUserDto, PublicUserDto } from "./users.api.dto";

export const fetchAllUsers = async (): Promise<PublicUserDto[]> => {
  try {
    const response = await authApiClient.get("/user/all");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUser = async (username: string): Promise<PublicUserDto> => {
  try {
    const response = await authApiClient.get(`/user/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (
  userModel: CreateEditUserDto
): Promise<void> => {
  try {
    const response = await authApiClient.post("/auth/register", userModel);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (
  username: string,
  userModel: CreateEditUserDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(`/user/${username}`, userModel);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deactivateUser = async (username: string): Promise<void> => {
  try {
    const response = await authApiClient.put(`/user/deactivate/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateUser = async (username: string): Promise<void> => {
  try {
    const response = await authApiClient.put(`/user/activate/${username}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
