import { configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import auth from "../../auth/store/auth.slice";
import profile from "../../profile/store/profile.slice";
import users from "../../users/store/users.slice";
import notifications from "./notifications/notifications.slice";
import terminals from "../../terminals/store/terminals.slice";
import vehicles from "../../vehicles/store/vehicles.slice";
import tenants from "../../tenants/store/tenants.slice";
import trailers from "../../trailers/store/trailers.slice";
import summary from "../../summary/store/summary.slice";
import email from "../../email/store/email.slice";
import map from "../../map/store/gpsmap.slice";
import alerts from "../../alertHistory/store/alertHistory.slice";
import webAnalytics from "../../webAnalytics/store/webanalytics.slice";
import settings from "../../settings/store/settings.slice";
import unsubscribe from "../../unsubscribe/store/unsubscribe.slice";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: {
    router: routerReducer,
    auth,
    profile,
    users,
    notifications,
    terminals,
    vehicles,
    tenants,
    trailers,
    summary,
    settings,
    email,
    map,
    webAnalytics,
    alerts,
    unsubscribe,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false }).concat(routerMiddleware),
});

export const history = createReduxHistory(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
