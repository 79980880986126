import React, { FC } from "react";
import { Outlet, Navigate, generatePath, useParams } from "react-router-dom";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";

export interface INonTenantRoute {
  fallbackRoute: string;
  element?: React.ReactElement | null;
}

export const NonTenantRoute: FC<INonTenantRoute> = ({
  fallbackRoute,
  element,
  children,
}) => {
  const currentParams = useParams();
  const tenantIds = getTenantIds();

  if (tenantIds.length >= 1) {
    return (
      <Navigate
        to={generatePath(fallbackRoute, {
          ...currentParams,
          tenantId: tenantIds[0],
        })}
        replace
      />
    );
  }

  if (element) {
    return <>{element}</>;
  }

  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
};
