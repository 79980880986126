import { Typography } from "@mui/material";
import styled from "styled-components";
import "@fontsource/roboto";
import { ASPHALT_LIGHT, BLACK } from "../../../app/theme/palette";

export const StyledContainer = styled.div<{ container: string }>`
  display: block;
  height: auto;
  width: 615px;
  margin-bottom: 12px !important;
  margin: auto;
  background-color: ${ASPHALT_LIGHT};
  padding: 36px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
`;

export const StyledColumn = styled.div`
  display: grid;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
`;

export const StyledRow = styled.div`
  width: 651px;
  display: inline-flex;
  margin-bottom: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
`;

export const StyledHeaderText = styled(Typography)`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  color: ${BLACK} !important;
  display: inline-block;
  margin-bottom: 12px !important;
  white-space: nowrap;
  font-weight: 800 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
`;

export const StyledSubText = styled(Typography)`
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  color: ${BLACK} !important;
  display: inline-block;
  margin-right: 4px !important;
  white-space: nowrap !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
`;

export const StyledText = styled(Typography)`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  color: ${BLACK} !important;
  display: inline-block;
  margin-right: 120px !important;
  white-space: nowrap !important;
`;

export const StyledLegendText = styled(Typography)`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10px !important;
  line-height: 9px !important;
  letter-spacing: 0.02em !important;
  color: ${BLACK};
`;

export const StyledTextContainer = styled.div`
  width: 217px;
  display: inline-flex;
  text-align: center !important;
`;

export const StyledAlertTextContainer = styled.div`
  width: 317px;
  display: inline-flex;
`;

export const StyledGpsContainer = styled.div`
  display: block;
  width: 100%;
`;
