import * as Yup from "yup";

export const tenantAddFormValidation = Yup.object().shape({
  id: Yup.string()
    .min(3)
    .matches(
      /^[\w_]{3,}$/,
      "ID must contain at least 3 characters, only alphabetic or underscore"
    )
    .required(),
});
