import { createAsyncThunk } from "@reduxjs/toolkit";
import { push, replace } from "redux-first-history";
import withError from "../../app/utils/with-thunk-error";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import {
  login as loginRequest,
  logout as logoutRequest,
  recoverPassword as recoverPasswordRequest,
  resetPassword as resetPasswordRequest,
  fetchProfile as fetchProfileRequest,
} from "../api/auth.api";
import {
  LoginDto,
  ResetPasswordDto,
  RecoverPasswordDto,
} from "../api/auth.api.dto";
import { ProfileModel } from "../model/auth.model";
import { mapProfileDtoToModel } from "../api/auth.api.mapper";
import {
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "../../app/const/routes";
import {
  openErrorNotification,
  openInfoNotification,
} from "../../app/store/notifications/notifications.slice";
import { UserRole } from "../../app/enum/UserRole";
import { TimeZones } from "../../users/enum/timezones";

export const login = createAsyncThunk(
  "auth/login",
  withError(
    async (
      { credentials, redirect }: { credentials: LoginDto; redirect?: string },
      { dispatch }
    ): Promise<string> => {
      const user = await loginRequest(credentials);
      const { home } = mapProfileDtoToModel(user);
      localStorage.setItem("token", user.token);
      localStorage.setItem("unitSystem", user.unitSystem || "imperial");
      localStorage.setItem("timezone", user.timeZone || TimeZones.US_EASTERN);
      if (user.tenantIds) {
        localStorage.setItem("tenantIds", JSON.stringify(user.tenantIds));
      }
      if (user.role === UserRole.Driver && !user.vehicle?.alternateId) {
        dispatch(openInfoNotification("You don't have a vehicle assigned"));
      }
      return dispatch(replace(redirect || home));
    }
  )
);

export const logout = createAsyncThunk(
  "auth/logout",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withError(
    async (payload: undefined, { rejectWithValue }): Promise<boolean> => {
      try {
        const response = await logoutRequest();
        const tenantIds = getTenantIds();
        localStorage.clear();
        if (tenantIds.length === 1) {
          localStorage.setItem("tenantIds", JSON.stringify(tenantIds));
        }
        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exception: any) {
        return rejectWithValue(exception.response.data);
      }
    }
  )
);

export const authorize = createAsyncThunk(
  "auth/authorize",
  withError(
    async (
      quiet: boolean | undefined,
      { dispatch, rejectWithValue }
    ): Promise<ProfileModel> => {
      try {
        return await fetchProfileRequest();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exception: any) {
        if (!quiet) {
          dispatch(openErrorNotification("Forbidden resource"));
        }
        return rejectWithValue(exception.response.data);
      }
    }
  )
);

export const recoverPassword = createAsyncThunk(
  "auth/recoverPassword",
  withError(
    async (
      recoverPasswordData: RecoverPasswordDto,
      { dispatch }
    ): Promise<void> => {
      await recoverPasswordRequest(recoverPasswordData);
      return dispatch(push(FORGOT_PASSWORD_SUCCESS));
    }
  )
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  withError(
    async (
      resetPasswordData: ResetPasswordDto,
      { dispatch }
    ): Promise<void> => {
      try {
        await resetPasswordRequest(resetPasswordData);
        return dispatch(push(RESET_PASSWORD_SUCCESS));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exception: any) {
        return dispatch(push(RESET_PASSWORD_ERROR));
      }
    }
  )
);

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withError(async (payload: undefined, { dispatch }): Promise<ProfileModel> => {
    return fetchProfileRequest();
  })
);
