import { styled } from "@mui/material/styles";
import spacing from "../../../app/theme/spacing";

export const StyledFormSection = styled("div")<{
  noPadding?: boolean;
}>(({ noPadding }) => ({
  ...(!noPadding && {
    paddingTop: spacing(3),
    paddingBottom: spacing(2.5),
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
  }),
}));
