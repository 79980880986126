import styled from "styled-components";
import { FormControl, TextField, Typography } from "@mui/material";
import { SCHOOL_BUS_RED } from "../../../app/theme/palette";

export const StyledFormControl = styled(FormControl)`
  width: 80%;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDisabledTextField = styled(TextField)`
  & input:disabled: {
    color: ${SCHOOL_BUS_RED};
  }
`;

export const StyledSubtext = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 12px;
  margin-left: 4px;
`;
