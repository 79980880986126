import React, { FC } from "react";
import TableSearch from "../../../app/components/Table/TableSearch";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import {
  fetchAllTrailers,
  fetchTrailersPages,
} from "../../store/trailers.actions";

export const TrailerSearchContainer: FC = () => {
  const dispatch = useAppDispatch();

  const onSearch = (value: string) => {
    dispatch(fetchTrailersPages(value));
    dispatch(
      fetchAllTrailers({
        pageInfo: { page: 0, rowsPerPage: 10 },
        search: value,
      })
    );
  };

  return <TableSearch onSearch={onSearch} />;
};
