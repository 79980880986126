import { authApiClient } from "../../app/api/authApi.client";
import { ApiSettingDto } from "./settings.api.dto";

export const fetchAllSettings = async (
  tenantId: string | undefined
): Promise<ApiSettingDto[]> => {
  try {
    const response = await authApiClient.get("/settings", {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSetting = async (
  name: string,
  tenantId: string | undefined
): Promise<ApiSettingDto[]> => {
  try {
    const response = await authApiClient.get(`/settings/${name}`, {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingValue = async (
  name: string,
  tenantId: string | undefined
): Promise<string> => {
  try {
    const data = await fetchSetting(name, tenantId);
    return data[0].value;
  } catch (error) {
    throw error;
  }
};

export const updateSettingValue = async (
  name: string,
  value: string,
  tenantId: string | undefined
): Promise<void> => {
  try {
    const response = await authApiClient.post(
      `/settings/${name}/${value}`,
      undefined,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const subscribeToEmailAlerts = async (): Promise<void> => {
  try {
    const response = await authApiClient.post("/user/email/subscribe");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const unsubscribeFromEmailAlerts = async (): Promise<void> => {
  try {
    const response = await authApiClient.post("/user/email/unsubscribe");
    return response.data;
  } catch (error) {
    throw error;
  }
};
