import React, { FC } from "react";
import { Typography } from "@mui/material";

interface IPageSubtitle {
  title: string;
}

export const PageSubtitle: FC<IPageSubtitle> = ({ title }) => (
  <Typography variant="subtitle2" mt={-2} mb={4}>
    {title}
  </Typography>
);
