import React, { FC } from "react";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { addTenant as addTenantAction } from "../../store/tenants.actions";
import { TenantDto } from "../../api/tenants.api.dto";
import { TenantForm } from "../../components/TenantForm/TenantForm";

export const TenantAddContainer: FC = () => {
  const dispatch = useAppDispatch();
  const onCreateTenant = async (tenantModel: TenantDto): Promise<void> => {
    dispatch(addTenantAction({ tenantModel }));
  };

  return <TenantForm onSubmit={onCreateTenant} />;
};
