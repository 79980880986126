import * as yup from "yup";

export const userEditFormValidation = yup.object().shape({
  password: yup.string().min(8).optional(),
  role: yup.string().required("Role is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().optional(),
  email: yup.string().email("Email is not valid").required("Email is required"),
  phoneNumber: yup.string().optional(),
  terminalExternalIds: yup.array().of(yup.string()).optional(),
  timeZone: yup.string().required("Timezone is required"),
});

export const userAddFormValidation = (showTenantField: boolean) =>
  yup.object().shape({
    username: yup.string().min(8).required(),
    password: yup.string().min(8).required("Password is required"),
    role: yup.string().required("Role is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().optional(),
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is required"),
    phoneNumber: yup.string().optional(),
    terminalExternalIds: yup.array().of(yup.string()).optional(),
    timeZone: yup.string().required("Timezone is required"),
    tenantId: showTenantField
      ? yup.string().required("Tenant Id is required")
      : yup.string().optional(),
  });
