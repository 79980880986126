import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { SettingsState } from "./settings.store";

export const selectSelf = (state: RootState): RootState => state;

export const selectSettingsRootState = createSelector(
  selectSelf,
  (state: RootState): SettingsState => state.settings
);

export const selectFetchSilencedNotificationsState = createSelector(
  selectSettingsRootState,
  (settings): AsyncState<string | null> => settings.fetchSilencedNotifications
);

export const selectFetchSilencedNotificationsData = createSelector(
  selectFetchSilencedNotificationsState,
  (fetchSilencedNotifications): string | null => fetchSilencedNotifications.data
);

export const selectFetchSilencedNotificationsStatus = createSelector(
  selectFetchSilencedNotificationsState,
  (fetchSilencedNotifications): AsyncStatus => fetchSilencedNotifications.status
);

export const selectIsFetchSilencedNotificationsPending = createSelector(
  selectFetchSilencedNotificationsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSaveSettingsState = createSelector(
  selectSettingsRootState,
  (settings): AsyncState<null> => settings.saveSettings
);

export const selectSaveSettingsStatus = createSelector(
  selectSaveSettingsState,
  (saveSettings): AsyncStatus => saveSettings.status
);

export const selectIsSaveSettingsPending = createSelector(
  selectSaveSettingsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSubscribeToEmailAlertsState = createSelector(
  selectSettingsRootState,
  (settings): AsyncState<null> => settings.subscribeToEmailAlerts
);

export const selectSubscribeToEmailAlertsStatus = createSelector(
  selectSubscribeToEmailAlertsState,
  (subscribeToEmailAlerts): AsyncStatus => subscribeToEmailAlerts.status
);

export const selectIsSubscribeToEmailAlertsPending = createSelector(
  selectSubscribeToEmailAlertsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectUnsubscribeFromEmailAlertsState = createSelector(
  selectSettingsRootState,
  (settings): AsyncState<null> => settings.unsubscribeFromEmailAlerts
);

export const selectUnsubscribeFromEmailAlertsStatus = createSelector(
  selectUnsubscribeFromEmailAlertsState,
  (unsubscribeFromEmailAlerts): AsyncStatus => unsubscribeFromEmailAlerts.status
);

export const selectIsUnsubscribeFromEmailAlertsPending = createSelector(
  selectUnsubscribeFromEmailAlertsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
