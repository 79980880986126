import { createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "redux-first-history";
import withError from "../../app/utils/with-thunk-error";
import {
  fetchAllTerminals as fetchAllTerminalsRequest,
  fetchTerminal as fetchTerminalRequest,
  updateTerminal as updateTerminalRequest,
  createTerminal as createTerminalRequest,
} from "../api/terminals.api";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../app/store/notifications/notifications.slice";
import {
  CreateTerminalDto,
  TerminalDto,
  EditTerminalDto,
} from "../api/terminals.api.dto";
import { TERMINALS } from "../../../modules/app/const/routes";

export const fetchAllTerminals = createAsyncThunk(
  "terminals/fetchAllTerminals",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<TerminalDto[]> =>
      await fetchAllTerminalsRequest()
  )
);

export const fetchTerminal = createAsyncThunk(
  "terminals/fetchTerminal",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({
      id,
      tenantId,
    }: {
      id: string;
      tenantId: string | undefined;
    }): Promise<TerminalDto> => await fetchTerminalRequest(id, tenantId)
  )
);

export const addTerminal = createAsyncThunk(
  "terminals/addTerminal",
  withError(
    async (
      {
        terminalModel,
        tenantId,
      }: {
        terminalModel: CreateTerminalDto;
        tenantId: string | undefined;
      },
      { dispatch }
    ): Promise<void> => {
      await createTerminalRequest(
        terminalModel,
        tenantId || terminalModel.tenantId
      );
      dispatch(openSuccessNotification("Terminal created successfully"));
      return dispatch(push(TERMINALS));
    }
  )
);

export const updateTerminal = createAsyncThunk(
  "terminals/updateTerminal",
  withError(
    async (
      {
        terminalId,
        tenantId,
        terminalModel,
      }: {
        terminalId: string;
        tenantId: string | undefined;
        terminalModel: EditTerminalDto;
      },
      { dispatch }
    ): Promise<void> => {
      try {
        await updateTerminalRequest(terminalId, tenantId, terminalModel);
        dispatch(openSuccessNotification("Terminal successfully changed"));
      } catch (err) {
        dispatch(openErrorNotification("Couldn't update Terminal"));
        throw err;
      }
    }
  )
);

export const updateTerminalEmails = createAsyncThunk(
  "terminals/updateTerminal",
  withError(
    async (
      {
        terminalId,
        tenantId,
        terminalModel,
      }: {
        terminalId: string;
        tenantId: string | undefined;
        terminalModel: EditTerminalDto;
      },
      { dispatch }
    ): Promise<void> => {
      try {
        await updateTerminalRequest(terminalId, tenantId, terminalModel);
        dispatch(
          openSuccessNotification("Email recipients successfully changed")
        );
      } catch (err) {
        dispatch(openErrorNotification("Couldn't edit email recipients"));
        throw err;
      }
    }
  )
);
