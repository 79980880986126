import { Typography } from "@mui/material";
import styled from "styled-components";
import { ASPHALT_LIGHTER_ALT, BLACK } from "../../app/theme/palette";

export const StyledLink = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: inherit;
  }
`;

export const StyledChartContainer = styled.div`
  background-color: ${ASPHALT_LIGHTER_ALT};
  min-width: 350px;
  height: 338px;
  margin: 0 15px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
`;

export const StyledChartTypography = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${BLACK};
  margin-left: 22px;
  margin-top: 22px;
`;
