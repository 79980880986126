import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";
import { EditProfileDto } from "../api/profile.api.dto";
import { updateProfile } from "../store/profile.actions";
import { fetchAllTerminals } from "../../terminals/store/terminals.actions";
import { selectIsUpdateProfilePending } from "../store/profile.selector";
import {
  selectFetchAllTerminalsData,
  selectIsFetchAllTerminalsPending,
} from "../../terminals/store/terminals.selector";
import { EditProfileForm } from "../components/EditProfileForm/EditProfileForm";

export const EditProfileFormContainer: FC = () => {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const terminals = useAppSelector(selectFetchAllTerminalsData);
  const isUpdateProfilePending = useAppSelector(selectIsUpdateProfilePending);
  const isFetchAllTerminalsPending = useAppSelector(
    selectIsFetchAllTerminalsPending
  );

  const onUpdateProfile = async (
    profileData: EditProfileDto
  ): Promise<void> => {
    dispatch(updateProfile(profileData));
  };

  useEffect(() => {
    dispatch(fetchAllTerminals());
  }, [dispatch]);

  return (
    <EditProfileForm
      user={user}
      terminals={terminals}
      onSubmit={onUpdateProfile}
      isPending={isUpdateProfilePending || isFetchAllTerminalsPending}
    />
  );
};
