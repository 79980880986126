import React, { FC, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { IconButton } from "@mui/material";
import {
  NotificationsOff as NotificationsOffIcon,
  Notifications as NotificationsIcon,
  LocationOn as LocationOnIcon,
} from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { BlankIcon } from "../../../app/components/BlankIcon/BlankIcon";
import {
  fetchAlerts as fetchAlertsAction,
  fetchAlertsPages as fetchAlertsPagesAction,
  muteNotification as muteNotificationAction,
  unmuteNotification as unmuteNotificationAction,
} from "../../store/alertHistory.actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { selectAlertsFilteredData } from "../../store/alertHistory.selector";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useUnitSystem } from "../../../app/hooks/useUnitSystem";
import {
  selectFetchAlertsPagesData,
  selectIsFetchAlertsPending,
} from "../../store/alertHistory.selector";
import { MeasurementEvent } from "../../../app/enum/Vehicle";
import { MeasurementEventTable } from "../../enum/alertHistory.enum";
import { AlertHistoryDto } from "../../api/alertHistory.api.dto";
import {
  getTemperatureValue,
  getTemperatureUnit,
} from "../../../app/utils/get-temperature-value";
import {
  getPressureValue,
  getPressureUnit,
} from "../../../app/utils/get-pressure-value";
import { timezoneCode } from "../../../app/utils/timezone-code";
import { getTimeZone } from "../../../app/utils/get-timezone";
import { ALERT_WEB_VIEW } from "../../../app/const/routes";
import { SIGNAL_LOSS, SYSTEM_ERROR } from "../../../app/const/errors";
import { StyledLink } from "../../../summary/components/Summary.style";

interface IAlertHistoryTableProps {
  types: string[];
  tenantId: string | undefined;
  terminalId: string | null;
  vehicleId: string | null;
  trailerId: string | null;
  start: string | null;
  end: string | null;
  goToVehicleStatusScreen: (
    vehicleId: string,
    tenantId: string | undefined
  ) => void;
  showTenantColumn?: boolean;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  sortField: string;
  setSortType: React.Dispatch<React.SetStateAction<number>>;
  sortType: number;
}

export const AlertHistoryTable: FC<IAlertHistoryTableProps> = ({
  types,
  tenantId,
  terminalId,
  vehicleId,
  trailerId,
  start,
  end,
  goToVehicleStatusScreen,
  showTenantColumn,
  setSortField,
  sortField,
  setSortType,
  sortType,
}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const alerts = useAppSelector(selectAlertsFilteredData);
  const pageData = useAppSelector(selectFetchAlertsPagesData);
  const isFetchAlertsPending = useAppSelector(selectIsFetchAlertsPending);
  const unitSystem = useUnitSystem();
  const temperatureUnit = getTemperatureUnit(unitSystem);
  const pressureUnit = getPressureUnit(unitSystem);

  const handleNotificationMute = (alertData: AlertHistoryDto) =>
    alertData.vehicle.alternateId &&
    dispatch(
      muteNotificationAction({
        alertData,
        vehicleId,
        trailerId,
        terminalId,
        tenantId,
        types,
        start,
        end,
        sortField,
        sortType,
        page,
        pageSize,
      })
    );

  const handleNotificationUnmute = (alertData: AlertHistoryDto) =>
    alertData.vehicle.alternateId &&
    dispatch(
      unmuteNotificationAction({
        alertData,
        vehicleId,
        trailerId,
        terminalId,
        tenantId,
        types,
        start,
        end,
        sortField,
        sortType,
        page,
        pageSize,
      })
    );

  const handleRowPress = (vehicleId: string, tenantId: string | undefined) =>
    goToVehicleStatusScreen(vehicleId, tenantId);

  const columns: GridColDef<AlertHistoryDto>[] = [
    {
      field: "type",
      headerName: "Type",
      minWidth: 195,
      flex: 1,
      filterable: false,
      sortable: true,
      headerClassName: "heading",
      valueGetter: (params) => {
        const key = Object.keys(MeasurementEvent).find(
          (key) =>
            MeasurementEvent[key as keyof typeof MeasurementEvent] ===
            params.row.type
        );
        return (
          MeasurementEventTable[key as keyof typeof MeasurementEventTable] ||
          null
        );
      },
      renderCell: (params) => params.value ?? "",
    },
    {
      field: "value",
      headerName: "Value",
      sortable: false,
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      valueGetter: (params) => {
        const type = params.row.type;
        if (
          type.startsWith(MeasurementEvent.PRESSURE_THRESHOLD) ||
          type === MeasurementEvent.AIR_LEAK
        ) {
          const pressure =
            getPressureValue(params.row.tires[0], unitSystem) || 0;
          return [SIGNAL_LOSS, SYSTEM_ERROR].includes(pressure + "")
            ? pressure
            : `${pressure} ${pressureUnit}`;
        } else if (type === MeasurementEvent.TEMPERATURE_THRESHOLD) {
          const temperature =
            getTemperatureValue(params.row.tires[0], unitSystem) || 0;
          return [SIGNAL_LOSS, SYSTEM_ERROR].includes(temperature + "")
            ? temperature
            : `${temperature} ${temperatureUnit}`;
        } else if (type === MeasurementEvent.IGNITION_ON) {
          return "On";
        } else if (type === MeasurementEvent.IGNITION_OFF) {
          return "Off";
        } else if (type === MeasurementEvent.TRAILER) {
          return typeof params.row.trailer !== "undefined"
            ? "Attached"
            : "Detached";
        }
        return null;
      },
      renderCell: (params) => params.value ?? "",
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      minWidth: 90,
      flex: 1,
      filterable: false,
      sortable: false,
      headerClassName: "heading",
      valueGetter: (params) =>
        params.row.vehicle?.name || params.row.vehicle?.alternateId || null,
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <p
              onClick={() =>
                params.row.vehicle.alternateId &&
                handleRowPress(
                  params.row.vehicle.alternateId,
                  params.row.vehicle.tenantId
                )
              }
              style={{ cursor: "pointer" }}
            >
              {params.value}
            </p>
          );
        }
        return "N/A";
      },
    },
    {
      field: "trailer",
      headerName: "Trailer",
      minWidth: 150,
      flex: 1,
      filterable: false,
      sortable: false,
      headerClassName: "heading",
      valueGetter: (params) => params.row.trailer?.id || null,
      renderCell: (params) => {
        if (params.value !== null) {
          return (
            <p
              onClick={() =>
                params.row.vehicle.alternateId &&
                handleRowPress(
                  params.row.vehicle.alternateId,
                  params.row.vehicle.tenantId
                )
              }
              style={{ cursor: "pointer" }}
            >
              {params.value}
            </p>
          );
        }
        return "N/A";
      },
    },
    {
      field: "axle",
      headerName: "Axle",
      minWidth: 70,
      flex: 1,
      filterable: false,
      sortable: false,
      headerClassName: "heading",
      valueGetter: (params) => {
        if (
          typeof params.row.tires[0]?.axle !== "undefined" &&
          params.row.type !== MeasurementEvent.IGNITION_OFF &&
          params.row.type !== MeasurementEvent.IGNITION_ON
        ) {
          return params.row.tires[0]?.axle + 1;
        }
        return null;
      },
      renderCell: (params) => params.value ?? "N/A",
    },
    {
      field: "tire",
      headerName: "Tire",
      minWidth: 80,
      flex: 1,
      filterable: false,
      sortable: false,
      headerClassName: "heading",
      valueGetter: (params) => {
        if (
          typeof params.row.tires[0]?.tire !== "undefined" &&
          params.row.type !== MeasurementEvent.IGNITION_OFF &&
          params.row.type !== MeasurementEvent.IGNITION_ON
        ) {
          return params.row.tires[0]?.tireLocation;
        }
        return null;
      },
      renderCell: (params) => params.value ?? "N/A",
    },
    ...(showTenantColumn
      ? [
          {
            field: "tenant",
            headerName: "Tenant",
            minWidth: 150,
            flex: 1,
            filterable: false,
            headerClassName: "heading",
            valueGetter: (params) => params.row.vehicle?.tenantId || null,
            renderCell: (params) => params.value ?? "N/A",
          } as GridColDef<AlertHistoryDto>,
        ]
      : []),
    {
      field: "timestamp",
      headerName: "Timestamp",
      minWidth: 190,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => {
        const now = new Date();
        const tz = timezoneCode(getTimeZone());
        const formattedDate = dayjs(params.row.timestamp || now).format(
          `MM/DD/YYYY [at] HH:mm:ss [${tz}]`
        );
        return (
          <StyledLink>
            {params.row.token !== undefined ? (
              <RouterLink
                to={`${ALERT_WEB_VIEW.replace(":token", params.row.token)}`}
              >
                {formattedDate}
              </RouterLink>
            ) : (
              <div>{formattedDate}</div>
            )}
          </StyledLink>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      align: "right",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              title="Check location on the map"
              aria-label="Check location on the map"
              size="small"
              href={`https://www.google.com/maps/search/?api=1&query=${params.row.gps.lat},${params.row.gps.lon}`}
              target="_blank"
              rel="noreferrer"
            >
              <LocationOnIcon />
            </IconButton>
            {typeof params.row.alertMuted !== "undefined" ? (
              !params.row.alertMuted ? (
                <IconButton
                  title="Mute this alert"
                  aria-label="Mute this alert"
                  size="small"
                  color="info"
                  onClick={() => handleNotificationMute(params.row)}
                >
                  <NotificationsIcon />
                </IconButton>
              ) : (
                <IconButton
                  title="Unmute this alert"
                  aria-label="Unmute this alert"
                  size="small"
                  color="error"
                  onClick={() => handleNotificationUnmute(params.row)}
                >
                  <NotificationsOffIcon />
                </IconButton>
              )
            ) : (
              <IconButton size="small" disabled>
                <BlankIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      fetchAlertsPagesAction({
        vehicleId,
        trailerId,
        terminalId,
        tenantId,
        types,
        start,
        end,
      })
    );
  }, [vehicleId, trailerId, terminalId, tenantId, types, start, end]);

  useEffect(() => {
    if (types.length > 0) {
      dispatch(
        fetchAlertsAction({
          vehicleId,
          trailerId,
          terminalId,
          tenantId,
          types,
          start,
          end,
          pageSize,
          page,
          sortField,
          sortType,
        })
      );
    }
  }, [
    vehicleId,
    trailerId,
    terminalId,
    tenantId,
    types,
    start,
    end,
    pageSize,
    page,
    sortField,
    sortType,
  ]);

  return (
    <DataGrid
      columns={columns}
      rows={alerts}
      paginationMode="server"
      pageSize={pageSize}
      rowCount={pageData.totalElements}
      rowsPerPageOptions={[25, 50, 100]}
      onPageChange={(page) => setPage(page)}
      onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      onSortModelChange={(model) => {
        if (typeof model[0] !== "undefined") {
          setSortField(model[0].field);
          setSortType(model[0].sort === "desc" ? -1 : 1);
        }
      }}
      pagination
      getRowId={() => uuidv4()}
      loading={isFetchAlertsPending}
      hideFooterPagination={isFetchAlertsPending}
      componentsProps={{
        pagination: {
          showFirstButton: true,
          showLastButton: true,
        },
      }}
    />
  );
};
