import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import {
  fetchWebAnalyticsConsumeCount as fetchWebAnalyticsConsumeRequest,
  fetchWebAnalyticsIngestCount as fetchWebAnalyticsIngestRequest,
  fetchWebAnalyticsQueueSize as fetchWebAnalyticsQueueSizeRequest,
} from "../api/webanalytics.api";

export const fetchWebAnalyticsConsumeCount = createAsyncThunk(
  "web/analytics/consume",
  withError(
    async ({ date }: { date: string }): Promise<[]> =>
      await fetchWebAnalyticsConsumeRequest(date)
  )
);

export const fetchWebAnalyticsIngestCount = createAsyncThunk(
  "web/analytics/ingest",
  withError(
    async ({ date }: { date: string }): Promise<[]> =>
      await fetchWebAnalyticsIngestRequest(date)
  )
);

export const fetchWebAnalyticsQueueSize = createAsyncThunk(
  "web/analytics/queue",
  withError(
    async ({ date }: { date: string }): Promise<[]> =>
      await fetchWebAnalyticsQueueSizeRequest(date)
  )
);
