import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AlertHistoryState } from "./alertHistory.store";
import { AlertHistoryDto } from "../api/alertHistory.api.dto";
import { PagesDto } from "../../summary/api/summary.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectAlertsRootState = createSelector(
  selectSelf,
  (state: RootState): AlertHistoryState => state.alerts
);

export const selectFetchAllAlertsState = createSelector(
  selectAlertsRootState,
  (alerts): AsyncState<AlertHistoryDto[]> => alerts.fetchAllAlerts
);

export const selectFetchAllAlertsData = createSelector(
  selectFetchAllAlertsState,
  (alerts): AlertHistoryDto[] => alerts.data
);

export const selectFetchAllAlertsStatus = createSelector(
  selectFetchAllAlertsState,
  (alerts): AsyncStatus => alerts.status
);

export const selectFetchAlertsState = createSelector(
  selectAlertsRootState,
  (alerts): AsyncState<AlertHistoryDto[]> => alerts.fetchAlerts
);

export const selectFetchAlertsData = createSelector(
  selectFetchAlertsState,
  (alerts): AlertHistoryDto[] => alerts.data
);

export const selectSearchAlerts = createSelector(
  selectAlertsRootState,
  (alerts): string => alerts.searchAlerts
);

export const selectAlertsFilteredData = createSelector(
  selectSearchAlerts,
  selectFetchAlertsData,
  (searchValue, alerts): AlertHistoryDto[] => {
    const data = alerts.map((alert) => alert);

    if (searchValue) {
      return data.filter(
        (alert) =>
          alert.type.toLowerCase().includes(searchValue.toLowerCase()) ||
          (alert.vehicle?.name &&
            alert.vehicle?.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (typeof alert.trailer?.name !== "undefined" &&
            alert.trailer?.name
              .toLowerCase()
              .includes(searchValue.toLowerCase()))
      );
    }

    return data;
  }
);

export const selectFetchAlertsStatus = createSelector(
  selectFetchAlertsState,
  (alerts): AsyncStatus => alerts.status
);

export const selectIsFetchAlertsPending = createSelector(
  selectFetchAlertsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAlertsPagesState = createSelector(
  selectAlertsRootState,
  (alerts): AsyncState<PagesDto> => {
    return alerts.fetchAlertsPages;
  }
);

export const selectFetchAlertsPagesData = createSelector(
  selectFetchAlertsPagesState,
  (alertsPages): PagesDto => alertsPages.data
);
