import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import {
  VehicleDto,
  CreateEditVehicleDto,
  MeasurementDto,
  PagesDto,
  PageInfoDto,
  HistoryDto,
} from "../api/vehicles.api.dto";
import {
  fetchAllVehicles as fetchAllVehiclesRequest,
  fetchAllVehiclesStats as fetchAllVehiclesStatsRequest,
  fetchVehicle as fetchVehicleRequest,
  fetchVehicleCurrentStats as fetchVehicleCurrentStatsRequest,
  fetchVehicleHistoricalStats as fetchVehicleHistoricalStatsRequest,
  editVehicle as editVehicleRequest,
  fetchVehiclesPages as fetchVehiclesPagesRequest,
  fetchAllVehiclesCurrentStats as fetchAllVehiclesCurrentStatsRequest,
  fetchPressureHistory as fetchPressureHistoryRequest,
  fetchTemperatureHistory as fetchTemperatureHistoryRequest,
} from "../api/vehicles.api";

export const fetchAllVehicles = createAsyncThunk(
  "vehicles/fetchAllVehicles",
  withError(
    async (args?: {
      pageInfo: PageInfoDto;
      search?: string;
    }): Promise<VehicleDto[]> =>
      await fetchAllVehiclesRequest(args?.pageInfo, args?.search)
  )
);

export const fetchAllVehiclesCurrentStats = createAsyncThunk(
  "vehicles/fetchAllVehiclesCurrentStats",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<VehicleDto[]> =>
      await fetchAllVehiclesCurrentStatsRequest()
  )
);

export const fetchAllVehiclesStats = createAsyncThunk(
  "vehicles/fetchAllVehiclesStats",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<VehicleDto[]> =>
      await fetchAllVehiclesStatsRequest()
  )
);

export const fetchVehicle = createAsyncThunk(
  "vehicles/fetchVehicle",
  withError(
    async ({
      vehicleId,
      tenantId,
    }: {
      vehicleId: string;
      tenantId: string | undefined;
    }): Promise<VehicleDto> => await fetchVehicleRequest(vehicleId, tenantId)
  )
);

export const fetchVehicleCurrentStats = createAsyncThunk(
  "vehicles/fetchVehicleCurrentStats",
  withError(
    async ({
      vehicleId,
      tenantId,
    }: {
      vehicleId: string;
      tenantId: string | undefined;
    }): Promise<MeasurementDto> =>
      await fetchVehicleCurrentStatsRequest(vehicleId, tenantId)
  )
);

export const fetchVehicleHistoricalStats = createAsyncThunk(
  "vehicles/fetchVehicleHistoricalStats",
  withError(
    async ({
      vehicleId,
      tenantId,
    }: {
      vehicleId: string;
      tenantId: string | undefined;
    }): Promise<MeasurementDto> =>
      await fetchVehicleHistoricalStatsRequest(vehicleId, tenantId)
  )
);

export const editVehicle = createAsyncThunk(
  "vehicles/editVehicle",
  withError(
    async (
      {
        vehicleId,
        tenantId,
        data,
      }: {
        vehicleId: string;
        tenantId: string | undefined;
        data: CreateEditVehicleDto;
      },
      { dispatch }
    ): Promise<void> => {
      await editVehicleRequest(vehicleId, tenantId, data);
      dispatch(openSuccessNotification("Vehicle has been updated"));
      dispatch(
        fetchAllVehicles({
          pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
        })
      );
    }
  )
);

export const fetchVehiclesPages = createAsyncThunk(
  "vehicles/fetchAlertsPages",
  withError(
    async (search?: string): Promise<PagesDto> =>
      await fetchVehiclesPagesRequest(search)
  )
);

export const fetchPressureHistory = createAsyncThunk(
  "vehicles/fetchPressureHistory",
  withError(
    async ({
      vehicleId,
      tenantId,
      dateFrom,
      dateTo,
    }: {
      vehicleId: string;
      tenantId: string | undefined;
      dateFrom: string;
      dateTo: string;
    }): Promise<HistoryDto[]> =>
      await fetchPressureHistoryRequest(vehicleId, tenantId, dateFrom, dateTo)
  )
);

export const fetchTemperatureHistory = createAsyncThunk(
  "vehicles/fetchTemperatureHistory",
  withError(
    async ({
      vehicleId,
      tenantId,
      dateFrom,
      dateTo,
    }: {
      vehicleId: string;
      tenantId: string | undefined;
      dateFrom: string;
      dateTo: string;
    }): Promise<HistoryDto[]> =>
      await fetchTemperatureHistoryRequest(
        vehicleId,
        tenantId,
        dateFrom,
        dateTo
      )
  )
);
