import React, { FC } from "react";
import { debounce } from "lodash";

import TableSearch from "../../app/components/Table/TableSearch";
import { TerminalDto } from "../api/terminals.api.dto";

interface ITerminalsSearchContainer {
  terminalsTableData: TerminalDto[];
  setFilteredData: React.Dispatch<React.SetStateAction<TerminalDto[]>>;
  canSeeTenants: boolean;
}

export const TerminalsSearchContainer: FC<ITerminalsSearchContainer> = ({
  terminalsTableData,
  setFilteredData,
  canSeeTenants,
}) => {
  const onSearch = (value: string) => {
    const data = terminalsTableData.map((terminal) => terminal);
    const filteredData = data.filter(
      (terminal) =>
        terminal.name.toLowerCase().includes(value.toLowerCase()) ||
        terminal.address.toLowerCase().includes(value.toLowerCase()) ||
        terminal.city.toLowerCase().includes(value.toLowerCase()) ||
        terminal.state.toLowerCase().includes(value.toLowerCase()) ||
        terminal.timeZone.toLowerCase().includes(value.toLowerCase()) ||
        (canSeeTenants &&
          terminal.tenantId?.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filteredData);
  };

  return <TableSearch onSearch={debounce(onSearch, 200)} />;
};
