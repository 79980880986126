export enum PressureStatus {
  EOP = "extreme over pressure",
  EUP = "extreme under pressure",
  OP = "over pressure",
  UP = "under pressure",
  GOOD = "good",
}

export enum MeasurementEvent {
  PERIODIC = "periodic",
  AIR_LEAK = "air_leak",
  PRESSURE_THRESHOLD = "pressure_threshold_detection",
  PRESSURE_THRESHOLD_0 = "pressure_threshold_detection_0",
  PRESSURE_THRESHOLD_1 = "pressure_threshold_detection_1",
  PRESSURE_THRESHOLD_3 = "pressure_threshold_detection_3",
  PRESSURE_THRESHOLD_4 = "pressure_threshold_detection_4",
  TEMPERATURE_THRESHOLD = "temperature_threshold_detection",
  IGNITION_ON = "ignition_on",
  IGNITION_OFF = "ignition_off",
  CVD_STARTUP = "cvd_startup",
  TRAILER = "trailer_id",
  LOST_SIGNAL = "lost_signal",
}
