import React, { FC } from "react";
import { push } from "redux-first-history";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import {
  Box,
  Stack,
  IconButton,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  TextField,
  TextFieldProps,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import { UserRole } from "../../../app/enum/UserRole";
import { Autocomplete } from "../../../app/components/Autocomplete/Autocomplete";
import { Button } from "../../../app/components/Button/Button";
import {
  userEditFormValidation,
  userAddFormValidation,
} from "./UserForm.validation";
import { PublicUserDto, CreateEditUserDto } from "../../api/users.api.dto";
import { TerminalDto } from "../../../terminals/api/terminals.api.dto";
import { USER } from "../../../app/const/routes";
import { TimeZones } from "../../enum/timezones";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";

export interface IUserFormProps {
  user?: PublicUserDto | null;
  terminals: TerminalDto[];
  showTenantField?: boolean;
  showTerminalField?: boolean;
  onSubmit: (formValues: CreateEditUserDto) => void;
  onDelete?: (username: string) => void;
  onRestore?: (username: string) => void;
  isPending?: boolean;
}

export const UserForm: FC<IUserFormProps> = ({
  user,
  terminals,
  showTenantField,
  showTerminalField,
  onSubmit,
  onDelete,
  onRestore,
  isPending,
}) => {
  const dispatch = useAppDispatch();
  const tenantIds = getTenantIds();

  const getTerminalIdOptions = () =>
    [...terminals].map((terminal) => terminal.externalId) as string[];

  const getTerminalIdOptionLabel = (option: string) => {
    const match = terminals.find((terminal) => terminal.externalId === option);
    if (match) {
      return `${match.name || match.externalId}`;
    }
    return "";
  };

  const loggedInUser = useCurrentUser();
  const userSelf = user?.username && loggedInUser?.username === user?.username;

  const handleCancelEdit = () => {
    dispatch(push(USER));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: user?.username || "",
        password: user ? undefined : "",
        role: user?.role || UserRole.Driver,
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        phoneNumber: user?.phoneNumber || "",
        terminalExternalIds: user?.terminalExternalIds || [],
        timeZone: user?.timeZone || TimeZones.US_EASTERN,
        tenantId: user?.tenantId || "",
      }}
      validationSchema={
        user ? userEditFormValidation : userAddFormValidation(!!showTenantField)
      }
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="username">Username</label>
            <TextField
              id="username"
              name="username"
              variant="filled"
              fullWidth
              value={values.username}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              required
              disabled={isPending || !!user}
            />
          </FormControl>
          {!user && (
            <FormControl>
              <label htmlFor="password">Password</label>
              <TextField
                id="password"
                name="password"
                variant="filled"
                type="password"
                fullWidth
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                required
                disabled={isPending}
              />
            </FormControl>
          )}
          <FormControl>
            <label htmlFor="first-name">First Name</label>
            <TextField
              id="first-name"
              name="firstName"
              variant="filled"
              fullWidth
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              required
              disabled={isPending}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="last-name">Last Name</label>
            <TextField
              id="last-name"
              name="lastName"
              variant="filled"
              fullWidth
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              disabled={isPending}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="email">Email</label>
            <TextField
              id="email"
              name="email"
              variant="filled"
              fullWidth
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              required
              disabled={isPending}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="phone-number">Phone number</label>
            <InputMask
              mask="(999) 999-9999"
              value={values.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isPending}
            >
              {(inputProps: TextFieldProps) => (
                <TextField
                  {...inputProps}
                  id="phone-number"
                  name="phoneNumber"
                  fullWidth
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  disabled={isPending}
                />
              )}
            </InputMask>
          </FormControl>
          <FormControl>
            <label htmlFor="timeZone">Timezone</label>
            <Select
              id="timeZone"
              name="timeZone"
              variant="filled"
              fullWidth
              value={values.timeZone}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isPending}
              required
            >
              {Object.entries(TimeZones).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <label htmlFor="role">Role</label>
            <Select
              id="role"
              name="role"
              variant="filled"
              fullWidth
              value={values.role}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isPending || !!user}
              required
            >
              {Object.entries(UserRole).map(([key, value], index) => (
                <MenuItem
                  key={index}
                  value={value}
                  disabled={
                    value === UserRole.SuperAdmin &&
                    !useShowForRoles([UserRole.SuperAdmin])
                  }
                >
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showTerminalField && (
            <FormControl>
              <label htmlFor="terminal-access">Terminal Access</label>
              <Autocomplete
                id="terminal-access"
                multiple
                disableCloseOnSelect
                disablePortal
                options={getTerminalIdOptions()}
                getOptionLabel={getTerminalIdOptionLabel}
                selectAllText="Select all"
                setFieldValue={setFieldValue}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText>
                      {getTerminalIdOptionLabel(option)}
                    </ListItemText>
                  </li>
                )}
                value={values.terminalExternalIds || []}
                onBlur={handleBlur}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string[] | null
                ) => {
                  setFieldValue("terminalExternalIds", newValue || []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search for a terminal"
                    error={Boolean(
                      touched.terminalExternalIds && errors.terminalExternalIds
                    )}
                    helperText={
                      touched.terminalExternalIds && errors.terminalExternalIds
                    }
                  />
                )}
                disabled={
                  values.role === UserRole.SuperAdmin ||
                  values.role === UserRole.Admin ||
                  values.role === UserRole.Driver ||
                  isPending
                }
              />
            </FormControl>
          )}
          {showTenantField && (
            <FormControl>
              <label htmlFor="tenantId">Tenant</label>
              <Select
                id="tenantId"
                name="tenantId"
                variant="filled"
                fullWidth
                value={values.tenantId}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isPending || !!user}
                required
              >
                {Object.entries(tenantIds).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.tenantId && Boolean(errors.tenantId) && (
                <FormHelperText error>{errors.tenantId}</FormHelperText>
              )}
            </FormControl>
          )}
          <Box
            display="flex"
            justifyContent={user ? "space-between" : "flex-end"}
            alignItems="center"
          >
            {user &&
              !userSelf &&
              (!user.disabledTimestamp ? (
                <IconButton
                  title="Delete user"
                  aria-label="Delete user"
                  size="large"
                  edge="start"
                  color="error"
                  onClick={() => {
                    if (onDelete) {
                      onDelete(values.username);
                    }
                  }}
                  disabled={isPending}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              ) : (
                <IconButton
                  title="Restore user"
                  aria-label="Restore user"
                  size="large"
                  edge="start"
                  color="success"
                  onClick={() => {
                    if (onRestore) {
                      onRestore(values.username);
                    }
                  }}
                  disabled={isPending}
                >
                  <RestoreIcon fontSize="inherit" />
                </IconButton>
              ))}
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="text" onClick={() => handleCancelEdit()}>
                Cancel
              </Button>
              <Button
                type="submit"
                pending={isPending}
                disabled={isPending || !isValid}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};
