import React, { FC } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { UserRole } from "../../../app/enum/UserRole";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { UsersTableContainer } from "../../containers/UsersTableContainer/UsersTableContainer";
import { UsersSearchContainer } from "../../containers/UsersSearchContainer/UsersSearchContainer";
import { UserAddButton } from "../../components/UserAddButton/UserAddButton";

export const UsersView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <PageTitle title="Users" />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <UsersSearchContainer />
              {useShowForRoles([UserRole.SuperAdmin, UserRole.Admin]) && (
                <UserAddButton sx={{ px: 2 }} />
              )}
            </Stack>
          </Grid>
        </Grid>
        <UsersTableContainer />
      </Box>
    </PageLayout>
  );
};
