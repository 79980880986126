import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { emailState } from "./email.store";
import {
  fetchSummaryEmail,
  fetchAlertEmail,
  fetchSummaryEmailPages,
} from "./email.actions";

const slice = createSlice({
  name: "email",
  initialState: emailState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryEmail.pending, (state) => ({
        ...state,
        fetchSummaryEmail: {
          ...state.fetchSummaryEmail,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchSummaryEmail.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchSummaryEmail: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchSummaryEmail.rejected, (state) => ({
        ...state,
        fetchSummaryEmail: {
          ...state.fetchSummaryEmail,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAlertEmail.pending, (state) => ({
        ...state,
        fetchAlertEmail: {
          ...state.fetchAlertEmail,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAlertEmail.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAlertEmail: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchAlertEmail.rejected, (state) => ({
        ...state,
        fetchAlertEmail: {
          ...state.fetchAlertEmail,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchSummaryEmailPages.pending, (state) => ({
        ...state,
        fetchSummaryEmailPages: {
          ...state.fetchSummaryEmailPages,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchSummaryEmailPages.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchSummaryEmailPages: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchSummaryEmailPages.rejected, (state) => ({
        ...state,
        fetchSummaryEmailPages: {
          ...state.fetchSummaryEmailPages,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export default slice.reducer;
