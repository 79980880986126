import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export interface GPSMapState {
  fetchGps: AsyncState<null>;
}

export const gpsmapState: GPSMapState = {
  fetchGps: {
    status: AsyncStatus.Void,
    data: null,
  },
};
