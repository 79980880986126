import React, { FC } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import { Box, FormControl, TextField, Link } from "@mui/material";
import { LoginDto } from "../../api/auth.api.dto";
import { loginFormValidationSchema } from "./LoginForm.validation";
import { Button } from "../../../app/components/Button/Button";
import { FORGOT_PASSWORD } from "../../../app/const/routes";

interface ILoginForm {
  onSubmit: (formValues: LoginDto) => void;
  isPending: boolean;
}

export const LoginForm: FC<ILoginForm> = ({ onSubmit, isPending }) => {
  const { tenantId } = useParams();

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
        tenantId: tenantId,
      }}
      validationSchema={loginFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="login-username">Username</label>
            <TextField
              id="login-username"
              type="email"
              name="username"
              value={values.username}
              disabled={isPending}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="login-password">Password</label>
            <TextField
              id="login-password"
              type="password"
              name="password"
              value={values.password}
              autoComplete="off"
              disabled={isPending}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link component={RouterLink} to={FORGOT_PASSWORD}>
              Forgot password
            </Link>
            <Button
              type="submit"
              pending={isPending}
              disabled={isPending || !isValid}
            >
              Log in
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
