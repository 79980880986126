import React, { FC } from "react";
import { Box, Container } from "@mui/material";

import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { LoginFormContainer } from "../../containers/LoginFormContainer/LoginFormContainer";

export const LoginView: FC = () => {
  return (
    <PageLayout hideSideNav>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <PageTitle title="Log In" />
          <LoginFormContainer />
        </Container>
      </Box>
    </PageLayout>
  );
};
