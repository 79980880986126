import React, { FC, useEffect } from "react";
import { Box, Typography, FormHelperText } from "@mui/material";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { TireCoordinateDto } from "../../../vehicles/api/vehicles.api.dto";
import {
  selectFetchAllTrailersData,
  selectIsFetchAllTrailersPending,
} from "../../store/trailers.selector";
import {
  VehicleTireDiagram,
  DiagramVehicleType,
} from "../../../vehicles/components/VehicleTireDiagram/VehicleTireDiagram";

export interface ITrailerSetupTiresContainerProps {
  trailerId: string | undefined;
  tenantId: string | undefined;
  selectedTireCoordinates: TireCoordinateDto[];
  setSelectedTireCoordinates: React.Dispatch<
    React.SetStateAction<TireCoordinateDto[]>
  >;
  errorMessage?: string;
  showTenantIds?: boolean;
}

export const TrailerSetupTiresContainer: FC<
  ITrailerSetupTiresContainerProps
> = ({
  trailerId,
  tenantId,
  selectedTireCoordinates,
  setSelectedTireCoordinates,
  errorMessage,
  showTenantIds,
}) => {
  const trailers = useAppSelector(selectFetchAllTrailersData);
  const isTrailersPending = useAppSelector(selectIsFetchAllTrailersPending);
  const selectedTrailer = trailers.find(
    (trailer) =>
      trailer.alternateId === trailerId &&
      (!showTenantIds || trailer.tenantId === tenantId)
  );

  const diagramVehicles: DiagramVehicleType[] = [
    { type: "truck" },
    { type: "trailer", data: selectedTrailer, editable: true },
  ];

  useEffect(() => {
    if (selectedTrailer) {
      setSelectedTireCoordinates(selectedTrailer.tiresMap || []);
    }
  }, [selectedTrailer]);

  return (
    <>
      <Typography sx={{ marginBottom: 5 }}>
        Select the number of axles and tires for each axle.
      </Typography>
      <Box sx={{ marginBottom: 5 }}>
        <VehicleTireDiagram
          interactive
          hideReadOnlyOnMobile
          vehicles={diagramVehicles}
          selectedTireCoordinates={selectedTireCoordinates}
          setSelectedTireCoordinates={setSelectedTireCoordinates}
          isPending={isTrailersPending}
          isDisabled={!selectedTrailer}
        />
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </Box>
    </>
  );
};
