import { TrailerDto } from "../../trailers/api/trailers.api.dto";
import { PublicUserDto } from "../../users/api/users.api.dto";

export interface VehicleDto {
  id: string;
  unitId?: string;
  alternateId: string;
  hardwareId: string;
  make: string;
  model: string;
  year: string;
  terminalId?: string;
  terminalName?: string;
  terminalExternalId?: string;
  terminal?: VehicleTerminalDto;
  cvd: DeviceDto;
  drivers?: PublicUserDto[];
  name?: string;
  type?: string;
  tenantId?: string;
  licensePlate?: string;
  tiresMap?: TireCoordinateDto[];
  tiresCount?: TireCountDto[];
  gps?: GpsDto;
  vehicleEmails?: string[];
  trailer?: TrailerDto;
  vehicleAlternateId: string;
  tires: TireDto[];
}

export interface CreateEditVehicleDto {
  make: string;
  model: string;
  type: string;
  name: string;
  licensePlate: string;
  driversUsernames?: PublicUserDto[];
  tiresMap?: TireCoordinateDto[];
  tiresCount?: TireCountDto[];
  terminalId?: string;
}

export interface VehicleTerminalDto {
  id: string;
  alternateId: string;
  email: string;
  type: VehicleTerminalType;
  name: string;
  address: string;
  zip: string;
  timeZone: string;
  gps: GpsDto;
  state?: string;
  city?: string;
  postal_code?: string;
  latitude?: number;
  longitude?: number;
}

export enum VehicleState {
  Critical = "critical",
  Cautionary = "cautionary",
  Offline = "offline",
  Normal = "normal",
}

export interface VehicleMapDto {
  lat: number;
  lng: number;
  name?: string;
  make?: string;
  model?: string;
  drivers?: PublicUserDto[];
  alternateId?: string;
  tenantId?: string | undefined;
  state?: VehicleState;
}

export type VehicleTerminalType = "terminal";

export interface GpsDto {
  description: string;
  city: string;
  state: string;
  country: string;
  lat: number;
  lon: number;
  heading: number;
  satellites: number;
  hdop: number;
  valid: boolean;
}

export interface DeviceDto {
  id: string;
  alternateId: string;
  type: string;
}

export interface MeasurementDto {
  type: MeasurementType;
  tires?: TireDto[];
  timestamp: Date;
  trailer: TrailerDto;
  vehicle: VehicleDto;
  TZ?: string;
  gps: GpsDto;
  timestampTZ?: Date;
}

export type MeasurementType =
  | "lost_signal"
  | "periodic"
  | "air_leak"
  | "pressure_threshold_detection"
  | "pressure_threshold_detection_0"
  | "pressure_threshold_detection_1"
  | "pressure_threshold_detection_3"
  | "pressure_threshold_detection_4"
  | "temperature_threshold_detection"
  | "ignition_on"
  | "ignition_off"
  | "cvd_startup"
  | "trailer_id";

export interface TireDto {
  tire: number;
  axle: number;
  temperature: number;
  pressure: number;
  temperatureFahrenheit?: number | string;
  temperatureCentigrade?: number | string;
  pressurePSI?: number | string;
  pressureKPA?: number | string;
  leak: boolean;
  sensorError: boolean;
  temperatureAlert: boolean;
  pressureAlert: boolean;
  pressureStatus: PressureStatusType;
  tireLocation?: string;
  timestamp: Date;
  trailer: number;
  truck: number;
  lostSignal: boolean;
}

export type PressureStatusType =
  | "extreme over pressure"
  | "over pressure"
  | "good"
  | "under pressure"
  | "extreme under pressure";

export interface TireCoordinateDto {
  axle: number;
  tire: number;
  tireId: string;
}

export interface TireCountDto {
  axle: number;
  tires: number;
}

export interface PagesDto {
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface PageInfoDto {
  page: number;
  rowsPerPage: number;
}

export interface HistoryDto {
  tireId: string;
  tireLocation: string;
  data: Array<string[]>;
}
