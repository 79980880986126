import React, { FC } from "react";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";

export type DateType = Dayjs;

export const DatePicker: FC<DatePickerProps<DateType, DateType>> = ({
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MuiDatePicker {...props} />
  </LocalizationProvider>
);
