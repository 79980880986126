import React, { FC } from "react";
import { Button as MuiButton, ButtonProps } from "@mui/material";
import { Loader } from "../Loader/Loader";

export interface IButton extends ButtonProps {
  pending?: boolean;
}

export const Button: FC<IButton> = ({ pending, ...rest }) => (
  <MuiButton
    {...rest}
    startIcon={pending ? <Loader inline /> : rest.startIcon}
  />
);
