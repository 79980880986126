import { TireCoordinateDto, TireCountDto } from "../api/vehicles.api.dto";
import { groupTireCoordinates } from "./group-tire-coordinates";

export const convertTireCountToCoordinatesArray = (
  count: TireCountDto,
  startIndex = 0
): TireCoordinateDto[] =>
  [...Array(count.tires)].map((value: undefined, index) => {
    const tireIndex = startIndex + index;
    return {
      axle: count.axle,
      tire: tireIndex,
      tireId: `${count.axle}${tireIndex}`,
    };
  });

export const convertTireCoordinatesToCountArray = (
  coordinates: TireCoordinateDto[]
) => {
  const coordinatesByAxle = groupTireCoordinates(coordinates);
  return coordinatesByAxle.reduce((count, axleCoordinates) => {
    count.push({
      axle: axleCoordinates[0].axle,
      tires: axleCoordinates.length,
    });
    return count;
  }, [] as TireCountDto[]);
};
