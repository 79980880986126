import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UnsubscribeState } from "./unsubscribe.store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export const selectSelf = (state: RootState): RootState => state;

export const selectUnsubState = createSelector(
  selectSelf,
  (state: RootState): UnsubscribeState => state.unsubscribe
);

export const selectUnsubscribeState = createSelector(
  selectUnsubState,
  (unsubscribe: UnsubscribeState): AsyncState<null> => unsubscribe.unsubscribe
);

export const selectUnsubscribeStatus = createSelector(
  selectUnsubscribeState,
  (unsubscribe: AsyncState<null>): AsyncStatus => unsubscribe.status
);

export const selectUnsubscribeStatusPending = createSelector(
  selectUnsubscribeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
