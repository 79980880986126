import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export interface UnsubscribeState {
  unsubscribe: AsyncState<null>;
}

export const unsubscribeState: UnsubscribeState = {
  unsubscribe: {
    status: AsyncStatus.Void,
    data: null,
  },
};
