import React, { FC, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Box, Grid, Stack } from "@mui/material";

import { MeasurementType } from "../../../vehicles/api/vehicles.api.dto";
import { MeasurementEvent } from "../../../app/enum/Vehicle";
import { MeasurementEventFilter } from "../../enum/alertHistory.enum";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { DateRangeType } from "../../../app/components/DateRangePicker/DateRangePicker";
import { AlertHistoryFiltersContainer } from "../../containers/AlertHistoryFiltersContainer";
import { AlertHistoryTableContainer } from "../../containers/AlertHistoryTableContainer";
import { ExportCsvButton } from "../../../app/components/ExportCsvButton/ExportCsvButton";
import { fetchAlertsCsv } from "../../api/alertHistory.api";

export const AlertHistoryView: FC = () => {
  const [alertTypes, setAlertTypes] = useState<MeasurementType[]>(
    Object.keys(MeasurementEventFilter)
      .filter((key) => key !== "IGNITION_ON" && key !== "IGNITION_OFF")
      .map((key) => MeasurementEvent[key as keyof typeof MeasurementEvent])
  );
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const terminalId = null;
  const [vehicleId, setVehicleId] = useState<string | null>(null);
  const [trailerId, setTrailerId] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRangeType>([
    dayjs().subtract(24, "hour"),
    dayjs(),
  ]);
  const dateRangeStart = useMemo(
    () =>
      dayjs(dateRange[0]).isValid()
        ? dayjs(dateRange[0]).format("YYYYMMDD")
        : null,
    [dateRange]
  );
  const dateRangeEnd = useMemo(
    () =>
      dayjs(dateRange[1]).isValid()
        ? dayjs(dateRange[1]).format("YYYYMMDD")
        : null,
    [dateRange]
  );
  const [sortField, setSortField] = useState<string>("timestamp");
  const [sortType, setSortType] = useState<number>(-1);

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3}>
          <Grid item>
            <PageTitle title="Alert History" />
          </Grid>
          <Grid item xs={12} md>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <AlertHistoryFiltersContainer
                selectedAlertTypes={alertTypes}
                selectedTenantId={tenantId}
                selectedVehicleId={vehicleId}
                selectedTrailerId={trailerId}
                selectedDateRange={dateRange}
                setSelectedAlertTypes={setAlertTypes}
                setSelectedTenantId={setTenantId}
                setSelectedVehicleId={setVehicleId}
                setSelectedTrailerId={setTrailerId}
                setSelectedDateRange={setDateRange}
              />
              <ExportCsvButton
                fetchAsync={() =>
                  fetchAlertsCsv(
                    vehicleId || "*",
                    trailerId || "*",
                    terminalId || "*",
                    tenantId,
                    alertTypes.length > 0 ? alertTypes.join("|") : "*",
                    dateRangeStart || "*",
                    dateRangeEnd || "*"
                  )
                }
                fileName={`alert-history-${dayjs().format("L")}`}
                sx={{ px: 2 }}
              />
            </Stack>
          </Grid>
        </Grid>
        <AlertHistoryTableContainer
          types={alertTypes}
          tenantId={tenantId}
          terminalId={terminalId}
          vehicleId={vehicleId}
          trailerId={trailerId}
          setSortField={setSortField}
          sortField={sortField}
          setSortType={setSortType}
          sortType={sortType}
          start={dateRangeStart}
          end={dateRangeEnd}
        />
      </Box>
    </PageLayout>
  );
};
