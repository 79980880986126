import { useCurrentUser } from "./useCurrentUser";
import { UserRole } from "../enum/UserRole";

export const useShowForRoles = (roles: UserRole[]) => {
  const user = useCurrentUser();
  if (user) {
    return roles.includes(user.role);
  }
  return false;
};
