import { lighten, darken, ThemeOptions } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import { AbsoluteLoader } from "../components/Loader/Loader";
import { ElevatedPaper } from "../components/ElevatedPaper/ElevatedPaper";
import { spacing } from "./spacing";
import { borderRadius } from "./shape";
import typography from "./typography";
import palette, { ASPHALT_LIGHT_ALT, BLACK } from "./palette";

const INPUT_PADDING_TOP = spacing(0.75);
const INPUT_PADDING_BOTTOM = spacing(0.875);

export default {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 0,
    },
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        marginBottom: spacing(3),
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
      hiddenLabel: true,
    },
    styleOverrides: {
      root: {
        marginBottom: spacing(3),
        justifyContent: "center",
        "label:not(.MuiInputLabel-root)": {
          marginBottom: spacing(1.5),
        },
        "> .MuiFormControl-root": {
          marginBottom: 0,
        },
        "&.MuiTextField-root": {
          marginBottom: 0,
        },
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        borderRadius: borderRadius(1),
        "&.MuiInputBase-colorSecondary": {
          backgroundColor: palette.secondary.main,
          "&:hover, &:focus": {
            backgroundColor: darken(
              palette.secondary.main,
              palette.tonalOffset / 5
            ),
          },
          "&.Mui-disabled": {
            backgroundColor: lighten(
              palette.secondary.main,
              palette.tonalOffset * 1.5
            ),
          },
        },
      },
      inputSizeSmall: {
        paddingTop: INPUT_PADDING_TOP,
        paddingBottom: INPUT_PADDING_BOTTOM,
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      hiddenLabel: true,
      disableUnderline: true,
    },
    styleOverrides: {
      inputSizeSmall: {
        paddingTop: INPUT_PADDING_TOP,
        paddingBottom: INPUT_PADDING_BOTTOM,
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      PaperComponent: ElevatedPaper,
      ChipProps: {
        size: "small",
      },
    },
    styleOverrides: {
      root: {
        "> .MuiFormControl-root": {
          marginBottom: 0,
        },
        ".MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input": {
          paddingTop: INPUT_PADDING_TOP,
          paddingBottom: INPUT_PADDING_BOTTOM,
        },
      },
      inputRoot: {
        paddingTop: 0,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        paddingTop: INPUT_PADDING_TOP,
        paddingBottom: INPUT_PADDING_BOTTOM,
        paddingLeft: spacing(4.5),
        paddingRight: spacing(4.5),
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: "inherit",
    },
  },
  MuiToggleButton: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        textTransform: "none",
        paddingTop: INPUT_PADDING_TOP,
        paddingBottom: INPUT_PADDING_BOTTOM,
        paddingLeft: spacing(4.5),
        paddingRight: spacing(4.5),
        color: "inherit",
        border: "none",
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        "&.Mui-selected": {
          color: "inherit",
        },
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
          border: "none",
        },
        "&.MuiToggleButton-secondary": {
          backgroundColor: palette.secondary.main,
        },
        "&.MuiToggleButton-secondary:hover, &.MuiToggleButton-secondary:focus":
          {
            backgroundColor: darken(
              palette.secondary.main,
              palette.tonalOffset / 5
            ),
          },
        "&.MuiToggleButton-secondary.Mui-selected": {
          backgroundColor: darken(
            palette.secondary.main,
            palette.tonalOffset / 1.5
          ),
        },
        "&.MuiToggleButton-secondary.Mui-disabled": {
          backgroundColor: lighten(
            palette.secondary.main,
            palette.tonalOffset * 1.5
          ),
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        marginBottom: spacing(2),
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        ".MuiTableRow-root:hover": {
          backgroundColor: ASPHALT_LIGHT_ALT,
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      head: {
        backgroundColor: palette.secondary.main,
      },
      root: {
        "&:nth-of-type(even)": {
          backgroundColor: palette.secondary.main,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 0,
      },
      head: {
        fontWeight: typography.fontWeightBold,
        letterSpacing: typography.h2.letterSpacing,
        color: BLACK,
      },
    },
  },
  MuiDataGrid: {
    defaultProps: {
      autoHeight: true,
      components: {
        LoadingOverlay: AbsoluteLoader,
      },
    },
    styleOverrides: {
      root: {
        border: 0,
        borderRadius: 0,
        ".MuiDataGrid-columnHeaders": {
          backgroundColor: palette.secondary.main,
          border: 0,
        },
        ".MuiDataGrid-columnHeader": {
          border: 0,
          outline: "0 !important",
        },
        ".MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0,
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: typography.fontWeightBold,
          letterSpacing: typography.h2.letterSpacing,
        },
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-row": {
          border: 0,
          "&:hover, &.Mui-selected:hover": {
            backgroundColor: ASPHALT_LIGHT_ALT,
            cursor: "default",
          },
          "&:nth-of-type(even)": {
            backgroundColor: palette.secondary.main,
            "&:hover, &.Mui-selected:hover": {
              backgroundColor: ASPHALT_LIGHT_ALT,
            },
          },
        },
        ".MuiDataGrid-cell": {
          border: 0,
          outline: "0 !important",
        },
      },
      menu: {
        ".MuiPaper-elevation0": {
          boxShadow: shadows[8],
        },
      },
    },
  },
} as ThemeOptions["components"];
