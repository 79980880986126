import React, { FC } from "react";
import { push } from "redux-first-history";
import { Formik } from "formik";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { TimeZones } from "../../../users/enum/timezones";
import { Button } from "../../../app/components/Button/Button";
import { CreateTerminalDto } from "../../../terminals/api/terminals.api.dto";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import {
  terminalEditFormValidation,
  terminalAddFormValidation,
} from "./TerminalForm.validation";
import { PublicUserDto } from "../../../users/api/users.api.dto";
import { TERMINALS } from "../../../app/const/routes";

export interface ITerminalFormProps {
  terminal: CreateTerminalDto | null;
  showTenantField?: boolean;
  onSubmit: (formValues: CreateTerminalDto) => void;
  isPending?: boolean;
  users: PublicUserDto[];
}

export const TerminalForm: FC<ITerminalFormProps> = ({
  terminal,
  users,
  showTenantField,
  onSubmit,
  isPending,
}) => {
  const dispatch = useAppDispatch();
  const handleCancelEdit = () => {
    dispatch(push(TERMINALS));
  };
  const tenantIds = getTenantIds();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        tenantId: terminal?.tenantId || "",
        id: terminal?.id || "",
        externalId: terminal?.externalId || "",
        address: terminal?.address || "",
        city: terminal?.city || "",
        zip: terminal?.zip || "",
        country: terminal?.country || "",
        name: terminal?.name || "",
        state: terminal?.state || "",
        timeZone: terminal?.timeZone || TimeZones.US_EASTERN,
        additionalEmailList: terminal?.additionalEmailList || [],
        latitude: terminal?.latitude || 40.68901,
        longitude: terminal?.longitude || -74.04392,
      }}
      validationSchema={
        terminal
          ? terminalEditFormValidation
          : terminalAddFormValidation(!!showTenantField)
      }
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          {showTenantField && (
            <FormControl>
              <label htmlFor="tenantId">Tenant</label>
              <Select
                id="tenantId"
                name="tenantId"
                variant="filled"
                fullWidth
                value={values.tenantId}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={!!terminal || isPending}
                required
              >
                {tenantIds.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.tenantId && Boolean(errors.tenantId) && (
                <FormHelperText error>{errors.tenantId}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl>
            <label htmlFor="id">Id</label>
            <TextField
              id="id"
              name="id"
              variant="filled"
              fullWidth
              value={values.id}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.id)}
              helperText={errors.id}
              required
              disabled={isPending || !!terminal}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="name">Name</label>
            <TextField
              id="name"
              name="name"
              variant="filled"
              fullWidth
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
              required
              disabled={isPending || !!terminal}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="timeZone">Timezone</label>
            <Select
              id="timeZone"
              name="timeZone"
              variant="filled"
              fullWidth
              value={values.timeZone}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isPending}
              required
            >
              {Object.entries(TimeZones).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <label htmlFor="country">Country</label>
            <TextField
              id="country"
              name="country"
              variant="filled"
              fullWidth
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.country)}
              helperText={errors.country}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="state">State</label>
            <TextField
              id="state"
              name="state"
              variant="filled"
              fullWidth
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.state)}
              helperText={errors.state}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="city">City</label>
            <TextField
              id="city"
              name="city"
              variant="filled"
              fullWidth
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.city)}
              helperText={errors.city}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="zip">Zip Code</label>
            <TextField
              id="zip"
              name="zip"
              variant="filled"
              fullWidth
              value={values.zip}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.zip)}
              helperText={errors.zip}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="address">Address</label>
            <TextField
              id="address"
              name="address"
              variant="filled"
              fullWidth
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.address)}
              helperText={errors.address}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="longitude">Longitude</label>
            <TextField
              id="longitude"
              name="longitude"
              variant="filled"
              fullWidth
              value={values.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.longitude)}
              helperText={errors.longitude}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="latitude">Latitude</label>
            <TextField
              id="latitude"
              name="latitude"
              variant="filled"
              fullWidth
              value={values.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(errors.latitude)}
              helperText={errors.latitude}
            />
          </FormControl>
          <FormControl>
            <label>Email List</label>
            <Autocomplete
              multiple
              freeSolo
              disableCloseOnSelect
              options={[
                ...new Set(
                  users
                    .filter(
                      (user) =>
                        user.tenantId &&
                        terminal?.tenantId &&
                        user.tenantId === terminal?.tenantId
                    )
                    .map((user) => user.email)
                ),
              ]}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText>{option}</ListItemText>
                </li>
              )}
              value={values.additionalEmailList}
              onBlur={handleBlur}
              onChange={(
                event: React.ChangeEvent<unknown>,
                newValues: string[] | null
              ) => {
                setFieldValue("additionalEmailList", newValues || []);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter another email address"
                  error={Boolean(
                    touched.additionalEmailList && errors.additionalEmailList
                  )}
                  helperText={
                    touched.additionalEmailList && errors.additionalEmailList
                  }
                />
              )}
              disabled={isPending}
              sx={[
                {
                  "& .MuiAutocomplete-inputRoot": {
                    py: 0.5,
                  },
                },
              ]}
            />
          </FormControl>
          <Box
            display="flex"
            justifyContent={terminal ? "space-between" : "flex-end"}
            alignItems="center"
          >
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="text" onClick={() => handleCancelEdit()}>
                Cancel
              </Button>
              <Button
                type="submit"
                pending={isPending}
                disabled={isPending || !isValid}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};
