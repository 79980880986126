declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

export default {
  htmlFontSize: 16,
  fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
  fontSize: 14,
  fontWeightLight: 400,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontFamily: "Teko, Helvetica, Arial, sans-serif",
    fontSize: "2.25rem",
    fontWeight: 400,
    letterSpacing: "0.02em",
  },
  h2: {
    fontSize: "1.075rem",
    fontWeight: 600,
    letterSpacing: "0.02em",
  },
  body3: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  button: {
    fontWeight: 600,
    letterSpacing: "0.02em",
    lineHeight: "1.643em",
  },
};
