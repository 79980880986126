import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TerminalDto } from "../api/terminals.api.dto";

export interface TerminalsState {
  fetchAllTerminals: AsyncState<TerminalDto[]>;
  fetchTerminal: AsyncState<TerminalDto | null>;
  updateTerminal: AsyncState<null>;
  addTerminal: AsyncState<null>;
}

export const terminalsState: TerminalsState = {
  fetchAllTerminals: {
    status: AsyncStatus.Void,
    data: [],
  },
  updateTerminal: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchTerminal: {
    status: AsyncStatus.Void,
    data: null,
  },
  addTerminal: {
    status: AsyncStatus.Void,
    data: null,
  },
};
