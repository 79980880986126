import React, { FC } from "react";
import { MenuList } from "@mui/material";
import {
  Square as SquareIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useShowForRoles } from "../../hooks/useShowForRoles";
import { isCurrentPath } from "../../utils/is-current-path";
import { UserRole } from "../../enum/UserRole";
import {
  ALERT_HISTORY,
  SUMMARY,
  USER,
  TENANTS,
  TERMINALS,
  WEB_DASHBOARD,
  TIRE_ALERT_EMAIL_SETUP,
  SUMMARY_ALERT_EMAIL_SETUP,
  TRAILER_SETUP_LIST,
  VEHICLE_SETUP_LIST,
  TRAILER_STATUS_LIST,
} from "../../const/routes";
import {
  StyledMenuItem,
  StyledPermanentDrawer,
  StyledTemporaryDrawer,
  StyledNavLink,
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from "./SideNav.style";

interface ISideNav {
  vehicleStatusLink: string;
  hasTenants?: boolean;
  toggleable: boolean;
  isOpen: boolean;
  onToggle: () => void;
}

export const SideNav: FC<ISideNav> = ({
  vehicleStatusLink,
  hasTenants,
  toggleable,
  isOpen,
  onToggle,
}) => {
  const items = [
    {
      title: "Summary",
      icon: <SquareIcon />,
      href: SUMMARY,
      allowedRoles: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FleetManager,
        UserRole.Shop,
      ],
    },
    {
      title: "Terminals",
      icon: <SquareIcon />,
      href: TERMINALS,
      allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      title: "Vehicle Status",
      icon: <SquareIcon />,
      href: vehicleStatusLink,
      allowedRoles: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FleetManager,
        UserRole.Shop,
        UserRole.Driver,
      ],
    },
    {
      title: "Trailer Status",
      icon: <SquareIcon />,
      href: TRAILER_STATUS_LIST,
      allowedRoles: [UserRole.SuperAdmin],
    },
    {
      title: "Alert History",
      icon: <SquareIcon />,
      href: ALERT_HISTORY,
      allowedRoles: [
        UserRole.SuperAdmin,
        UserRole.Admin,
        UserRole.FleetManager,
        UserRole.Shop,
        UserRole.Driver,
      ],
    },
    {
      title: "Users",
      icon: <SquareIcon />,
      href: USER,
      allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
    },
    {
      title: "Web Dashboard",
      icon: <SquareIcon />,
      href: WEB_DASHBOARD,
      allowedRoles: [UserRole.SuperAdmin],
    },
    ...(hasTenants
      ? [
          {
            title: "Tenants",
            icon: <SquareIcon />,
            href: TENANTS,
            allowedRoles: [UserRole.SuperAdmin],
          },
        ]
      : []),
    {
      title: "Email Setup",
      icon: <SquareIcon />,
      allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
      items: [
        {
          title: "Tire Alerts",
          icon: <SquareIcon />,
          href: TIRE_ALERT_EMAIL_SETUP,
          allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
        },
        ...(!hasTenants
          ? [
              {
                title: "Summary",
                icon: <SquareIcon />,
                href: SUMMARY_ALERT_EMAIL_SETUP,
                allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
              },
            ]
          : []),
      ],
    },
    {
      title: "Asset Management",
      icon: <SquareIcon />,
      allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
      items: [
        {
          title: "Setup Vehicle",
          icon: <SquareIcon />,
          href: VEHICLE_SETUP_LIST,
          allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
        },
        {
          title: "Setup Trailer",
          icon: <SquareIcon />,
          href: TRAILER_SETUP_LIST,
          allowedRoles: [UserRole.SuperAdmin, UserRole.Admin],
        },
      ],
    },
  ];

  const list = () => (
    <MenuList>
      {items.map(
        (item, index) =>
          useShowForRoles(item.allowedRoles) && (
            <StyledMenuItem key={index}>
              {item.items ? (
                <StyledAccordion
                  defaultExpanded={
                    !!item.items.find(
                      (nestedItem) =>
                        nestedItem.href && isCurrentPath(nestedItem.href)
                    )
                  }
                >
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {item.icon}
                    {item.title}
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    {item.items.map(
                      (nestedItem, nestedIndex) =>
                        useShowForRoles(nestedItem.allowedRoles) && (
                          <StyledMenuItem key={nestedIndex} component="div">
                            <StyledNavLink to={nestedItem.href}>
                              {nestedItem.icon}
                              {nestedItem.title}
                            </StyledNavLink>
                          </StyledMenuItem>
                        )
                    )}
                  </StyledAccordionDetails>
                </StyledAccordion>
              ) : (
                <StyledNavLink to={item.href}>
                  {item.icon}
                  {item.title}
                </StyledNavLink>
              )}
            </StyledMenuItem>
          )
      )}
    </MenuList>
  );

  if (toggleable) {
    return (
      <StyledTemporaryDrawer
        open={isOpen}
        onClose={onToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {list()}
      </StyledTemporaryDrawer>
    );
  }

  return <StyledPermanentDrawer>{list()}</StyledPermanentDrawer>;
};
