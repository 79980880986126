import React, { FC, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { Paper, TableContainer, TextField, Stack } from "@mui/material";
import { useUnitSystem } from "../../../app/hooks/useUnitSystem";
import { getTemperatureUnit } from "../../../app/utils/get-temperature-value";
import { getPressureUnit } from "../../../app/utils/get-pressure-value";
import { HistoryDto } from "../../api/vehicles.api.dto";
import { Loader } from "../../../app/components/Loader/Loader";
import {
  DateRangePicker,
  DateRangeType,
} from "../../../app/components/DateRangePicker/DateRangePicker";
import { UnitSystem } from "../../../settings/enum/settings.enum";
import { BLACK } from "../../../app/theme/palette";
import {
  StyledFilterButton,
  StyledLineKey,
  StyledTooltipText,
  StyledErrorText,
  StyledTooltipRow,
} from "./HistoryLineGraph.style";

type EChartsOption = echarts.EChartsOption;

interface IHistoryLineGraph {
  type: "pressure" | "temperature";
  historyData: HistoryDto[];
  tireIds: string[];
  dateRange: DateRangeType;
  onDateRangeChange: (dateRange: DateRangeType) => void;
  isLoading?: boolean;
}

export const HistoryLineGraph: FC<IHistoryLineGraph> = ({
  type,
  historyData,
  tireIds,
  dateRange,
  onDateRangeChange,
  isLoading,
}) => {
  const [dateRangePickerValue, setDateRangePickerValue] = useState(dateRange);
  const unitSystem = useUnitSystem();
  const temperatureUnit = getTemperatureUnit(unitSystem);
  const pressureUnit = getPressureUnit(unitSystem);
  const selectedHistoryData = historyData.filter((data) =>
    tireIds.includes(data.tireId)
  );
  const dateRangeDaysAgo =
    dateRange[0]?.isValid() &&
    dateRange[1]?.isValid() &&
    dateRange[1].isSame(dayjs(), "day")
      ? dayjs().diff(dateRange[0], "day")
      : null;

  const graphOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formatter: (params: any) => {
        const el = document.createElement("div");
        const arrParams = Array.isArray(params) ? params : [params];
        el.innerHTML = renderToStaticMarkup(
          <>
            {arrParams.map((param, index: number) => {
              return (
                <div key={index}>
                  {index === 0 ? (
                    <StyledTooltipText>
                      Date&ensp;{" "}
                      <strong>
                        {param.data &&
                          param.data[0] &&
                          dayjs
                            .unix(param.data[0] / 1000)
                            .format("MM/DD HH:mm Z")}
                      </strong>
                    </StyledTooltipText>
                  ) : null}
                  <StyledTooltipRow key={index}>
                    <StyledLineKey style={{ stroke: param.color }} />
                    <StyledTooltipText>
                      Axle/Tire&emsp; <strong>{param.seriesName}</strong>
                      &emsp;&emsp;
                      {type === "pressure" ? `${pressureUnit} ` : "Temp. "}
                      &emsp;
                      <strong>{param.value[1]}</strong>
                    </StyledTooltipText>
                  </StyledTooltipRow>
                </div>
              );
            })}
          </>
        );
        return el;
      },
    },
    legend: {
      data: selectedHistoryData.map(
        (data) => `${+data.tireId.charAt(0) + 1}/${data.tireLocation}`
      ),
      itemWidth: 72.5,
      itemGap: 40,
      top: "70%",
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: "43%",
      containLabel: true,
    },
    xAxis: {
      type: "time",
      name: "Date",
      nameLocation: "middle",
      nameGap: 40,
      axisLine: { show: true, lineStyle: { color: BLACK } },
      nameTextStyle: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 16 },
      axisLabel: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 16 },
    },
    yAxis: {
      type: "value",
      name:
        type === "pressure"
          ? `Pressure (${pressureUnit})`
          : `Temperature (${temperatureUnit})`,
      nameLocation: "middle",
      nameGap: 40,
      axisLine: { show: true, lineStyle: { color: BLACK } },
      splitLine: { show: true },
      nameTextStyle: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 16 },
      axisLabel: { fontFamily: "Montserrat", fontSize: 14, lineHeight: 16 },
    },
    series: selectedHistoryData.map((data) => {
      return {
        name: `${+data.tireId.charAt(0) + 1}/${data.tireLocation}`,
        type: "line",
        data: data.data
          .filter((dataPoints) => {
            return dataPoints[1] !== "-2147483648";
          })
          .map((dataPoints) => {
            return [
              Number(dataPoints[0]) * 1000,
              getDatapointValue(type, unitSystem, dataPoints),
            ];
          }),
      };
    }),
  };

  return (
    <TableContainer component={Paper} sx={{ position: "relative" }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        justifyContent="flex-end"
      >
        <StyledFilterButton
          color="secondary"
          value={0}
          selected={dateRangeDaysAgo === 0}
          onClick={() => {
            if (dateRangeDaysAgo !== 0) {
              onDateRangeChange([dayjs(), dayjs()]);
            }
          }}
          disabled={isLoading || tireIds.length === 0}
        >
          Today
        </StyledFilterButton>
        <StyledFilterButton
          color="secondary"
          value={7}
          selected={dateRangeDaysAgo === 6}
          onClick={() => {
            if (dateRangeDaysAgo !== 6) {
              onDateRangeChange([dayjs().subtract(6, "day"), dayjs()]);
            }
          }}
          disabled={isLoading || tireIds.length === 0}
        >
          Last 7 Days
        </StyledFilterButton>
        <StyledFilterButton
          color="secondary"
          value={30}
          selected={dateRangeDaysAgo === 29}
          onClick={() => {
            if (dateRangeDaysAgo !== 29) {
              onDateRangeChange([dayjs().subtract(29, "day"), dayjs()]);
            }
          }}
          disabled={isLoading || tireIds.length === 0}
        >
          Last 30 Days
        </StyledFilterButton>
        <StyledFilterButton
          color="secondary"
          value={60}
          selected={dateRangeDaysAgo === 59}
          onClick={() => {
            if (dateRangeDaysAgo !== 59) {
              onDateRangeChange([dayjs().subtract(59, "day"), dayjs()]);
            }
          }}
          disabled={isLoading || tireIds.length === 0}
        >
          Last 60 Days
        </StyledFilterButton>
        <DateRangePicker
          value={dateRangePickerValue}
          minDate={dayjs().subtract(59, "day")}
          maxDate={dayjs()}
          inputFormat={"MM/DD/YYYY"}
          onChange={(newRange) => {
            if (
              newRange[0] &&
              newRange[1] &&
              (!newRange[0].isSame(dateRangePickerValue[0]) ||
                !newRange[1].isSame(dateRangePickerValue[1]))
            ) {
              setDateRangePickerValue(newRange);
            }
          }}
          onClose={() => {
            if (
              !dateRangePickerValue[0]?.isSame(dateRange[0]) ||
              !dateRangePickerValue[1]?.isSame(dateRange[1])
            ) {
              onDateRangeChange(dateRangePickerValue);
            }
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                label=""
                color="secondary"
                inputProps={{
                  ...startProps.inputProps,
                  placeholder: "From date",
                }}
                sx={{ width: 130, mr: 1, mb: 0 }}
              />
              <TextField
                {...endProps}
                label=""
                color="secondary"
                inputProps={{
                  ...endProps.inputProps,
                  placeholder: "Until date",
                }}
                sx={{ width: 130, mb: 0 }}
              />
            </>
          )}
          disabled={isLoading || tireIds.length === 0}
        />
      </Stack>
      {!isLoading &&
        (tireIds.length === 0 ? (
          <StyledErrorText variant="h2">
            Select tire(s) to display data
          </StyledErrorText>
        ) : historyData.length === 0 ? (
          <StyledErrorText variant="h2">Error retrieving data</StyledErrorText>
        ) : selectedHistoryData.length === 0 ? (
          <StyledErrorText variant="h2">
            No data available for that timeframe
          </StyledErrorText>
        ) : null)}
      <ReactECharts
        option={graphOption}
        notMerge={true}
        style={{ height: 650 }}
      />
      {isLoading && <Loader absolute />}
    </TableContainer>
  );
};
function getDatapointValue(
  type: string,
  unitSystem: UnitSystem,
  dataPoints: string[]
): string {
  return type === "pressure"
    ? unitSystem == UnitSystem.Imperial
      ? (+dataPoints[1] / 6.89475729).toFixed(2)
      : (+dataPoints[1]).toFixed(2)
    : unitSystem == UnitSystem.Imperial
    ? (+dataPoints[1] * 1.8 + 32).toFixed(2)
    : (+dataPoints[1]).toFixed(2);
}
