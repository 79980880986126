import React, { FC, useEffect } from "react";
import { push } from "redux-first-history";
import { generatePath } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import { fetchAllUsers } from "../../store/users.actions";
import {
  selectUsersFilteredData,
  selectIsFetchAllUsersPending,
} from "../../store/users.selector";
import { UsersTable } from "../../components/UsersTable/UsersTable";
import { UserRole } from "../../../app/enum/UserRole";
import { USER_EDIT } from "../../../app/const/routes";

export const UsersTableContainer: FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsersFilteredData);
  const isUsersPending = useAppSelector(selectIsFetchAllUsersPending);
  const tenantIds = getTenantIds();
  const canEdit = useShowForRoles([UserRole.SuperAdmin, UserRole.Admin]);
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const goToUserEditScreen = (username: string) =>
    dispatch(
      push(
        generatePath(USER_EDIT, {
          username,
        })
      )
    );

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  return (
    <UsersTable
      users={users}
      goToUserEditScreen={goToUserEditScreen}
      showEditActions={canEdit}
      showTenantColumn={canSeeTenants}
      isLoading={isUsersPending}
    />
  );
};
