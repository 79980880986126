import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import { Box, FormControl, TextField, Link, Alert } from "@mui/material";
import { RecoverPasswordDto } from "../../api/auth.api.dto";
import { forgotPasswordFormValidationSchema } from "./ForgotPasswordForm.validation";
import { Button } from "../../../app/components/Button/Button";
import { LOGIN } from "../../../app/const/routes";

interface IForgotPasswordForm {
  onSubmit: (formValues: RecoverPasswordDto) => void;
  isPending: boolean;
  showNotification: boolean;
}

export const ForgotPasswordForm: FC<IForgotPasswordForm> = ({
  onSubmit,
  isPending,
  showNotification,
}) => {
  if (showNotification) {
    return (
      <Box>
        <Alert severity="info">
          We have e-mailed you a password reset link.
        </Alert>
        <Link component={RouterLink} to={LOGIN}>
          Back to Log In
        </Link>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
      }}
      validationSchema={forgotPasswordFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="forgot-username">User name</label>
            <TextField
              id="forgot-username"
              type="text"
              name="username"
              value={values.username}
              disabled={isPending}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="forgot-email">Email address</label>
            <TextField
              id="forgot-email"
              type="email"
              name="email"
              value={values.email}
              disabled={isPending}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link component={RouterLink} to={LOGIN}>
              Back to Log In
            </Link>
            <Button
              type="submit"
              pending={isPending}
              disabled={isPending || !isValid}
            >
              Send password reset link
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
