import * as Yup from "yup";

export const resetPasswordFormValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(8, "Username must have 8 to 50 characters")
    .max(50, "Username must have 8 to 50 characters")
    .required("Username is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(100, "Email must have 100 or fewer characters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "New password must have 8 to 50 characters")
    .max(50, "New password must have 8 to 50 characters")
    .required("New password is required"),
  passwordRepeat: Yup.string()
    .min(8, "Repeat new password must have 8 to 50 characters")
    .max(50, "Repeat new password must have 8 to 50 characters")
    .oneOf([Yup.ref("password"), null], "Password doesn't match")
    .required("Repeat new password is required"),
});
