import React, { FC } from "react";
import { Box, CircularProgress } from "@mui/material";

export interface ILoader {
  inline?: boolean;
  absolute?: boolean;
}

export const Loader: FC<ILoader> = ({ inline, absolute }) => {
  if (inline) {
    return <CircularProgress size={16} color="inherit" />;
  }
  if (absolute) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        height="100%"
        width="100%"
        position="absolute"
        top={0}
        left={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(255,255,255,0.5)",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex="1"
      height="100%"
    >
      <CircularProgress size={50} />
    </Box>
  );
};

export const AbsoluteLoader: FC = () => <Loader absolute />;
