import React, { FC } from "react";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import { PressureStatus } from "../../../app/enum/Vehicle";
import {
  ASPHALT_LIGHTER,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
  WHITE,
} from "../../../app/theme/palette";
import { SIGNAL_LOSS, SYSTEM_ERROR } from "../../../app/const/errors";
import { MeasurementDto, TireDto } from "../../api/vehicles.api.dto";
import { setTireStatus } from "../../utils/set-tire-status";
import {
  StyledTire,
  StyledTireText,
  StyledContainer,
  StyledOuterTireRow,
  StyledInnerTireRow,
  StyledRow,
  StyledTruck,
  StyledTopText,
  StyledTypeText,
  StyledText,
  StyledTrailer,
  StyledBorderSpace,
} from "./VehicleDiagram.style";

interface IVehicleDiagram {
  vehicle: MeasurementDto;
  interactive: boolean;
  tireIds?: string[];
  setTireIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const VehicleDiagram: FC<IVehicleDiagram> = ({
  vehicle,
  interactive,
  tireIds,
  setTireIds,
}) => {
  const AXLE_COUNT = 10;
  const TRUCK_AXLE_COUNT = 3;

  const alertColor = (tireData: TireDto) => {
    return tireData.lostSignal ||
      !tireData.temperatureCentigrade ||
      !tireData.temperatureFahrenheit ||
      tireData.pressurePSI === SYSTEM_ERROR ||
      tireData.pressureKPA === SYSTEM_ERROR ||
      tireData.pressurePSI === SIGNAL_LOSS ||
      tireData.pressureKPA === SIGNAL_LOSS ||
      tireData.temperatureCentigrade === SYSTEM_ERROR ||
      tireData.temperatureFahrenheit === SYSTEM_ERROR ||
      tireData.temperatureCentigrade === SIGNAL_LOSS ||
      tireData.temperatureFahrenheit === SIGNAL_LOSS
      ? "no-data"
      : tireData.leak ||
        tireData.temperatureAlert ||
        tireData.pressureStatus === PressureStatus.EOP ||
        tireData.pressureStatus === PressureStatus.EUP
      ? "red"
      : tireData.pressureStatus === PressureStatus.OP ||
        tireData.pressureStatus === PressureStatus.UP
      ? "orange"
      : tireData.pressureStatus === PressureStatus.GOOD
      ? "green"
      : "none";
  };

  const handleTireClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    color: string,
    tireId: string
  ) => {
    if (
      !e.currentTarget.firstElementChild?.classList.contains("borderSpacing")
    ) {
      e.currentTarget.style.border = `3px solid ${
        color === "no-data"
          ? ASPHALT_LIGHTER
          : color === "red"
          ? SCHOOL_BUS_RED
          : color === "orange"
          ? CAUTIONARY_ORANGE
          : color === "green"
          ? TRUCKING_GREEN
          : color === "error"
          ? ASPHALT_LIGHTER
          : ASPHALT_LIGHTER
      }`;
      setTireIds && tireIds && setTireIds([...tireIds, tireId]);
      e.currentTarget.style.borderRadius = "3px";
      if (e.currentTarget.firstElementChild)
        e.currentTarget.firstElementChild.className += " borderSpacing";
    } else {
      setTireIds &&
        tireIds &&
        setTireIds(tireIds.filter((el) => el !== tireId));
      e.currentTarget.firstElementChild?.classList.remove("borderSpacing");
      e.currentTarget.style.border = `3px solid ${WHITE}`;
    }
  };

  const tireRow = (tiresData: TireDto[], tire: number) => {
    const truckTires = [];
    const trailerTires = [];

    if (tiresData) {
      for (let axle = 0; axle < AXLE_COUNT; axle += 1) {
        if (axle < TRUCK_AXLE_COUNT) {
          truckTires[axle] = tiresData.find(
            (axleTire) => axleTire.axle === axle && axleTire.tire === tire
          );
        } else {
          trailerTires[axle] = tiresData.find(
            (axleTire) => axleTire.axle === axle && axleTire.tire === tire
          );
        }
      }
    }

    const tires = [
      ...truckTires,
      ...trailerTires.filter((axleTire) => axleTire === undefined),
      ...trailerTires.filter((axleTire) => axleTire !== undefined),
    ];

    return tires.map((axleTire, axle) => {
      if (axleTire) {
        return (
          <StyledBorderSpace
            key={`${axle}${tire}`}
            onClick={(e) =>
              interactive
                ? handleTireClick(
                    e,
                    alertColor(axleTire),
                    `${axleTire.axle}${axleTire.tire}`
                  )
                : null
            }
          >
            <StyledTire alert={alertColor(axleTire)} interactive={interactive}>
              <StyledTireText>{setTireStatus(axleTire)}</StyledTireText>
            </StyledTire>
          </StyledBorderSpace>
        );
      }
      return (
        <StyledBorderSpace key={`${axle}${tire}`}>
          <StyledTire alert="no data" interactive={interactive}>
            <StyledTireText>--</StyledTireText>
          </StyledTire>
        </StyledBorderSpace>
      );
    });
  };

  const nowTimestamp = dayjs();

  const vehicleData = cloneDeep(vehicle);

  if (vehicleData?.tires) {
    // Don't display data older than 7 days ago
    vehicleData.tires = vehicleData?.tires?.filter((tire) => {
      const tireTimestamp = dayjs(tire.timestamp);
      const diff = nowTimestamp.diff(tireTimestamp, "day");
      return diff < 7;
    });
  }

  return (
    <StyledContainer style={{ marginBottom: "96px !important" }}>
      {vehicleData?.tires && (
        <>
          <StyledOuterTireRow>
            {tireRow(vehicleData?.tires, 3)}
          </StyledOuterTireRow>
          <StyledInnerTireRow>
            {tireRow(vehicleData?.tires, 2)}
          </StyledInnerTireRow>
        </>
      )}
      <StyledRow>
        <StyledTruck>
          <StyledTopText>Truck</StyledTopText>
          <StyledTypeText>
            {vehicleData?.vehicle?.name ||
              vehicleData?.vehicle?.alternateId ||
              "N/A"}
          </StyledTypeText>
          <StyledText>
            ID: {vehicleData?.vehicle?.unitId || vehicleData?.vehicle?.id}
          </StyledText>
        </StyledTruck>
        <StyledTrailer>
          <StyledTopText>Trailer</StyledTopText>
          <StyledTypeText>
            {vehicleData?.trailer?.name || vehicleData?.trailer?.type || "N/A"}
          </StyledTypeText>
          <StyledText>ID: {vehicleData?.trailer?.id || "N/A"}</StyledText>
        </StyledTrailer>
      </StyledRow>
      {vehicleData?.tires && (
        <>
          <StyledInnerTireRow>
            {tireRow(vehicleData?.tires, 1)}
          </StyledInnerTireRow>
          <StyledOuterTireRow>
            {tireRow(vehicleData?.tires, 0)}
          </StyledOuterTireRow>
        </>
      )}
    </StyledContainer>
  );
};
