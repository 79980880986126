import { TimeZones } from "../../users/enum/timezones";

export const timezoneCode = (timezone: string) => {
  return timezone === TimeZones.US_ARIZONA || timezone === TimeZones.US_MOUNTAIN
    ? "MT"
    : timezone === TimeZones.US_CENTRAL
    ? "CT"
    : timezone === TimeZones.US_EASTERN
    ? "ET"
    : timezone === TimeZones.US_PACIFIC
    ? "PT"
    : "ET";
};
