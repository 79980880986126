import React, { FC } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { UserAddContainer } from "../../containers/UserAddContainer/UserAddContainer";

export const UserAddView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <PageTitle title="Add User" />
          <UserAddContainer />
        </Container>
      </Box>
    </PageLayout>
  );
};
