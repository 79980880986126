import React, { FC, useRef, useState, useEffect } from "react";
import { push } from "redux-first-history";
import { generatePath, useParams } from "react-router-dom";
import { FormikProps } from "formik";
import { Grid, Stack, IconButton, Box } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import { CreateEditTrailerDto } from "../../api/trailers.api.dto";
import { TireCoordinateDto } from "../../../vehicles/api/vehicles.api.dto";
import { selectIsEditTrailerPending } from "../../store/trailers.selector";
import { TrailerSetupFormContainer } from "../../containers/TrailerSetupFormContainer/TrailerSetupFormContainer";
import { TrailerSetupTiresContainer } from "../../containers/TrailerSetupTiresContainer/TrailerSetupTiresContainer";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { Button } from "../../../app/components/Button/Button";
import {
  TRAILER_SETUP_LIST,
  TRAILER_SETUP,
  TRAILER_SETUP_TENANT,
} from "../../../app/const/routes";
import { deleteTrailer } from "../../store/trailers.actions";
import { UserRole } from "../../../app/enum/UserRole";

export const TrailerSetupView: FC = () => {
  const dispatch = useAppDispatch();
  const { trailerId, tenantId } = useParams();
  const formikRef = useRef<FormikProps<CreateEditTrailerDto>>(null);
  const isEditTrailerPending = useAppSelector(selectIsEditTrailerPending);
  const [selectedTireCoordinates, setSelectedTireCoordinates] = useState<
    TireCoordinateDto[]
  >([]);
  const [tireCoordinatesError, setTireCoordinatesError] = useState<
    string | undefined
  >(undefined);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const changeTrailer = (
    trailerId: string | undefined,
    tenantId: string | undefined
  ) => {
    trailerId
      ? dispatch(
          push(
            canSeeTenants && tenantId
              ? generatePath(TRAILER_SETUP_TENANT, {
                  trailerId,
                  tenantId,
                })
              : generatePath(TRAILER_SETUP, {
                  trailerId,
                })
          )
        )
      : resetTrailerId();
  };

  const resetTrailerId = () => {
    dispatch(push(TRAILER_SETUP_LIST));
  };

  const handleDelete = () => {
    trailerId && dispatch(deleteTrailer({ trailerId, tenantId }));
  };

  useEffect(() => {
    selectedTireCoordinates &&
      formikRef.current?.setFieldValue("tiresMap", [
        ...selectedTireCoordinates,
      ]);
    setTimeout(() => {
      if (
        !formikRef.current?.dirty &&
        JSON.stringify(selectedTireCoordinates) !==
          JSON.stringify(formikRef.current?.initialValues.tiresMap)
      ) {
        formikRef.current?.setFieldTouched("tiresMap", true);
      }
    }, 1);
  }, [selectedTireCoordinates]);

  return (
    <PageLayout>
      <PageTitle title="Trailer Setup" />
      <Grid container spacing={7}>
        <Grid item sm={4}>
          <TrailerSetupFormContainer
            formikRef={formikRef}
            selectedTrailerId={trailerId}
            selectedTenantId={tenantId}
            showTenantIds={canSeeTenants}
            changeTrailer={changeTrailer}
            setTireCoordinatesError={setTireCoordinatesError}
          />
        </Grid>
        <Grid item sm={8}>
          <TrailerSetupTiresContainer
            trailerId={trailerId}
            tenantId={tenantId}
            selectedTireCoordinates={selectedTireCoordinates}
            setSelectedTireCoordinates={setSelectedTireCoordinates}
            errorMessage={tireCoordinatesError}
            showTenantIds={canSeeTenants}
          />
          <Box
            display="flex"
            justifyContent={trailerId ? "space-between" : "flex-end"}
            alignItems="center"
          >
            {trailerId && (
              <IconButton
                title="Delete trailer"
                aria-label="Delete trailer"
                size="large"
                edge="start"
                color="error"
                onClick={() => handleDelete()}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="text"
                onClick={() => resetTrailerId()}
                disabled={isEditTrailerPending}
              >
                Cancel
              </Button>
              <Button
                onClick={() => formikRef.current?.submitForm()}
                pending={isEditTrailerPending}
                disabled={isEditTrailerPending || !trailerId}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
