import React, { FC } from "react";
import { Formik } from "formik";
import {
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Button } from "../../../app/components/Button/Button";
import { UnitSystem, TimeZone } from "../../enum/settings.enum";
import { MeasurementEvent } from "../../../app/enum/Vehicle";
import { SettingsDto, EditSettingsDto } from "../../api/settings.api.dto";
import { editSettingsFormValidation } from "./EditSettingsForm.validation";
import { StyledFormSection } from "./EditSettingsForm.style";
// import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
// import { UserRole } from "../../../app/enum/UserRole";

interface IEditSettingsFormProps {
  settings: SettingsDto;
  tenantIds: string[];
  selectedTenantId: string | undefined;
  setSelectedTenantId: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSubmit: (formValues: EditSettingsDto) => void;
  onToggleEmailAlerts: () => void;
  showGlobalSettings?: boolean;
  showTenantField?: boolean;
  isPending?: boolean;
  isToggleEmailAlertsPending?: boolean;
  isGlobalSettingsPending?: boolean;
}

export const EditSettingsForm: FC<IEditSettingsFormProps> = ({
  settings,
  tenantIds,
  selectedTenantId,
  setSelectedTenantId,
  onSubmit,
  onToggleEmailAlerts,
  showGlobalSettings,
  showTenantField,
  isPending,
  isToggleEmailAlertsPending,
  isGlobalSettingsPending,
}) => {
  // TODO: activate email subscribe feature
  // const showSubscribeEmail = !useShowForRoles([UserRole.SuperAdmin]);
  const showSubscribeEmail = false;

  const isNotificationSilenced = (
    silencedNotifications: string | null,
    notification: string
  ) =>
    silencedNotifications !== null &&
    silencedNotifications.split(",").includes(notification);

  const appendSilencedNotification = (
    silencedNotifications: string | null,
    notification: string
  ) => {
    if (silencedNotifications === null) return "";
    const silencedNotificationsArray = silencedNotifications
      .split(",")
      .filter((value) => value !== "");
    if (
      !silencedNotificationsArray.includes(notification) &&
      notification !== ""
    ) {
      return [...silencedNotificationsArray, notification].join(",");
    }
    return silencedNotifications;
  };

  const removeSilencedNotification = (
    silencedNotifications: string | null,
    notification: string
  ) => {
    if (silencedNotifications === null) return "";
    const silencedNotificationsArray = silencedNotifications
      .split(",")
      .filter((value) => value !== "");
    if (
      silencedNotificationsArray.includes(notification) &&
      notification !== ""
    ) {
      return silencedNotificationsArray
        .filter((silencedNotification) => silencedNotification !== notification)
        .join(",");
    }
    return silencedNotifications;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        unitSystem: settings.unitSystem || UnitSystem.Imperial,
        timeZone: settings.timeZone || TimeZone.US_Pacific,
        silencedNotifications:
          settings.silencedNotifications ||
          settings.silencedNotifications === ""
            ? settings.silencedNotifications
            : null,
      }}
      validationSchema={editSettingsFormValidation}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          {showGlobalSettings && (
            <Typography variant="h2">My Account</Typography>
          )}
          <StyledFormSection noPadding={!showGlobalSettings}>
            <FormControl>
              <label htmlFor="time-zone">Time Zone</label>
              <Select
                id="time-zone"
                name="timeZone"
                variant="filled"
                fullWidth
                value={values.timeZone}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.timeZone && Boolean(errors.timeZone)}
                disabled={isPending}
                required
              >
                {Object.values(TimeZone).map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.timeZone && Boolean(errors.timeZone) && (
                <FormHelperText error>{errors.timeZone}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <label htmlFor="unit-system">Unit System</label>
              <Select
                id="unit-system"
                name="unitSystem"
                variant="filled"
                fullWidth
                value={values.unitSystem}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.unitSystem && Boolean(errors.unitSystem)}
                disabled={isPending}
                required
              >
                {Object.entries(UnitSystem).map(([key, value], index) => (
                  <MenuItem key={index} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              {touched.unitSystem && Boolean(errors.unitSystem) && (
                <FormHelperText error>{errors.unitSystem}</FormHelperText>
              )}
            </FormControl>
            {showSubscribeEmail && (
              <FormControl>
                <label>Email Alerts</label>
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => onToggleEmailAlerts()}
                    pending={isToggleEmailAlertsPending}
                    disabled={isPending || isToggleEmailAlertsPending}
                  >
                    {settings.emailSubscribed ? "Unsubscribe" : "Subscribe"}
                  </Button>
                </Box>
              </FormControl>
            )}
          </StyledFormSection>
          {showGlobalSettings && (
            <>
              <Typography variant="h2">My Fleet</Typography>
              <StyledFormSection>
                {showTenantField && (
                  <FormControl>
                    <label htmlFor="unit-system">Tenant</label>
                    <Autocomplete
                      id="tenant-id"
                      disablePortal
                      options={tenantIds}
                      value={selectedTenantId || null}
                      onBlur={handleBlur}
                      onChange={(
                        event: React.ChangeEvent<unknown>,
                        newValue: string | null
                      ) => {
                        setSelectedTenantId(newValue || undefined);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Tenant"
                          sx={{ minWidth: 130 }}
                        />
                      )}
                      disabled={isGlobalSettingsPending}
                    />
                  </FormControl>
                )}
                <FormControl>
                  <label>Email Alerts</label>
                  {/* TODO: add pressure_threshold_detection_{0,1,3,4} */}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            !isNotificationSilenced(
                              values.silencedNotifications,
                              MeasurementEvent.PRESSURE_THRESHOLD
                            )
                          }
                          onBlur={handleBlur}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "silencedNotifications",
                              event.target.checked
                                ? removeSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.PRESSURE_THRESHOLD
                                  )
                                : appendSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.PRESSURE_THRESHOLD
                                  )
                            );
                          }}
                          disabled={
                            isGlobalSettingsPending ||
                            (showTenantField && !selectedTenantId)
                          }
                        />
                      }
                      label="Pressure"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            !isNotificationSilenced(
                              values.silencedNotifications,
                              MeasurementEvent.TEMPERATURE_THRESHOLD
                            )
                          }
                          onBlur={handleBlur}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "silencedNotifications",
                              event.target.checked
                                ? removeSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.TEMPERATURE_THRESHOLD
                                  )
                                : appendSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.TEMPERATURE_THRESHOLD
                                  )
                            );
                          }}
                          disabled={
                            isGlobalSettingsPending ||
                            (showTenantField && !selectedTenantId)
                          }
                        />
                      }
                      label="Temperature"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            !isNotificationSilenced(
                              values.silencedNotifications,
                              MeasurementEvent.AIR_LEAK
                            )
                          }
                          onBlur={handleBlur}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "silencedNotifications",
                              event.target.checked
                                ? removeSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.AIR_LEAK
                                  )
                                : appendSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.AIR_LEAK
                                  )
                            );
                          }}
                          disabled={
                            isGlobalSettingsPending ||
                            (showTenantField && !selectedTenantId)
                          }
                        />
                      }
                      label="Air Leak"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            !isNotificationSilenced(
                              values.silencedNotifications,
                              MeasurementEvent.LOST_SIGNAL
                            )
                          }
                          onBlur={handleBlur}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue(
                              "silencedNotifications",
                              event.target.checked
                                ? removeSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.LOST_SIGNAL
                                  )
                                : appendSilencedNotification(
                                    values.silencedNotifications,
                                    MeasurementEvent.LOST_SIGNAL
                                  )
                            );
                          }}
                          disabled={
                            isGlobalSettingsPending ||
                            (showTenantField && !selectedTenantId)
                          }
                        />
                      }
                      label="Lost Signal"
                    />
                  </FormGroup>
                </FormControl>
              </StyledFormSection>
            </>
          )}
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                pending={isPending}
                disabled={isPending || isGlobalSettingsPending || !isValid}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};
