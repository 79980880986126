import { authApiClient } from "../../app/api/authApi.client";
import {
  SummaryDto,
  AlertDto,
  PageInfoDto,
} from "../../summary/api/summary.api.dto";
import { PagesDto } from "../../summary/api/summary.api.dto";

export const fetchSummaryEmail = async (
  terminalId: string,
  date: string,
  pageInfo?: PageInfoDto
): Promise<SummaryDto> => {
  try {
    const response = await authApiClient.get(
      `/notify/paginated/daily/${terminalId}/${date}/${pageInfo?.page || 0}/${
        pageInfo?.rowsPerPage || 5
      }`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAlertEmail = async (token: string): Promise<AlertDto> => {
  try {
    const response = await authApiClient.get(`/stats/alert/${token}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTireAlertEmailBcc = async (
  vehicleId: string,
  tenantId: string | undefined,
  emails: string[]
): Promise<void> => {
  try {
    const response = await authApiClient.put(
      `/data/vehicle/${vehicleId}/email`,
      {
        vehicleEmails: emails,
      },
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSummaryEmailPages = async (
  terminalId: string,
  tenantId: string | undefined,
  rowsPerPage: number | 5
): Promise<PagesDto> => {
  try {
    const response = await authApiClient.get(
      `/notify/pages/${terminalId}/${rowsPerPage}`,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
