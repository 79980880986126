import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Avatar } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../../../../assets/doran_logo.svg";
import { TOP_NAV_HEIGHT } from "../../const/nav";
import { ASPHALT, ASPHALT_LIGHT } from "../../theme/palette";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: TOP_NAV_HEIGHT,
  backgroundColor: ASPHALT_LIGHT,
  zIndex: theme.zIndex.drawer + 1,
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: TOP_NAV_HEIGHT,
  minHeight: "0px !important",
  paddingLeft: `${theme.spacing(3)} !important`,
  paddingRight: `${theme.spacing(3)} !important`,
}));

export const StyledNavLink = styled(NavLink)({
  display: "flex",
});

export const StyledLogo = styled(LogoSvg)({
  width: 126,
  height: 31,
});

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 35,
  height: 35,
  fontSize: theme.typography.body1.fontSize,
  backgroundColor: ASPHALT,
}));
