import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { PublicUserDto } from "../api/users.api.dto";

export interface UsersState {
  fetchAllUsers: AsyncState<PublicUserDto[]>;
  fetchUser: AsyncState<PublicUserDto | null>;
  addUser: AsyncState<null>;
  updateUser: AsyncState<null>;
  deactivateUser: AsyncState<null>;
  activateUser: AsyncState<null>;
  searchUsers: string;
}

export const usersState: UsersState = {
  fetchAllUsers: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  addUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  updateUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  deactivateUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  activateUser: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchUsers: "",
};
