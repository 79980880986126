import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { VehiclesState, vehiclesState } from "./vehicles.store";
import {
  fetchAllVehicles,
  fetchAllVehiclesStats,
  fetchVehicle,
  fetchVehicleCurrentStats,
  fetchVehicleHistoricalStats,
  editVehicle,
  fetchVehiclesPages,
  fetchAllVehiclesCurrentStats,
  fetchPressureHistory,
  fetchTemperatureHistory,
} from "./vehicles.actions";

const slice = createSlice({
  name: "vehicles",
  initialState: vehiclesState,
  reducers: {
    clearFetchAllVehiclesState: (state: VehiclesState) => ({
      ...state,
      fetchAllVehicles: vehiclesState.fetchAllVehicles,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVehicles.pending, (state) => ({
        ...state,
        fetchAllVehicles: {
          ...state.fetchAllVehicles,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllVehicles.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllVehicles: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllVehicles.rejected, (state) => ({
        ...state,
        fetchAllVehicles: {
          ...state.fetchAllVehicles,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAllVehiclesStats.pending, (state) => ({
        ...state,
        fetchAllVehiclesStats: {
          ...state.fetchAllVehiclesStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllVehiclesStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllVehiclesStats: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllVehiclesStats.rejected, (state) => ({
        ...state,
        fetchAllVehiclesStats: {
          ...state.fetchAllVehiclesStats,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchVehicle.pending, (state) => ({
        ...state,
        fetchVehicle: {
          ...state.fetchVehicle,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchVehicle.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchVehicle: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchVehicle.rejected, (state) => ({
        ...state,
        fetchVehicle: {
          ...state.fetchVehicle,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchVehicleCurrentStats.pending, (state) => ({
        ...state,
        fetchVehicleCurrentStats: {
          ...state.fetchVehicleCurrentStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchVehicleCurrentStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchVehicleCurrentStats: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchVehicleCurrentStats.rejected, (state) => ({
        ...state,
        fetchVehicleCurrentStats: {
          ...state.fetchVehicleCurrentStats,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAllVehiclesCurrentStats.pending, (state) => ({
        ...state,
        fetchAllVehiclesCurrentStats: {
          ...state.fetchAllVehiclesCurrentStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllVehiclesCurrentStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllVehiclesCurrentStats: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllVehiclesCurrentStats.rejected, (state) => ({
        ...state,
        fetchAllVehiclesCurrentStats: {
          ...state.fetchAllVehiclesCurrentStats,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchVehicleHistoricalStats.pending, (state) => ({
        ...state,
        fetchVehicleHistoricalStats: {
          ...state.fetchVehicleHistoricalStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchVehicleHistoricalStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchVehicleHistoricalStats: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchVehicleHistoricalStats.rejected, (state) => ({
        ...state,
        fetchVehicleHistoricalStats: {
          ...state.fetchVehicleHistoricalStats,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(editVehicle.pending, (state) => ({
        ...state,
        editVehicle: {
          ...state.editVehicle,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(editVehicle.fulfilled, (state) => ({
        ...state,
        editVehicle: {
          ...state.editVehicle,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(editVehicle.rejected, (state) => ({
        ...state,
        editVehicle: {
          ...state.editVehicle,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchVehiclesPages.pending, (state) => ({
        ...state,
        fetchVehiclesPages: {
          ...state.fetchVehiclesPages,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchVehiclesPages.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchVehiclesPages: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchVehiclesPages.rejected, (state) => ({
        ...state,
        fetchVehiclesPages: {
          ...state.fetchVehiclesPages,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchPressureHistory.pending, (state) => ({
        ...state,
        fetchPressureHistory: {
          ...state.fetchPressureHistory,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchPressureHistory.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchPressureHistory: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchPressureHistory.rejected, (state) => ({
        ...state,
        fetchPressureHistory: {
          ...state.fetchPressureHistory,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTemperatureHistory.pending, (state) => ({
        ...state,
        fetchTemperatureHistory: {
          ...state.fetchTemperatureHistory,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTemperatureHistory.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTemperatureHistory: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchTemperatureHistory.rejected, (state) => ({
        ...state,
        fetchTemperatureHistory: {
          ...state.fetchTemperatureHistory,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const { clearFetchAllVehiclesState } = slice.actions;
export default slice.reducer;
