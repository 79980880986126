import React, { FC } from "react";
import dayjs from "dayjs";
import { Box, TextField } from "@mui/material";
import { PageLayout } from "../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../app/components/PageTitle/PageTitle";
import {
  DatePicker,
  DateType,
} from "../../app/components/DatePicker/DatePicker";
import { WebDashboardContainer } from "../containers/WebDashboardContainer";
import { StyledContainer } from "./WebDashboardView.style";

export const WebDashboardView: FC = () => {
  const [selectedDate, setSelectedDate] = React.useState<DateType>(dayjs());

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <StyledContainer maxWidth="lg">
          <PageTitle title="Dashboard Analytics"></PageTitle>
          <Box mb={3}>
            <DatePicker
              value={selectedDate}
              onChange={(newValue) => {
                if (newValue && !selectedDate.isSame(newValue)) {
                  setSelectedDate(newValue);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label=""
                  inputProps={{
                    ...props.inputProps,
                    placeholder: "Select a date",
                  }}
                />
              )}
            />
          </Box>
          <WebDashboardContainer selectedDate={selectedDate} />
        </StyledContainer>
      </Box>
    </PageLayout>
  );
};
