import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { Notifications } from "./modules/app/components/Notifications/Notifications";
import { store, history } from "./modules/app/store";
import "./index.css";
import theme from "./modules/app/theme";
import "@fontsource/montserrat";
import "@fontsource/teko";
import { authApiClient } from "./modules/app/api/authApi.client";
import {
  onFulfilled,
  onRejected,
} from "./modules/app/api/interceptors/logout.interceptor";
import { AuthenticationRoute } from "./modules/auth/components/AuthenticationRoute/AuthenticationRoute";
import { ProtectedRoute } from "./modules/auth/components/ProtectedRoute/ProtectedRoute";
import { TenantRoute } from "./modules/auth/components/TenantRoute/TenantRoute";
import { NonTenantRoute } from "./modules/auth/components/NonTenantRoute/NonTenantRoute";
import { HomeRoute } from "./modules/auth/components/HomeRoute/HomeRoute";
import { UserRole } from "./modules/app/enum/UserRole";

import { LoginView } from "./modules/auth/views/LoginView/LoginView";
import { ForgotPasswordView } from "./modules/auth/views/ForgotPasswordView/ForgotPasswordView";
import { ResetPasswordView } from "./modules/auth/views/ResetPasswordView/ResetPasswordView";
import { ProfileView } from "./modules/profile/views/ProfileView/ProfileView";
import { SummaryView } from "./modules/summary/views/SummaryView/SummaryView";
import { TerminalsView } from "./modules/terminals/views/TerminalsView/TerminalsView";
import { TerminalsAddView } from "./modules/terminals/views/TerminalsAddView/TerminalsAddView";
import { TerminalsEditView } from "./modules/terminals/views/TerminalsEditView/TerminalsEditView";
import { UsersView } from "./modules/users/views/UsersView/UsersView";
import { SettingsView } from "./modules/settings/views/SettingsView/SettingsView";
import { UserAddView } from "./modules/users/views/UserAddView/UserAddView";
import { UserEditView } from "./modules/users/views/UserEditView/UserEditView";
import { VehicleStatusListView } from "./modules/vehicles/views/VehicleStatusListView/VehicleStatusListView";
import { TrailerStatusListView } from "./modules/trailers/views/TrailerStatusListView/TrailerStatusListView";
import { VehicleStatusView } from "./modules/vehicles/views/VehicleStatusView/VehicleStatusView";
import { VehicleSetupView } from "./modules/vehicles/views/VehicleSetupView/VehicleSetupView";
import { TrailerSetupView } from "./modules/trailers/views/TrailerSetupView/TrailerSetupView";
import { EmailWebView } from "./modules/email/views/EmailWebView";
import { TenantAddView } from "./modules/tenants/views/TenantAddView/TenantAddView";
import { TenantsView } from "./modules/tenants/views/TenantsView/TenantsView";
import { WebDashboardView } from "./modules/webAnalytics/views/WebDashboardView";
import { GPSMapView } from "./modules/map/views/GPSMapView";
import { AlertHistoryView } from "./modules/alertHistory/views/AlertHistoryView/AlertHistoryView";
import { TireAlertEmailSetupView } from "./modules/email/views/TireAlertEmailSetupView";
import { SummaryAlertEmailSetupView } from "./modules/email/views/SummaryAlertEmailSetupView";
import { AlertWebView } from "./modules/email/views/AlertWebView";
import { UnsubscribeView } from "./modules/unsubscribe/views/UnsubscribeView";

import {
  LOGIN,
  LOGIN_TENANT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  PROFILE,
  SETTINGS,
  SUMMARY,
  VEHICLE_STATUS_LIST,
  VEHICLE_STATUS,
  VEHICLE_STATUS_TENANT,
  VEHICLE_SETUP_LIST,
  VEHICLE_SETUP,
  VEHICLE_SETUP_TENANT,
  TRAILER_SETUP_LIST,
  TRAILER_SETUP,
  TRAILER_SETUP_TENANT,
  SUMMARY_WEB_VIEW,
  USER,
  USER_ADD,
  USER_EDIT,
  WEB_DASHBOARD,
  ALERT_HISTORY,
  HOME,
  TIRE_ALERT_EMAIL_SETUP,
  SUMMARY_ALERT_EMAIL_SETUP,
  GPS_MAP,
  ALERT_WEB_VIEW,
  TRAILER_STATUS_LIST,
  TERMINALS,
  TERMINALS_ADD,
  TERMINALS_EDIT,
  TERMINALS_EDIT_TENANT,
  UNSUBSCRIBE,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
  TENANTS,
  TENANT_ADD,
} from "./modules/app/const/routes";

authApiClient.interceptors.response.use(onFulfilled, onRejected);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={3000}
            maxSnack={4}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Routes>
              <Route element={<AuthenticationRoute />}>
                <Route
                  path={LOGIN}
                  element={
                    <NonTenantRoute fallbackRoute={LOGIN_TENANT}>
                      <LoginView />
                    </NonTenantRoute>
                  }
                />
                <Route path={LOGIN_TENANT} element={<LoginView />} />
                <Route path={UNSUBSCRIBE} element={<UnsubscribeView />} />
                <Route
                  path={UNSUBSCRIBE_SUCCESS}
                  element={<UnsubscribeView />}
                />
                <Route path={UNSUBSCRIBE_ERROR} element={<UnsubscribeView />} />
                <Route
                  path={FORGOT_PASSWORD}
                  element={<ForgotPasswordView />}
                />
                <Route
                  path={FORGOT_PASSWORD_SUCCESS}
                  element={<ForgotPasswordView />}
                />
                <Route path={RESET_PASSWORD} element={<ResetPasswordView />} />
                <Route
                  path={RESET_PASSWORD_SUCCESS}
                  element={<ResetPasswordView />}
                />
                <Route
                  path={RESET_PASSWORD_ERROR}
                  element={<ResetPasswordView />}
                />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path={HOME} element={<HomeRoute />} />
                <Route path={PROFILE} element={<ProfileView />} />
                <Route path={SETTINGS} element={<SettingsView />} />
                <Route path={ALERT_HISTORY} element={<AlertHistoryView />} />
                <Route path={VEHICLE_STATUS} element={<VehicleStatusView />} />
                <Route path={GPS_MAP} element={<GPSMapView />} />
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    roles={[
                      UserRole.SuperAdmin,
                      UserRole.Admin,
                      UserRole.FleetManager,
                      UserRole.Shop,
                    ]}
                    unauthorizedElement={<HomeRoute />}
                  />
                }
              >
                <Route path={SUMMARY} element={<SummaryView />} />
                <Route
                  path={VEHICLE_STATUS_LIST}
                  element={<VehicleStatusListView />}
                />
                <Route
                  path={VEHICLE_STATUS_TENANT}
                  element={
                    <TenantRoute fallbackRoute={VEHICLE_STATUS}>
                      <VehicleStatusView />
                    </TenantRoute>
                  }
                />
                <Route
                  path={VEHICLE_SETUP_LIST}
                  element={<VehicleSetupView />}
                />
                <Route path={VEHICLE_SETUP} element={<VehicleSetupView />} />
                <Route
                  path={VEHICLE_SETUP_TENANT}
                  element={
                    <TenantRoute fallbackRoute={VEHICLE_SETUP}>
                      <VehicleSetupView />
                    </TenantRoute>
                  }
                />
                <Route
                  path={TRAILER_STATUS_LIST}
                  element={<TrailerStatusListView />}
                />
                <Route
                  path={TRAILER_SETUP_LIST}
                  element={<TrailerSetupView />}
                />
                <Route path={TRAILER_SETUP} element={<TrailerSetupView />} />
                <Route
                  path={TRAILER_SETUP_TENANT}
                  element={
                    <TenantRoute fallbackRoute={TRAILER_SETUP}>
                      <TrailerSetupView />
                    </TenantRoute>
                  }
                />
                <Route path={SUMMARY_WEB_VIEW} element={<EmailWebView />} />
                <Route path={ALERT_WEB_VIEW} element={<AlertWebView />} />
                <Route
                  path={TIRE_ALERT_EMAIL_SETUP}
                  element={<TireAlertEmailSetupView />}
                />
                <Route
                  path={SUMMARY_ALERT_EMAIL_SETUP}
                  element={
                    <NonTenantRoute fallbackRoute={SUMMARY}>
                      <SummaryAlertEmailSetupView />
                    </NonTenantRoute>
                  }
                />
                <Route path={TERMINALS} element={<TerminalsView />} />
                <Route
                  element={
                    <ProtectedRoute
                      roles={[
                        UserRole.SuperAdmin,
                        UserRole.Admin,
                        UserRole.Shop,
                      ]}
                      unauthorizedElement={<HomeRoute />}
                    />
                  }
                >
                  <Route
                    path={TERMINALS_ADD}
                    element={
                      <TenantRoute fallbackRoute={TERMINALS}>
                        <TerminalsAddView />
                      </TenantRoute>
                    }
                  />
                  <Route
                    path={TERMINALS_EDIT}
                    element={
                      <TenantRoute fallbackRoute={TERMINALS}>
                        <TerminalsEditView />
                      </TenantRoute>
                    }
                  />
                  <Route
                    path={TERMINALS_EDIT_TENANT}
                    element={
                      <TenantRoute fallbackRoute={TERMINALS}>
                        <TerminalsEditView />
                      </TenantRoute>
                    }
                  />
                </Route>
                <Route path={USER} element={<UsersView />} />
                <Route
                  element={
                    <ProtectedRoute
                      roles={[
                        UserRole.SuperAdmin,
                        UserRole.Admin,
                        UserRole.Shop,
                      ]}
                      unauthorizedElement={<HomeRoute />}
                    />
                  }
                >
                  <Route path={USER_ADD} element={<UserAddView />} />
                  <Route path={USER_EDIT} element={<UserEditView />} />
                </Route>
                <Route path={WEB_DASHBOARD} element={<WebDashboardView />} />
                <Route
                  element={
                    <ProtectedRoute
                      roles={[UserRole.SuperAdmin]}
                      unauthorizedElement={<HomeRoute />}
                    />
                  }
                >
                  <Route
                    path={TENANTS}
                    element={
                      <TenantRoute fallbackRoute={HOME}>
                        <TenantsView />
                      </TenantRoute>
                    }
                  />
                  <Route path={TENANT_ADD} element={<TenantAddView />} />
                </Route>
              </Route>
              <Route path="*" element={<HomeRoute />} />
            </Routes>
            <Notifications />
          </SnackbarProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Router>
  </Provider>,
  document.getElementById("root")
);
