import { UnitSystem, UnitSymbol } from "../../settings/enum/settings.enum";

export const getPressureValue = (
  obj: {
    pressurePSI?: number | string;
    pressureKPA?: number | string;
  },
  unitSystem: UnitSystem
): number | string | null => {
  if (typeof obj === "object") {
    if (
      unitSystem === UnitSystem.Metric &&
      "pressureKPA" in obj &&
      obj.pressureKPA !== undefined
    ) {
      return obj.pressureKPA;
    }

    if ("pressurePSI" in obj && obj.pressurePSI !== undefined) {
      return obj.pressurePSI;
    }
  }

  return null;
};

export const getPressureUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.Metric) {
    return UnitSymbol.Kpa;
  }
  return UnitSymbol.Psi;
};
