import React, { FC } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import {
  ASPHALT_LIGHT,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
  BLACK,
  WHITE,
} from "../../app/theme/palette";
import { StyledChartContainer, StyledChartTypography } from "./Summary.style";
import { Loader } from "../../app/components/Loader/Loader";
import {
  EmptyFilter,
  IVehicleAlerts,
  Severity,
} from "../containers/SummaryTableContainer";

type EChartsOption = echarts.EChartsOption;

interface ISummaryPieChart {
  alertCounts: IVehicleAlerts;
  isVehicleDataPending: boolean;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const SummaryPieChart: FC<ISummaryPieChart> = ({
  alertCounts,
  isVehicleDataPending,
  filter,
  setFilter,
}) => {
  const pieOption: EChartsOption = {
    tooltip: {
      trigger: "item",
      formatter: "<b>{c} vehicle(s)</b>",
    },
    series: [
      {
        type: "pie",
        radius: "60%",
        avoidLabelOverlap: true,
        label: {
          formatter: "{b}{d}%",
          color: BLACK,
          fontFamily: "Montserrat",
          bleedMargin: 0,
        },
        labelLine: {
          length: 10,
          length2: 10,
          lineStyle: { color: ASPHALT_LIGHT, width: 2 },
        },
        data: [
          alertCounts.criticalCount > 0
            ? {
                id: Severity.Critical,
                value: alertCounts.criticalCount,
                name: "Critical - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
          alertCounts.cautionaryCount > 0
            ? {
                id: Severity.Cautionary,
                value: alertCounts.cautionaryCount,
                name: "Cautionary - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
          alertCounts.normalCount > 0
            ? {
                id: Severity.Normal,
                value: alertCounts.normalCount,
                name: "Normal - ",
                itemStyle: { borderColor: WHITE },
              }
            : {},
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
    color: [SCHOOL_BUS_RED, CAUTIONARY_ORANGE, TRUCKING_GREEN],
  };

  return (
    <StyledChartContainer>
      <StyledChartTypography>
        Overall Vehicles with Alerts
      </StyledChartTypography>
      {!isVehicleDataPending ? (
        <ReactECharts
          ref={(e) => {
            if (e) {
              const chart = e.getEchartsInstance();
              chart?.on("click", (params) => {
                // eslint-disable-next-line
                const optionId = params.data as any;
                filter === params.data ? setFilter("") : setFilter(optionId.id);

                chart.dispatchAction({
                  type: "downplay",
                  seriesIndex: 0,
                  dataIndex: params.currentIndex,
                });

                chart.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: params.dataIndex,
                });
              });

              chart.getZr().on("click", function (event) {
                if (!event.target) {
                  chart.clear();
                  chart.setOption(pieOption);
                  setFilter(EmptyFilter.NONE);
                }
              });
            }
          }}
          option={pieOption}
        />
      ) : (
        <Loader />
      )}
    </StyledChartContainer>
  );
};
