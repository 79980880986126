import React, { FC } from "react";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  DateRange as MuiDateRange,
  DateRangePicker as MuiDateRangePicker,
  DateRangePickerProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "5207f76cf69a32ea62f6b5b6d481a45bTz01MTAzNyxFPTE2OTQ5NzYzNzA4NzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export type DateType = Dayjs;
export type DateRangeType = MuiDateRange<DateType>;

export const DateRangePicker: FC<DateRangePickerProps<DateType, DateType>> = ({
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MuiDateRangePicker {...props} />
  </LocalizationProvider>
);
