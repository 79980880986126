import React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  Drawer,
  DrawerProps,
  MenuItem,
  MenuItemProps,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  TOP_NAV_HEIGHT,
  SIDE_NAV_MOBILE_WIDTH,
  SIDE_NAV_DESKTOP_WIDTH,
} from "../../const/nav";
import { ASPHALT, ASPHALT_LIGHT_ALT, TIRE_BLACK } from "../../theme/palette";

const TemporaryDrawer = (props: DrawerProps) => (
  <Drawer {...props} variant="temporary">
    {props.children}
  </Drawer>
);

const PermanentDrawer = (props: DrawerProps) => (
  <Drawer {...props} variant="permanent">
    {props.children}
  </Drawer>
);

export const StyledTemporaryDrawer = styled(TemporaryDrawer)({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: SIDE_NAV_MOBILE_WIDTH,
    paddingTop: TOP_NAV_HEIGHT,
    backgroundColor: ASPHALT_LIGHT_ALT,
  },
});

export const StyledPermanentDrawer = styled(PermanentDrawer)({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: SIDE_NAV_DESKTOP_WIDTH,
    paddingTop: TOP_NAV_HEIGHT,
    backgroundColor: ASPHALT_LIGHT_ALT,
  },
});

export const StyledMenuItem = styled(MenuItem)<
  MenuItemProps & {
    component?: React.ElementType;
  }
>({
  padding: 0,
});

const StyledMenuLink = ({ theme }: { theme: Theme }): CSSObject => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  color: TIRE_BLACK,
  textDecoration: "none",
  wordWrap: "break-word",
  whiteSpace: "normal",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),

  ".MuiSvgIcon-root": {
    color: ASPHALT,
    marginRight: theme.spacing(1),
  },

  "&.active": {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  ...StyledMenuLink({ theme }),
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  background: "transparent",

  "&.Mui-expanded, &.Mui-expanded:first-of-type": {
    marginTop: theme.spacing(1.5),
  },

  "&.Mui-expanded, &.Mui-expanded:last-of-type": {
    marginBottom: theme.spacing(2),
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  ...StyledMenuLink({ theme }),

  minHeight: "0px !important",
  alignItems: "flex-start",

  ".MuiAccordionSummary-content": {
    margin: 0,

    "&.Mui-expanded": {
      minHeight: 0,
      margin: 0,
    },
  },

  ".MuiAccordionSummary-expandIconWrapper": {
    marginLeft: theme.spacing(1),

    ".MuiSvgIcon-root": {
      marginRight: 0,
    },
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,

  ".MuiMenuItem-root > a": {
    paddingLeft: theme.spacing(5),
  },
}));
