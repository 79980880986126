import React, { FC, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridApi,
  GridCellValue,
  GridRenderCellParams,
  GridAlignment,
} from "@mui/x-data-grid";
import { PublicUserDto } from "../../api/users.api.dto";
import { StyledDisabledUser } from "./UsersTable.style";

interface IUsersTable {
  users: PublicUserDto[];
  goToUserEditScreen: (username: string) => void;
  showEditActions?: boolean;
  showTenantColumn?: boolean;
  isLoading?: boolean;
}

export const UsersTable: FC<IUsersTable> = ({
  users,
  goToUserEditScreen,
  showEditActions,
  showTenantColumn,
  isLoading,
}) => {
  const [pageSize, setPageSize] = useState<number>(25);

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Username",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) =>
        params.row.disabledTimestamp ? (
          <StyledDisabledUser>
            <Tooltip title={params.row.username}>
              <span>{params.row.username}</span>
            </Tooltip>
          </StyledDisabledUser>
        ) : (
          <Tooltip title={params.row.username}>
            <span>{params.row.username}</span>
          </Tooltip>
        ),
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.getValue(params.id, "firstName") || ""} ${
          params.getValue(params.id, "lastName") || ""
        }`,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.firstName + " " + params.row.lastName}>
          <span>{params.row.firstName + " " + params.row.lastName}</span>
        </Tooltip>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.email}>
          <span>{params.row.email}</span>
        </Tooltip>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) =>
        params.row.phoneNumber && (
          <Tooltip title={params.row.phoneNumber}>
            <span>{params.row.phoneNumber}</span>
          </Tooltip>
        ),
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.role}>
          <span>{params.row.role}</span>
        </Tooltip>
      ),
    },
    ...(showTenantColumn
      ? [
          {
            field: "tenantId",
            headerName: "Tenant",
            minWidth: 150,
            flex: 1,
            filterable: false,
            headerClassName: "heading",
            renderCell: (params: GridRenderCellParams) =>
              params.row.tenantId ? (
                <Tooltip title={params.row.tenantId}>
                  <span>{params.row.tenantId}</span>
                </Tooltip>
              ) : (
                "N/A"
              ),
          },
        ]
      : []),
    ...(showEditActions
      ? [
          {
            field: "action",
            headerName: "",
            align: "right" as GridAlignment,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
              const onClick = () => {
                const api: GridApi = params.api;
                const thisRow: Record<string, GridCellValue> = {};

                api
                  .getAllColumns()
                  .filter((c) => c.field !== "__check__" && !!c)
                  .forEach(
                    (c) =>
                      (thisRow[c.field] = params.getValue(params.id, c.field))
                  );

                if (thisRow.username) {
                  goToUserEditScreen(thisRow.username as string);
                }
              };

              return (
                <IconButton
                  title="Edit this user"
                  aria-label="Edit this user"
                  size="small"
                  onClick={onClick}
                >
                  <EditIcon />
                </IconButton>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <DataGrid
      rows={users}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[25, 50, 100]}
      onPageSizeChange={(newPageSize) => {
        setPageSize(newPageSize);
      }}
      pagination
      getRowId={(row) => row.username}
      loading={isLoading}
      hideFooterPagination={isLoading}
      componentsProps={{
        pagination: {
          showFirstButton: true,
          showLastButton: true,
        },
      }}
    />
  );
};
