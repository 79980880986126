import { apiClient } from "../../app/api/api.client";
import { authApiClient } from "../../app/api/authApi.client";
import {
  LoginDto,
  LoginResponse,
  RecoverPasswordDto,
  ResetPasswordDto,
} from "./auth.api.dto";
import { ProfileModel } from "../model/auth.model";
import { mapProfileDtoToModel } from "./auth.api.mapper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const login = async (credentials: LoginDto): Promise<LoginResponse> => {
  try {
    const { data } = await apiClient.post("/auth/login", credentials);
    return data;
  } catch (error) {
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = async (): Promise<any> => {
  try {
    const { data } = await authApiClient.post("/auth/logout");
    return data;
  } catch (error) {
    throw error;
  }
};

export const recoverPassword = async (
  recoverPasswordData: RecoverPasswordDto
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      "/auth/reset/request",
      recoverPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (
  resetPasswordData: ResetPasswordDto
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      "/auth/reset/submit",
      resetPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchProfile = async (): Promise<ProfileModel> => {
  try {
    const { data } = await authApiClient.get("/auth/me");
    return mapProfileDtoToModel(data);
  } catch (error) {
    throw error;
  }
};
