import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { LoginForm } from "../../components/LoginForm/LoginForm";
import { LoginDto } from "../../api/auth.api.dto";
import { login } from "../../store/auth.action";
import {
  selectIsLoginStatusPending,
  selectLoginRedirectToPreviousLocation,
} from "../../store/auth.selector";
import { LocationState } from "../../../app/models/LocationState";

export const LoginFormContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isLoginPending = useAppSelector(selectIsLoginStatusPending);
  const redirectToPreviousLocation = useAppSelector(
    selectLoginRedirectToPreviousLocation
  );
  const location = useLocation();
  const from = (location.state as LocationState)?.from;
  const previousLocation = from?.pathname;

  const onSubmit = (credentials: LoginDto) =>
    dispatch(
      login({
        credentials,
        ...(redirectToPreviousLocation && { redirect: previousLocation }),
      })
    );

  return <LoginForm onSubmit={onSubmit} isPending={isLoginPending} />;
};
