import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import {
  updateProfile as updateProfileRequest,
  uploadProfileImage as uploadProfileImageRequest,
  deleteProfileImage as deleteProfileImageRequest,
} from "../api/profile.api";
import { fetchProfile } from "../../auth/store/auth.action";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../app/store/notifications/notifications.slice";
import { EditProfileDto } from "../api/profile.api.dto";

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  withError(
    async (profileData: EditProfileDto, { dispatch }): Promise<void> => {
      try {
        await updateProfileRequest({ ...profileData, image: undefined });
        if (profileData.image !== undefined) {
          if (profileData.image) {
            const formData = new FormData();
            formData.append("file", profileData.image);
            await uploadProfileImageRequest(formData);
          } else {
            await deleteProfileImageRequest();
          }
        }
        dispatch(openSuccessNotification("Profile successfully updated"));
        dispatch(fetchProfile());
      } catch (err) {
        dispatch(
          openErrorNotification("Couldn't save changes to your profile")
        );
        throw err;
      }
    }
  )
);
