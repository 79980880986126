import { createSlice } from "@reduxjs/toolkit";
import { UnsubscribeState, unsubscribeState } from "./unsubscribe.store";

const slice = createSlice({
  name: "unsubscribe",
  initialState: unsubscribeState,
  reducers: {
    unsubscribeState: (state: UnsubscribeState) => ({
      ...state,
      unsubscribe: unsubscribeState.unsubscribe,
    }),
  },
});

export default slice.reducer;
