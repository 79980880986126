import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Add as AddIcon } from "@mui/icons-material";

import { USER_ADD } from "../../../app/const/routes";
import { Button, IButton } from "../../../app/components/Button/Button";

type IUserAddButton = IButton;

export const UserAddButton: FC<IUserAddButton> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={() => navigate(USER_ADD)}
      {...props}
    >
      New User
    </Button>
  );
};
