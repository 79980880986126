import React, { FC } from "react";
import { push } from "redux-first-history";
import { Formik } from "formik";
import { Box, Stack, FormControl, TextField } from "@mui/material";
import { Button } from "../../../app/components/Button/Button";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { TenantDto } from "../../api/tenants.api.dto";
import { TENANTS } from "../../../app/const/routes";
import { tenantAddFormValidation } from "./TenantForm.validation";

export interface ITenantFormProps {
  onSubmit: (formValues: TenantDto) => void;
  isPending?: boolean;
}

export const TenantForm: FC<ITenantFormProps> = ({ onSubmit, isPending }) => {
  const dispatch = useAppDispatch();
  const handleCancelEdit = () => {
    dispatch(push(TENANTS));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: "",
      }}
      validationSchema={tenantAddFormValidation}
      onSubmit={onSubmit}
    >
      {({
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="id">Id</label>
            <TextField
              id="id"
              name="id"
              variant="filled"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.id && Boolean(errors.id)}
              helperText={touched.id && errors.id}
              required
              disabled={isPending}
            />
          </FormControl>
          <Box>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="text" onClick={() => handleCancelEdit()}>
                Cancel
              </Button>
              <Button
                type="submit"
                pending={isPending}
                disabled={isPending || !isValid}
              >
                Save
              </Button>
            </Stack>
          </Box>
        </form>
      )}
    </Formik>
  );
};
