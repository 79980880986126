import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { authState } from "./auth.store";
import {
  login,
  logout,
  authorize,
  recoverPassword,
  resetPassword,
  fetchProfile,
} from "./auth.action";

const slice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    setLoginRedirectToPreviousLocation: (
      state,
      { payload }: { payload: boolean }
    ) => ({
      ...state,
      login: {
        ...state.login,
        redirectToPreviousLocation: payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Pending,
      },
      logout: { ...authState.logout },
    }));
    builder.addCase(login.fulfilled, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Fulfilled,
        redirectToPreviousLocation: false,
      },
    }));
    builder.addCase(login.rejected, (state) => ({
      ...state,
      login: {
        ...state.login,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(logout.pending, (state) => ({
      ...state,
      logout: { ...state.logout, status: AsyncStatus.Pending },
      login: {
        ...authState.login,
        redirectToPreviousLocation: state.login.redirectToPreviousLocation,
      },
      authorization: { ...authState.authorization },
      profile: { ...authState.profile },
    }));
    builder.addCase(logout.fulfilled, (state) => ({
      ...state,
      logout: { ...state.logout, status: AsyncStatus.Fulfilled },
    }));
    builder.addCase(logout.rejected, (state) => ({
      ...state,
      logout: { ...state.logout, status: AsyncStatus.Rejected },
    }));

    builder.addCase(authorize.pending, (state) => ({
      ...state,
      authorization: {
        ...state.authorization,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(authorize.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          profile: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
          authorization: {
            status: AsyncStatus.Fulfilled,
            data: true,
          },
        };
      }

      return state;
    });
    builder.addCase(authorize.rejected, (state) => ({
      ...state,
      authorization: {
        status: AsyncStatus.Rejected,
        data: false,
      },
    }));

    builder.addCase(recoverPassword.pending, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(recoverPassword.fulfilled, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(recoverPassword.rejected, (state) => ({
      ...state,
      recoverPassword: {
        ...state.recoverPassword,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(resetPassword.pending, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(resetPassword.fulfilled, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(resetPassword.rejected, (state) => ({
      ...state,
      resetPassword: {
        ...state.resetPassword,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(fetchProfile.pending, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          profile: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchProfile.rejected, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export const { setLoginRedirectToPreviousLocation } = slice.actions;
export default slice.reducer;
