import { authApiClient } from "../../app/api/authApi.client";
import { EditProfileDto } from "./profile.api.dto";

export const updateProfile = async (
  profileData: EditProfileDto
): Promise<void> => {
  try {
    const response = await authApiClient.put("/user", profileData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileImage = async (file: FormData): Promise<void> => {
  try {
    const response = await authApiClient.post("/user/image", file);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProfileImage = async (): Promise<void> => {
  try {
    const response = await authApiClient.delete("/user/image");
    return response.data;
  } catch (error) {
    throw error;
  }
};
