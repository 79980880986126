import { styled } from "@mui/system";
import { ASPHALT, WHITE } from "../../../app/theme/palette";

export const StyledButton = styled("button")`
  margin-left: 12px;
  margin-bottom: 10px;
  margin-right: 0 !important;
  background-color: ${ASPHALT};
  padding: 8px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: ${WHITE};
  border: none;
  cursor: pointer;
  height: 40px;
`;

export const StyledLabel = styled("div")`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${WHITE};
  margin-left: 6px;
`;
