import React, { FC } from "react";
import { Typography } from "@mui/material";

interface IPageTitle {
  title: string;
}

export const PageTitle: FC<IPageTitle> = ({ title }) => (
  <Typography variant="h1">{title}</Typography>
);
