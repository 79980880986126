import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { UsersState } from "./users.store";
import { PublicUserDto } from "../api/users.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectUsersRootState = createSelector(
  selectSelf,
  (state: RootState): UsersState => state.users
);

export const selectFetchAllUsersState = createSelector(
  selectUsersRootState,
  (users): AsyncState<PublicUserDto[]> => users.fetchAllUsers
);

export const selectFetchAllUsersData = createSelector(
  selectFetchAllUsersState,
  (fetchAllUsers): PublicUserDto[] => fetchAllUsers.data
);

export const selectFetchUserState = createSelector(
  selectUsersRootState,
  (users): AsyncState<PublicUserDto | null> => users.fetchUser
);

export const selectFetchUserData = createSelector(
  selectFetchUserState,
  (fetchUser): PublicUserDto | null => fetchUser.data
);

export const selectFetchUserStatus = createSelector(
  selectFetchUserState,
  (fetchUser): AsyncStatus => fetchUser.status
);

export const selectIsFetchUserPending = createSelector(
  selectFetchUserStatus,
  (status): boolean => status === AsyncStatus.Pending
);

export const selectIsFetchUserFulfilled = createSelector(
  selectFetchUserStatus,
  (status): boolean => status === AsyncStatus.Fulfilled
);

export const selectSearchUsers = createSelector(
  selectUsersRootState,
  (users): string => users.searchUsers
);

export const selectUsersFilteredData = createSelector(
  selectSearchUsers,
  selectFetchAllUsersData,
  (searchValue, users): PublicUserDto[] => {
    const data = users.map((user) => user);

    if (searchValue) {
      return data.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.phoneNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.role.toLowerCase().includes(searchValue.toLowerCase()) ||
          user.username.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);

export const selectFetchAllUsersStatus = createSelector(
  selectFetchAllUsersState,
  (fetchAllUsers): AsyncStatus => fetchAllUsers.status
);

export const selectIsFetchAllUsersPending = createSelector(
  selectFetchAllUsersStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAddUserState = createSelector(
  selectUsersRootState,
  (users): AsyncState<null> => users.addUser
);

export const selectAddUserStatus = createSelector(
  selectAddUserState,
  (addUser): AsyncStatus => addUser.status
);

export const selectIsAddUserPending = createSelector(
  selectAddUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectUpdateUserState = createSelector(
  selectUsersRootState,
  (users): AsyncState<null> => users.updateUser
);

export const selectUpdateUserStatus = createSelector(
  selectUpdateUserState,
  (updateUser): AsyncStatus => updateUser.status
);

export const selectIsUpdateUserPending = createSelector(
  selectUpdateUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeactivateUserState = createSelector(
  selectUsersRootState,
  (users): AsyncState<null> => users.deactivateUser
);

export const selectDeactivateUserStatus = createSelector(
  selectDeactivateUserState,
  (deactivateUser): AsyncStatus => deactivateUser.status
);

export const selectIsDeactivateUserPending = createSelector(
  selectDeactivateUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectActivateUserState = createSelector(
  selectUsersRootState,
  (users): AsyncState<null> => users.activateUser
);

export const selectActivateUserStatus = createSelector(
  selectActivateUserState,
  (activateUser): AsyncStatus => activateUser.status
);

export const selectIsActivateUserPending = createSelector(
  selectActivateUserStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
