import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { summaryState } from "./summary.store";
import {
  fetchDailySummary,
  fetchLatestSummary,
  fetchSummaryPages,
} from "./summary.action";

const slice = createSlice({
  name: "summary",
  initialState: summaryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailySummary.pending, (state) => ({
        ...state,
        fetchDailySummary: {
          ...state.fetchDailySummary,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchDailySummary.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchDailySummary: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchDailySummary.rejected, (state) => ({
        ...state,
        fetchDailySummary: {
          ...state.fetchDailySummary,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchLatestSummary.pending, (state) => ({
        ...state,
        fetchLatestSummary: {
          ...state.fetchLatestSummary,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchLatestSummary.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchLatestSummary: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchLatestSummary.rejected, (state) => ({
        ...state,
        fetchLatestSummary: {
          ...state.fetchLatestSummary,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchSummaryPages.pending, (state) => ({
        ...state,
        fetchSummaryPages: {
          ...state.fetchSummaryPages,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchSummaryPages.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchSummaryPages: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchSummaryPages.rejected, (state) => ({
        ...state,
        fetchSummaryPages: {
          ...state.fetchSummaryPages,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export default slice.reducer;
