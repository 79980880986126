import { AxiosError, AxiosResponse } from "axios";
import { store } from "../../store";
import { logout } from "../../../auth/store/auth.action";
import { setLoginRedirectToPreviousLocation } from "../../../auth/store/auth.slice";
import { openInfoNotification } from "../../store/notifications/notifications.slice";

const allowedToFail = ["/auth/me", "/auth/logout"];

export const onFulfilled = (response: AxiosResponse) => response;

export const onRejected = (error: AxiosError) => {
  const { config, response } = error;
  const originalRequest = config;
  const status = response?.status || null;
  const { url } = originalRequest;

  if (allowedToFail.includes(url as string)) {
    return Promise.reject(error);
  }

  if (status === 401) {
    const { dispatch } = store;

    dispatch(setLoginRedirectToPreviousLocation(true));
    dispatch(logout());

    return dispatch(
      openInfoNotification(
        "You don't have enough permissions to perform that operation or your session has expired"
      )
    );
  }

  return Promise.reject(error);
};
