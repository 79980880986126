import React, { FC, useEffect } from "react";
import { FormikProps } from "formik";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { convertTireCoordinatesToCountArray } from "../../utils/convert-tire-count-coordinates";
import { CreateEditVehicleDto } from "../../api/vehicles.api.dto";
import { fetchAllVehicles, editVehicle } from "../../store/vehicles.actions";
import { fetchAllUsers } from "../../../users/store/users.actions";
import {
  selectFetchAllVehiclesData,
  selectIsFetchAllVehiclesPending,
} from "../../store/vehicles.selector";
import {
  selectFetchAllUsersData,
  selectIsFetchAllUsersPending,
} from "../../../users/store/users.selector";
import {
  selectFetchAllTerminalsData,
  selectIsFetchAllTerminalsPending,
} from "../../../terminals/store/terminals.selector";
import { VehicleSetupForm } from "../../components/VehicleSetupForm/VehicleSetupForm";
import { UserRole } from "../../../app/enum/UserRole";
import { fetchAllTerminals } from "../../../terminals/store/terminals.actions";

export interface IVehicleSetupFormContainerProps {
  formikRef: React.Ref<FormikProps<CreateEditVehicleDto>>;
  selectedVehicleId: string | undefined;
  selectedTenantId: string | undefined;
  showTenantIds?: boolean;
  changeVehicle: (
    vehicleId: string | undefined,
    tenantId: string | undefined
  ) => void;
  setTireCoordinatesError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const VehicleSetupFormContainer: FC<IVehicleSetupFormContainerProps> = ({
  formikRef,
  selectedVehicleId,
  selectedTenantId,
  showTenantIds,
  changeVehicle,
  setTireCoordinatesError,
}) => {
  const dispatch = useAppDispatch();
  const terminals = useAppSelector(selectFetchAllTerminalsData);
  const users = useAppSelector(selectFetchAllUsersData);
  const vehicles = useAppSelector(selectFetchAllVehiclesData);
  const isVehiclesPending = useAppSelector(selectIsFetchAllVehiclesPending);
  const isUsersPending = useAppSelector(selectIsFetchAllUsersPending);
  const isTerminalsPending = useAppSelector(selectIsFetchAllTerminalsPending);
  const drivers = users
    .filter((user) => user.role === UserRole.Driver)
    .sort((d1, d2) => {
      return d1.username.localeCompare(d2.username);
    });

  const onSubmit = (vehicleFormData: CreateEditVehicleDto) => {
    return (
      selectedVehicleId &&
      dispatch(
        editVehicle({
          vehicleId: selectedVehicleId,
          tenantId: selectedTenantId,
          data: {
            ...vehicleFormData,
            tiresCount: convertTireCoordinatesToCountArray(
              vehicleFormData.tiresMap || []
            ),
          },
        })
      )
    );
  };

  useEffect(() => {
    dispatch(
      fetchAllVehicles({
        pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
      })
    );
    dispatch(fetchAllUsers());
    dispatch(fetchAllTerminals());
  }, [dispatch]);

  return (
    <VehicleSetupForm
      formikRef={formikRef}
      vehicles={vehicles}
      selectedVehicleId={selectedVehicleId}
      selectedTenantId={selectedTenantId}
      showTenantIds={showTenantIds}
      changeVehicle={changeVehicle}
      setTireCoordinatesError={setTireCoordinatesError}
      drivers={drivers}
      terminals={terminals}
      onSubmit={onSubmit}
      isPending={isVehiclesPending || isUsersPending || isTerminalsPending}
    />
  );
};
