import React, { FC } from "react";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { TerminalForm } from "../components/TerminalForm/TerminalForm";
import { UserRole } from "../../app/enum/UserRole";
import { selectIsFetchAllTerminalsPending } from "../store/terminals.selector";
import { CreateTerminalDto } from "../../terminals/api/terminals.api.dto";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { addTerminal } from "../store/terminals.actions";
import {
  selectFetchAllUsersData,
  selectIsFetchAllUsersPending,
} from "../../users/store/users.selector";

export const TerminalsAddContainer: FC = () => {
  const dispatch = useAppDispatch();
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const isFetchTerminalPending = useAppSelector(
    selectIsFetchAllTerminalsPending
  );

  const onCreateTerminal = (terminalModel: CreateTerminalDto) => {
    return dispatch(
      addTerminal({
        terminalModel,
        tenantId: terminalModel.tenantId,
      })
    );
  };

  const users = useAppSelector(selectFetchAllUsersData);
  const isUsersPending = useAppSelector(selectIsFetchAllUsersPending);

  return (
    <TerminalForm
      terminal={null}
      users={users}
      showTenantField={canSeeTenants}
      onSubmit={onCreateTerminal}
      isPending={isFetchTerminalPending || isUsersPending}
    />
  );
};
