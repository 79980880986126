import * as Yup from "yup";

export const forgotPasswordFormValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(8, "Username must have 8 to 50 characters")
    .max(50, "Username must have 8 to 50 characters")
    .required("Username is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(100, "Email must have 100 or fewer characters")
    .required("Email is required"),
});
