import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AlertsSummaryDto, PagesDto, SummaryDto } from "../api/summary.api.dto";

export interface SummaryState {
  fetchDailySummary: AsyncState<SummaryDto[]>;
  fetchLatestSummary: AsyncState<AlertsSummaryDto[]>;
  fetchSummaryPages: AsyncState<PagesDto>;
}

export const summaryState: SummaryState = {
  fetchDailySummary: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchLatestSummary: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchSummaryPages: {
    status: AsyncStatus.Void,
    data: { pageSize: 0, totalElements: 0, totalPages: 0 },
  },
};
