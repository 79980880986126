export enum UnitSystem {
  Metric = "metric",
  Imperial = "imperial",
}

export enum UnitSymbol {
  Fahrenheit = "°F",
  Celsius = "°C",
  Psi = "psi",
  Kpa = "kPa",
}

export enum TimeZone {
  US_Arizona = "US/Arizona",
  US_Central = "US/Central",
  US_Eastern = "US/Eastern",
  US_Mountain = "US/Mountain",
  US_Pacific = "US/Pacific",
}
