import { authApiClient } from "../../app/api/authApi.client";
import {
  CreateTerminalDto,
  TerminalDto,
  EditTerminalDto,
} from "./terminals.api.dto";

export const fetchAllTerminals = async (): Promise<TerminalDto[]> => {
  try {
    const response = await authApiClient.get(`/data/terminals`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTerminal = async (
  id: string,
  tenantId?: string
): Promise<TerminalDto> => {
  try {
    const response = await authApiClient.get(`/data/terminal/${id}`, {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTerminal = async (
  terminalId: string,
  tenantId: string | undefined,
  terminalModel: EditTerminalDto
): Promise<void> => {
  try {
    const response = await authApiClient.put(
      `/data/terminal/${terminalId}`,
      terminalModel,
      {
        tenantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Creates a Terminal. Available only in multi-tenant environments.
export const createTerminal = async (
  terminalModel: CreateTerminalDto,
  tenantId: string | undefined
): Promise<void> => {
  try {
    const response = await authApiClient.post("/data/terminal", terminalModel, {
      tenantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
