import React, { FC, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { Loader } from "../../../app/components/Loader/Loader";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";
import { authorize } from "../../store/auth.action";
import {
  selectIsAuthorizationVoid,
  selectIsAuthorizationPending,
  selectIsAuthorizationFulfilled,
  selectIsAuthorizationRejected,
} from "../../store/auth.selector";
import { LocationState } from "../../../app/models/LocationState";
import { HOME } from "../../../app/const/routes";

export interface IAuthenticationRoute {
  element?: React.ReactElement | null;
}

export const AuthenticationRoute: FC<IAuthenticationRoute> = ({
  element,
  children,
}) => {
  const dispatch = useAppDispatch();
  const isAuthorizationVoid = useAppSelector(selectIsAuthorizationVoid);
  const isAuthorizationPending = useAppSelector(selectIsAuthorizationPending);
  const isAuthorizationFulfilled = useAppSelector(
    selectIsAuthorizationFulfilled
  );
  const isAuthorizationRejected = useAppSelector(selectIsAuthorizationRejected);
  const location = useLocation();
  const from = (location.state as LocationState)?.from;
  const previousLocation = from?.pathname;
  const user = useCurrentUser();

  useEffect(() => {
    if (isAuthorizationVoid) {
      dispatch(authorize(true));
    }
  }, [dispatch]);

  if (isAuthorizationPending) {
    return <Loader />;
  }

  if (isAuthorizationRejected) {
    if (element) {
      return <>{element}</>;
    }

    if (children) {
      return <>{children}</>;
    }

    return <Outlet />;
  }

  if (isAuthorizationFulfilled) {
    return <Navigate to={previousLocation || user?.home || HOME} replace />;
  }

  return null;
};
