import dayjs from "dayjs";
import { authApiClient } from "../../app/api/authApi.client";
import {
  AlertsSummaryDto,
  PageInfoDto,
  PagesDto,
  SummaryDto,
} from "./summary.api.dto";
import { EmptyFilter } from "../containers/SummaryTableContainer";

export const fetchDailySummary = async (): Promise<SummaryDto[]> => {
  try {
    const response = await authApiClient.get(
      `/notify/paginated/daily/${dayjs().format("YYYY-MM-DD")}`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLatestSummary = async (
  pageInfo: PageInfoDto,
  severity?: string
): Promise<AlertsSummaryDto[]> => {
  try {
    const severityParam =
      severity && severity !== EmptyFilter.NONE ? severity.toLowerCase() : "";

    const response = await authApiClient.get(
      `/stats/alerts/${pageInfo.page}/${pageInfo.rowsPerPage}/${severityParam}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSummaryPages = async (
  pageInfo: PageInfoDto,
  severity?: string
): Promise<PagesDto> => {
  const severityParam =
    severity && severity !== EmptyFilter.NONE ? severity.toLowerCase() : "";

  try {
    const response = await authApiClient.get(
      `/stats/pages/alerts/${pageInfo.rowsPerPage}/${severityParam}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSummaryCsv = async (): Promise<string> => {
  try {
    const response = await authApiClient.get("/stats/download/alerts");
    return response.data;
  } catch (error) {
    throw error;
  }
};
