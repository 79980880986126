import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { fetchAllUsers } from "../../users/store/users.actions";
import {
  selectFetchAllUsersData,
  selectIsFetchAllUsersPending,
} from "../../users/store/users.selector";
import { EmailAlertDto } from "../api/email.api.dto";
import { EmailAlertSetupForm } from "../components/EmailAlertSetupForm/EmailAlertSetupForm";
import { editTireAlertEmailBcc } from "../store/email.actions";
import {
  fetchAllTerminals as fetchAllTerminalsAction,
  updateTerminalEmails as updateTerminalEmailsAction,
} from "../../terminals/store/terminals.actions";
import {
  selectFetchAllTerminalsAndPlaceholderTerminalData,
  selectIsFetchAllTerminalsPending,
} from "../../terminals/store/terminals.selector";
import { UserRole } from "../../app/enum/UserRole";

interface IEmailAlertSetupContainer {
  isSummaryScreen: boolean;
}

export const EmailAlertSetupContainer: FC<IEmailAlertSetupContainer> = ({
  isSummaryScreen,
}) => {
  const dispatch = useAppDispatch();
  const terminals = useAppSelector(
    selectFetchAllTerminalsAndPlaceholderTerminalData
  );
  const isTerminalsPending = useAppSelector(selectIsFetchAllTerminalsPending);
  const users = useAppSelector(selectFetchAllUsersData);
  const isUsersPending = useAppSelector(selectIsFetchAllUsersPending);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  useEffect(() => {
    dispatch(fetchAllTerminalsAction());
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const onSubmit = (formData: EmailAlertDto) => {
    !isSummaryScreen
      ? dispatch(
          editTireAlertEmailBcc({
            vehicleId: formData.vehicleId,
            tenantId: formData.vehicleTenantId,
            emails: formData.emails,
          })
        )
      : dispatch(
          updateTerminalEmailsAction({
            terminalId: formData.terminalId,
            tenantId: formData.terminalTenantId,
            terminalModel: {
              additionalEmailList: formData.emails,
            },
          })
        );
  };

  return (
    <EmailAlertSetupForm
      terminals={terminals}
      onSubmit={onSubmit}
      isTerminalsPending={isTerminalsPending}
      users={users}
      isUsersPending={isUsersPending}
      isSummaryScreen={isSummaryScreen}
      showTenantIds={canSeeTenants}
    />
  );
};
