import { styled } from "@mui/material/styles";
import {
  ASPHALT,
  ASPHALT_LIGHT,
  WHITE,
  BLACK,
} from "../../../app/theme/palette";

export const VehicleContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "disabled" && prop !== "interactive" && prop !== "sx",
})<{
  disabled?: boolean;
  interactive?: boolean;
}>(({ theme, disabled, interactive }) => ({
  opacity: disabled ? 0.25 : 1,
  marginRight: theme.spacing(5),

  ...(disabled && {
    userSelect: "none",
    pointerEvents: "none",
  }),

  ...(!disabled &&
    interactive && {
      userSelect: "none",
    }),

  "&:last-child": {
    marginRight: 0,
  },
}));

export const TireRow = styled("div", {
  shouldForwardProp: (prop) => prop !== "verticalAlign",
})<{
  verticalAlign: string;
}>(({ verticalAlign }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: verticalAlign === "bottom" ? "flex-end" : "flex-start",
}));

export const TireColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  marginRight: theme.spacing(2),

  "&:last-child": {
    marginRight: 0,
  },
}));

export const Tire = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "disabled" && prop !== "faded" && prop !== "interactive",
})<{
  disabled?: boolean;
  faded?: boolean;
  interactive?: boolean;
}>(({ theme, disabled, faded, interactive }) => ({
  height: 28,
  lineHeight: "28px",
  textAlign: "center",
  color: WHITE,
  backgroundColor: ASPHALT,
  borderRadius: Number(theme.shape.borderRadius) * 0.5,
  marginBottom: theme.spacing(2),

  ...((disabled || faded) && {
    backgroundColor: ASPHALT_LIGHT,
  }),

  ...(!disabled &&
    interactive && {
      cursor: "pointer",
      transition: theme.transitions.create(
        ["color", "background-color", "box-shadow"],
        {
          duration: theme.transitions.duration.shorter,
        }
      ),
      "&:hover": {
        color: BLACK,
        backgroundColor: faded ? ASPHALT : ASPHALT_LIGHT,
      },
      "&.selected": {
        boxShadow: `inset 0px 0px 0px 2.5px ${WHITE}, 0px 0px 0px 4px ${
          faded ? ASPHALT_LIGHT : ASPHALT
        }`,
      },
    }),
}));

export const VehicleBody = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: 100,
  backgroundColor: ASPHALT_LIGHT,
  borderRadius: Number(theme.shape.borderRadius),
  marginBottom: theme.spacing(2),
}));

export const VehicleDirectionLabel = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: theme.spacing(1),
  transform: "rotate(-90deg) translateX(-50%)",
  transformOrigin: "top left",
  fontSize: theme.typography.body3.fontSize,
  fontWeight: theme.typography.body3.fontWeight,
  lineHeight: 1,
}));

export const VehicleTypeLabel = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: "50%",
  transform: "translateX(-50%)",
  fontSize: theme.typography.body3.fontSize,
  fontWeight: theme.typography.body3.fontWeight,
  lineHeight: 1,
}));

export const VehicleNameLabel = styled("div")(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: "55%",
  transform: "translateY(-50%)",
  fontSize: theme.typography.body3.fontSize,
  fontWeight: theme.typography.body3.fontWeight,
  lineHeight: 1,
  textAlign: "center",
  paddingLeft: theme.spacing(3.5),
  paddingRight: theme.spacing(3.5),

  "& strong": {
    display: "block",
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}));
