import { createTheme, ThemeOptions } from "@mui/material/styles";
import components from "./components";
import palette from "./palette";
import spacing from "./spacing";
import shape from "./shape";
import typography from "./typography";

const theme = createTheme({
  components,
  palette,
  spacing,
  shape,
  typography,
} as ThemeOptions);

export default theme;
