import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import {
  Box,
  FormControl,
  TextField,
  Link,
  Alert,
  AlertTitle,
} from "@mui/material";
import { ResetPasswordDto } from "../../api/auth.api.dto";
import { resetPasswordFormValidationSchema } from "./ResetPasswordForm.validation";
import { Button } from "../../../app/components/Button/Button";
import { LOGIN, FORGOT_PASSWORD } from "../../../app/const/routes";

interface IResetPasswordForm {
  onSubmit: (formValues: ResetPasswordDto) => void;
  isPending: boolean;
  showSuccessNotification: boolean;
  showErrorNotification: boolean;
}

export const ResetPasswordForm: FC<IResetPasswordForm> = ({
  onSubmit,
  isPending,
  showSuccessNotification,
  showErrorNotification,
}) => {
  if (showSuccessNotification) {
    return (
      <Box>
        <Alert severity="success">
          <AlertTitle>Password changed</AlertTitle>
          Your password has been changed. You can now{" "}
          <Link component={RouterLink} to={LOGIN}>
            log in
          </Link>{" "}
          to the platform with your new credentials.
        </Alert>
        <Link component={RouterLink} to={LOGIN}>
          Go to Log In
        </Link>
      </Box>
    );
  }

  if (showErrorNotification) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Invalid link</AlertTitle>
          Unfortunately, the reset password link has been expired. Please use
          the{" "}
          <Link component={RouterLink} to={FORGOT_PASSWORD}>
            forgot password
          </Link>{" "}
          page to generate new link.
        </Alert>
        <Link component={RouterLink} to={FORGOT_PASSWORD}>
          Go to Forgot Password
        </Link>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
        password: "",
        passwordRepeat: "",
        resetPasswordToken: "",
      }}
      validationSchema={resetPasswordFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="reset-username">User name</label>
            <TextField
              id="reset-username"
              type="text"
              name="username"
              value={values.username}
              disabled={isPending}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="reset-email">Email address</label>
            <TextField
              id="reset-email"
              type="email"
              name="email"
              value={values.email}
              disabled={isPending}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <label htmlFor="reset-new-password">New password</label>
            <TextField
              id="reset-new-password"
              type="password"
              name="password"
              value={values.password}
              disabled={isPending}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormControl>
          <FormControl>
            <label htmlFor="reset-password-repeat">Repeat new password</label>
            <TextField
              id="reset-password-repeat"
              type="password"
              name="passwordRepeat"
              value={values.passwordRepeat}
              disabled={isPending}
              error={Boolean(touched.passwordRepeat && errors.passwordRepeat)}
              helperText={touched.passwordRepeat && errors.passwordRepeat}
              onBlur={handleBlur}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormControl>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link component={RouterLink} to={LOGIN}>
              Back to Log In
            </Link>
            <Button
              type="submit"
              pending={isPending}
              disabled={isPending || !isValid}
            >
              Change password
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
