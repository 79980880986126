import * as Yup from "yup";

export const loginFormValidationSchema = Yup.object().shape({
  username: Yup.string()
    .min(8, "Username must have 8 to 50 characters")
    .max(50, "Username must have 8 to 50 characters")
    .required("Username is required"),
  password: Yup.string()
    .min(8, "Password must have 8 to 50 characters")
    .max(50, "Password must have 8 to 50 characters")
    .required("Password is required"),
  tenantId: Yup.string().optional(),
});
