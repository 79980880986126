import React, { FC } from "react";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { isCurrentPath } from "../../../app/utils/is-current-path";
import { RecoverPasswordDto } from "../../api/auth.api.dto";
import { recoverPassword } from "../../store/auth.action";
import { selectIsRecoverPasswordStatusPending } from "../../store/auth.selector";
import { ForgotPasswordForm } from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import { FORGOT_PASSWORD_SUCCESS } from "../../../app/const/routes";

export const ForgotPasswordFormContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isRecoverPasswordPending = useAppSelector(
    selectIsRecoverPasswordStatusPending
  );

  const onSubmit = (recoverPasswordData: RecoverPasswordDto) =>
    dispatch(recoverPassword(recoverPasswordData));

  return (
    <ForgotPasswordForm
      onSubmit={onSubmit}
      isPending={isRecoverPasswordPending}
      showNotification={isCurrentPath(FORGOT_PASSWORD_SUCCESS)}
    />
  );
};
