import React, { FC } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { TenantsTableContainer } from "../../containers/TenantsTableContainer/TenantsTableContainer";
import { TenantAddButton } from "../../components/TenantAddButton/TenantAddButton";
import { TenantsSearchContainer } from "../../containers/TenantsSearchContainer/TenantsSearchContainer";

export const TenantsView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <PageTitle title="Tenants" />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <TenantsSearchContainer />
              <TenantAddButton sx={{ px: 2 }} />
            </Stack>
          </Grid>
        </Grid>
        <TenantsTableContainer />
      </Box>
    </PageLayout>
  );
};
