import React, { FC, useState } from "react";
import { TextField } from "@mui/material";

import { StyledSearchIcon } from "./TableSearch.style";

interface ITableSearchInput {
  onSearch: (searchTerm: string) => void;
  filter?: string;
}

const TableSearchInput: FC<ITableSearchInput> = ({ onSearch }) => {
  const [value, setValue] = useState<string>("");

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => (e.key === "Enter" ? onSearch(value) : null)}
      variant="filled"
      size="small"
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <StyledSearchIcon onClick={() => onSearch(value)} cursor="pointer" />
        ),
      }}
      sx={{ flex: 1 }}
    />
  );
};

export default TableSearchInput;
