import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TrailersState } from "./trailers.store";
import { TrailerDto } from "../api/trailers.api.dto";
import {
  HistoryDto,
  MeasurementDto,
  PagesDto,
} from "../../vehicles/api/vehicles.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectTrailersRootState = createSelector(
  selectSelf,
  (state: RootState): TrailersState => state.trailers
);

export const selectFetchAllTrailersState = createSelector(
  selectTrailersRootState,
  (trailers): AsyncState<TrailerDto[]> => trailers.fetchAllTrailers
);

export const selectFetchAllTrailersData = createSelector(
  selectFetchAllTrailersState,
  (fetchAllTrailers): TrailerDto[] => fetchAllTrailers.data
);

export const selectFetchAllTrailersStatus = createSelector(
  selectFetchAllTrailersState,
  (fetchAllTrailers): AsyncStatus => fetchAllTrailers.status
);

export const selectIsFetchAllTrailersPending = createSelector(
  selectFetchAllTrailersStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailerState = createSelector(
  selectTrailersRootState,
  (trailers): AsyncState<TrailerDto | null> => trailers.fetchTrailer
);

export const selectFetchTrailerData = createSelector(
  selectFetchTrailerState,
  (fetchTrailer): TrailerDto | null => fetchTrailer.data
);

export const selectFetchTrailerStatus = createSelector(
  selectFetchTrailerState,
  (fetchTrailer): AsyncStatus => fetchTrailer.status
);

export const selectIsFetchTrailerPending = createSelector(
  selectFetchTrailerStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailerCurrentStatsState = createSelector(
  selectTrailersRootState,
  (trailers): AsyncState<MeasurementDto | null> =>
    trailers.fetchTrailerCurrentStats
);

export const selectFetchTrailerCurrentStatsData = createSelector(
  selectFetchTrailerCurrentStatsState,
  (fetchTrailerCurrentStats): MeasurementDto | null =>
    fetchTrailerCurrentStats.data
);

export const selectFetchTrailerCurrentStatsStatus = createSelector(
  selectFetchTrailerCurrentStatsState,
  (fetchTrailerCurrentStats): AsyncStatus => fetchTrailerCurrentStats.status
);

export const selectIsFetchTrailerCurrentStatsPending = createSelector(
  selectFetchTrailerCurrentStatsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailerHistoricalStatsState = createSelector(
  selectTrailersRootState,
  (trailers): AsyncState<MeasurementDto | null> =>
    trailers.fetchTrailerHistoricalStats
);

export const selectFetchTrailerHistoricalStatsData = createSelector(
  selectFetchTrailerHistoricalStatsState,
  (fetchTrailerHistoricalStats): MeasurementDto | null =>
    fetchTrailerHistoricalStats.data
);

export const selectFetchTrailerHistoricalStatsStatus = createSelector(
  selectFetchTrailerHistoricalStatsState,
  (fetchTrailerHistoricalStats): AsyncStatus =>
    fetchTrailerHistoricalStats.status
);

export const selectIsFetchTrailerHistoricalStatsPending = createSelector(
  selectFetchTrailerHistoricalStatsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditTrailerState = createSelector(
  selectTrailersRootState,
  (trailers): AsyncState<null> => trailers.editTrailer
);

export const selectEditTrailerStatus = createSelector(
  selectEditTrailerState,
  (editTrailer): AsyncStatus => editTrailer.status
);

export const selectIsEditTrailerPending = createSelector(
  selectEditTrailerStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailersPagesState = createSelector(
  selectTrailersRootState,
  (pages): AsyncState<PagesDto> => pages.fetchTrailersPages
);

export const selectFetchTrailersPagesData = createSelector(
  selectFetchTrailersPagesState,
  (fetchTrailersPages): PagesDto => fetchTrailersPages.data
);

export const selectFetchTrailersPagesStatus = createSelector(
  selectFetchTrailersPagesState,
  (fetchTrailersPages): AsyncStatus => fetchTrailersPages.status
);

export const selectIsFetchTrailersPagesPending = createSelector(
  selectFetchTrailersPagesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailerPressureHistoryState = createSelector(
  selectTrailersRootState,
  (vehicle): AsyncState<HistoryDto[]> => vehicle.fetchTrailerPressureHistory
);

export const selectFetchTrailerPressureHistoryData = createSelector(
  selectFetchTrailerPressureHistoryState,
  (fetchTrailerPressureHistory): HistoryDto[] =>
    fetchTrailerPressureHistory.data
);

export const selectFetchTrailerPressureHistoryStatus = createSelector(
  selectFetchTrailerPressureHistoryState,
  (fetchTrailerPressureHistory): AsyncStatus =>
    fetchTrailerPressureHistory.status
);

export const selectIsFetchTrailerPressureHistoryPending = createSelector(
  selectFetchTrailerPressureHistoryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTrailerTemperatureHistoryState = createSelector(
  selectTrailersRootState,
  (trailer): AsyncState<HistoryDto[]> => trailer.fetchTrailerTemperatureHistory
);

export const selectFetchTrailerTemperatureHistoryData = createSelector(
  selectFetchTrailerTemperatureHistoryState,
  (fetchTrailerTemperatureHistory): HistoryDto[] =>
    fetchTrailerTemperatureHistory.data
);

export const selectFetchTrailerTemperatureHistoryStatus = createSelector(
  selectFetchTrailerTemperatureHistoryState,
  (fetchTrailerTemperatureHistory): AsyncStatus =>
    fetchTrailerTemperatureHistory.status
);

export const selectIsFetchTrailerTemperatureHistoryPending = createSelector(
  selectFetchTrailerTemperatureHistoryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
