import React, { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { fetchSummaryPages, fetchLatestSummary } from "../store/summary.action";
import { SummaryBarChart } from "../components/SummaryBarChart";
import { SummaryPieChart } from "../components/SummaryPieChart";
import {
  selectFetchAlertsPagesData,
  selectFetchAlertsSummaryData,
  selectIsFetchAlertsSummaryPending,
} from "../store/summary.selector";
import { SummaryDataTable } from "../components/SummaryDataTable";
import { StyledRow } from "../components/Summary.style";
import { fetchAllVehiclesCurrentStats } from "../../vehicles/store/vehicles.actions";
import {
  selectFetchAllVehiclesCurrentStatsData,
  selectIsFetchAllVehiclesCurrentStatsPending,
} from "../../vehicles/store/vehicles.selector";
import {
  VehicleMapDto,
  VehicleState,
  VehicleTerminalDto,
} from "../../vehicles/api/vehicles.api.dto";
import { GPSMapContainer } from "../../map/containers/GPSMapContainer";
import { UserRole } from "../../app/enum/UserRole";
import {
  getCautionaryTireCount,
  getCriticalTireCount,
  getOfflineTireCount,
} from "../utils/get-vehicle-tire-count";

export type IVehicleAlerts = {
  criticalCount: number;
  cautionaryCount: number;
  offlineCount: number;
  normalCount: number;
};

export type ITireAlerts = {
  criticalCount: number;
  cautionaryCount: number;
  offlineCount: number;
};

export enum EmptyFilter {
  NONE = "none",
}

export enum Severity {
  Critical = "critical",
  Cautionary = "cautionary",
  Normal = "normal",
  NoData = "noData",
}

export const SummaryTableContainer: FC = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const latestAlerts = useAppSelector(selectFetchAlertsSummaryData);
  const vehicles = useAppSelector(selectFetchAllVehiclesCurrentStatsData);
  const pageData = useAppSelector(selectFetchAlertsPagesData);
  const isVehicleDataPending = useAppSelector(
    selectIsFetchAllVehiclesCurrentStatsPending
  );
  const isTableSummaryDataPending = useAppSelector(
    selectIsFetchAlertsSummaryPending
  );
  const [filter, setFilter] = useState("");
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handlePageChange(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (page: number, rowsPerPage: number) =>
    dispatch(
      fetchLatestSummary({ pageInfo: { page, rowsPerPage }, severity: filter })
    );

  const vehicleAlerts = useMemo<IVehicleAlerts>(
    () =>
      (vehicles ?? []).reduce<IVehicleAlerts>(
        (count, vehicle) => {
          const isCritical = getCriticalTireCount(vehicle) > 0;
          const isCautionary = getCautionaryTireCount(vehicle) > 0;
          const isOffline = getOfflineTireCount(vehicle) > 0;

          if (isCritical) {
            return { ...count, criticalCount: count.criticalCount + 1 };
          } else if (isCautionary) {
            return { ...count, cautionaryCount: count.cautionaryCount + 1 };
          } else if (isOffline) {
            return { ...count, offlineCount: count.offlineCount + 1 };
          } else {
            return { ...count, normalCount: count.normalCount + 1 };
          }
        },
        {
          criticalCount: 0,
          cautionaryCount: 0,
          offlineCount: 0,
          normalCount: 0,
        }
      ),
    [vehicles]
  );

  const tireAlerts = useMemo<ITireAlerts>(
    () =>
      (vehicles ?? []).reduce<ITireAlerts>(
        (count, vehicle) => {
          const criticalTireCount = getCriticalTireCount(vehicle);
          const cautionaryTireCount = getCautionaryTireCount(vehicle);
          const offlineTireCount = getOfflineTireCount(vehicle);

          if (criticalTireCount > 0) {
            return {
              ...count,
              criticalCount: count.criticalCount + criticalTireCount,
            };
          } else if (cautionaryTireCount > 0) {
            return {
              ...count,
              cautionaryCount: count.cautionaryCount + cautionaryTireCount,
            };
          } else if (offlineTireCount > 0) {
            return {
              ...count,
              offlineCount: count.offlineCount + offlineTireCount,
            };
          } else {
            return { ...count };
          }
        },
        {
          criticalCount: 0,
          cautionaryCount: 0,
          offlineCount: 0,
        }
      ),
    [vehicles]
  );

  const terminals = useMemo(
    () =>
      vehicles
        ?.map((vehicle) => vehicle.terminal)
        .filter(
          (terminal, index, self): terminal is VehicleTerminalDto =>
            !!terminal && self.indexOf(terminal) === index
        ) ?? [],
    [vehicles]
  );

  const positions = useMemo(
    () =>
      vehicles?.map((vehicle) => {
        const isCritical = getCriticalTireCount(vehicle) > 0;
        const isCautionary = getCautionaryTireCount(vehicle) > 0;
        const isOffline = getOfflineTireCount(vehicle) > 0;

        return {
          lat: vehicle?.gps?.lat || 33.9364757,
          lng: vehicle?.gps?.lon || -118.3080658,
          name: vehicle?.name,
          make: vehicle?.make,
          model: vehicle?.model,
          drivers: vehicle?.drivers,
          alternateId: vehicle?.alternateId,
          tenantId: vehicle?.tenantId,
          state: isCritical
            ? VehicleState.Critical
            : isCautionary
            ? VehicleState.Cautionary
            : isOffline
            ? VehicleState.Offline
            : VehicleState.Normal,
        } as VehicleMapDto;
      }) ?? [],
    [vehicles]
  );

  const filteredPositions = useMemo(() => {
    switch (filter) {
      case "Critical":
      case "Critical - ":
        return positions.filter(
          (position) => position.state === VehicleState.Critical
        );
      case "Cautionary":
      case "Cautionary - ":
        return positions.filter(
          (position) => position.state === VehicleState.Cautionary
        );
      case "Lost Signal":
        return positions.filter(
          (position) => position.state === VehicleState.Offline
        );
      case "Normal - ":
        return positions.filter(
          (position) => position.state === VehicleState.Normal
        );
      default:
        return positions;
    }
  }, [positions, filter]);

  useEffect(() => {
    handlePageChange(page, rowsPerPage);
    dispatch(
      fetchSummaryPages({ pageInfo: { page, rowsPerPage }, severity: filter })
    );
    dispatch(fetchAllVehiclesCurrentStats());
  }, [filter]);

  useEffect(() => {
    handlePageChange(0, rowsPerPage);
  }, [rowsPerPage]);

  return (
    <>
      <StyledRow>
        <SummaryPieChart
          alertCounts={vehicleAlerts}
          isVehicleDataPending={isVehicleDataPending}
          filter={filter}
          setFilter={setFilter}
        />
        <SummaryBarChart
          tireAlerts={tireAlerts}
          isVehicleDataPending={isVehicleDataPending}
          filter={filter}
          setFilter={setFilter}
        />
        <GPSMapContainer
          fullView={false}
          filter={filter}
          vehicles={vehicles}
          positions={filteredPositions}
          terminals={terminals}
        />
      </StyledRow>
      <SummaryDataTable
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        pageData={pageData}
        latestAlerts={latestAlerts}
        isTableSummaryDataPending={isTableSummaryDataPending}
        showTenantIds={canSeeTenants}
      />
    </>
  );
};
