import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { EmailState } from "./email.store";
import {
  AlertDto,
  PagesDto,
  SummaryDto,
} from "../../summary/api/summary.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectEmailRootState = createSelector(
  selectSelf,
  (state: RootState): EmailState => state.email
);

export const selectFetchSummaryEmailState = createSelector(
  selectEmailRootState,
  (webview): AsyncState<SummaryDto | null> => webview.fetchSummaryEmail
);

export const selectFetchSummaryEmailData = createSelector(
  selectFetchSummaryEmailState,
  (fetchSummaryEmail): SummaryDto | null => fetchSummaryEmail.data
);

export const selectFetchSummaryEmailStatus = createSelector(
  selectFetchSummaryEmailState,
  (fetchSummaryEmail): AsyncStatus => fetchSummaryEmail.status
);

export const selectIsFetchSummaryEmailPending = createSelector(
  selectFetchSummaryEmailStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAlertEmailState = createSelector(
  selectEmailRootState,
  (webview): AsyncState<AlertDto | null> => {
    return webview.fetchAlertEmail;
  }
);

export const selectFetchAlertEmailData = createSelector(
  selectFetchAlertEmailState,
  (fetchAlertEmail): AlertDto | null => {
    return fetchAlertEmail.data;
  }
);

export const selectFetchAlertEmailStatus = createSelector(
  selectFetchAlertEmailState,
  (fetchAlertEmail): AsyncStatus => fetchAlertEmail.status
);

export const selectIsFetchAlertEmailPending = createSelector(
  selectFetchAlertEmailStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchEmailPagesState = createSelector(
  selectEmailRootState,
  (pages): AsyncState<PagesDto> => pages.fetchSummaryEmailPages
);

export const selectFetchEmailPagesData = createSelector(
  selectFetchEmailPagesState,
  (fetchSummaryEmailPages): PagesDto => fetchSummaryEmailPages.data
);

export const selectFetchEmailPagesStatus = createSelector(
  selectFetchEmailPagesState,
  (fetchSummaryEmailPages): AsyncStatus => fetchSummaryEmailPages.status
);

export const selectIsFetchEmailPagesPending = createSelector(
  selectFetchEmailPagesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
