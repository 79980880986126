import { createSlice } from "@reduxjs/toolkit";
import { gpsmapState } from "./gpsmap.store";

const slice = createSlice({
  name: "webview",
  initialState: gpsmapState,
  reducers: {},
});

export default slice.reducer;
