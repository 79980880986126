import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AlertHistoryState, alertHistoryState } from "./alertHistory.store";
import {
  fetchAllAlerts,
  fetchAlerts,
  fetchAlertsPages,
} from "./alertHistory.actions";

const slice = createSlice({
  name: "alerts",
  initialState: alertHistoryState,
  reducers: {
    clearFetchAllAlertsState: (state: AlertHistoryState) => ({
      ...state,
      fetchAllAlerts: alertHistoryState.fetchAllAlerts,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAlerts.pending, (state) => ({
        ...state,
        fetchAllAlerts: {
          ...state.fetchAllAlerts,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllAlerts.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllAlerts: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllAlerts.rejected, (state) => ({
        ...state,
        fetchAllAlerts: {
          ...state.fetchAllAlerts,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAlerts.pending, (state) => ({
        ...state,
        fetchAlerts: {
          ...state.fetchAlerts,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAlerts.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAlerts: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAlerts.rejected, (state) => ({
        ...state,
        fetchAlerts: {
          ...state.fetchAlerts,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAlertsPages.pending, (state) => ({
        ...state,
        fetchAlertsPages: {
          ...state.fetchAlertsPages,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAlertsPages.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAlertsPages: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchAlertsPages.rejected, (state) => ({
        ...state,
        fetchAlertsPages: {
          ...state.fetchAlertsPages,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const { clearFetchAllAlertsState } = slice.actions;
export default slice.reducer;
