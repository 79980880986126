import React, { FC } from "react";
import { Outlet, Navigate, generatePath, useParams } from "react-router-dom";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";

export interface ITenantRoute {
  fallbackRoute: string;
  element?: React.ReactElement | null;
}

export const TenantRoute: FC<ITenantRoute> = ({
  fallbackRoute,
  element,
  children,
}) => {
  const currentParams = useParams();
  const tenantIds = getTenantIds();

  if (tenantIds.length === 0) {
    return <Navigate to={generatePath(fallbackRoute, currentParams)} replace />;
  }

  if (element) {
    return <>{element}</>;
  }

  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
};
