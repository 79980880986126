import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TerminalsState } from "./terminals.store";
import { TerminalDto } from "../api/terminals.api.dto";
import { PLACEHOLDER_TERMINAL_ID } from "../../app/const/terminals";

export const selectSelf = (state: RootState): RootState => state;

export const selectTerminalsRootState = createSelector(
  selectSelf,
  (state: RootState): TerminalsState => state.terminals
);

export const selectFetchAllTerminalsState = createSelector(
  selectTerminalsRootState,
  (terminals): AsyncState<TerminalDto[]> => terminals.fetchAllTerminals
);

export const selectFetchAllTerminalsData = createSelector(
  selectFetchAllTerminalsState,
  (fetchAllTerminals): TerminalDto[] =>
    fetchAllTerminals.data.filter(
      (terminal) => terminal.id !== PLACEHOLDER_TERMINAL_ID
    )
);

export const selectFetchAllTerminalsAndPlaceholderTerminalData = createSelector(
  selectFetchAllTerminalsState,
  (fetchAllTerminals): TerminalDto[] => fetchAllTerminals.data
);

export const selectFetchAllTerminalsStatus = createSelector(
  selectFetchAllTerminalsState,
  (fetchAllTerminals): AsyncStatus => fetchAllTerminals.status
);

export const selectIsFetchAllTerminalsPending = createSelector(
  selectFetchAllTerminalsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTerminalState = createSelector(
  selectTerminalsRootState,
  (terminals): AsyncState<TerminalDto | null> => terminals.fetchTerminal
);

export const selectFetchTerminalData = createSelector(
  selectFetchTerminalState,
  (fetchTerminal): TerminalDto | null => fetchTerminal.data
);
