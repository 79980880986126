import { useEffect } from "react";
import { DEFAULT_FETCH_INTERVAL } from "../const/intervals";

export const useInterval = (
  callback: () => void,
  delay = DEFAULT_FETCH_INTERVAL
) => {
  useEffect(() => {
    const id = setInterval(callback, delay);
    return () => clearInterval(id);
  }, [delay]);
};
