import { UnitSystem, UnitSymbol } from "../../settings/enum/settings.enum";

export const getTemperatureValue = (
  obj: {
    temperatureFahrenheit?: number | string;
    temperatureCentigrade?: number | string;
  },
  unitSystem: UnitSystem
): number | string | null => {
  if (typeof obj === "object") {
    if (
      unitSystem === UnitSystem.Metric &&
      "temperatureCentigrade" in obj &&
      obj.temperatureCentigrade !== undefined
    ) {
      return obj.temperatureCentigrade;
    }

    if (
      "temperatureFahrenheit" in obj &&
      obj.temperatureFahrenheit !== undefined
    ) {
      return obj.temperatureFahrenheit;
    }
  }

  return null;
};

export const getTemperatureUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.Metric) {
    return UnitSymbol.Celsius;
  }
  return UnitSymbol.Fahrenheit;
};
