import { PressureStatus } from "../../app/enum/Vehicle";
import { TireDto } from "../api/vehicles.api.dto";

export const setTireStatus = (tire: TireDto) => {
  return tire.lostSignal ||
    !tire.temperatureCentigrade ||
    !tire.temperatureFahrenheit ||
    tire.pressurePSI === "System Error"
    ? "--"
    : tire.pressureStatus === PressureStatus.EUP
    ? "EUP"
    : tire.leak
    ? "TL"
    : tire.temperatureAlert
    ? "HT"
    : tire.pressureStatus === PressureStatus.EOP
    ? "EOP"
    : tire.pressureStatus === PressureStatus.UP
    ? "UP"
    : tire.pressureStatus === PressureStatus.OP
    ? "OP"
    : tire.pressureStatus === PressureStatus.GOOD
    ? "N"
    : "--";
};
