import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export interface ProfileState {
  updateProfile: AsyncState<null>;
}

export const profileState: ProfileState = {
  updateProfile: {
    status: AsyncStatus.Void,
    data: null,
  },
};
