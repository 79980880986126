import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import {
  VehicleDto,
  MeasurementDto,
  PagesDto,
  HistoryDto,
} from "../api/vehicles.api.dto";

export interface VehiclesState {
  fetchAllVehicles: AsyncState<VehicleDto[]>;
  fetchAllVehiclesStats: AsyncState<VehicleDto[]>;
  fetchVehicle: AsyncState<VehicleDto | null>;
  fetchVehicleCurrentStats: AsyncState<MeasurementDto | null>;
  fetchAllVehiclesCurrentStats: AsyncState<VehicleDto[]>;
  fetchVehicleHistoricalStats: AsyncState<MeasurementDto | null>;
  editVehicle: AsyncState<null>;
  fetchVehiclesPages: AsyncState<PagesDto>;
  fetchPressureHistory: AsyncState<HistoryDto[]>;
  fetchTemperatureHistory: AsyncState<HistoryDto[]>;
}

export const vehiclesState: VehiclesState = {
  fetchAllVehicles: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchAllVehiclesStats: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchVehicle: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchVehicleCurrentStats: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchAllVehiclesCurrentStats: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchVehicleHistoricalStats: {
    status: AsyncStatus.Void,
    data: null,
  },
  editVehicle: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchVehiclesPages: {
    status: AsyncStatus.Void,
    data: { pageSize: 0, totalElements: 0, totalPages: 0 },
  },
  fetchPressureHistory: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchTemperatureHistory: {
    status: AsyncStatus.Void,
    data: [],
  },
};
