import { Typography } from "@mui/material";
import styled from "styled-components";
import {
  ASPHALT,
  ASPHALT_LIGHT,
  ASPHALT_LIGHTER,
  TRUCKING_GREEN,
  SCHOOL_BUS_RED,
  CAUTIONARY_ORANGE,
  BLACK,
  WHITE,
} from "../../app/theme/palette";

export const StyledHeaderText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  display: inline-block;
  margin-bottom: 12px;
`;
export const StyledBlockHeaderText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  display: block;
  margin-bottom: 12px;
`;
export const StyledLink = styled.div`
  display: inline-block;
  a {
    color: inherit;
    text-decoration: none;
  }
  a:visited {
    color: inherit;
  }
`;
export const StyledContainer = styled.div`
  display: block;
  width: 651px;
  margin: 48px auto;
  padding-bottom: 48px;
`;

export const StyledTire = styled.div<{ alert: string }>`
  width: 44.84px;
  height: 22.42px;
  background-color: ${({ alert }) =>
    alert === "green"
      ? TRUCKING_GREEN
      : alert === "red"
      ? SCHOOL_BUS_RED
      : alert === "orange"
      ? CAUTIONARY_ORANGE
      : alert === "error"
      ? ASPHALT_LIGHTER
      : WHITE};
  margin: 5px;
  margin-right: 15px;
`;

export const StyledTireText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${WHITE};
`;

export const StyledTruck = styled.div`
  width: 177.76px;
  height: 89.68px;
  background: ${ASPHALT_LIGHT};
  border-radius: 4px;
  margin-right: 30px;
`;

export const StyledTrailer = styled.div`
  width: 443.61px;
  height: 89.68px;
  background: ${ASPHALT_LIGHT};
  border-radius: 4px;
`;

export const StyledRow = styled.div`
  display: inline-flex;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
`;

export const StyledInnerTireRow = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
  margin: auto;
  float: right;
`;

export const StyledOuterTireRow = styled.div`
  display: inline-flex;
  float: right;
`;

export const StyledTopText = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${ASPHALT};
  margin-left: 10px;
`;

export const StyledTypeText = styled(Typography)`
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  text-align: center;
`;

export const StyledText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  text-align: center;
`;
