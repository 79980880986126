import React, { FC } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { EditProfileFormContainer } from "../../containers/EditProfileFormContainer";

export const ProfileView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <PageTitle title="My Profile" />
          <EditProfileFormContainer />
        </Container>
      </Box>
    </PageLayout>
  );
};
