import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import {
  fetchUser as fetchUserAction,
  updateUser as updateUserAction,
  deactivateUser as deactivateUserAction,
  activateUser as activateUserAction,
} from "../../store/users.actions";
import { fetchAllTerminals as fetchAllTerminalsAction } from "../../../terminals/store/terminals.actions";
import {
  selectFetchUserData,
  selectIsFetchUserPending,
  selectIsAddUserPending,
  selectIsUpdateUserPending,
  selectIsDeactivateUserPending,
  selectIsActivateUserPending,
} from "../../store/users.selector";
import {
  selectFetchAllTerminalsData,
  selectIsFetchAllTerminalsPending,
} from "../../../terminals/store/terminals.selector";
import { CreateEditUserDto } from "../../api/users.api.dto";
import { UserForm } from "../../components/UserForm/UserForm";
import { UserRole } from "../../../app/enum/UserRole";

export const UserEditContainer: FC = () => {
  const { username } = useParams();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectFetchUserData);
  const terminals = useAppSelector(selectFetchAllTerminalsData);
  const isFetchUserPending = useAppSelector(selectIsFetchUserPending);
  const isFetchAllTerminalsPending = useAppSelector(
    selectIsFetchAllTerminalsPending
  );
  const isCreateUserPending = useAppSelector(selectIsAddUserPending);
  const isUpdateUserPending = useAppSelector(selectIsUpdateUserPending);
  const isDeactivateUserPending = useAppSelector(selectIsDeactivateUserPending);
  const isActivateUserPending = useAppSelector(selectIsActivateUserPending);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const canSeeTerminals = useShowForRoles([
    UserRole.SuperAdmin,
    UserRole.Admin,
  ]);

  const onUpdateUser = async (userModel: CreateEditUserDto): Promise<void> => {
    username && dispatch(updateUserAction({ username, userModel }));
  };

  const onDeactivateUser = async (username?: string): Promise<void> => {
    username && dispatch(deactivateUserAction({ username }));
  };

  const onActivateUser = async (username?: string): Promise<void> => {
    username && dispatch(activateUserAction({ username }));
  };

  useEffect(() => {
    if (canSeeTerminals) {
      dispatch(fetchAllTerminalsAction());
    }
  }, [dispatch]);

  useEffect(() => {
    if (username) {
      dispatch(fetchUserAction(username));
    }
  }, [dispatch, username]);

  return (
    <UserForm
      user={userData}
      terminals={terminals}
      showTenantField={canSeeTenants}
      showTerminalField={canSeeTerminals}
      onSubmit={onUpdateUser}
      onDelete={onDeactivateUser}
      onRestore={onActivateUser}
      isPending={
        isFetchUserPending ||
        isFetchAllTerminalsPending ||
        isCreateUserPending ||
        isUpdateUserPending ||
        isDeactivateUserPending ||
        isActivateUserPending
      }
    />
  );
};
