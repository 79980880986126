import React, { FC } from "react";
import { useMediaQuery, Theme, Box } from "@mui/material";
import { Loader } from "../../../app/components/Loader/Loader";
import {
  VehicleDto,
  TireCoordinateDto,
  TireCountDto,
} from "../../api/vehicles.api.dto";
import { TrailerDto } from "../../../trailers/api/trailers.api.dto";
import { convertTireCountToCoordinatesArray } from "../../utils/convert-tire-count-coordinates";
import { groupTireCoordinates } from "../../utils/group-tire-coordinates";
import { fillTireIdsForCoordinates } from "../../utils/fill-tire-ids-coordinates";
import {
  VehicleContainer,
  TireRow,
  TireColumn,
  Tire,
  VehicleBody,
  VehicleDirectionLabel,
  VehicleTypeLabel,
  VehicleNameLabel,
} from "./VehicleTireDiagram.style";

export type DiagramVehicleType = {
  type: "truck" | "trailer";
  data?: VehicleDto | TrailerDto;
  editable?: boolean;
};

type AxleSide = "left" | "right";

interface IVehicleTireDiagram {
  showIds?: boolean;
  interactive?: boolean;
  hideReadOnlyOnMobile?: boolean;
  vehicles: DiagramVehicleType[];
  selectedTireCoordinates?: TireCoordinateDto[];
  setSelectedTireCoordinates?: React.Dispatch<
    React.SetStateAction<TireCoordinateDto[]>
  >;
  isPending?: boolean;
  isDisabled?: boolean;
}

export const VehicleTireDiagram: FC<IVehicleTireDiagram> = ({
  showIds,
  interactive,
  hideReadOnlyOnMobile,
  vehicles,
  selectedTireCoordinates = [],
  setSelectedTireCoordinates,
  isPending,
  isDisabled,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const filteredVehicles =
    isMobile && hideReadOnlyOnMobile
      ? vehicles.filter((vehicle) => vehicle.editable)
      : vehicles;
  const tireCountTemplates: { [type: string]: TireCountDto[] } = {
    truck: [
      { axle: 0, tires: 4 },
      { axle: 1, tires: 4 },
      { axle: 2, tires: 4 },
    ],
    trailer: [
      { axle: 3, tires: 4 },
      { axle: 4, tires: 4 },
      { axle: 5, tires: 4 },
      { axle: 6, tires: 4 },
      { axle: 7, tires: 4 },
      { axle: 8, tires: 4 },
      { axle: 9, tires: 4 },
    ],
  };
  const axlesAcrossVehicles = Object.values(filteredVehicles).reduce(
    (total, { type }) => total + tireCountTemplates[type].length,
    0
  );

  const tireCoordinateIsSelected = (coordinate: TireCoordinateDto) =>
    selectedTireCoordinates.find(
      (selectedCoordinate) =>
        selectedCoordinate.axle === coordinate.axle &&
        selectedCoordinate.tire === coordinate.tire
    );

  const renderTire = (
    parentId: string,
    tire: TireCoordinateDto,
    oppositeTire: TireCoordinateDto,
    disabled?: boolean,
    faded?: boolean
  ) => {
    const id = `${parentId}-${tire.tire}`;
    if (disabled) {
      return (
        <Tire key={id} disabled>
          {showIds && `${tire.axle}${tire.tire}`}
        </Tire>
      );
    }
    if (interactive) {
      return (
        <Tire
          key={id}
          interactive
          faded={faded}
          className={tireCoordinateIsSelected(tire) ? "selected" : ""}
          onClick={() => {
            if (setSelectedTireCoordinates) {
              if (tireCoordinateIsSelected(tire)) {
                setSelectedTireCoordinates(
                  selectedTireCoordinates.filter(
                    (coordinate) =>
                      coordinate.axle !== tire.axle ||
                      (coordinate.tire !== tire.tire &&
                        coordinate.tire !== oppositeTire.tire)
                  )
                );
              } else {
                setSelectedTireCoordinates([
                  ...selectedTireCoordinates,
                  tire,
                  oppositeTire,
                ]);
              }
            }
          }}
        >
          {showIds && `${tire.axle}${tire.tire}`}
        </Tire>
      );
    }
    return (
      <Tire key={id} faded={faded}>
        {showIds && `${tire.axle}${tire.tire}`}
      </Tire>
    );
  };

  const renderTireColumn = (
    parentId: string,
    tires: TireCoordinateDto[],
    oppositeTires: TireCoordinateDto[],
    disabled?: boolean,
    faded?: boolean
  ) => {
    const id = `${parentId}-${tires[0].axle}`;
    return (
      <TireColumn key={id}>
        {[...tires]
          .reverse()
          .map((tire, index) =>
            renderTire(id, tire, oppositeTires[index], disabled, faded)
          )}
      </TireColumn>
    );
  };

  const renderTireRow = (
    parentId: string,
    side: AxleSide,
    tiresByAxle: TireCoordinateDto[][],
    oppositeTiresByAxle: TireCoordinateDto[][],
    disabled?: boolean,
    faded?: boolean
  ) => {
    const id = `${parentId}-${side}`;
    return (
      <TireRow key={id} verticalAlign={side === "left" ? "top" : "bottom"}>
        {tiresByAxle.map((tires, index) =>
          renderTireColumn(
            id,
            tires,
            oppositeTiresByAxle[index],
            disabled,
            faded
          )
        )}
      </TireRow>
    );
  };

  if (isPending) {
    return <Loader />;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {filteredVehicles.map(({ type, data, editable = false }, index) => {
        const id = `vehicle-${index}`;
        const typeLabel = type.charAt(0).toUpperCase() + type.slice(1);
        const leftTiresByAxle = groupTireCoordinates(
          tireCountTemplates[type].flatMap((count) => {
            const half = Math.floor(count.tires / 2);
            const coordinates = convertTireCountToCoordinatesArray({
              ...count,
              tires: half,
            });
            return fillTireIdsForCoordinates(coordinates);
          })
        );
        const rightTiresByAxle = groupTireCoordinates(
          tireCountTemplates[type].flatMap((count) => {
            const half = Math.floor(count.tires / 2);
            const coordinates = convertTireCountToCoordinatesArray(
              {
                ...count,
                tires: half,
              },
              half
            );
            return fillTireIdsForCoordinates(coordinates);
          })
        );
        const faded = !showIds;
        const disabled = isDisabled || (!showIds && interactive && !editable);
        return (
          <VehicleContainer
            key={id}
            disabled={disabled}
            interactive={interactive}
            sx={{
              flexGrow: tireCountTemplates[type].length / axlesAcrossVehicles,
            }}
          >
            {renderTireRow(
              id,
              "right",
              rightTiresByAxle,
              leftTiresByAxle,
              disabled,
              faded
            )}
            <VehicleBody key={`${id}-body`}>
              <VehicleTypeLabel>{typeLabel}</VehicleTypeLabel>
              {showIds && data && (
                <VehicleNameLabel>
                  <strong>{data.name}</strong>
                  ID: {data.alternateId}
                </VehicleNameLabel>
              )}
              <VehicleDirectionLabel>Front</VehicleDirectionLabel>
            </VehicleBody>
            {renderTireRow(
              id,
              "left",
              leftTiresByAxle,
              rightTiresByAxle,
              disabled,
              faded
            )}
          </VehicleContainer>
        );
      })}
    </Box>
  );
};
