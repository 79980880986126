import React, { FC } from "react";
import { Formik } from "formik";
import { Box, FormControl, TextField, Alert, AlertTitle } from "@mui/material";
import { UnsubscribeDto } from "../../api/unsubscribe.api.dto";
import { Button } from "../../../app/components/Button/Button";
import { unsubscribeFormValidationSchema } from "./UnsubscribeForm.validation";

interface IUnsubscribeForm {
  onSubmit: (formValues: UnsubscribeDto) => void;
  isPending: boolean;
  showSuccessNotification: boolean;
  showErrorNotification: boolean;
}

export const UnsubscribeForm: FC<IUnsubscribeForm> = ({
  onSubmit,
  isPending,
  showSuccessNotification,
  showErrorNotification,
}) => {
  if (showSuccessNotification) {
    return (
      <Box>
        <Alert severity="success">
          <AlertTitle>
            You have successfully unsubscribed. You&#39;ll no longer receive our
            emails, but you can change your decision at any time.
          </AlertTitle>
        </Alert>
      </Box>
    );
  }

  if (showErrorNotification) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Something went wrong.</AlertTitle>
        </Alert>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        username: "",
        email: "",
      }}
      validationSchema={unsubscribeFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        isValid,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormControl>
            <label htmlFor="forgot-email">Email address</label>
            <TextField
              id="forgot-email"
              type="email"
              name="email"
              value={values.email}
              disabled={isPending}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormControl>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              type="submit"
              pending={isPending}
              disabled={isPending || !isValid}
            >
              Unsubscribe
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
