import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AuthLoginState, AuthState } from "./auth.store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ProfileModel } from "../model/auth.model";

export const selectSelf = (state: RootState): RootState => state;

export const selectAuthState = createSelector(
  selectSelf,
  (state: RootState): AuthState => state.auth
);

export const selectLoginState = createSelector(
  selectAuthState,
  (auth: AuthState): AuthLoginState<null> => auth.login
);

export const selectLoginStatus = createSelector(
  selectLoginState,
  (login: AuthLoginState<null>): AsyncStatus => login.status
);

export const selectIsLoginStatusPending = createSelector(
  selectLoginStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectLoginRedirectToPreviousLocation = createSelector(
  selectLoginState,
  (login: AuthLoginState<null>): boolean => login.redirectToPreviousLocation
);

export const selectLogoutState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncState<null> => auth.logout
);

export const selectLogoutStatus = createSelector(
  selectLogoutState,
  (logout: AsyncState<null>): AsyncStatus => logout.status
);

export const selectIsLogoutStatusPending = createSelector(
  selectLogoutStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsLogoutStatusFulfilled = createSelector(
  selectLogoutStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Fulfilled
);

export const selectIsLogoutStatusRejected = createSelector(
  selectLogoutStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Rejected
);

export const selectAuthorizationState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncState<boolean> => auth.authorization
);

export const selectAuthorizationData = createSelector(
  selectAuthorizationState,
  (authorization: AsyncState<boolean>): boolean => authorization.data || false
);

export const selectAuthorizationStatus = createSelector(
  selectAuthorizationState,
  (data: AsyncState<boolean>): AsyncStatus => data.status
);

export const selectIsAuthorizationVoid = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Void
);

export const selectIsAuthorizationPending = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsAuthorizationFulfilled = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Fulfilled
);

export const selectIsAuthorizationRejected = createSelector(
  selectAuthorizationStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Rejected
);

export const selectRecoverPasswordState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncState<null> => auth.recoverPassword
);

export const selectRecoverPasswordStatus = createSelector(
  selectRecoverPasswordState,
  (recoverPassword: AsyncState<null>): AsyncStatus => recoverPassword.status
);

export const selectIsRecoverPasswordStatusPending = createSelector(
  selectRecoverPasswordStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectResetPasswordState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncState<null> => auth.resetPassword
);

export const selectResetPasswordStatus = createSelector(
  selectResetPasswordState,
  (resetPassword: AsyncState<null>): AsyncStatus => resetPassword.status
);

export const selectIsResetPasswordStatusPending = createSelector(
  selectResetPasswordStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectProfileState = createSelector(
  selectAuthState,
  (auth: AuthState): AsyncState<ProfileModel | null> => auth.profile
);

export const selectProfileData = createSelector(
  selectProfileState,
  (profile: AsyncState<ProfileModel | null>): ProfileModel | null =>
    profile.data || null
);

export const selectProfileStatus = createSelector(
  selectProfileState,
  (profile: AsyncState<ProfileModel | null>): AsyncStatus => profile.status
);

export const selectIsProfileStatusPending = createSelector(
  selectProfileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectIsProfileStatusFulfilled = createSelector(
  selectProfileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Fulfilled
);

export const selectIsProfileStatusRejected = createSelector(
  selectProfileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Rejected
);
