import React, { FC, useEffect, useState } from "react";
import { useCurrentUser } from "../../app/hooks/useCurrentUser";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { EditSettingsForm } from "../components/EditSettingsForm/EditSettingsForm";
import {
  fetchSilencedNotifications,
  saveSettings,
  subscribeToEmailAlerts,
  unsubscribeFromEmailAlerts,
} from "../store/settings.actions";
import { EditSettingsDto, SettingsDto } from "../api/settings.api.dto";
import {
  selectFetchSilencedNotificationsData,
  selectIsFetchSilencedNotificationsPending,
  selectIsSaveSettingsPending,
  selectIsSubscribeToEmailAlertsPending,
  selectIsUnsubscribeFromEmailAlertsPending,
} from "../store/settings.selector";
import { UserRole } from "../../app/enum/UserRole";

export const EditSettingsFormContainer: FC = () => {
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const silencedNotifications = useAppSelector(
    selectFetchSilencedNotificationsData
  );
  const isFetchSilencedNotificationsPending = useAppSelector(
    selectIsFetchSilencedNotificationsPending
  );
  const isSaveSettingsPending = useAppSelector(selectIsSaveSettingsPending);
  const isSubscribeToEmailAlertsPending = useAppSelector(
    selectIsSubscribeToEmailAlertsPending
  );
  const isUnsubscribeFromEmailAlertsPending = useAppSelector(
    selectIsUnsubscribeFromEmailAlertsPending
  );
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const canEditGlobalSettings = useShowForRoles([
    UserRole.SuperAdmin,
    UserRole.Admin,
  ]);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const getCurrentSettings = (): SettingsDto => ({
    unitSystem: user?.unitSystem,
    timeZone: user?.timeZone,
    emailSubscribed: user?.emailSubscribed,
    silencedNotifications,
  });

  const onSaveSettings = async (
    settingsData: EditSettingsDto
  ): Promise<void> => {
    dispatch(saveSettings({ settingsData, tenantId }));
  };

  const onToggleEmailAlerts = async (): Promise<void> => {
    if (user?.emailSubscribed) {
      dispatch(unsubscribeFromEmailAlerts());
    } else {
      dispatch(subscribeToEmailAlerts());
    }
  };

  useEffect(() => {
    if (canEditGlobalSettings && (!canSeeTenants || tenantId)) {
      dispatch(fetchSilencedNotifications({ tenantId }));
    }
  }, [dispatch, tenantId]);

  return (
    <EditSettingsForm
      settings={getCurrentSettings()}
      tenantIds={tenantIds}
      selectedTenantId={tenantId}
      setSelectedTenantId={setTenantId}
      onSubmit={onSaveSettings}
      onToggleEmailAlerts={onToggleEmailAlerts}
      showGlobalSettings={canEditGlobalSettings}
      showTenantField={canSeeTenants}
      isPending={isSaveSettingsPending}
      isToggleEmailAlertsPending={
        isSubscribeToEmailAlertsPending || isUnsubscribeFromEmailAlertsPending
      }
      isGlobalSettingsPending={
        isSaveSettingsPending || isFetchSilencedNotificationsPending
      }
    />
  );
};
