import React, { FC, useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  VehicleDto,
  MeasurementType,
} from "../../../vehicles/api/vehicles.api.dto";
import { TrailerDto } from "../../../trailers/api/trailers.api.dto";
import { MeasurementEvent } from "../../../app/enum/Vehicle";
import { MeasurementEventFilter } from "../../enum/alertHistory.enum";
import {
  DateRangePicker,
  DateRangeType,
} from "../../../app/components/DateRangePicker/DateRangePicker";
import {
  StyledWrapper,
  StyledFiltersWrapper,
} from "./AlertHistoryFilters.style";

interface IAlertHistoryFilters {
  tenantIds: string[];
  vehicles: VehicleDto[];
  trailers: TrailerDto[];
  selectedAlertTypes: MeasurementType[];
  selectedTenantId: string | undefined;
  selectedVehicleId: string | null;
  selectedTrailerId: string | null;
  selectedDateRange: DateRangeType;
  setSelectedAlertTypes: React.Dispatch<
    React.SetStateAction<MeasurementType[]>
  >;
  setSelectedTenantId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedVehicleId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedTrailerId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRangeType>>;
  showTenantFilter?: boolean;
  showVehicleAndTrailerFilter?: boolean;
  isLoading?: boolean;
}

export const AlertHistoryFilters: FC<IAlertHistoryFilters> = ({
  tenantIds,
  vehicles,
  trailers,
  selectedAlertTypes,
  selectedTenantId,
  selectedVehicleId,
  selectedTrailerId,
  setSelectedAlertTypes,
  setSelectedTenantId,
  setSelectedVehicleId,
  setSelectedTrailerId,
  setSelectedDateRange,
  showTenantFilter,
  showVehicleAndTrailerFilter,
  isLoading,
}) => {
  const [datePickerValue, setDatePickerValue] = useState<DateRangeType>([
    null,
    null,
  ]);

  const getVehicleIdOptions = () =>
    [...vehicles]
      .filter((vehicle) => !!vehicle.alternateId)
      .map((vehicle) => vehicle.alternateId) as string[];

  const getVehicleIdOptionLabel = (option: string) => {
    const match = vehicles.find((vehicle) => vehicle.alternateId === option);
    if (match) {
      return match.name
        ? `${match.alternateId} (${match.name})`
        : match.alternateId;
    }
    return "";
  };

  const getTrailerIdOptions = () =>
    [...trailers]
      .filter((trailer) => !!trailer.alternateId)
      .map((trailer) => trailer.alternateId) as string[];

  const getTrailerIdOptionLabel = (option: string) => {
    const match = trailers.find((trailer) => trailer.alternateId === option);
    if (match) {
      return match.name
        ? `${match.alternateId} (${match.name})`
        : match.alternateId;
    }
    return "";
  };

  return (
    <StyledWrapper>
      <Typography sx={{ minWidth: 80, mt: 0.7, mb: 1.5 }}>
        Filter by:
      </Typography>
      <StyledFiltersWrapper>
        <DateRangePicker
          value={datePickerValue}
          onChange={(newRange) => {
            if (
              newRange[0] &&
              newRange[1] &&
              (!newRange[0].isSame(datePickerValue[0]) ||
                !newRange[1].isSame(datePickerValue[1]))
            ) {
              setDatePickerValue(newRange);
              setSelectedDateRange([newRange[0], newRange[1]]);
            }
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                label=""
                inputProps={{
                  ...startProps.inputProps,
                  placeholder: "From date",
                }}
                sx={{ width: 130, mr: 2, mb: 0 }}
              />
              <TextField
                {...endProps}
                label=""
                inputProps={{
                  ...endProps.inputProps,
                  placeholder: "Until date",
                }}
                sx={{ width: 130, mb: 0 }}
              />
            </>
          )}
          disabled={isLoading}
        />
        <Select
          id="alert-type"
          multiple
          autoWidth
          displayEmpty
          value={selectedAlertTypes}
          onChange={(event: SelectChangeEvent<MeasurementType[]>) => {
            setSelectedAlertTypes(event.target.value as MeasurementType[]);
          }}
          renderValue={(selected) => (
            <Typography>
              Alert Type{selected.length > 0 && ` (${selected.length})`}
            </Typography>
          )}
          sx={{ minWidth: 150 }}
          disabled={isLoading}
        >
          {Object.entries(MeasurementEventFilter).map(([key, title]) => {
            const value =
              MeasurementEvent[key as keyof typeof MeasurementEvent];
            return (
              <MenuItem
                key={value}
                value={value}
                disabled={
                  selectedAlertTypes.indexOf(value) > -1 &&
                  selectedAlertTypes.length === 1
                }
              >
                <Checkbox checked={selectedAlertTypes.indexOf(value) > -1} />
                <ListItemText>{title}</ListItemText>
              </MenuItem>
            );
          })}
        </Select>
        {showTenantFilter && (
          <Autocomplete
            id="tenant-filter"
            disablePortal
            options={tenantIds}
            value={selectedTenantId || null}
            onChange={(
              event: React.ChangeEvent<unknown>,
              newValue: string | null
            ) => {
              setSelectedTenantId(newValue || undefined);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Tenant"
                sx={{ minWidth: 130 }}
              />
            )}
            disabled={isLoading}
          />
        )}
        {showVehicleAndTrailerFilter && (
          <Autocomplete
            id="vehicle-filter"
            disablePortal
            options={getVehicleIdOptions()}
            getOptionLabel={getVehicleIdOptionLabel}
            value={selectedVehicleId}
            freeSolo={true}
            onChange={(
              event: React.ChangeEvent<unknown>,
              newValue: string | null
            ) => {
              setSelectedVehicleId(newValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder="Vehicle"
                  sx={{ minWidth: 130 }}
                />
              );
            }}
            disabled={isLoading}
          />
        )}
        {showVehicleAndTrailerFilter && (
          <Autocomplete
            id="trailer-filter"
            disablePortal
            options={getTrailerIdOptions()}
            getOptionLabel={getTrailerIdOptionLabel}
            value={selectedTrailerId}
            freeSolo={true}
            onChange={(
              event: React.ChangeEvent<unknown>,
              newValue: string | null
            ) => {
              setSelectedTrailerId(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Trailer"
                sx={{ minWidth: 120 }}
              />
            )}
            disabled={isLoading}
          />
        )}
      </StyledFiltersWrapper>
    </StyledWrapper>
  );
};
