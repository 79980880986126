import { styled } from "@mui/material/styles";
import { MapContainer } from "react-leaflet";
import { Link } from "react-router-dom";

export const StyledMapContainer = styled(MapContainer)({
  height: "100%",
  maxHeight: 500,
  width: "100%",
  maxWidth: 1024,

  img: {
    height: "auto",
  },
});

export const StyledMapLink = styled(Link)({
  color: "inherit !important",
  textDecoration: "none",
});
