export const HOME = "/";
export const LOGIN = "/login";
export const LOGIN_TENANT = "/login/:tenantId";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_SUCCESS = `${FORGOT_PASSWORD}/success`;
export const RESET_PASSWORD = "/reset-password";
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}/success`;
export const RESET_PASSWORD_ERROR = `${RESET_PASSWORD}/error`;

export const PROFILE = "/profile";

export const SETTINGS = "/settings";

export const USER = "/user";
export const USER_ADD = `${USER}/add`;
export const USER_EDIT = `${USER}/edit/:username`;

export const UNSUBSCRIBE = "/unsubscribe";
export const UNSUBSCRIBE_SUCCESS = "/unsubscribe/success";
export const UNSUBSCRIBE_ERROR = "/unsubscribe/error";

export const SUMMARY = "/summary";

export const VEHICLE = "/vehicle";
export const VEHICLE_STATUS_LIST = `${VEHICLE}/status`;
export const VEHICLE_STATUS = `${VEHICLE}/status/:vehicleId`;
export const VEHICLE_STATUS_TENANT = `${VEHICLE}/status/:vehicleId/:tenantId`;
export const VEHICLE_SETUP_LIST = `${VEHICLE}/setup`;
export const VEHICLE_SETUP = `${VEHICLE}/setup/:vehicleId`;
export const VEHICLE_SETUP_TENANT = `${VEHICLE}/setup/:vehicleId/:tenantId`;

export const TIRE_ALERT_EMAIL_SETUP = `/email-setup/tire-alerts`;
export const SUMMARY_ALERT_EMAIL_SETUP = `/email-setup/summary-alerts`;

export const TRAILER = "/trailer";
export const TRAILER_STATUS_LIST = `${TRAILER}/status`;
export const TRAILER_STATUS = `${TRAILER}/status/:trailerId`;
export const TRAILER_STATUS_TENANT = `${TRAILER}/status/:trailerId/:tenantId`;
export const TRAILER_SETUP_LIST = `${TRAILER}/setup`;
export const TRAILER_SETUP = `${TRAILER}/setup/:trailerId`;
export const TRAILER_SETUP_TENANT = `${TRAILER}/setup/:trailerId/:tenantId`;

export const ALERT_HISTORY = "/alert-history";

export const SUMMARY_WEB_VIEW = "/summary_webview/:terminalId/:date";
export const ALERT_WEB_VIEW = "/alert_webview/:token";

export const WEB_DASHBOARD = "/web/dashboard";
export const TENANTS = "/tenants";
export const TENANT_ADD = `${TENANTS}/add`;

export const GPS_MAP = "/map";

export const TERMINALS = "/terminals";
export const TERMINALS_ADD = `${TERMINALS}/add`;
export const TERMINALS_EDIT = `${TERMINALS}/edit/:id`;
export const TERMINALS_EDIT_TENANT = `${TERMINALS}/edit/:id/:tenantId`;
