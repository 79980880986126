import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { WebAnalyticsState } from "./webanalytics.store";

export const selectSelf = (state: RootState): RootState => state;

export const selectWebAnalyticsConsumeRootState = createSelector(
  selectSelf,
  (state: RootState): WebAnalyticsState => state.webAnalytics
);

export const selectFetchWebAnalyticsConsumeState = createSelector(
  selectWebAnalyticsConsumeRootState,
  (webAnalytics): AsyncState<[] | null> => webAnalytics.consumeAnalytics
);

export const selectFetchWebAnalyticsConsumeData = createSelector(
  selectWebAnalyticsConsumeRootState,
  (webAnalytics): [] | null => webAnalytics.consumeAnalytics.data
);

export const selectFetchWebAnalyticsConsumeStatus = createSelector(
  selectWebAnalyticsConsumeRootState,
  (webAnalytics): AsyncStatus => webAnalytics.consumeAnalytics.status
);

export const selectIsFetchWebAnalyticsConsumePending = createSelector(
  selectFetchWebAnalyticsConsumeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

/* Message Ingest */

export const selectWebAnalyticsIngestRootState = createSelector(
  selectSelf,
  (state: RootState): WebAnalyticsState => state.webAnalytics
);

export const selectFetchWebAnalyticsIngestState = createSelector(
  selectWebAnalyticsIngestRootState,
  (webAnalytics): AsyncState<[] | null> => webAnalytics.ingestAnalytics
);

export const selectFetchWebAnalyticsIngestData = createSelector(
  selectWebAnalyticsIngestRootState,
  (webAnalytics): [] | null => webAnalytics.ingestAnalytics.data
);

export const selectFetchWebAnalyticsIngestStatus = createSelector(
  selectWebAnalyticsIngestRootState,
  (webAnalytics): AsyncStatus => webAnalytics.ingestAnalytics.status
);

export const selectIsFetchWebAnalyticsIngestPending = createSelector(
  selectFetchWebAnalyticsIngestStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

/* Queue Size */

export const selectQueueSizeRootState = createSelector(
  selectSelf,
  (state: RootState): WebAnalyticsState => state.webAnalytics
);

export const selectQueueSizeState = createSelector(
  selectQueueSizeRootState,
  (webAnalytics): AsyncState<[] | null> => webAnalytics.queueSize
);

export const selectQueueSizeData = createSelector(
  selectQueueSizeRootState,
  (webAnalytics): [] | null => webAnalytics.queueSize.data
);

export const selectQueueSizeStatus = createSelector(
  selectQueueSizeRootState,
  (webAnalytics): AsyncStatus => webAnalytics.queueSize.status
);

export const selectIsQueueSizePending = createSelector(
  selectQueueSizeStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
