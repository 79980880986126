import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { TrailerDto, CreateEditTrailerDto } from "../api/trailers.api.dto";
import {
  HistoryDto,
  MeasurementDto,
  PageInfoDto,
  PagesDto,
} from "../../vehicles/api/vehicles.api.dto";
import {
  fetchAllTrailers as fetchAllTrailersRequest,
  fetchTrailer as fetchTrailerRequest,
  fetchTrailerCurrentStats as fetchTrailerCurrentStatsRequest,
  fetchTrailerHistoricalStats as fetchTrailerHistoricalStatsRequest,
  editTrailer as editTrailerRequest,
  deleteTrailer as deleteTrailerRequest,
  fetchTrailersPages as fetchTrailersPagesRequest,
  fetchTrailerPressureHistory as fetchTrailerPressureHistoryRequest,
  fetchTrailerTemperatureHistory as fetchTrailerTemperatureHistoryRequest,
} from "../api/trailers.api";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";

export const fetchAllTrailers = createAsyncThunk(
  "trailers/fetchAllTrailers",
  withError(
    async (args?: {
      pageInfo: PageInfoDto;
      search?: string;
    }): Promise<TrailerDto[]> =>
      await fetchAllTrailersRequest(args?.pageInfo, args?.search)
  )
);

export const fetchTrailer = createAsyncThunk(
  "trailers/fetchTrailer",
  withError(
    async ({
      trailerId,
      tenantId,
    }: {
      trailerId: string;
      tenantId: string | undefined;
    }): Promise<TrailerDto> => await fetchTrailerRequest(trailerId, tenantId)
  )
);

export const fetchTrailerCurrentStats = createAsyncThunk(
  "trailers/fetchTrailerCurrentStats",
  withError(
    async ({
      trailerId,
      tenantId,
    }: {
      trailerId: string;
      tenantId: string | undefined;
    }): Promise<MeasurementDto> =>
      await fetchTrailerCurrentStatsRequest(trailerId, tenantId)
  )
);

export const fetchTrailerHistoricalStats = createAsyncThunk(
  "trailers/fetchTrailerHistoricalStats",
  withError(
    async ({
      trailerId,
      tenantId,
    }: {
      trailerId: string;
      tenantId: string | undefined;
    }): Promise<MeasurementDto> =>
      await fetchTrailerHistoricalStatsRequest(trailerId, tenantId)
  )
);

export const editTrailer = createAsyncThunk(
  "trailers/editTrailer",
  withError(
    async (
      {
        trailerId,
        tenantId,
        data,
      }: {
        trailerId: string;
        tenantId: string | undefined;
        data: CreateEditTrailerDto;
      },
      { dispatch }
    ): Promise<void> => {
      await editTrailerRequest(trailerId, tenantId, data);
      dispatch(openSuccessNotification("Trailer has been updated"));
      dispatch(
        fetchAllTrailers({
          pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
        })
      );
    }
  )
);

export const deleteTrailer = createAsyncThunk(
  "trailers/deleteTrailer",
  withError(
    async (
      {
        trailerId,
        tenantId,
      }: { trailerId: string; tenantId: string | undefined },
      { dispatch }
    ): Promise<void> => {
      await deleteTrailerRequest(trailerId, tenantId);
      dispatch(
        openSuccessNotification(`Trailer ${trailerId} has been deleted`)
      );
      dispatch(
        fetchAllTrailers({
          pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
        })
      );
    }
  )
);

export const fetchTrailersPages = createAsyncThunk(
  "trailers/fetchTrailersPages",
  withError(
    async (search?: string): Promise<PagesDto> =>
      await fetchTrailersPagesRequest(search)
  )
);

export const fetchTrailerPressureHistory = createAsyncThunk(
  "vehicles/fetchTrailerPressureHistory",
  withError(
    async ({
      trailerId,
      tenantId,
      dateFrom,
      dateTo,
    }: {
      trailerId: string;
      tenantId: string | undefined;
      dateFrom: string;
      dateTo: string;
    }): Promise<HistoryDto[]> =>
      await fetchTrailerPressureHistoryRequest(
        trailerId,
        tenantId,
        dateFrom,
        dateTo
      )
  )
);

export const fetchTrailerTemperatureHistory = createAsyncThunk(
  "vehicles/fetchTrailerTemperatureHistory",
  withError(
    async ({
      trailerId,
      tenantId,
      dateFrom,
      dateTo,
    }: {
      trailerId: string;
      tenantId: string | undefined;
      dateFrom: string;
      dateTo: string;
    }): Promise<HistoryDto[]> =>
      await fetchTrailerTemperatureHistoryRequest(
        trailerId,
        tenantId,
        dateFrom,
        dateTo
      )
  )
);
