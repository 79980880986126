import React, { FC } from "react";
import {
  Autocomplete,
  Checkbox,
  ListItemText,
  TextField,
  FormControl,
  Button,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import { EmailAlertDto } from "../../api/email.api.dto";
import { TerminalDto } from "../../../terminals/api/terminals.api.dto";
import {
  StyledColumn,
  StyledForm,
  StyledFormControl,
  StyledSubtext,
} from "./EmailAlertSetupForm.style";
import { EmailAlertSetupFormValidationSchema } from "./EmailAlertSetupForm.validation";
import { PublicUserDto } from "../../../users/api/users.api.dto";
import { ASPHALT_LIGHTER } from "../../../app/theme/palette";

interface IEmailAlertSetupForm {
  terminals: TerminalDto[];
  onSubmit: (formValues: EmailAlertDto) => void;
  isTerminalsPending: boolean;
  users: PublicUserDto[];
  isUsersPending: boolean;
  isSummaryScreen: boolean;
  showTenantIds?: boolean;
}
export const EmailAlertSetupForm: FC<IEmailAlertSetupForm> = ({
  terminals,
  onSubmit,
  isTerminalsPending,
  users,
  isUsersPending,
  isSummaryScreen,
  showTenantIds,
}) => {
  const concatTerminalOrVehicleTenantIds = (
    terminalOrVehicleId: string,
    tenantId: string | undefined
  ) => {
    if (showTenantIds) {
      return `${terminalOrVehicleId}|||${tenantId || ""}`;
    }
    return terminalOrVehicleId;
  };

  const splitTerminalTenantIds = (concatenatedIds: string) => {
    if (showTenantIds) {
      const [terminalId, tenantId] = concatenatedIds.split("|||", 2);
      return {
        terminalId,
        tenantId: tenantId || undefined,
      };
    }
    return {
      terminalId: concatenatedIds,
      tenantId: undefined,
    };
  };

  const splitVehicleTenantIds = (concatenatedIds: string) => {
    if (showTenantIds) {
      const [vehicleId, tenantId] = concatenatedIds.split("|||", 2);
      return {
        vehicleId,
        tenantId: tenantId || undefined,
      };
    }
    return {
      vehicleId: concatenatedIds,
      tenantId: undefined,
    };
  };

  const findTerminal = (terminalId: string, tenantId: string | undefined) =>
    terminals.find(
      (terminal) =>
        terminal.id === terminalId &&
        (!showTenantIds || terminal.tenantId === tenantId)
    );

  const getTerminalOptions = () =>
    [...terminals].map((terminal) =>
      concatTerminalOrVehicleTenantIds(terminal.id, terminal.tenantId)
    );

  const getTerminalOptionLabel = (option: string) => {
    const { terminalId, tenantId } = splitTerminalTenantIds(option);
    const match = findTerminal(terminalId, tenantId);
    let label = "";
    if (match) {
      if (match.name) {
        label = match.name;
      } else {
        label = match.id;
      }
      if (showTenantIds && match.tenantId) {
        label += ` [${match.tenantId}]`;
      }
    }
    return label;
  };

  const getVehicleOptions = (
    terminalId: string,
    terminalTenantId: string | undefined
  ) =>
    findTerminal(terminalId, terminalTenantId)?.vehicles?.map((vehicle) =>
      concatTerminalOrVehicleTenantIds(vehicle.alternateId, vehicle.tenantId)
    ) || [];

  const getVehicleOptionLabel = (
    terminalId: string,
    terminalTenantId: string | undefined,
    option: string
  ) => {
    const { vehicleId, tenantId: vehicleTenantId } =
      splitVehicleTenantIds(option);
    const match = findTerminal(terminalId, terminalTenantId)?.vehicles?.find(
      (vehicle) =>
        vehicle.alternateId === vehicleId &&
        (!showTenantIds || vehicle.tenantId === vehicleTenantId)
    );
    let label = "";
    if (match) {
      if (match.name) {
        label = match.name;
      } else {
        label = match.alternateId;
      }
      if (showTenantIds && match.tenantId) {
        label += ` [${match.tenantId}]`;
      }
    }
    return label;
  };

  const getAlertEmailOptions = (
    terminalId: string,
    terminalTenantId: string | undefined,
    vehicleId: string,
    vehicleTenantId: string | undefined
  ) =>
    findTerminal(terminalId, terminalTenantId)?.vehicles?.find(
      (vehicle) =>
        vehicle.alternateId === vehicleId &&
        (!showTenantIds || vehicle.tenantId === vehicleTenantId)
    )?.vehicleEmails || [];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        vehicleId: "",
        vehicleTenantId: "",
        terminalId: "",
        terminalEmail: "",
        terminalTenantId: "",
        emails: [] as string[],
      }}
      validationSchema={EmailAlertSetupFormValidationSchema(isSummaryScreen)}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <StyledForm onSubmit={handleSubmit} noValidate>
          <StyledColumn style={{ flex: 1 }}>
            <StyledFormControl>
              <label>Select Terminal</label>
              <Autocomplete
                disableClearable
                options={getTerminalOptions()}
                getOptionLabel={getTerminalOptionLabel}
                value={concatTerminalOrVehicleTenantIds(
                  values.terminalId,
                  values.terminalTenantId
                )}
                onBlur={handleBlur}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string | null
                ) => {
                  if (newValue) {
                    const { terminalId, tenantId } =
                      splitTerminalTenantIds(newValue);
                    setFieldValue("terminalId", terminalId);
                    setFieldValue("terminalTenantId", tenantId);
                    setFieldValue(
                      "terminalEmail",
                      findTerminal(terminalId, tenantId)?.email || ""
                    );
                    setFieldValue(
                      "emails",
                      !isSummaryScreen
                        ? []
                        : findTerminal(terminalId, tenantId)
                            ?.additionalEmailList || []
                    );
                  } else {
                    setFieldValue("terminalId", "");
                    setFieldValue("terminalTenantId", "");
                    setFieldValue("terminalEmail", "");
                    setFieldValue("emails", []);
                  }
                  setFieldValue("vehicleId", "");
                  setFieldValue("vehicleTenantId", "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.terminalId && errors.terminalId)}
                    helperText={touched.terminalId && errors.terminalId}
                  />
                )}
                disabled={isTerminalsPending}
              />
            </StyledFormControl>
            {!isSummaryScreen ? (
              <StyledFormControl>
                <label>Select Vehicle</label>
                <Autocomplete
                  disableClearable
                  options={getVehicleOptions(
                    values.terminalId,
                    values.terminalTenantId
                  )}
                  getOptionLabel={(option) =>
                    getVehicleOptionLabel(
                      values.terminalId,
                      values.terminalTenantId,
                      option
                    )
                  }
                  value={concatTerminalOrVehicleTenantIds(
                    values.vehicleId,
                    values.vehicleTenantId
                  )}
                  onBlur={handleBlur}
                  onChange={(
                    event: React.ChangeEvent<unknown>,
                    newValue: string | null
                  ) => {
                    if (newValue) {
                      const { vehicleId, tenantId } =
                        splitVehicleTenantIds(newValue);
                      setFieldValue(
                        "emails",
                        getAlertEmailOptions(
                          values.terminalId,
                          values.terminalTenantId,
                          vehicleId,
                          tenantId
                        )
                      );
                      setFieldValue("vehicleId", vehicleId);
                      setFieldValue("vehicleTenantId", tenantId);
                    } else {
                      setFieldValue("emails", []);
                      setFieldValue("vehicleId", "");
                      setFieldValue("vehicleTenantId", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.vehicleId && errors.vehicleId)}
                      helperText={touched.vehicleId && errors.vehicleId}
                    />
                  )}
                  disabled={isTerminalsPending || !values.terminalId}
                />
              </StyledFormControl>
            ) : null}
            <StyledFormControl>
              <label>Terminal Email</label>
              <TextField
                inputProps={{
                  style: {
                    WebkitTextFillColor: "black",
                    background: ASPHALT_LIGHTER,
                    borderRadius: 5,
                  },
                }}
                disabled
                type="email"
                value={values.terminalEmail || ""}
              />
              <StyledSubtext>
                This email address can be edited in the Platform Science System.
              </StyledSubtext>
            </StyledFormControl>
          </StyledColumn>

          <StyledColumn style={{ flex: 2 }}>
            <FormControl variant="filled">
              <label>
                {!isSummaryScreen
                  ? "Define who should receive tire alerts for the selected vehicle."
                  : "Define who should receive daily summary emails for the selected terminal."}
              </label>
              <Autocomplete
                multiple
                freeSolo
                disableCloseOnSelect
                options={[...new Set(users.map((user) => user.email))]}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    <ListItemText>{option}</ListItemText>
                  </li>
                )}
                value={values.emails}
                onBlur={handleBlur}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValues: string[] | null
                ) => {
                  setFieldValue("emails", newValues || []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Enter another email address"
                    error={Boolean(touched.emails && errors.emails)}
                    helperText={touched.emails && errors.emails}
                  />
                )}
                disabled={
                  isTerminalsPending ||
                  isUsersPending ||
                  !values.terminalId ||
                  !isSummaryScreen
                    ? !values.vehicleId
                    : false
                }
                sx={[
                  {
                    "& .MuiAutocomplete-inputRoot": {
                      py: 0.5,
                    },
                  },
                ]}
              />
            </FormControl>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="text" onClick={() => resetForm()}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={
                  isTerminalsPending ||
                  isUsersPending ||
                  !values.terminalId ||
                  !isSummaryScreen
                    ? !values.vehicleId
                    : false
                }
              >
                Save
              </Button>
            </Stack>
          </StyledColumn>
        </StyledForm>
      )}
    </Formik>
  );
};
