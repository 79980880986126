import React, { FC } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../app/components/PageTitle/PageTitle";
import { EmailAlertSetupContainer } from "../containers/EmailAlertSetupContainer";

export const TireAlertEmailSetupView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="lg">
          <PageTitle title="Tire Alert Email Setup" />
          <EmailAlertSetupContainer isSummaryScreen={false} />
        </Container>
      </Box>
    </PageLayout>
  );
};
