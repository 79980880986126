import React, { FC } from "react";
import { TerminalsTable } from "../components/TerminalsTable";
import { TerminalDto } from "../api/terminals.api.dto";

interface ITerminalsViewContainer {
  terminals: TerminalDto[];
  canEdit: boolean;
  canSeeTenants: boolean;
}

export const TerminalsViewContainer: FC<ITerminalsViewContainer> = ({
  terminals,
  canEdit,
  canSeeTenants,
}) => (
  <TerminalsTable
    showEditActions={canEdit}
    showTenantColumn={canSeeTenants}
    terminalsTableData={terminals}
  />
);
