export const getTenantIds = (): string[] => {
  try {
    const parsed = JSON.parse(localStorage.getItem("tenantIds") || "");
    if (Array.isArray(parsed)) {
      return parsed;
    }
    // eslint-disable-next-line no-empty
  } catch {}
  return [];
};
