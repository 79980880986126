import React, { FC, useEffect } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { FormControl, TextField, Autocomplete, Link } from "@mui/material";
import { TrailerDto, CreateEditTrailerDto } from "../../api/trailers.api.dto";
import { trailerSetupFormValidationSchema } from "./TrailerSetupForm.validation";
import { VEHICLE_SETUP, VEHICLE_SETUP_TENANT } from "../../../app/const/routes";

interface ITrailerSetupForm {
  formikRef: React.Ref<FormikProps<CreateEditTrailerDto>>;
  onSubmit: (formValues: CreateEditTrailerDto) => void;
  isPending: boolean;
  trailers: TrailerDto[];
  selectedTrailerId: string | undefined;
  selectedTenantId: string | undefined;
  showTenantIds?: boolean;
  changeTrailer: (
    trailerId: string | undefined,
    tenantId: string | undefined
  ) => void;
  setTireCoordinatesError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const TrailerSetupForm: FC<ITrailerSetupForm> = ({
  formikRef,
  onSubmit,
  isPending,
  trailers,
  selectedTrailerId,
  selectedTenantId,
  showTenantIds,
  changeTrailer,
  setTireCoordinatesError,
}) => {
  const selectedTrailer = trailers.find(
    (trailer) =>
      trailer.alternateId === selectedTrailerId &&
      (!showTenantIds || trailer.tenantId === selectedTenantId)
  );

  const concatTrailerTenantIds = (
    trailerId: string,
    tenantId: string | undefined
  ) => {
    if (showTenantIds) {
      return `${trailerId}|||${tenantId || ""}`;
    }
    return trailerId;
  };

  const splitTrailerTenantIds = (concatenatedIds: string) => {
    if (showTenantIds) {
      const [trailerId, tenantId] = concatenatedIds.split("|||", 2);
      return {
        trailerId,
        tenantId: tenantId || undefined,
      };
    }
    return {
      trailerId: concatenatedIds,
      tenantId: undefined,
    };
  };

  const getTrailerIdOptions = () =>
    [...trailers]
      .filter((trailer) => !!trailer.alternateId)
      .sort((a, b) => a.alternateId.localeCompare(b.alternateId))
      .map((trailer) =>
        concatTrailerTenantIds(trailer.alternateId, trailer.tenantId)
      );

  const getTrailerIdOptionLabel = (option: string) => {
    const { trailerId, tenantId } = splitTrailerTenantIds(option);
    const match = trailers.find(
      (trailer) =>
        trailer.alternateId === trailerId &&
        (!showTenantIds || trailer.tenantId === tenantId)
    );
    let label = "";
    if (match) {
      if (match.name) {
        label = `${match.alternateId} (${match.name})`;
      } else {
        label = `${match.alternateId}`;
      }
      if (showTenantIds && match.tenantId) {
        label += ` [${match.tenantId}]`;
      }
    }
    return label;
  };

  const getSelectedTrailerIdOption = () =>
    selectedTrailerId
      ? concatTrailerTenantIds(selectedTrailerId, selectedTenantId)
      : "";

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        name: selectedTrailer?.name || "",
        type: selectedTrailer?.type || "",
        licensePlate: selectedTrailer?.licensePlate || "",
        tiresMap: selectedTrailer?.tiresMap || [],
      }}
      validationSchema={trailerSetupFormValidationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => {
        useEffect(() => {
          setTireCoordinatesError(
            values.tiresMap && touched.tiresMap && errors.tiresMap
              ? errors.tiresMap
              : undefined
          );
        }, [values, touched, errors]);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormControl>
              <label htmlFor="trailer-setup-id">Select trailer ID</label>
              <Autocomplete
                id="trailer-setup-id"
                disablePortal
                disableClearable
                options={getTrailerIdOptions()}
                getOptionLabel={getTrailerIdOptionLabel}
                value={getSelectedTrailerIdOption()}
                onBlur={handleBlur}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string | null
                ) => {
                  if (newValue) {
                    const { trailerId, tenantId } =
                      splitTrailerTenantIds(newValue);
                    changeTrailer(trailerId, tenantId);
                  } else {
                    changeTrailer(undefined, undefined);
                  }
                  resetForm();
                }}
                renderInput={(params) => <TextField {...params} />}
                disabled={isPending}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="trailer-setup-name">Name</label>
              <TextField
                id="trailer-setup-name"
                type="text"
                name="name"
                value={values.name}
                disabled={!selectedTrailer || isPending}
                error={Boolean(selectedTrailer && touched.name && errors.name)}
                helperText={selectedTrailer && touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="trailer-setup-type">Type</label>
              <TextField
                id="trailer-setup-type"
                type="text"
                name="type"
                value={values.type}
                disabled={!selectedTrailer || isPending}
                error={Boolean(selectedTrailer && touched.type && errors.type)}
                helperText={selectedTrailer && touched.type && errors.type}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="trailer-setup-license">License plate</label>
              <TextField
                id="trailer-setup-license"
                type="text"
                name="licensePlate"
                value={values.licensePlate}
                disabled={!selectedTrailer || isPending}
                error={Boolean(
                  selectedTrailer && touched.licensePlate && errors.licensePlate
                )}
                helperText={
                  selectedTrailer && touched.licensePlate && errors.licensePlate
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            {selectedTrailer?.vehicle && (
              <FormControl>
                <label htmlFor="trailer-setup-vehicle">Assigned vehicle</label>
                <TextField
                  id="trailer-setup-vehicle"
                  type="text"
                  value={
                    selectedTrailer?.vehicle.name
                      ? `${selectedTrailer?.vehicle.alternateId} (${selectedTrailer?.vehicle.name})`
                      : selectedTrailer?.vehicle.make &&
                        selectedTrailer?.vehicle.model
                      ? `${selectedTrailer?.vehicle.alternateId} (${selectedTrailer?.vehicle.make} ${selectedTrailer?.vehicle.model} ${selectedTrailer?.vehicle.year})`
                      : `${selectedTrailer?.vehicle.alternateId}`
                  }
                  disabled
                  helperText={
                    !dirty &&
                    selectedTrailer?.vehicle?.alternateId && (
                      <Link
                        component={RouterLink}
                        to={
                          showTenantIds && selectedTrailer.vehicle.tenantId
                            ? generatePath(VEHICLE_SETUP_TENANT, {
                                vehicleId: selectedTrailer.vehicle.alternateId,
                                tenantId: selectedTrailer.vehicle.tenantId,
                              })
                            : generatePath(VEHICLE_SETUP, {
                                vehicleId: selectedTrailer.vehicle.alternateId,
                              })
                        }
                      >
                        Edit vehicle
                      </Link>
                    )
                  }
                />
              </FormControl>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
