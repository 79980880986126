import React, { FC, useState } from "react";
import { generatePath } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon, LatLngExpression } from "leaflet";
import { Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  LocationCity as LocationCityIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import {
  VehicleMapDto,
  VehicleState,
  VehicleTerminalDto,
} from "../../vehicles/api/vehicles.api.dto";
import {
  ASPHALT,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
} from "../../app/theme/palette";
import { StyledMapContainer, StyledMapLink } from "./GPSMap.style";
import { VEHICLE_STATUS, VEHICLE_STATUS_TENANT } from "../../app/const/routes";

export interface IGPSMapProps {
  positions: VehicleMapDto[];
  terminals?: VehicleTerminalDto[];
  fullView?: boolean;
  customHeight?: number;
  customWidth?: number;
  customZoom?: number;
  centerVehicle?: [number, number];
  showTenantIds?: boolean;
}

export const GPSMap: FC<IGPSMapProps> = ({
  positions,
  fullView,
  terminals,
  customHeight,
  customWidth,
  customZoom,
  centerVehicle,
  showTenantIds,
}) => {
  const [zoomLevel, setZoomLevel] = useState(
    typeof customZoom !== "undefined" ? customZoom : 4.4
  ); // initial zoom level provided for MapContainer

  function MyComponent() {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setZoomLevel(mapEvents.getZoom());
      },
    });
    return null;
  }
  return (
    <div id="theMap">
      <StyledMapContainer
        center={
          typeof centerVehicle !== "undefined"
            ? (centerVehicle as LatLngExpression)
            : [36.5, -83.27]
        }
        zoom={typeof customZoom !== "undefined" ? customZoom : 4.4}
        style={{
          height: fullView
            ? "100vh"
            : typeof customHeight !== "undefined"
            ? customHeight + "px"
            : "338px",
          maxHeight: fullView ? "100%" : "500px",
          width:
            typeof customWidth !== "undefined" ? customWidth + "px" : "auto",
          maxWidth: "100%",
        }}
      >
        <MyComponent />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {positions
          ? positions
              .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng))
              .map((position, index) => {
                const vehicleIconMarkup = renderToStaticMarkup(
                  <LocalShippingIcon
                    htmlColor={
                      position.state === VehicleState.Critical
                        ? SCHOOL_BUS_RED
                        : position.state === VehicleState.Cautionary
                        ? CAUTIONARY_ORANGE
                        : position.state === VehicleState.Offline
                        ? ASPHALT
                        : TRUCKING_GREEN
                    }
                    sx={{
                      backgroundColor: "transparent",
                      fontSize:
                        zoomLevel < 5
                          ? 5
                          : zoomLevel < 6
                          ? 10
                          : zoomLevel < 9
                          ? 17
                          : zoomLevel < 12
                          ? 25
                          : 27,
                    }}
                  />
                );
                const vehicleMarker = divIcon({
                  html: vehicleIconMarkup,
                  className: "transparent",
                });
                const vehicleStatusLink =
                  position.alternateId &&
                  (showTenantIds && position.tenantId
                    ? generatePath(VEHICLE_STATUS_TENANT, {
                        vehicleId: position.alternateId,
                        tenantId: position.tenantId,
                      })
                    : generatePath(VEHICLE_STATUS, {
                        vehicleId: position.alternateId,
                      }));
                return (
                  <Marker
                    position={[position.lat, position.lng]}
                    icon={vehicleMarker}
                    key={index}
                  >
                    <Popup>
                      <h3>
                        {vehicleStatusLink ? (
                          <StyledMapLink to={vehicleStatusLink}>
                            {position.alternateId}
                            {position.name && ` - ${position.name}`}
                          </StyledMapLink>
                        ) : (
                          <>
                            {position.alternateId}
                            {position.name && ` - ${position.name}`}
                          </>
                        )}
                      </h3>
                      <div>
                        {position.make} {position.model}
                      </div>
                      <div>
                        Driven by
                        <ul>
                          {position.drivers?.map((driver) => {
                            return (
                              <li key={driver.username}>
                                {driver.firstName} {driver.lastName}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Popup>
                  </Marker>
                );
              })
          : ""}
        {terminals
          ? terminals.map(
              (
                {
                  latitude,
                  longitude,
                  name,
                  address,
                  city,
                  state,
                  postal_code,
                },
                index
              ) => {
                const lng = longitude;
                const lat = latitude;
                const locationIconMarkup = renderToStaticMarkup(
                  <LocationCityIcon
                    sx={{
                      color: "#24428B",
                      backgroundColor: "transparent",
                      fontSize:
                        zoomLevel < 5
                          ? 20
                          : zoomLevel < 8
                          ? 25
                          : zoomLevel < 10
                          ? 30
                          : zoomLevel < 14
                          ? 32
                          : zoomLevel < 17
                          ? 35
                          : 50,
                    }}
                  />
                );
                const locationMarker = divIcon({
                  html: locationIconMarkup,
                  className: "transparent",
                });

                return (
                  lat &&
                  lng && (
                    <Marker
                      position={[lat, lng]}
                      icon={locationMarker}
                      key={index}
                    >
                      <Popup>
                        <h3>{name}</h3>
                        <div>
                          {address}
                          <br />
                          {city}, {state} {postal_code}
                        </div>
                      </Popup>
                    </Marker>
                  )
                );
              }
            )
          : ""}
      </StyledMapContainer>
    </div>
  );
};
