import React, { FC, useEffect } from "react";
import { debounce } from "lodash";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import {
  clearSearchUsersState as clearSearchUsersStateAction,
  searchUsers as searchUsersAction,
} from "../../store/users.slice";
import TableSearch from "../../../app/components/Table/TableSearch";

export const UsersSearchContainer: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearSearchUsersStateAction());
    };
  }, [dispatch]);

  const onSearch = (value: string) => dispatch(searchUsersAction(value));

  return <TableSearch onSearch={debounce(onSearch, 200)} />;
};
