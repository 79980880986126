import { styled } from "@mui/system";

import { IconButton, IconButtonProps } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

export const StyledIconButton = styled(IconButton)<IconButtonProps>`
  padding: 6px;
  margin-right: -12px;
`;

export const StyledClearIcon = styled(Clear)`
  font-size: 24px;
  color: #606060;
`;

export const StyledSearchIcon = styled(Search)`
  font-size: 24px;
  margin-right: -6px;
  color: #606060;
`;
