import React, { FC } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { DateType } from "../../app/components/DatePicker/DatePicker";
import { StyledChartContainer } from "./ConsumerGraph.style";

type EChartsOption = echarts.EChartsOption;

interface IWebAnalyticsConsumerGraph {
  xData: string[];
  ingests: number[];
  consumes: number[];
  totals: number[];
  date: DateType;
}

export const WebAnalyticsConsumerGraph: FC<IWebAnalyticsConsumerGraph> = ({
  xData,
  ingests,
  consumes,
  totals,
  date,
}) => {
  const lineGraphOptions: EChartsOption = {
    title: {
      text: "Queue Analytics",
      subtext: date.format("MM-DD-YYYY"),
      left: "center",
      top: 10,
    },
    grid: {
      top: 70,
      bottom: 80,
      height: 550,
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        saveAsImage: {},
      },
      orient: "vertical",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        animation: false,
        label: {
          backgroundColor: "#505765",
        },
      },
    },
    legend: {
      data: ["Ingested", "Consumed", "Totals"],
      left: 10,
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 75,
        end: 100,
      },
      {
        type: "inside",
        realtime: true,
        start: 75,
        end: 100,
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: false,
      axisLine: { onZero: false },
      data: xData,
    },
    yAxis: [
      {
        name: "Ingested",
        type: "value",
        max: (Math.max(...ingests) * 1.2).toFixed(2),
      },
      {
        name: "Totals",
        type: "value",
        inverse: true,
        max: (Math.max(...totals) * 1.2).toFixed(2),
      },
      {
        name: "Consumed",
        type: "value",
        max: (Math.max(...consumes) * 1.2).toFixed(20),
      },
    ],
    series: [
      {
        name: "Ingested",
        type: "line",
        areaStyle: {},
        lineStyle: {
          width: 1,
        },
        emphasis: {
          focus: "series",
        },
        data: ingests,
      },
      {
        name: "Consumed",
        type: "line",
        areaStyle: {},
        lineStyle: {
          width: 1,
        },
        emphasis: {
          focus: "series",
        },
        data: consumes,
      },
      {
        name: "Totals",
        type: "line",
        yAxisIndex: 1,
        areaStyle: {},
        data: totals,
        emphasis: {
          focus: "series",
        },
        lineStyle: {
          width: 1,
        },
      },
    ],
  };
  return (
    <StyledChartContainer>
      <ReactECharts
        lazyUpdate={false}
        option={lineGraphOptions}
        style={{ height: "700px", left: 0, top: 0, width: "100%" }}
      />
    </StyledChartContainer>
  );
};
