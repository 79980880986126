import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { AlertHistoryDto } from "../api/alertHistory.api.dto";
import {
  fetchAlerts as fetchAlertsRequest,
  fetchAlertsPages as fetchAlertsPagesRequest,
  muteNotification as muteNotificationRequest,
  unmuteNotification as unmuteNotificationRequest,
} from "../api/alertHistory.api";
import { PagesDto } from "../../summary/api/summary.api.dto";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";

export const fetchAllAlerts = createAsyncThunk(
  "alerts/fetchAllAlerts",
  withError(
    // eslint-disable-next-line
    async ({ tenantId }: { tenantId: string | undefined }): Promise<AlertHistoryDto[]> =>
      fetchAlertsRequest(
        "*",
        "*",
        "*",
        tenantId,
        "*",
        "*",
        "*",
        "timestamp",
        -1
      )
  )
);

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  withError(
    async ({
      vehicleId,
      trailerId,
      terminalId,
      tenantId,
      types,
      start,
      end,
      sortField,
      sortType,
      pageSize,
      page,
    }: {
      vehicleId: string | null;
      trailerId: string | null;
      terminalId: string | null;
      tenantId: string | undefined;
      types: string[];
      start: string | null;
      end: string | null;
      sortField: string;
      sortType: number;
      pageSize?: number;
      page?: number;
    }): Promise<AlertHistoryDto[]> =>
      await fetchAlertsRequest(
        vehicleId || "*",
        trailerId || "*",
        terminalId || "*",
        tenantId,
        types.length > 0 ? types.join("|") : "*",
        start || "*",
        end || "*",
        sortField,
        sortType,
        pageSize,
        page
      )
  )
);

export const fetchAlertsPages = createAsyncThunk(
  "alerts/fetchAlertsPages",
  withError(
    async ({
      vehicleId,
      trailerId,
      terminalId,
      tenantId,
      types,
      start,
      end,
    }: {
      vehicleId: string | null;
      trailerId: string | null;
      terminalId: string | null;
      tenantId: string | undefined;
      types: string[];
      start: string | null;
      end: string | null;
    }): Promise<PagesDto> =>
      await fetchAlertsPagesRequest(
        vehicleId || "*",
        trailerId || "*",
        terminalId || "*",
        tenantId,
        types.length > 0 ? types.join("|") : "*",
        start || "*",
        end || "*"
      )
  )
);

export const muteNotification = createAsyncThunk(
  "alerts/mute_notification",
  withError(
    async (
      {
        alertData,
        vehicleId,
        trailerId,
        terminalId,
        tenantId,
        types,
        start,
        end,
        sortField,
        sortType,
        page,
        pageSize,
      }: {
        alertData: AlertHistoryDto;
        vehicleId: string | null;
        trailerId: string | null;
        terminalId: string | null;
        tenantId: string | undefined;
        types: string[];
        start: string | null;
        end: string | null;
        sortField: string;
        sortType: number;
        page: number;
        pageSize: number;
      },
      { dispatch }
    ): Promise<void> => {
      await muteNotificationRequest(
        alertData.vehicle.alternateId,
        alertData.vehicle.tenantId,
        alertData.tires[0].tire,
        alertData.tires[0].axle
      );
      dispatch(
        fetchAlerts({
          vehicleId,
          trailerId,
          terminalId,
          tenantId,
          types,
          start,
          end,
          sortField,
          sortType,
          page,
          pageSize,
        })
      );
      dispatch(openSuccessNotification("Alert muted successfully"));
    }
  )
);

export const unmuteNotification = createAsyncThunk(
  "alerts/unmute_notification",
  withError(
    async (
      {
        alertData,
        vehicleId,
        trailerId,
        terminalId,
        tenantId,
        types,
        start,
        end,
        sortField,
        sortType,
        page,
        pageSize,
      }: {
        alertData: AlertHistoryDto;
        vehicleId: string | null;
        trailerId: string | null;
        terminalId: string | null;
        tenantId: string | undefined;
        types: string[];
        start: string | null;
        end: string | null;
        sortField: string;
        sortType: number;
        page: number;
        pageSize: number;
      },
      { dispatch }
    ): Promise<void> => {
      await unmuteNotificationRequest(
        alertData.vehicle.alternateId,
        alertData.vehicle.tenantId,
        alertData.tires[0].tire,
        alertData.tires[0].axle
      );
      dispatch(
        fetchAlerts({
          vehicleId,
          trailerId,
          terminalId,
          tenantId,
          types,
          start,
          end,
          sortField,
          sortType,
          page,
          pageSize,
        })
      );
      dispatch(openSuccessNotification("Alert unmuted successfully"));
    }
  )
);
