import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Add as AddIcon } from "@mui/icons-material";

import { TERMINALS_ADD } from "../../app/const/routes";
import { Button, IButton } from "../../app/components/Button/Button";

type ITerminalsAdd = IButton;

export const TerminalsAdd: FC<ITerminalsAdd> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={() => navigate(TERMINALS_ADD)}
      {...props}
    >
      New Terminal
    </Button>
  );
};
