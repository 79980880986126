import React, { FC, useState, useEffect } from "react";
import dayjs from "dayjs";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import {
  selectFetchWebAnalyticsConsumeData,
  selectFetchWebAnalyticsIngestData,
  selectQueueSizeData,
} from "../store/webanalytics.selector";
import {
  fetchWebAnalyticsConsumeCount,
  fetchWebAnalyticsIngestCount,
  fetchWebAnalyticsQueueSize,
} from "../store/webanalytics.actions";
import { WebAnalyticsConsumerGraph } from "../components/WebAnalyticsConsumerGraph";
import { useInterval } from "../../app/hooks/useInterval";
import { DateType } from "../../app/components/DatePicker/DatePicker";

interface IWebDashboardContainer {
  selectedDate: DateType;
}

export const WebDashboardContainer: FC<IWebDashboardContainer> = ({
  selectedDate,
}) => {
  const dispatch = useAppDispatch();
  const analyticsConsumeData = useAppSelector(
    selectFetchWebAnalyticsConsumeData
  );
  const analyticsIngestData = useAppSelector(selectFetchWebAnalyticsIngestData);
  const queueSizeData = useAppSelector(selectQueueSizeData);
  const [xData, setXData] = useState<string[]>([]);
  const [ingests, setIngests] = useState<number[]>([]);
  const [consumes, setConsumes] = useState<number[]>([]);
  const [totals, setTotals] = useState<number[]>([]);

  useEffect(() => {
    const date = selectedDate.format("YYYYMMDD");
    dispatch(
      fetchWebAnalyticsConsumeCount({
        date,
      })
    );
    dispatch(
      fetchWebAnalyticsIngestCount({
        date,
      })
    );
    dispatch(
      fetchWebAnalyticsQueueSize({
        date,
      })
    );
  }, [dispatch, selectedDate]);

  useEffect(() => {
    if (analyticsConsumeData) {
      const x = Object.keys(analyticsConsumeData).map(function (timestamp) {
        const stamp = parseInt(timestamp) * 1000;
        return dayjs(stamp).format("HH:MM");
      });
      setXData(x);
      const y1 = Object.values(analyticsConsumeData).map(function (value) {
        return parseFloat(value);
      });
      setConsumes(y1);
    }
    if (analyticsIngestData) {
      const y2 = Object.values(analyticsIngestData).map(function (value) {
        return parseFloat(value);
      });
      setIngests(y2);
    }
    if (queueSizeData) {
      const y3 = Object.values(queueSizeData).map(function (value) {
        return parseFloat(value);
      });
      setTotals(y3);
    }
  }, [analyticsConsumeData, analyticsIngestData, queueSizeData]);

  useInterval(() => {
    if (selectedDate.isSame(dayjs())) {
      const date = selectedDate.format("YYYYMMDD");
      dispatch(
        fetchWebAnalyticsConsumeCount({
          date,
        })
      );
      dispatch(
        fetchWebAnalyticsIngestCount({
          date,
        })
      );
      dispatch(
        fetchWebAnalyticsQueueSize({
          date,
        })
      );
    }
  }, 60000);

  return (
    <div id="chart-dom">
      <WebAnalyticsConsumerGraph
        xData={xData}
        ingests={ingests}
        consumes={consumes}
        totals={totals}
        date={selectedDate}
      />
    </div>
  );
};
