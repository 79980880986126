import { generatePath } from "react-router-dom";
import { ProfileDto } from "./auth.api.dto";
import { ProfileModel } from "../model/auth.model";
import { UserRole } from "../../app/enum/UserRole";
import {
  SUMMARY,
  PROFILE,
  VEHICLE_STATUS_LIST,
  VEHICLE_STATUS,
} from "../../app/const/routes";

export const mapProfileDtoToModel = (profileDto: ProfileDto): ProfileModel => {
  if (profileDto.role === UserRole.Driver) {
    if (profileDto.vehicle?.alternateId) {
      const vehicleStatusPath = generatePath(VEHICLE_STATUS, {
        vehicleId: profileDto.vehicle.alternateId,
      });
      return {
        ...profileDto,
        home: vehicleStatusPath,
        vehicleStatus: vehicleStatusPath,
        hideSideNav: false,
      };
    }
    return {
      ...profileDto,
      home: PROFILE,
      vehicleStatus: "",
      hideSideNav: true,
    };
  }
  return {
    ...profileDto,
    home: SUMMARY,
    vehicleStatus: VEHICLE_STATUS_LIST,
    hideSideNav: false,
  };
};
