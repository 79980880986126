import { TireCoordinateDto } from "../api/vehicles.api.dto";

export const groupTireCoordinates = (coordinates: TireCoordinateDto[]) =>
  coordinates.reduce((grouped, coordinate) => {
    grouped[coordinate.axle] = grouped[coordinate.axle] || [];
    grouped[coordinate.axle].push(coordinate);
    return grouped;
  }, [] as TireCoordinateDto[][]);

export const ungroupTireCoordinates = (grouped: TireCoordinateDto[][]) => {
  return grouped.flat(1);
};
