export enum MeasurementEventFilter {
  AIR_LEAK = "Air Leak",
  PRESSURE_THRESHOLD_0 = "Critical High Pressure",
  PRESSURE_THRESHOLD_1 = "Cautionary High Pressure",
  PRESSURE_THRESHOLD_3 = "Cautionary Low Pressure",
  PRESSURE_THRESHOLD_4 = "Critical Low Pressure",
  TEMPERATURE_THRESHOLD = "Temperature",
  IGNITION_ON = "Ignition On",
  IGNITION_OFF = "Ignition Off",
  LOST_SIGNAL = "Lost Signal",
}

export enum MeasurementEventTable {
  PERIODIC = "Periodic",
  AIR_LEAK = "Air leak",
  PRESSURE_THRESHOLD = "Pressure",
  PRESSURE_THRESHOLD_0 = "Critical High Pressure",
  PRESSURE_THRESHOLD_1 = "Cautionary High Pressure",
  PRESSURE_THRESHOLD_3 = "Cautionary Low Pressure",
  PRESSURE_THRESHOLD_4 = "Critical Low Pressure",
  TEMPERATURE_THRESHOLD = "Temperature",
  IGNITION_ON = "Ignition on",
  IGNITION_OFF = "Ignition off",
  CVD_STARTUP = "CVD startup",
  TRAILER = "Trailer",
  LOST_SIGNAL = "Lost Signal",
}
