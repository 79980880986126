import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { EditSettingsDto } from "../api/settings.api.dto";
import { updateProfile as updateProfileRequest } from "../../profile/api/profile.api";
import {
  fetchSettingValue as fetchSettingValueRequest,
  updateSettingValue as updateSettingValueRequest,
  subscribeToEmailAlerts as subscribeToEmailAlertsRequest,
  unsubscribeFromEmailAlerts as unsubscribeFromEmailAlertsRequest,
} from "../api/settings.api";
import { fetchProfile } from "../../auth/store/auth.action";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../app/store/notifications/notifications.slice";

export const saveSettings = createAsyncThunk(
  "settings/saveSettings",
  withError(
    async (
      {
        settingsData,
        tenantId,
      }: {
        settingsData: EditSettingsDto;
        tenantId: string | undefined;
      },
      { dispatch }
    ): Promise<void> => {
      try {
        await updateProfileRequest({
          unitSystem: settingsData.unitSystem,
          timeZone: settingsData.timeZone,
        });
        if (settingsData.silencedNotifications !== null) {
          await updateSettingValueRequest(
            "silence",
            settingsData.silencedNotifications,
            tenantId
          );
        }
        dispatch(openSuccessNotification("Settings successfully saved"));
        dispatch(fetchProfile());
      } catch (err) {
        dispatch(openErrorNotification("Couldn't save settings"));
        throw err;
      }
    }
  )
);

export const fetchSilencedNotifications = createAsyncThunk(
  "settings/fetchSilencedNotifications",
  withError(
    async ({ tenantId }: { tenantId: string | undefined }): Promise<string> =>
      await fetchSettingValueRequest("silenced_alerts", tenantId)
  )
);

export const subscribeToEmailAlerts = createAsyncThunk(
  "settings/subscribeToEmailAlerts",
  withError(async (payload: undefined, { dispatch }): Promise<void> => {
    try {
      await subscribeToEmailAlertsRequest();
      dispatch(
        openSuccessNotification("Successfully subscribed to email alerts")
      );
      dispatch(fetchProfile());
    } catch (err) {
      dispatch(openErrorNotification("Couldn't subscribe to email alerts"));
      throw err;
    }
  })
);

export const unsubscribeFromEmailAlerts = createAsyncThunk(
  "settings/unsubscribeFromEmailAlerts",
  withError(async (payload: undefined, { dispatch }): Promise<void> => {
    try {
      await unsubscribeFromEmailAlertsRequest();
      dispatch(
        openSuccessNotification("Successfully unsubscribed from email alerts")
      );
      dispatch(fetchProfile());
    } catch (err) {
      dispatch(openErrorNotification("Couldn't unsubscribe from email alerts"));
      throw err;
    }
  })
);
