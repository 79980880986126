import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TenantDto } from "../api/tenants.api.dto";

export interface TenantsState {
  fetchAllTenants: AsyncState<TenantDto[]>;
  addTenant: AsyncState<null>;
  searchTenants: string;
}

export const tenantsState: TenantsState = {
  fetchAllTenants: {
    status: AsyncStatus.Void,
    data: [],
  },
  addTenant: {
    status: AsyncStatus.Void,
    data: null,
  },
  searchTenants: "",
};
