import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Loader } from "../../../app/components/Loader/Loader";
import { PressureStatus } from "../../../app/enum/Vehicle";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import {
  fetchTrailerPressureHistory,
  fetchTrailerTemperatureHistory,
} from "../../../trailers/store/trailers.actions";
import {
  selectFetchTrailerPressureHistoryData,
  selectFetchTrailerTemperatureHistoryData,
  selectIsFetchTrailerPressureHistoryPending,
  selectIsFetchTrailerTemperatureHistoryPending,
} from "../../../trailers/store/trailers.selector";
import { TireDto } from "../../api/vehicles.api.dto";
import { DateRangeType } from "../../../app/components/DateRangePicker/DateRangePicker";
import { HistoryLineGraph } from "../../components/HistoryLineGraph/HistoryLineGraph";
import { TireTable } from "../../components/TireTable/TireTable";
import { VehicleDiagram } from "../../components/VehicleDiagram/VehicleDiagram";
import { VehicleStatusPieChart } from "../../components/VehicleStatusPieChart/VehicleStatusPieChart";
import {
  fetchVehicleCurrentStats,
  fetchPressureHistory,
  fetchTemperatureHistory,
} from "../../store/vehicles.actions";
import {
  selectFetchPressureHistoryData,
  selectFetchTemperatureHistoryData,
  selectFetchVehicleCurrentStatsData,
  selectIsFetchVehicleCurrentStatsPending,
  selectIsFetchPressureHistoryPending,
  selectIsFetchTemperatureHistoryPending,
} from "../../store/vehicles.selector";
import {
  StyledFormControl,
  StyledLabel,
  StyledRow,
} from "./VehicleStatusContainer.style";
import { ExportButton } from "../../components/ExportButton/ExportButton";
import { useCurrentUser } from "../../../app/hooks/useCurrentUser";
import { UserRole } from "../../../app/enum/UserRole";

export type ITireAlerts = {
  normalCount: number;
  cautionaryCount: number;
  criticalCount: number;
  noDataCount: number;
};

enum ViewType {
  tireTable = "tireTable",
  pressureGraph = "pressureGraph",
  temperatureGraph = "temperatureGraph",
}

export const VehicleStatusContainer: FC = () => {
  const { vehicleId, tenantId } = useParams();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const vehicle = useAppSelector(selectFetchVehicleCurrentStatsData);
  const trailerId = vehicle?.trailer?.alternateId;
  const vehiclePressureHistory = useAppSelector(selectFetchPressureHistoryData);
  const vehicleTemperatureHistory = useAppSelector(
    selectFetchTemperatureHistoryData
  );
  const trailerPressureHistory = useAppSelector(
    selectFetchTrailerPressureHistoryData
  );
  const trailerTemperatureHistory = useAppSelector(
    selectFetchTrailerTemperatureHistoryData
  );
  const isVehiclePending = useAppSelector(
    selectIsFetchVehicleCurrentStatsPending
  );
  const isPressureHistoryPending = useAppSelector(
    selectIsFetchPressureHistoryPending
  );
  const isTemperatureHistoryPending = useAppSelector(
    selectIsFetchTemperatureHistoryPending
  );
  const isTrailerPressureHistoryPending = useAppSelector(
    selectIsFetchTrailerPressureHistoryPending
  );
  const isTrailerTemperatureHistoryPending = useAppSelector(
    selectIsFetchTrailerTemperatureHistoryPending
  );
  const [view, setView] = useState<ViewType>(ViewType.tireTable);
  const [tireIds, setTireIds] = useState<string[]>([]);
  const pressureHistory = [
    ...vehiclePressureHistory,
    ...trailerPressureHistory,
  ];
  const temperatureHistory = [
    ...vehicleTemperatureHistory,
    ...trailerTemperatureHistory,
  ];
  const [pressureGraphDateRange, setPressureGraphDateRange] =
    useState<DateRangeType>([dayjs(), dayjs()]);
  const [temperatureGraphDateRange, setTemperatureGraphDateRange] =
    useState<DateRangeType>([dayjs(), dayjs()]);
  const tireAlerts = {
    normalCount: 0,
    cautionaryCount: 0,
    criticalCount: 0,
    noDataCount: 0,
  };

  const handlePressureGraphDateRangeChange = (dateRange: DateRangeType) => {
    if (dateRange[0]?.isValid() && dateRange[1]?.isValid()) {
      const dateFrom = dateRange[0].format("YYYYMMDD");
      const dateTo = dateRange[1].format("YYYYMMDD");
      vehicleId &&
        dispatch(
          fetchPressureHistory({
            vehicleId,
            tenantId,
            dateFrom,
            dateTo,
          })
        );
      trailerId &&
        dispatch(
          fetchTrailerPressureHistory({
            trailerId,
            tenantId,
            dateFrom,
            dateTo,
          })
        );
      setPressureGraphDateRange(dateRange);
    }
  };

  const handleTemperatureGraphDateRangeChange = (dateRange: DateRangeType) => {
    if (dateRange[0]?.isValid() && dateRange[1]?.isValid()) {
      const dateFrom = dateRange[0].format("YYYYMMDD");
      const dateTo = dateRange[1].format("YYYYMMDD");
      vehicleId &&
        dispatch(
          fetchTemperatureHistory({
            vehicleId,
            tenantId,
            dateFrom,
            dateTo,
          })
        );
      trailerId &&
        dispatch(
          fetchTrailerTemperatureHistory({
            trailerId,
            tenantId,
            dateFrom,
            dateTo,
          })
        );
      setTemperatureGraphDateRange(dateRange);
    }
  };

  const handleViewChange = (event: SelectChangeEvent<ViewType>) =>
    setView(event.target.value as ViewType);

  if (vehicle?.tires) {
    const nowTimestamp = dayjs();

    vehicle?.tires.forEach((tire: TireDto) => {
      const tireTimestamp = dayjs(tire.timestamp);
      const diff = nowTimestamp.diff(tireTimestamp, "day");

      // Don't display data older than 7 days ago
      if (diff > 6) {
        return;
      }

      tire.lostSignal
        ? (tireAlerts.noDataCount += 1)
        : tire.leak ||
          tire.temperatureAlert ||
          tire.pressureStatus === PressureStatus.EUP ||
          tire.pressureStatus === PressureStatus.EOP
        ? (tireAlerts.criticalCount += 1)
        : tire.pressureStatus === PressureStatus.UP ||
          tire.pressureStatus === PressureStatus.OP
        ? (tireAlerts.cautionaryCount += 1)
        : tire.pressureStatus === PressureStatus.GOOD
        ? (tireAlerts.normalCount += 1)
        : null;
    });
  }

  useEffect(() => {
    let fetch = true;
    if (
      user?.role === UserRole.Driver &&
      vehicleId !== user?.vehicle?.alternateId
    ) {
      fetch = false;
    }
    if (fetch) {
      if (vehicleId) {
        dispatch(fetchVehicleCurrentStats({ vehicleId, tenantId }));
      }
    }
  }, []);

  useEffect(() => {
    if (view === ViewType.pressureGraph && pressureHistory.length === 0) {
      handlePressureGraphDateRangeChange(pressureGraphDateRange);
    }
    if (view === ViewType.temperatureGraph && temperatureHistory.length === 0) {
      handleTemperatureGraphDateRangeChange(temperatureGraphDateRange);
    }
  }, [view]);

  return !isVehiclePending ? (
    <>
      <VehicleStatusPieChart tireAlerts={tireAlerts} />
      {vehicle && (
        <>
          <VehicleDiagram
            vehicle={vehicle}
            interactive={true}
            tireIds={tireIds}
            setTireIds={setTireIds}
          />
          <StyledRow>
            <StyledFormControl>
              <StyledLabel>Select View: </StyledLabel>
              <Select
                sx={{ width: 324, height: 36 }}
                value={view}
                onChange={(e) => handleViewChange(e)}
              >
                <MenuItem value={ViewType.tireTable}>Table Overview</MenuItem>
                <MenuItem value={ViewType.pressureGraph}>
                  Pressure History Line Graph
                </MenuItem>
                <MenuItem value={ViewType.temperatureGraph}>
                  Temperature History Line Graph
                </MenuItem>
              </Select>
            </StyledFormControl>
            {vehicle?.tires && (
              <ExportButton
                tires={vehicle.tires}
                vehicleId={vehicle?.vehicle?.alternateId}
                sx={{ px: 2, ml: 1.5, mb: 3 }}
              />
            )}
          </StyledRow>
          {view === ViewType.tireTable ? (
            vehicle?.tires && <TireTable tires={vehicle.tires} />
          ) : view === ViewType.pressureGraph ? (
            <HistoryLineGraph
              type="pressure"
              historyData={pressureHistory}
              tireIds={tireIds}
              dateRange={pressureGraphDateRange}
              onDateRangeChange={handlePressureGraphDateRangeChange}
              isLoading={
                isPressureHistoryPending || isTrailerPressureHistoryPending
              }
            />
          ) : view === ViewType.temperatureGraph ? (
            <HistoryLineGraph
              type="temperature"
              historyData={temperatureHistory}
              tireIds={tireIds}
              dateRange={temperatureGraphDateRange}
              onDateRangeChange={handleTemperatureGraphDateRangeChange}
              isLoading={
                isTemperatureHistoryPending ||
                isTrailerTemperatureHistoryPending
              }
            />
          ) : null}
        </>
      )}
    </>
  ) : (
    <Loader />
  );
};
