import * as Yup from "yup";

export const EmailAlertSetupFormValidationSchema = (isSummaryScreen: boolean) =>
  Yup.object().shape({
    vehicleId: !isSummaryScreen
      ? Yup.string().required("Vehicle is required")
      : Yup.string().optional(),
    terminalId: Yup.string().required("Terminal is required"),
    terminalEmail: Yup.string().optional(),
    emails: Yup.array()
      .of(Yup.string().email("Email is not valid"))
      .required("An email is required")
      .min(1, "An email is required"),
  });
