import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";

import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { UserRole } from "../../../app/enum/UserRole";
import { TerminalDto } from "../../api/terminals.api.dto";
import { fetchAllTerminals } from "../../store/terminals.actions";
import { selectFetchAllTerminalsData } from "../../store/terminals.selector";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { TerminalsAdd } from "../../components/TerminalsAdd";
import { TerminalsViewContainer } from "../../containers/TerminalsViewContainer";
import { TerminalsSearchContainer } from "../../containers/TerminalsSearchContainer";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";

export const TerminalsView: FC = () => {
  const dispatch = useAppDispatch();
  const tenantIds = getTenantIds();
  const [filteredData, setFilteredData] = useState<TerminalDto[]>([]);
  const canEdit =
    useShowForRoles([UserRole.SuperAdmin, UserRole.Admin]) &&
    tenantIds.length > 0;
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const terminalsTableData: TerminalDto[] = useAppSelector(
    selectFetchAllTerminalsData
  );

  useEffect(() => {
    dispatch(fetchAllTerminals());
  }, [dispatch]);

  useEffect(() => {
    setFilteredData(terminalsTableData);
  }, [terminalsTableData]);

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <PageTitle title="Terminals" />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <TerminalsSearchContainer
                canSeeTenants={canSeeTenants}
                terminalsTableData={terminalsTableData}
                setFilteredData={setFilteredData}
              />
              {canEdit && <TerminalsAdd sx={{ px: 2 }} />}
            </Stack>
          </Grid>
        </Grid>
        <TerminalsViewContainer
          terminals={filteredData}
          canEdit={canEdit}
          canSeeTenants={canSeeTenants}
        />
      </Box>
    </PageLayout>
  );
};
