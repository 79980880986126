import React, { FC, useEffect } from "react";
import { push } from "redux-first-history";
import { generatePath } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import {
  fetchAllVehicles,
  fetchVehiclesPages,
} from "../../store/vehicles.actions";
import {
  selectFetchAllVehiclesData,
  selectFetchVehiclesPagesData,
  selectIsFetchAllVehiclesPending,
} from "../../store/vehicles.selector";
import { VehicleStatusList } from "../../components/VehicleStatusList/VehicleStatusList";
import {
  VEHICLE_STATUS,
  VEHICLE_STATUS_TENANT,
} from "../../../app/const/routes";
import { UserRole } from "../../../app/enum/UserRole";

export const VehicleStatusListContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isVehiclesPending = useAppSelector(selectIsFetchAllVehiclesPending);
  const vehicles = useAppSelector(selectFetchAllVehiclesData);
  const pageData = useAppSelector(selectFetchVehiclesPagesData);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const goToVehicleStatusScreen = (
    vehicleId: string,
    tenantId: string | undefined
  ) =>
    vehicleId &&
    dispatch(
      push(
        canSeeTenants && tenantId
          ? generatePath(VEHICLE_STATUS_TENANT, {
              vehicleId,
              tenantId,
            })
          : generatePath(VEHICLE_STATUS, {
              vehicleId,
            })
      )
    );

  const handlePageChange = (page: number, rowsPerPage: number) =>
    dispatch(fetchAllVehicles({ pageInfo: { page, rowsPerPage } }));

  useEffect(() => {
    dispatch(fetchVehiclesPages());
    dispatch(fetchAllVehicles({ pageInfo: { page: 0, rowsPerPage: 10 } }));
  }, [dispatch]);

  return (
    <VehicleStatusList
      vehicles={vehicles}
      showTenantColumn={canSeeTenants}
      goToVehicleStatusScreen={goToVehicleStatusScreen}
      isVehiclesPending={isVehiclesPending}
      pageData={pageData}
      handlePageChange={handlePageChange}
    />
  );
};
