import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TenantsState } from "./tenants.store";
import { TenantDto } from "../api/tenants.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectTenantsRootState = createSelector(
  selectSelf,
  (state: RootState): TenantsState => state.tenants
);

export const selectFetchAllTenantsState = createSelector(
  selectTenantsRootState,
  (tenants): AsyncState<TenantDto[]> => tenants.fetchAllTenants
);

export const selectFetchAllTenantsData = createSelector(
  selectFetchAllTenantsState,
  (fetchAlltenants): TenantDto[] => fetchAlltenants.data
);

export const selectFetchAllTenantsStatus = createSelector(
  selectFetchAllTenantsState,
  (fetchAlltenants): AsyncStatus => fetchAlltenants.status
);

export const selectIsFetchAllTenantsPending = createSelector(
  selectFetchAllTenantsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAddTenantState = createSelector(
  selectTenantsRootState,
  (tenants): AsyncState<null> => tenants.addTenant
);

export const selectAddTenantStatus = createSelector(
  selectAddTenantState,
  (addTenant): AsyncStatus => addTenant.status
);

export const selectIsAddTenantPending = createSelector(
  selectAddTenantStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSearchTenants = createSelector(
  selectTenantsRootState,
  (tenants): string => tenants.searchTenants
);

export const selectTenantsFilteredData = createSelector(
  selectSearchTenants,
  selectFetchAllTenantsData,
  (searchValue, tenants): TenantDto[] => {
    const data = tenants.map((tenant) => tenant);

    if (searchValue) {
      return data.filter((tenant) =>
        tenant.id.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return data;
  }
);
