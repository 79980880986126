import React, { FC } from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { StyledChartContainer, StyledChartTypography } from "./Summary.style";
import {
  ASPHALT,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  BLACK,
} from "../../app/theme/palette";
import { Loader } from "../../app/components/Loader/Loader";
import {
  EmptyFilter,
  ITireAlerts,
  Severity,
} from "../containers/SummaryTableContainer";

type EChartsOption = echarts.EChartsOption;

interface ISummaryBarChart {
  tireAlerts: ITireAlerts;
  isVehicleDataPending: boolean;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const SummaryBarChart: FC<ISummaryBarChart> = ({
  tireAlerts,
  isVehicleDataPending,
  filter,
  setFilter,
}) => {
  const barOption: EChartsOption = {
    xAxis: {
      type: "category",
      data: ["Critical", "Cautionary", "Lost Signal"],
      axisLabel: {
        interval: 0,
        color: BLACK,
        fontFamily: "Montserrat",
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      axisLine: { show: true },
      splitLine: { show: false },
      axisLabel: { color: BLACK, fontFamily: "Montserrat" },
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        data: [
          {
            id: Severity.Critical,
            value: tireAlerts.criticalCount,
            itemStyle: { color: SCHOOL_BUS_RED },
          },
          {
            id: Severity.Cautionary,
            value: tireAlerts.cautionaryCount,
            itemStyle: { color: CAUTIONARY_ORANGE },
          },
          {
            id: Severity.NoData,
            value: tireAlerts.offlineCount,
            itemStyle: { color: ASPHALT },
          },
        ],
        type: "bar",
      },
    ],
  };

  return (
    <StyledChartContainer>
      <StyledChartTypography>Overall Tires with Alerts</StyledChartTypography>
      {!isVehicleDataPending ? (
        <ReactECharts
          ref={(e) => {
            if (e) {
              const chart = e.getEchartsInstance();
              chart?.on("click", (params) => {
                // eslint-disable-next-line
                const optionId = params.data as any;
                filter === optionId.id ? setFilter("") : setFilter(params.name);

                chart.dispatchAction({
                  type: "downplay",
                  seriesIndex: 0,
                  dataIndex: params.currentIndex,
                });

                chart.dispatchAction({
                  type: "highlight",
                  seriesIndex: 0,
                  dataIndex: params.dataIndex,
                });
              });

              chart.getZr().on("click", function (event) {
                if (!event.target) {
                  chart.clear();
                  chart.setOption(barOption);
                  setFilter(EmptyFilter.NONE);
                }
              });
            }
          }}
          option={barOption}
        />
      ) : (
        <Loader />
      )}
    </StyledChartContainer>
  );
};
