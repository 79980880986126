import React, { FC } from "react";
import { push } from "redux-first-history";
import { generatePath, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import { selectFetchVehicleCurrentStatsData } from "../../store/vehicles.selector";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { PageSubtitle } from "../../../app/components/PageSubtitle/PageSubtitle";
import { VehicleStatusContainer } from "../../containers/VehicleStatusContainer/VehicleStatusContainer";
import { StyledButton, StyledLabel } from "./VehicleStatusView.style";
import { VEHICLE_SETUP, VEHICLE_SETUP_TENANT } from "../../../app/const/routes";
import { UserRole } from "../../../app/enum/UserRole";

export const VehicleStatusView: FC = () => {
  const dispatch = useAppDispatch();
  const { vehicleId, tenantId } = useParams();
  const data = useAppSelector(selectFetchVehicleCurrentStatsData);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const canEdit = useShowForRoles([UserRole.SuperAdmin, UserRole.Admin]);

  return (
    <PageLayout>
      <PageSubtitle title="Vehicle Status" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle
          title={data?.vehicle?.name || data?.vehicle?.hardwareId || ""}
        />
        {canEdit && (
          <StyledButton
            onClick={() =>
              vehicleId &&
              dispatch(
                push(
                  canSeeTenants && tenantId
                    ? generatePath(VEHICLE_SETUP_TENANT, {
                        vehicleId,
                        tenantId,
                      })
                    : generatePath(VEHICLE_SETUP, {
                        vehicleId,
                      })
                )
              )
            }
          >
            <EditIcon sx={{ width: 20 }} />
            <StyledLabel>Edit Vehicle</StyledLabel>
          </StyledButton>
        )}
      </div>
      <Box
        flexDirection="column"
        height="100%"
        justifyContent="center"
        flex={1}
      >
        <VehicleStatusContainer />
      </Box>
    </PageLayout>
  );
};
