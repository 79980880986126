import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { AlertHistoryDto } from "../api/alertHistory.api.dto";
import { PagesDto } from "../../summary/api/summary.api.dto";

export interface AlertHistoryState {
  fetchAllAlerts: AsyncState<AlertHistoryDto[]>;
  fetchAlerts: AsyncState<AlertHistoryDto[]>;
  fetchAlertsPages: AsyncState<PagesDto>;
  searchAlerts: string;
}

export const alertHistoryState: AlertHistoryState = {
  fetchAllAlerts: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchAlerts: {
    status: AsyncStatus.Void,
    data: [],
  },
  fetchAlertsPages: {
    status: AsyncStatus.Void,
    data: { pageSize: 0, totalElements: 0, totalPages: 0 },
  },
  searchAlerts: "",
};
