import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import { TOP_NAV_HEIGHT } from "../../const/nav";

export const MainWrapper = styled("main", {
  shouldForwardProp: (prop) => prop !== "offsetLeft",
})<{
  offsetLeft?: number;
}>(({ theme, offsetLeft = 0 }) => ({
  minHeight: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
  paddingLeft: offsetLeft,
  backgroundColor: theme.palette.background.default,
}));

export const FullHeightContainer = styled(Container)({
  minHeight: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
});
