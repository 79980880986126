export const ASPHALT = "#6B6B6B";
export const ASPHALT_LIGHT = "#C4C4C4";
export const ASPHALT_LIGHTER = "#E5E5E5";
export const ASPHALT_LIGHT_ALT = "#CED0D6";
export const ASPHALT_LIGHTER_ALT = "#EBEFF2";

export const DORAN_BLUE = "#23418B";
export const TRUCKING_GREEN = "#32620E";
export const OFF_ROAD_BROWN = "#845438";
export const SCHOOL_BUS_RED = "#BC1823";
export const CAUTIONARY_ORANGE = "#D97500";
export const TIRE_BLACK = "#000000";

export const BLACK = TIRE_BLACK;
export const WHITE = "#FFFFFF";

export default {
  primary: {
    main: ASPHALT,
  },
  secondary: {
    main: ASPHALT_LIGHTER_ALT,
  },
  error: {
    main: SCHOOL_BUS_RED,
  },
  warning: {
    main: CAUTIONARY_ORANGE,
  },
  info: {
    main: DORAN_BLUE,
  },
  success: {
    main: TRUCKING_GREEN,
  },
  background: {
    default: WHITE,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
