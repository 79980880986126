import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { fetchAllTenants } from "../../store/tenants.actions";
import {
  selectTenantsFilteredData,
  selectIsFetchAllTenantsPending,
} from "../../store/tenants.selector";
import { TenantsTable } from "../../components/TenantsTable/TenantsTable";

export const TenantsTableContainer: FC = () => {
  const dispatch = useAppDispatch();
  const tenants = useAppSelector(selectTenantsFilteredData);
  const isTenantsPending = useAppSelector(selectIsFetchAllTenantsPending);

  useEffect(() => {
    dispatch(fetchAllTenants());
  }, [dispatch]);

  return (
    <TenantsTable terminalsTableData={tenants} isLoading={isTenantsPending} />
  );
};
