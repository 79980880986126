import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { push } from "redux-first-history";
import {
  fetchAllTenants as fetchAllTenantsRequest,
  createTenant as createTenantRequest,
  disableTenant as disableTenantRequest,
  enableTenant as enableTenantRequest,
} from "../api/tenants.api";
import { openSuccessNotification } from "../../app/store/notifications/notifications.slice";
import { TenantDto } from "../api/tenants.api.dto";
import { TENANTS } from "../../app/const/routes";

export const fetchAllTenants = createAsyncThunk(
  "tenants/fetchAllTenants",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<TenantDto[]> =>
      await fetchAllTenantsRequest()
  )
);

export const addTenant = createAsyncThunk(
  "tenants/addTenant",
  withError(
    async (
      { tenantModel }: { tenantModel: TenantDto },
      { dispatch }
    ): Promise<void> => {
      await createTenantRequest(tenantModel);
      dispatch(openSuccessNotification("Tenant created successfully"));
      return dispatch(push(TENANTS));
    }
  )
);

export const disableTenant = createAsyncThunk(
  "tenants/disableTenant",
  withError(async (id: string, { dispatch }): Promise<void> => {
    await disableTenantRequest(id);
    dispatch(fetchAllTenants());
    dispatch(openSuccessNotification("Tenant disabled successfully"));
  })
);

export const enableTenant = createAsyncThunk(
  "tenants/enableTenant",
  withError(async (id: string, { dispatch }): Promise<void> => {
    await enableTenantRequest(id);
    dispatch(fetchAllTenants());
    dispatch(openSuccessNotification("Tenant enabled successfully"));
  })
);
