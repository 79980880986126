import React, { FC } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { PageSubtitle } from "../../../app/components/PageSubtitle/PageSubtitle";
import { TrailerSearchContainer } from "../../containers/TrailerStatusListContainer/TrailerSearchContainer";
import { TrailerStatusListContainer } from "../../containers/TrailerStatusListContainer/TrailerStatusListContainer";

export const TrailerStatusListView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <PageTitle title="Trailers" />
            <PageSubtitle title="Select a trailer to view its status." />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <TrailerSearchContainer />
            </Stack>
          </Grid>
        </Grid>
        <TrailerStatusListContainer />
      </Box>
    </PageLayout>
  );
};
