export enum MeasurementEventTable {
  PERIODIC = "Periodic",
  AIR_LEAK = "Tire Leak",
  PRESSURE_THRESHOLD = "Pressure Alert",
  TEMPERATURE_THRESHOLD = "High Temperature",
  IGNITION_ON = "Ignition On",
  IGNITION_OFF = "Ignition Off",
  CVD_STARTUP = "CVD Startup",
  TRAILER = "Trailer",
}
