import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ProfileModel } from "../model/auth.model";

export interface AuthLoginState<T> extends AsyncState<T> {
  redirectToPreviousLocation: boolean;
}

export interface AuthState {
  login: AuthLoginState<null>;
  logout: AsyncState<null>;
  recoverPassword: AsyncState<null>;
  resetPassword: AsyncState<null>;
  profile: AsyncState<ProfileModel | null>;
  authorization: AsyncState<boolean>;
}

export const authState: AuthState = {
  login: {
    status: AsyncStatus.Void,
    data: null,
    redirectToPreviousLocation: false,
  },
  logout: {
    status: AsyncStatus.Void,
    data: null,
  },
  authorization: {
    status: AsyncStatus.Void,
    data: false,
  },
  recoverPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  resetPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  profile: {
    status: AsyncStatus.Void,
    data: null,
  },
};
