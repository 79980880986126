import axios from "axios";
import { REACT_APP_API_BASE_URL } from "../const/env";

export const apiClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
