import React, { FC } from "react";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { isCurrentPath } from "../../../app/utils/is-current-path";
import { UnsubscribeDto } from "../../api/unsubscribe.api.dto";
import { unsubscribe } from "../../store/unsubscribe.action";
import { selectUnsubscribeStatusPending } from "../../store/unsubscribe.selector";
import { UnsubscribeForm } from "../../components/UnsubscribeForm/UnsubscribeForm";
import {
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_ERROR,
} from "../../../app/const/routes";

export const UnsubscribeContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isUnsubscribePending = useAppSelector(selectUnsubscribeStatusPending);

  const onSubmit = (unsubscribeData: UnsubscribeDto) =>
    dispatch(unsubscribe(unsubscribeData));

  return (
    <UnsubscribeForm
      onSubmit={onSubmit}
      isPending={isUnsubscribePending}
      showSuccessNotification={isCurrentPath(UNSUBSCRIBE_SUCCESS)}
      showErrorNotification={isCurrentPath(UNSUBSCRIBE_ERROR)}
    />
  );
};
