import React, { FC } from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  Stack,
} from "@mui/material";

import { fetchSummaryCsv } from "../api/summary.api";
import { AlertsSummaryDto, PagesDto } from "../api/summary.api.dto";
import { Loader } from "../../app/components/Loader/Loader";
import { ExportCsvButton } from "../../app/components/ExportCsvButton/ExportCsvButton";
import { StyledLink } from "./Summary.style";
import { timezoneCode } from "../../app/utils/timezone-code";
import { getTimeZone } from "../../app/utils/get-timezone";
import {
  VEHICLE_STATUS,
  VEHICLE_STATUS_TENANT,
  ALERT_WEB_VIEW,
} from "../../app/const/routes";

dayjs.extend(localizedFormat);

interface ISummaryDataTable {
  latestAlerts: AlertsSummaryDto[];
  isTableSummaryDataPending: boolean;
  pageData: PagesDto;
  showTenantIds?: boolean;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const SummaryDataTable: FC<ISummaryDataTable> = ({
  latestAlerts,
  isTableSummaryDataPending,
  pageData,
  showTenantIds,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
}) => {
  const tz = timezoneCode(getTimeZone());

  const rows = latestAlerts.map((alert) => ({
    lastAlert: dayjs(alert.timestamp).format(`L [at] LTS [${tz}]`),
    vehicle: alert.vehicle?.name || alert.vehicle?.hardwareId || "",
    vehicleId: alert.vehicle?.alternateId || "",
    vehicleTenantId: alert.vehicle?.tenantId,
    trailer: alert.trailer?.name || "",
    trailerId: alert.trailer?.alternateId || "",
    tenantId: alert.vehicle?.tenantId || "",
    terminal: alert.terminal?.name,
    driver: alert.vehicle?.driver?.firstName || "",
    token: alert.token,
  }));

  return (
    <>
      <Stack direction="row" spacing={1.5} justifyContent="flex-end" mb={3}>
        <ExportCsvButton
          fetchAsync={fetchSummaryCsv}
          fileName={`summary-${dayjs().format("L")}`}
          disabled={isTableSummaryDataPending}
          sx={{ px: 2 }}
        />
      </Stack>
      <TableContainer
        component={Paper}
        style={{ marginBottom: "75px !important" }}
      >
        {!isTableSummaryDataPending ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Last Alert</TableCell>
                <TableCell align="center">Vehicle</TableCell>
                <TableCell align="center">Vehicle ID</TableCell>
                <TableCell align="center">Trailer</TableCell>
                <TableCell align="center">Trailer ID</TableCell>
                <TableCell align="center">Terminal</TableCell>
                <TableCell align="center">Driver</TableCell>
                {showTenantIds && (
                  <TableCell align="center">Tenant Id</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index: number) => {
                const vehicleStatusLink =
                  row.vehicleId &&
                  (showTenantIds && row.vehicleTenantId
                    ? generatePath(VEHICLE_STATUS_TENANT, {
                        vehicleId: row.vehicleId,
                        tenantId: row.vehicleTenantId,
                      })
                    : generatePath(VEHICLE_STATUS, {
                        vehicleId: row.vehicleId,
                      }));
                return (
                  <TableRow key={`${row}_${index}`}>
                    <TableCell align="center">
                      <StyledLink>
                        <RouterLink
                          to={`${ALERT_WEB_VIEW.replace(":token", row.token)}`}
                        >
                          {row.lastAlert}
                        </RouterLink>
                      </StyledLink>
                    </TableCell>
                    <TableCell align="center">{row.vehicle}</TableCell>
                    <TableCell align="center">
                      {vehicleStatusLink ? (
                        <StyledLink>
                          <RouterLink to={vehicleStatusLink}>
                            {row.vehicleId}
                          </RouterLink>
                        </StyledLink>
                      ) : (
                        row.vehicleId
                      )}
                    </TableCell>
                    <TableCell align="center">{row.trailer}</TableCell>
                    <TableCell align="center">{row.trailerId}</TableCell>
                    <TableCell align="center">{row.terminal}</TableCell>
                    <TableCell align="center">{row.driver}</TableCell>
                    {showTenantIds && (
                      <TableCell align="center">{row.tenantId}</TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={pageData.totalElements}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (
          <Loader />
        )}
      </TableContainer>
    </>
  );
};
