import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TerminalsState, terminalsState } from "./terminals.store";
import {
  addTerminal,
  fetchAllTerminals,
  fetchTerminal,
  updateTerminal,
} from "./terminals.actions";

const slice = createSlice({
  name: "terminals",
  initialState: terminalsState,
  reducers: {
    clearFetchAllTerminalsState: (state: TerminalsState) => ({
      ...state,
      fetchAllTerminals: terminalsState.fetchAllTerminals,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTerminal.pending, (state) => ({
        ...state,
        addTerminal: {
          ...state.addTerminal,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(addTerminal.fulfilled, (state) => ({
        ...state,
        addTerminal: {
          ...state.addTerminal,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(addTerminal.rejected, (state) => ({
        ...state,
        addTerminal: {
          ...state.addTerminal,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchTerminal.pending, (state) => ({
        ...state,
        fetchTerminal: {
          ...state.fetchTerminal,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTerminal.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTerminal: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchTerminal.rejected, (state) => ({
        ...state,
        fetchTerminal: {
          ...state.fetchTerminal,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchAllTerminals.pending, (state) => ({
        ...state,
        fetchAllTerminals: {
          ...state.fetchAllTerminals,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllTerminals.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllTerminals: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllTerminals.rejected, (state) => ({
        ...state,
        fetchAllTerminals: {
          ...state.fetchAllTerminals,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(updateTerminal.pending, (state) => ({
        ...state,
        updateTerminal: {
          ...state.updateTerminal,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(updateTerminal.fulfilled, (state) => ({
        ...state,
        updateTerminal: {
          ...state.updateTerminal,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(updateTerminal.rejected, (state) => ({
        ...state,
        updateTerminal: {
          ...state.updateTerminal,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const { clearFetchAllTerminalsState } = slice.actions;
export default slice.reducer;
