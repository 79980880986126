import React, { FC, useRef, useState, useEffect } from "react";
import { push } from "redux-first-history";
import { generatePath, useParams } from "react-router-dom";
import { FormikProps } from "formik";
import { Grid, Stack } from "@mui/material";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import {
  CreateEditVehicleDto,
  TireCoordinateDto,
} from "../../api/vehicles.api.dto";
import { selectIsEditVehiclePending } from "../../store/vehicles.selector";
import { VehicleSetupFormContainer } from "../../containers/VehicleSetupFormContainer/VehicleSetupFormContainer";
import { VehicleSetupTiresContainer } from "../../containers/VehicleSetupTiresContainer/VehicleSetupTiresContainer";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { Button } from "../../../app/components/Button/Button";
import {
  VEHICLE_SETUP_LIST,
  VEHICLE_SETUP,
  VEHICLE_SETUP_TENANT,
} from "../../../app/const/routes";
import { UserRole } from "../../../app/enum/UserRole";

export const VehicleSetupView: FC = () => {
  const dispatch = useAppDispatch();
  const { vehicleId, tenantId } = useParams();
  const formikRef = useRef<FormikProps<CreateEditVehicleDto>>(null);
  const isEditVehiclePending = useAppSelector(selectIsEditVehiclePending);
  const [selectedTireCoordinates, setSelectedTireCoordinates] = useState<
    TireCoordinateDto[]
  >([]);
  const [tireCoordinatesError, setTireCoordinatesError] = useState<
    string | undefined
  >(undefined);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;

  const changeVehicle = (
    vehicleId: string | undefined,
    tenantId: string | undefined
  ) => {
    vehicleId
      ? dispatch(
          push(
            canSeeTenants && tenantId
              ? generatePath(VEHICLE_SETUP_TENANT, {
                  vehicleId,
                  tenantId,
                })
              : generatePath(VEHICLE_SETUP, {
                  vehicleId,
                })
          )
        )
      : resetVehicleId();
  };

  const resetVehicleId = () => {
    dispatch(push(VEHICLE_SETUP_LIST));
  };

  useEffect(() => {
    formikRef.current?.setFieldValue("tiresMap", [...selectedTireCoordinates]);
    setTimeout(() => {
      if (
        !formikRef.current?.dirty &&
        JSON.stringify(selectedTireCoordinates) !==
          JSON.stringify(formikRef.current?.initialValues.tiresMap)
      ) {
        formikRef.current?.setFieldTouched("tiresMap", true);
      }
    }, 1);
  }, [selectedTireCoordinates]);

  return (
    <PageLayout>
      <PageTitle title="Vehicle Setup" />
      <Grid container spacing={7}>
        <Grid item sm={4}>
          <VehicleSetupFormContainer
            formikRef={formikRef}
            selectedVehicleId={vehicleId}
            selectedTenantId={tenantId}
            showTenantIds={canSeeTenants}
            changeVehicle={changeVehicle}
            setTireCoordinatesError={setTireCoordinatesError}
          />
        </Grid>
        <Grid item sm={8}>
          <VehicleSetupTiresContainer
            vehicleId={vehicleId}
            tenantId={tenantId}
            selectedTireCoordinates={selectedTireCoordinates}
            setSelectedTireCoordinates={setSelectedTireCoordinates}
            errorMessage={tireCoordinatesError}
            showTenantIds={canSeeTenants}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="text"
              onClick={() => resetVehicleId()}
              disabled={isEditVehiclePending}
            >
              Cancel
            </Button>
            <Button
              onClick={() => formikRef.current?.submitForm()}
              pending={isEditVehiclePending}
              disabled={isEditVehiclePending || !vehicleId}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </PageLayout>
  );
};
