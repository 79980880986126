import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TrailersState, trailersState } from "./trailers.store";
import {
  fetchAllTrailers,
  fetchTrailer,
  fetchTrailerCurrentStats,
  fetchTrailerHistoricalStats,
  editTrailer,
  fetchTrailersPages,
  fetchTrailerPressureHistory,
  fetchTrailerTemperatureHistory,
} from "./trailers.actions";

const slice = createSlice({
  name: "trailers",
  initialState: trailersState,
  reducers: {
    clearFetchAllTrailersState: (state: TrailersState) => ({
      ...state,
      fetchAllTrailers: trailersState.fetchAllTrailers,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTrailers.pending, (state) => ({
        ...state,
        fetchAllTrailers: {
          ...state.fetchAllTrailers,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllTrailers.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllTrailers: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllTrailers.rejected, (state) => ({
        ...state,
        fetchAllTrailers: {
          ...state.fetchAllTrailers,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailer.pending, (state) => ({
        ...state,
        fetchTrailer: {
          ...state.fetchTrailer,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTrailer.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTrailer: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchTrailer.rejected, (state) => ({
        ...state,
        fetchTrailer: {
          ...state.fetchTrailer,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailerCurrentStats.pending, (state) => ({
        ...state,
        fetchTrailerCurrentStats: {
          ...state.fetchTrailerCurrentStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTrailerCurrentStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTrailerCurrentStats: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchTrailerCurrentStats.rejected, (state) => ({
        ...state,
        fetchTrailerCurrentStats: {
          ...state.fetchTrailerCurrentStats,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailerHistoricalStats.pending, (state) => ({
        ...state,
        fetchTrailerHistoricalStats: {
          ...state.fetchTrailerHistoricalStats,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTrailerHistoricalStats.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTrailerHistoricalStats: {
              status: AsyncStatus.Fulfilled,
              data: { ...payload },
            },
          };
        }
        return state;
      })
      .addCase(fetchTrailerHistoricalStats.rejected, (state) => ({
        ...state,
        fetchTrailerHistoricalStats: {
          ...state.fetchTrailerHistoricalStats,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(editTrailer.pending, (state) => ({
        ...state,
        editTrailer: {
          ...state.editTrailer,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(editTrailer.fulfilled, (state) => ({
        ...state,
        editTrailer: {
          ...state.editTrailer,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(editTrailer.rejected, (state) => ({
        ...state,
        editTrailer: {
          ...state.editTrailer,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailersPages.pending, (state) => ({
        ...state,
        fetchTrailersPages: {
          ...state.fetchTrailersPages,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTrailersPages.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTrailersPages: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchTrailersPages.rejected, (state) => ({
        ...state,
        fetchTrailersPages: {
          ...state.fetchTrailersPages,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailerPressureHistory.pending, (state) => ({
        ...state,
        fetchTrailerPressureHistory: {
          ...state.fetchTrailerPressureHistory,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchTrailerPressureHistory.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchTrailerPressureHistory: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchTrailerPressureHistory.rejected, (state) => ({
        ...state,
        fetchTrailerPressureHistory: {
          ...state.fetchTrailerPressureHistory,
          status: AsyncStatus.Rejected,
        },
      }))

      .addCase(fetchTrailerTemperatureHistory.pending, (state) => ({
        ...state,
        fetchTrailerTemperatureHistory: {
          ...state.fetchTrailerTemperatureHistory,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(
        fetchTrailerTemperatureHistory.fulfilled,
        (state, { payload }) => {
          if (payload) {
            return {
              ...state,
              fetchTrailerTemperatureHistory: {
                status: AsyncStatus.Fulfilled,
                data: [...payload],
              },
            };
          }
          return state;
        }
      )
      .addCase(fetchTrailerTemperatureHistory.rejected, (state) => ({
        ...state,
        fetchTrailerTemperatureHistory: {
          ...state.fetchTrailerTemperatureHistory,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const { clearFetchAllTrailersState } = slice.actions;
export default slice.reducer;
