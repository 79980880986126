import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { NotificationsState } from "./notifications.store";

export const selectSelf = (state: RootState): RootState => state;

export const selectNotificationsState = createSelector(
  selectSelf,
  (state: RootState): NotificationsState => state.notifications
);
