import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { SummaryState } from "./summary.store";
import { AlertsSummaryDto, PagesDto, SummaryDto } from "../api/summary.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectSummaryRootState = createSelector(
  selectSelf,
  (state: RootState): SummaryState => state.summary
);

export const selectFetchDailySummaryState = createSelector(
  selectSummaryRootState,
  (summary): AsyncState<SummaryDto[]> => summary.fetchDailySummary
);

export const selectFetchDailySummaryData = createSelector(
  selectFetchDailySummaryState,
  (fetchDailySummary): SummaryDto[] => fetchDailySummary.data
);

export const selectFetchDailySummaryStatus = createSelector(
  selectFetchDailySummaryState,
  (fetchDailySummary): AsyncStatus => fetchDailySummary.status
);

export const selectIsFetchDailySummaryPending = createSelector(
  selectFetchDailySummaryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAlertsSummaryState = createSelector(
  selectSummaryRootState,
  (summary): AsyncState<AlertsSummaryDto[]> => summary.fetchLatestSummary
);

export const selectFetchAlertsSummaryData = createSelector(
  selectFetchAlertsSummaryState,
  (fetchLatestSummary): AlertsSummaryDto[] => fetchLatestSummary.data
);

export const selectFetchAlertsSummaryStatus = createSelector(
  selectFetchAlertsSummaryState,
  (fetchLatestSummary): AsyncStatus => fetchLatestSummary.status
);

export const selectIsFetchAlertsSummaryPending = createSelector(
  selectFetchAlertsSummaryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAlertsPagesState = createSelector(
  selectSummaryRootState,
  (pages): AsyncState<PagesDto> => pages.fetchSummaryPages
);

export const selectFetchAlertsPagesData = createSelector(
  selectFetchAlertsPagesState,
  (fetchSummaryPages): PagesDto => fetchSummaryPages.data
);

export const selectFetchAlertsPagesStatus = createSelector(
  selectFetchAlertsPagesState,
  (fetchSummaryPages): AsyncStatus => fetchSummaryPages.status
);

export const selectIsFetchAlertsPagesPending = createSelector(
  selectFetchAlertsPagesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
