import React, { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  FilterListOff as FilterListOffIcon,
  FilterList as FilterListOnIcon,
} from "@mui/icons-material";

import { TenantDto } from "../../api/tenants.api.dto";
import {
  disableTenant as disableTenantAction,
  enableTenant as enableTenantAction,
} from "../../store/tenants.actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface ITenantsTable {
  terminalsTableData: TenantDto[];
  isLoading?: boolean;
}

export const TenantsTable: FC<ITenantsTable> = ({
  terminalsTableData,
  isLoading,
}) => {
  const dispatch = useAppDispatch();

  const handleTenantEnable = (id: string) =>
    id && dispatch(enableTenantAction(id));

  const handleTenantDisable = (id: string) =>
    id && dispatch(disableTenantAction(id));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.id}>
          <span>{params.row.id}</span>
        </Tooltip>
      ),
    },
    {
      field: "enabled",
      headerName: "Enabled",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row?.disabled}>
          <span>{params.row?.disabled ? "No" : "Yes"}</span>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "",
      align: "right",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.disabled ? (
              <IconButton
                title="Enable this tenant"
                aria-label="Enable this tenant"
                size="small"
                color="info"
                onClick={() => handleTenantEnable(params.row.id)}
              >
                <FilterListOffIcon />
              </IconButton>
            ) : (
              <IconButton
                title="Disable this tenant"
                aria-label="Disable this tenant"
                size="small"
                color="error"
                onClick={() => handleTenantDisable(params.row.id)}
              >
                <FilterListOnIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={terminalsTableData}
      columns={columns}
      getRowId={(row) => row.id}
      loading={isLoading}
      hideFooterPagination={true}
    />
  );
};
