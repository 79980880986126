import React, { FC, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../../app/utils/get-tenant-ids";
import { CreateEditUserDto } from "../../api/users.api.dto";
import { addUser as addUserAction } from "../../store/users.actions";
import { fetchAllTerminals as fetchAllTerminalsAction } from "../../../terminals/store/terminals.actions";
import {
  selectFetchAllTerminalsData,
  selectIsFetchAllTerminalsPending,
} from "../../../terminals/store/terminals.selector";
import { UserForm } from "../../components/UserForm/UserForm";
import { UserRole } from "../../../app/enum/UserRole";

export const UserAddContainer: FC = () => {
  const dispatch = useAppDispatch();
  const terminals = useAppSelector(selectFetchAllTerminalsData);
  const isFetchAllTerminalsPending = useAppSelector(
    selectIsFetchAllTerminalsPending
  );
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const canSeeTerminals = useShowForRoles([
    UserRole.SuperAdmin,
    UserRole.Admin,
  ]);

  const onCreateUser = async (userModel: CreateEditUserDto): Promise<void> => {
    dispatch(addUserAction({ userModel }));
  };

  useEffect(() => {
    if (canSeeTerminals) {
      dispatch(fetchAllTerminalsAction());
    }
  }, [dispatch]);

  return (
    <UserForm
      terminals={terminals}
      showTenantField={canSeeTenants}
      showTerminalField={canSeeTerminals}
      onSubmit={onCreateUser}
      isPending={isFetchAllTerminalsPending}
    />
  );
};
