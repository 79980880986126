import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { webAnalyticsState } from "./webanalytics.store";
import {
  fetchWebAnalyticsConsumeCount,
  fetchWebAnalyticsIngestCount,
  fetchWebAnalyticsQueueSize,
} from "./webanalytics.actions";

const slice = createSlice({
  name: "webAnalytics",
  initialState: webAnalyticsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebAnalyticsConsumeCount.pending, (state) => ({
        ...state,
        consumeAnalytics: {
          ...state.consumeAnalytics,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(
        fetchWebAnalyticsConsumeCount.fulfilled,
        (state, { payload }) => {
          if (payload) {
            return {
              ...state,
              consumeAnalytics: {
                status: AsyncStatus.Fulfilled,
                data: payload,
              },
            };
          }
          return state;
        }
      )
      .addCase(fetchWebAnalyticsConsumeCount.rejected, (state) => ({
        ...state,
        consumeAnalytics: {
          ...state.consumeAnalytics,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchWebAnalyticsIngestCount.pending, (state) => ({
        ...state,
        ingestAnalytics: {
          ...state.ingestAnalytics,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchWebAnalyticsIngestCount.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            ingestAnalytics: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchWebAnalyticsIngestCount.rejected, (state) => ({
        ...state,
        queueSize: {
          ...state.queueSize,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchWebAnalyticsQueueSize.pending, (state) => ({
        ...state,
        queueSize: {
          ...state.queueSize,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchWebAnalyticsQueueSize.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            queueSize: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchWebAnalyticsQueueSize.rejected, (state) => ({
        ...state,
        queueSize: {
          ...state.queueSize,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export default slice.reducer;
