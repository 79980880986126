import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export interface SettingsState {
  fetchSilencedNotifications: AsyncState<string | null>;
  saveSettings: AsyncState<null>;
  subscribeToEmailAlerts: AsyncState<null>;
  unsubscribeFromEmailAlerts: AsyncState<null>;
}

export const settingsState: SettingsState = {
  fetchSilencedNotifications: {
    status: AsyncStatus.Void,
    data: null,
  },
  saveSettings: {
    status: AsyncStatus.Void,
    data: null,
  },
  subscribeToEmailAlerts: {
    status: AsyncStatus.Void,
    data: null,
  },
  unsubscribeFromEmailAlerts: {
    status: AsyncStatus.Void,
    data: null,
  },
};
