import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { TenantsState, tenantsState } from "./tenants.store";
import { fetchAllTenants, addTenant } from "./tenants.actions";

const slice = createSlice({
  name: "tenants",
  initialState: tenantsState,
  reducers: {
    searchTenants: (state: TenantsState, { payload }: { payload: string }) => ({
      ...state,
      searchTenants: payload,
    }),
    clearSearchTenantsState: (state: TenantsState) => ({
      ...state,
      searchTenants: tenantsState.searchTenants,
    }),
    clearFetchAllTenantsState: (state: TenantsState) => ({
      ...state,
      fetchAllTenants: tenantsState.fetchAllTenants,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTenants.pending, (state) => ({
        ...state,
        fetchAllTenants: {
          ...state.fetchAllTenants,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllTenants.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllTenants: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllTenants.rejected, (state) => ({
        ...state,
        fetchAllTenants: {
          ...state.fetchAllTenants,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(addTenant.pending, (state) => ({
        ...state,
        addTenant: {
          ...state.addTenant,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(addTenant.fulfilled, (state) => ({
        ...state,
        addTenant: {
          ...state.addTenant,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(addTenant.rejected, (state) => ({
        ...state,
        addTenant: {
          ...state.addTenant,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const {
  searchTenants,
  clearSearchTenantsState,
  clearFetchAllTenantsState,
} = slice.actions;
export default slice.reducer;
