import React, { FC } from "react";
import { Box, Grid, Stack } from "@mui/material";
import dayjs from "dayjs";

import { fetchAllVehiclesCsv } from "../../api/vehicles.api";
import { PageLayout } from "../../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../../app/components/PageTitle/PageTitle";
import { PageSubtitle } from "../../../app/components/PageSubtitle/PageSubtitle";
import { ExportCsvButton } from "../../../app/components/ExportCsvButton/ExportCsvButton";
import { VehicleSearchContainer } from "../../containers/VehicleStatusListContainer/VehicleSearchContainer";
import { VehicleStatusListContainer } from "../../containers/VehicleStatusListContainer/VehicleStatusListContainer";

export const VehicleStatusListView: FC = () => {
  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <PageTitle title="Vehicles" />
            <PageSubtitle title="Select a vehicle to view its status." />
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Stack
              direction="row"
              spacing={1.5}
              justifyContent="flex-end"
              mb={4}
            >
              <VehicleSearchContainer />
              <ExportCsvButton
                fetchAsync={fetchAllVehiclesCsv}
                fileName={`vehicles-${dayjs().format("L")}`}
                sx={{ px: 2 }}
              />
            </Stack>
          </Grid>
        </Grid>
        <VehicleStatusListContainer />
      </Box>
    </PageLayout>
  );
};
