import { styled } from "@mui/material/styles";
import spacing from "../../../app/theme/spacing";

export const StyledWrapper = styled("div")(({ theme }) => ({
  marginTop: spacing(-5),

  "> *:last-child": {
    marginBottom: spacing(4),
  },

  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginTop: 0,

    "> *": {
      marginBottom: spacing(2),
    },
  },
}));

export const StyledFiltersWrapper = styled("div")(({ theme }) => ({
  "> *": {
    marginBottom: spacing(1.5),
  },

  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",

    "> * > .MuiFormControl-root:not(:last-child), > *:not(:last-child)": {
      marginRight: spacing(1.5),
    },
  },
}));
