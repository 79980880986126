import React, { FC, useEffect } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { FormControl, TextField, Autocomplete, Link } from "@mui/material";
import { VehicleDto, CreateEditVehicleDto } from "../../api/vehicles.api.dto";
import { PublicUserDto } from "../../../users/api/users.api.dto";
import { TerminalDto } from "../../../terminals/api/terminals.api.dto";
import { vehicleSetupFormValidationSchema } from "./VehicleSetupForm.validation";
import { TRAILER_SETUP, TRAILER_SETUP_TENANT } from "../../../app/const/routes";

interface IVehicleSetupForm {
  formikRef: React.Ref<FormikProps<CreateEditVehicleDto>>;
  onSubmit: (formValues: CreateEditVehicleDto) => void;
  isPending: boolean;
  vehicles: VehicleDto[];
  selectedVehicleId: string | undefined;
  selectedTenantId: string | undefined;
  showTenantIds?: boolean;
  changeVehicle: (
    vehicleId: string | undefined,
    tenantId: string | undefined
  ) => void;
  setTireCoordinatesError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  drivers: PublicUserDto[];
  terminals: TerminalDto[];
}

export const VehicleSetupForm: FC<IVehicleSetupForm> = ({
  formikRef,
  onSubmit,
  isPending,
  vehicles,
  selectedVehicleId,
  selectedTenantId,
  showTenantIds,
  changeVehicle,
  setTireCoordinatesError,
  drivers,
  terminals,
}) => {
  const selectedVehicle = vehicles.find(
    (vehicle) =>
      vehicle.alternateId === selectedVehicleId &&
      (!showTenantIds || vehicle.tenantId === selectedTenantId)
  );
  const [assignedDrivers, setAssignedDrivers] = React.useState<string[]>([]);
  const [assignedTerminal, setAssignedTerminal] = React.useState<string>("");

  const concatVehicleTenantIds = (
    vehicleId: string,
    tenantId: string | undefined
  ) => {
    if (showTenantIds) {
      return `${vehicleId}|||${tenantId || ""}`;
    }
    return vehicleId;
  };

  const splitVehicleTenantIds = (concatenatedIds: string) => {
    if (showTenantIds) {
      const [vehicleId, tenantId] = concatenatedIds.split("|||", 2);
      return {
        vehicleId,
        tenantId: tenantId || undefined,
      };
    }
    return {
      vehicleId: concatenatedIds,
      tenantId: undefined,
    };
  };

  const getVehicleIdOptions = () =>
    [...vehicles]
      .filter((vehicle) => !!vehicle.alternateId)
      .sort((a, b) => a.alternateId.localeCompare(b.alternateId))
      .map((vehicle) =>
        concatVehicleTenantIds(vehicle.alternateId, vehicle.tenantId)
      );

  const getVehicleIdOptionLabel = (option: string) => {
    const { vehicleId, tenantId } = splitVehicleTenantIds(option);
    const match = vehicles.find(
      (vehicle) =>
        vehicle.alternateId === vehicleId &&
        (!showTenantIds || vehicle.tenantId === tenantId)
    );
    let label = "";
    if (match) {
      if (match.name) {
        label = `${match.alternateId} (${match.name})`;
      } else if (match.make && match.model) {
        label = `${match.alternateId} (${match.make} ${match.model} ${match.year})`;
      } else {
        label = `${match.alternateId}`;
      }
      if (showTenantIds && match.tenantId) {
        label += ` [${match.tenantId}]`;
      }
    }
    return label;
  };

  const getSelectedVehicleIdOption = () =>
    selectedVehicleId
      ? concatVehicleTenantIds(selectedVehicleId, selectedTenantId)
      : "";

  const getDriverIdOptions = () => drivers.map((driver) => driver.username);

  const terminalOptions = terminals
    .filter((terminal) => {
      return (
        selectedVehicle &&
        (!showTenantIds || terminal.tenantId == selectedVehicle.tenantId)
      );
    })
    .map((terminal) => terminal.name);

  const getDriverIdOptionLabel = (option: string) => {
    const match = drivers.find((driver) => driver.username === option);
    if (match) {
      return match.username;
    }
    return "";
  };

  useEffect(() => {
    setAssignedDrivers(
      selectedVehicle?.drivers?.map((driver) => driver.username) || []
    );
    setAssignedTerminal(selectedVehicle?.terminalId || "");
  }, [selectedVehicle]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        make: selectedVehicle?.make || "",
        model: selectedVehicle?.model || "",
        name: selectedVehicle?.name || "",
        type: selectedVehicle?.type || "",
        terminalId: selectedVehicle?.terminalId || "",
        licensePlate: selectedVehicle?.licensePlate || "",
        driversUsernames: selectedVehicle?.drivers || [],
        tiresMap: selectedVehicle?.tiresMap || [],
      }}
      validationSchema={vehicleSetupFormValidationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
      }) => {
        useEffect(() => {
          setTireCoordinatesError(
            values.tiresMap && touched.tiresMap && errors.tiresMap
              ? errors.tiresMap
              : undefined
          );
        }, [values, touched, errors]);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormControl>
              <label htmlFor="vehicle-setup-id">Select vehicle ID</label>
              <Autocomplete
                id="vehicle-setup-id"
                disablePortal
                disableClearable
                options={getVehicleIdOptions()}
                getOptionLabel={getVehicleIdOptionLabel}
                value={getSelectedVehicleIdOption()}
                onBlur={handleBlur}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  newValue: string | null
                ) => {
                  if (newValue) {
                    const { vehicleId, tenantId } =
                      splitVehicleTenantIds(newValue);
                    changeVehicle(vehicleId, tenantId);
                  } else {
                    changeVehicle(undefined, undefined);
                  }
                  resetForm();
                }}
                renderInput={(params) => <TextField {...params} />}
                disabled={isPending}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-make">Make</label>
              <TextField
                id="vehicle-setup-make"
                type="text"
                name="make"
                value={values.make}
                disabled={!showTenantIds}
                error={Boolean(selectedVehicle && touched.make && errors.make)}
                helperText={selectedVehicle && touched.make && errors.make}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-model">Model</label>
              <TextField
                id="vehicle-setup-model"
                type="text"
                name="model"
                value={values.model}
                disabled={!showTenantIds}
                error={Boolean(
                  selectedVehicle && touched.model && errors.model
                )}
                helperText={selectedVehicle && touched.model && errors.model}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-name">Name</label>
              <TextField
                id="vehicle-setup-name"
                type="text"
                name="name"
                value={values.name}
                disabled={!selectedVehicle || isPending}
                error={Boolean(selectedVehicle && touched.name && errors.name)}
                helperText={selectedVehicle && touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-type">Type</label>
              <TextField
                id="vehicle-setup-type"
                type="text"
                name="type"
                value={values.type}
                disabled={!selectedVehicle || isPending}
                error={Boolean(selectedVehicle && touched.type && errors.type)}
                helperText={selectedVehicle && touched.type && errors.type}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-license">License plate</label>
              <TextField
                id="vehicle-setup-license"
                type="text"
                name="licensePlate"
                value={values.licensePlate}
                disabled={!selectedVehicle || isPending}
                error={Boolean(
                  selectedVehicle && touched.licensePlate && errors.licensePlate
                )}
                helperText={
                  selectedVehicle && touched.licensePlate && errors.licensePlate
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <label htmlFor="vehicle-setup-username">Assign a driver</label>
              <Autocomplete
                id="vehicle-setup-username"
                disablePortal
                multiple
                options={getDriverIdOptions()}
                getOptionLabel={getDriverIdOptionLabel}
                value={assignedDrivers}
                onBlur={handleBlur}
                onChange={(event, newValue: string[]) => {
                  setAssignedDrivers(newValue.map((option) => option));
                  setFieldValue("driversUsernames", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      selectedVehicle &&
                        touched.driversUsernames &&
                        errors.driversUsernames
                    )}
                    helperText={
                      selectedVehicle &&
                      touched.driversUsernames &&
                      errors.driversUsernames
                    }
                    placeholder={
                      assignedDrivers.length > 0 ? "" : "No driver assigned"
                    }
                  />
                )}
                disabled={!selectedVehicle || isPending}
              />
            </FormControl>
            {!!terminalOptions.length && showTenantIds && (
              <FormControl>
                <label htmlFor="vehicle-setup-username">
                  Assign a terminal
                </label>
                <Autocomplete
                  id="vehicle-setup-terminal"
                  key="terminalId"
                  disablePortal
                  options={terminalOptions}
                  value={assignedTerminal}
                  onBlur={handleBlur}
                  onChange={(event, newValue: string | null) => {
                    setFieldValue("terminalId", newValue || "");
                    setAssignedTerminal(newValue || "");
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      value === undefined || value === "" || option == value
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        selectedVehicle?.terminalId
                          ? ""
                          : "No terminal assigned"
                      }
                    />
                  )}
                  disabled={!selectedVehicle || isPending}
                />
              </FormControl>
            )}
            {selectedVehicle?.trailer && (
              <FormControl>
                <label htmlFor="vehicle-setup-trailer">Assigned trailer</label>
                <TextField
                  id="vehicle-setup-trailer"
                  type="text"
                  value={
                    selectedVehicle?.trailer.name
                      ? `${selectedVehicle?.trailer.alternateId} (${selectedVehicle?.trailer.name})`
                      : `${selectedVehicle?.trailer.alternateId}`
                  }
                  disabled
                  helperText={
                    !dirty &&
                    selectedVehicle?.trailer?.alternateId && (
                      <Link
                        component={RouterLink}
                        to={
                          showTenantIds && selectedVehicle.trailer.tenantId
                            ? generatePath(TRAILER_SETUP_TENANT, {
                                trailerId: selectedVehicle.trailer.alternateId,
                                tenantId: selectedVehicle.trailer.tenantId,
                              })
                            : generatePath(TRAILER_SETUP, {
                                trailerId: selectedVehicle.trailer.alternateId,
                              })
                        }
                      >
                        Edit trailer
                      </Link>
                    )
                  }
                />
              </FormControl>
            )}
          </form>
        );
      }}
    </Formik>
  );
};
