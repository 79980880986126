import { PressureStatus } from "../../app/enum/Vehicle";
import { VehicleDto } from "../../vehicles/api/vehicles.api.dto";

export const getCriticalTireCount = (vehicle: VehicleDto) =>
  vehicle.tires?.filter(
    (tire) =>
      tire.leak ||
      tire.temperatureAlert ||
      tire.pressureStatus === PressureStatus.EUP ||
      tire.pressureStatus === PressureStatus.EOP
  ).length ?? 0;

export const getCautionaryTireCount = (vehicle: VehicleDto) =>
  vehicle.tires?.filter(
    (tire) =>
      tire.pressureStatus === PressureStatus.UP ||
      tire.pressureStatus === PressureStatus.OP
  ).length ?? 0;

export const getOfflineTireCount = (vehicle: VehicleDto) =>
  vehicle.tires?.filter((tire) => tire.sensorError || tire.lostSignal).length ??
  0;
