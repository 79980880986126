import React, { FC, useState } from "react";
import { useMediaQuery, Theme, CssBaseline } from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { getTenantIds } from "../../utils/get-tenant-ids";
import {
  MainWrapper,
  FullHeightContainer,
} from "../PageLayout/PageLayout.style";
import { TopNav } from "../TopNav/TopNav";
import { SideNav } from "../SideNav/SideNav";
import { SIDE_NAV_DESKTOP_WIDTH } from "../../const/nav";

interface IPageLayout {
  hideSideNav?: boolean;
}

export const PageLayout: FC<IPageLayout> = ({ hideSideNav, children }) => {
  const user = useCurrentUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [leftNavIsOpen, setLeftNavIsOpen] = useState(false);
  const tenantIds = getTenantIds();
  const hasTenants = tenantIds.length > 0;

  return (
    <>
      <CssBaseline />
      <TopNav
        showLeftNavToggle={!hideSideNav && !user?.hideSideNav && isMobile}
        onLeftNavToggle={() => setLeftNavIsOpen(!leftNavIsOpen)}
      />
      {!hideSideNav && !user?.hideSideNav && (
        <SideNav
          vehicleStatusLink={user?.vehicleStatus || ""}
          hasTenants={hasTenants}
          toggleable={isMobile}
          isOpen={leftNavIsOpen}
          onToggle={() => setLeftNavIsOpen(!leftNavIsOpen)}
        />
      )}
      <MainWrapper
        offsetLeft={
          !hideSideNav && !user?.hideSideNav && !isMobile
            ? SIDE_NAV_DESKTOP_WIDTH
            : 0
        }
      >
        <FullHeightContainer maxWidth="lg" sx={{ py: 6 }}>
          {children}
        </FullHeightContainer>
      </MainWrapper>
    </>
  );
};
