import { createAsyncThunk } from "@reduxjs/toolkit";
import withError from "../../app/utils/with-thunk-error";
import { push } from "redux-first-history";
import {
  fetchAllUsers as fetchAllUsersRequest,
  fetchUser as fetchUserRequest,
  updateUser as updateUserRequest,
  createUser as createUserRequest,
  deactivateUser as deactivateUserRequest,
  activateUser as activateUserRequest,
} from "../api/users.api";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../app/store/notifications/notifications.slice";
import { CreateEditUserDto, PublicUserDto } from "../api/users.api.dto";
import { SUMMARY, USER } from "../../app/const/routes";

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  withError(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (args: undefined): Promise<PublicUserDto[]> =>
      await fetchAllUsersRequest()
  )
);

export const fetchUser = createAsyncThunk(
  "users/fetchUser",
  withError(async (username: string): Promise<PublicUserDto> => {
    return fetchUserRequest(username);
  })
);

export const addUser = createAsyncThunk(
  "users/addUser",
  withError(
    async (
      { userModel }: { userModel: CreateEditUserDto },
      { dispatch }
    ): Promise<void> => {
      await createUserRequest(userModel);
      dispatch(openSuccessNotification("User created successfully"));
      return dispatch(push(USER));
    }
  )
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  withError(
    async (
      {
        username,
        userModel,
      }: {
        username: string;
        userModel: CreateEditUserDto;
      },
      { dispatch }
    ): Promise<void> => {
      try {
        await updateUserRequest(username, userModel);
        dispatch(openSuccessNotification("User updated successfully"));
        return dispatch(push(USER));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exception: any) {
        dispatch(openErrorNotification("Forbidden resource"));
        return dispatch(push(SUMMARY));
      }
    }
  )
);

export const deactivateUser = createAsyncThunk(
  "users/deactivateUser",
  withError(
    async (
      {
        username,
      }: {
        username: string;
      },
      { dispatch }
    ): Promise<void> => {
      await deactivateUserRequest(username);
      dispatch(openSuccessNotification("User deactivated successfully"));
      return dispatch(push(USER));
    }
  )
);

export const activateUser = createAsyncThunk(
  "users/activateUser",
  withError(
    async (
      {
        username,
      }: {
        username: string;
      },
      { dispatch }
    ): Promise<void> => {
      await activateUserRequest(username);
      dispatch(openSuccessNotification("User reactivated successfully"));
      return dispatch(push(USER));
    }
  )
);
