import * as Yup from "yup";

export const trailerSetupFormValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  licensePlate: Yup.string().optional(),
  tiresMap: Yup.array()
    .of(
      Yup.object().shape({
        axle: Yup.number(),
        tire: Yup.number(),
        tireId: Yup.string(),
      })
    )
    .typeError("The axle and tire configuration must be specified")
    .required("The axle and tire configuration must be specified")
    .min(2, "The axle and tire configuration must be specified"),
});
