import { createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "redux-first-history";
import withError from "../../app/utils/with-thunk-error";
import { UnsubscribeDto } from "../api/unsubscribe.api.dto";
import { unsubscribe as unsubscribeRequest } from "../api/unsubscribe.api";
import { UNSUBSCRIBE_ERROR, UNSUBSCRIBE_SUCCESS } from "../../app/const/routes";

export const unsubscribe = createAsyncThunk(
  "auth/resetPassword",
  withError(
    async (unsubscribeData: UnsubscribeDto, { dispatch }): Promise<void> => {
      try {
        await unsubscribeRequest(unsubscribeData);
        return dispatch(push(UNSUBSCRIBE_SUCCESS));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exception: any) {
        return dispatch(push(UNSUBSCRIBE_ERROR));
      }
    }
  )
);
