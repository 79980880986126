import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { SummaryDto, AlertDto } from "../../summary/api/summary.api.dto";
import { PagesDto } from "../../summary/api/summary.api.dto";

export interface EmailState {
  fetchSummaryEmail: AsyncState<SummaryDto | null>;
  fetchAlertEmail: AsyncState<AlertDto | null>;
  fetchSummaryEmailPages: AsyncState<PagesDto>;
}

export const emailState: EmailState = {
  fetchAlertEmail: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchSummaryEmail: {
    status: AsyncStatus.Void,
    data: null,
  },
  fetchSummaryEmailPages: {
    status: AsyncStatus.Void,
    data: { pageSize: 0, totalElements: 0, totalPages: 0 },
  },
};
