import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { selectFetchTerminalData } from "../store/terminals.selector";
import { TerminalForm } from "../components/TerminalForm/TerminalForm";
import { UserRole } from "../../app/enum/UserRole";
import { selectIsFetchAllTerminalsPending } from "../store/terminals.selector";
import { EditTerminalDto } from "../../terminals/api/terminals.api.dto";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { updateTerminal } from "../store/terminals.actions";
import { fetchTerminal as fetchTerminalAction } from "../../terminals/store/terminals.actions";
import {
  selectFetchAllUsersData,
  selectIsFetchAllUsersPending,
} from "../../users/store/users.selector";
import { fetchAllUsers } from "../../users/store/users.actions";

export const TerminalsEditContainer: FC = () => {
  const { id, tenantId } = useParams();
  const terminal = useAppSelector(selectFetchTerminalData);
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const isFetchTerminalPending = useAppSelector(
    selectIsFetchAllTerminalsPending
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(
        fetchTerminalAction({
          id,
          tenantId,
        })
      );
      dispatch(fetchAllUsers());
    }
  }, [dispatch, id, tenantId]);

  const onUpdateTerminal = (terminalModel: EditTerminalDto) => {
    return dispatch(
      updateTerminal({
        terminalModel,
        tenantId: tenantId,
        terminalId: id as string,
      })
    );
  };

  const users = useAppSelector(selectFetchAllUsersData);
  const isUsersPending = useAppSelector(selectIsFetchAllUsersPending);

  return (
    <TerminalForm
      terminal={terminal}
      users={users}
      showTenantField={canSeeTenants}
      onSubmit={onUpdateTerminal}
      isPending={isFetchTerminalPending || isUsersPending}
    />
  );
};
