import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { isCurrentPath } from "../../../app/utils/is-current-path";
import { ResetPasswordDto } from "../../api/auth.api.dto";
import { resetPassword } from "../../store/auth.action";
import { selectIsResetPasswordStatusPending } from "../../store/auth.selector";
import { ResetPasswordForm } from "../../components/ResetPasswordForm/ResetPasswordForm";
import {
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "../../../app/const/routes";

export const ResetPasswordFormContainer: FC = () => {
  const dispatch = useAppDispatch();
  const isResetPasswordPending = useAppSelector(
    selectIsResetPasswordStatusPending
  );
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const onSubmit = (resetPasswordData: ResetPasswordDto) =>
    dispatch(
      resetPassword({
        ...resetPasswordData,
        resetPasswordToken: token || "",
      })
    );

  return (
    <ResetPasswordForm
      onSubmit={onSubmit}
      isPending={isResetPasswordPending}
      showSuccessNotification={isCurrentPath(RESET_PASSWORD_SUCCESS)}
      showErrorNotification={isCurrentPath(RESET_PASSWORD_ERROR) || !token}
    />
  );
};
