import React, { FC, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { PageLayout } from "../../app/components/PageLayout/PageLayout";
import { PageTitle } from "../../app/components/PageTitle/PageTitle";
import { GPSMapContainer } from "../../map/containers/GPSMapContainer";
import {
  TireDto,
  VehicleMapDto,
  VehicleState,
  VehicleTerminalDto,
} from "../../vehicles/api/vehicles.api.dto";
import { PressureStatus } from "../../app/enum/Vehicle";
import { useAppSelector } from "../../app/hooks/useAppSelector";
import { selectFetchAllVehiclesCurrentStatsData } from "../../vehicles/store/vehicles.selector";
import { fetchAllVehiclesCurrentStats } from "../../vehicles/store/vehicles.actions";
import { fetchSummaryPages } from "../../summary/store/summary.action";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";

export const GPSMapView: FC = () => {
  const dispatch = useAppDispatch();
  const vehicles = useAppSelector(selectFetchAllVehiclesCurrentStatsData);

  const terminals: VehicleTerminalDto[] = [];
  const positions: VehicleMapDto[] = [];
  vehicles?.forEach((vehicle) => {
    const isCritical = vehicle.tires
      ? vehicle.tires.some(
          (tire: TireDto) =>
            tire.leak ||
            tire.temperatureAlert ||
            tire.pressureStatus === PressureStatus.EUP ||
            tire.pressureStatus === PressureStatus.EOP
        )
      : false;

    const isCautionary = vehicle.tires
      ? vehicle.tires.some(
          (tire: TireDto) =>
            tire.pressureStatus === PressureStatus.UP ||
            tire.pressureStatus === PressureStatus.OP
        )
      : false;

    if (typeof vehicle?.terminal !== "undefined") {
      const terminalExists = terminals.some(function (terminal) {
        return terminal?.id === vehicle?.terminal?.id;
      });
      if (!terminalExists) {
        terminals.push(vehicle?.terminal);
      }
    }

    positions.push({
      lat: vehicle?.gps?.lat || 33.9364757,
      lng: vehicle?.gps?.lon || -118.3080658,
      name: vehicle?.name,
      make: vehicle?.make,
      model: vehicle?.model,
      drivers: vehicle?.drivers,
      alternateId: vehicle?.alternateId,
      tenantId: vehicle?.tenantId,
      state: isCritical
        ? VehicleState.Critical
        : isCautionary
        ? VehicleState.Cautionary
        : VehicleState.Normal,
    });
  });

  useEffect(() => {
    dispatch(fetchAllVehiclesCurrentStats());
    dispatch(
      fetchSummaryPages({
        pageInfo: { page: 0, rowsPerPage: 10 },
        severity: undefined,
      })
    );
  }, []);

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container>
          <PageTitle title="GPS Full View" />
          <GPSMapContainer
            positions={positions}
            terminals={terminals}
            fullView={true}
          />
        </Container>
      </Box>
    </PageLayout>
  );
};
