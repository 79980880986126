import React, { FC } from "react";
import TableSearch from "../../../app/components/Table/TableSearch";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import {
  fetchAllVehicles,
  fetchVehiclesPages,
} from "../../store/vehicles.actions";

export const VehicleSearchContainer: FC = () => {
  const dispatch = useAppDispatch();

  const onSearch = (value: string) => {
    dispatch(fetchVehiclesPages(value));
    dispatch(
      fetchAllVehicles({
        pageInfo: { page: 0, rowsPerPage: 10 },
        search: value,
      })
    );
  };

  return <TableSearch onSearch={onSearch} />;
};
