import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";
import { VehiclesAlertsDto } from "../../summary/api/summary.api.dto";
import { TireDto } from "../../vehicles/api/vehicles.api.dto";
import { setTireStatus } from "../../vehicles/utils/set-tire-status";
import {
  StyledContainer,
  StyledTire,
  StyledTruck,
  StyledTrailer,
  StyledRow,
  StyledInnerTireRow,
  StyledOuterTireRow,
  StyledTireText,
  StyledTopText,
  StyledTypeText,
  StyledText,
  StyledHeaderText,
  StyledLink,
} from "./SummaryEmail.style";
import { PressureStatus } from "../../app/enum/Vehicle";

interface ISummaryAlertVehicle {
  vehicleData: VehiclesAlertsDto;
  vehicleStatusLink: string;
}

export const SummaryAlertVehicle: FC<ISummaryAlertVehicle> = ({
  vehicleData,
  vehicleStatusLink,
}) => {
  const TIRE_COUNT = 40;

  const alertColor = (tireData: TireDto) => {
    return tireData.lostSignal
      ? "error"
      : tireData.leak ||
        tireData.temperatureAlert ||
        tireData.pressureStatus === PressureStatus.EOP ||
        tireData.pressureStatus === PressureStatus.EUP
      ? "red"
      : tireData.pressureStatus === PressureStatus.OP ||
        tireData.pressureStatus === PressureStatus.UP
      ? "orange"
      : tireData.pressureStatus === PressureStatus.GOOD
      ? "green"
      : "no data";
  };

  const tireRow = (tiresData: TireDto[], tire: number) => {
    const tires = [];
    for (let i = 0; i < TIRE_COUNT / 4; i += 1) {
      let count = 0;
      for (let j = 0; j < tiresData.length; j += 1) {
        if (tiresData[j].tire === tire && tiresData[j].axle === i) {
          tires.push(
            <StyledTire alert={alertColor(tiresData[j])} key={i}>
              <StyledTireText>{setTireStatus(tiresData[j])}</StyledTireText>
            </StyledTire>
          );
        } else {
          count += 1;
        }
      }
      if (count === tiresData.length) {
        tires.push(
          <StyledTire alert="no data" key={i}>
            <StyledTireText>--</StyledTireText>
          </StyledTire>
        );
      }
    }
    return tires;
  };

  return (
    <>
      <StyledHeaderText style={{ marginRight: "24px !important" }}>
        Daily Trip Info:
      </StyledHeaderText>
      <StyledHeaderText>
        Vehicle Name / ID:{" "}
        {vehicleStatusLink ? (
          <StyledLink>
            <RouterLink to={vehicleStatusLink}>{vehicleData.id}</RouterLink>
          </StyledLink>
        ) : (
          vehicleData.id
        )}
      </StyledHeaderText>
      <Box style={{ overflowX: "auto" }}>
        <StyledContainer style={{ marginBottom: "96px !important" }}>
          <StyledOuterTireRow>
            {tireRow(vehicleData.current.tires, 3)}
          </StyledOuterTireRow>
          <StyledInnerTireRow>
            {tireRow(vehicleData.current.tires, 2)}
          </StyledInnerTireRow>
          <StyledRow>
            <StyledTruck>
              <StyledTopText>Truck</StyledTopText>
              <StyledTypeText>
                <StyledLink>
                  <RouterLink to={vehicleStatusLink}>
                    {vehicleData.name !== ""
                      ? vehicleData.name
                      : vehicleData.hardwareId}
                  </RouterLink>
                </StyledLink>
              </StyledTypeText>
              <StyledText>
                ID:{" "}
                <StyledLink>
                  <RouterLink to={vehicleStatusLink}>
                    {vehicleData.id}
                  </RouterLink>
                </StyledLink>
              </StyledText>
            </StyledTruck>
            <StyledTrailer>
              <StyledTopText>Trailer</StyledTopText>
              <StyledTypeText>
                {vehicleData.trailerName || vehicleData.trailerType}
              </StyledTypeText>
              <StyledText>ID: {vehicleData.trailerId || "N/A"}</StyledText>
            </StyledTrailer>
          </StyledRow>
          <StyledInnerTireRow>
            {tireRow(vehicleData.current.tires, 1)}
          </StyledInnerTireRow>
          <StyledOuterTireRow>
            {tireRow(vehicleData.current.tires, 0)}
          </StyledOuterTireRow>
        </StyledContainer>
      </Box>
    </>
  );
};
