import { authApiClient } from "../../app/api/authApi.client";

export const fetchWebAnalyticsConsumeCount = async (
  date: string
): Promise<[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/endpoint/count/byminute/broker-consume/${date}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchWebAnalyticsIngestCount = async (
  date: string
): Promise<[]> => {
  try {
    const response = await authApiClient.get(
      `/monitor/endpoint/count/byminute/broker-ingest/${date}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchWebAnalyticsQueueSize = async (date: string): Promise<[]> => {
  try {
    const response = await authApiClient.get(`/monitor/queue/size/${date}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
