import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { UsersState, usersState } from "./users.store";
import {
  fetchAllUsers,
  fetchUser,
  addUser,
  updateUser,
  deactivateUser,
  activateUser,
} from "./users.actions";

const slice = createSlice({
  name: "users",
  initialState: usersState,
  reducers: {
    searchUsers: (state: UsersState, { payload }: { payload: string }) => ({
      ...state,
      searchUsers: payload,
    }),
    clearSearchUsersState: (state: UsersState) => ({
      ...state,
      searchUsers: usersState.searchUsers,
    }),
    clearFetchAllUsersState: (state: UsersState) => ({
      ...state,
      fetchAllUsers: usersState.fetchAllUsers,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => ({
        ...state,
        fetchAllUsers: {
          ...state.fetchAllUsers,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchAllUsers: {
              status: AsyncStatus.Fulfilled,
              data: [...payload],
            },
          };
        }
        return state;
      })
      .addCase(fetchAllUsers.rejected, (state) => ({
        ...state,
        fetchAllUsers: {
          ...state.fetchAllUsers,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(fetchUser.pending, (state) => ({
        ...state,
        fetchUser: {
          ...state.fetchUser,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        if (payload) {
          return {
            ...state,
            fetchUser: {
              status: AsyncStatus.Fulfilled,
              data: payload,
            },
          };
        }
        return state;
      })
      .addCase(fetchUser.rejected, (state) => ({
        ...state,
        fetchUser: {
          ...state.fetchUser,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(addUser.pending, (state) => ({
        ...state,
        addUser: {
          ...state.addUser,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(addUser.fulfilled, (state) => ({
        ...state,
        addUser: {
          ...state.addUser,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(addUser.rejected, (state) => ({
        ...state,
        addUser: {
          ...state.addUser,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(updateUser.pending, (state) => ({
        ...state,
        updateUser: {
          ...state.updateUser,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(updateUser.fulfilled, (state) => ({
        ...state,
        updateUser: {
          ...state.updateUser,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(updateUser.rejected, (state) => ({
        ...state,
        updateUser: {
          ...state.updateUser,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(deactivateUser.pending, (state) => ({
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(deactivateUser.fulfilled, (state) => ({
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(deactivateUser.rejected, (state) => ({
        ...state,
        deactivateUser: {
          ...state.deactivateUser,
          status: AsyncStatus.Rejected,
        },
      }))
      .addCase(activateUser.pending, (state) => ({
        ...state,
        activateUser: {
          ...state.activateUser,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(activateUser.fulfilled, (state) => ({
        ...state,
        activateUser: {
          ...state.activateUser,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(activateUser.rejected, (state) => ({
        ...state,
        activateUser: {
          ...state.activateUser,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export const { searchUsers, clearSearchUsersState, clearFetchAllUsersState } =
  slice.actions;
export default slice.reducer;
