import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { ProfileState } from "./profile.store";

export const selectSelf = (state: RootState): RootState => state;

export const selectProfileRootState = createSelector(
  selectSelf,
  (state: RootState): ProfileState => state.profile
);

export const selectUpdateProfileState = createSelector(
  selectProfileRootState,
  (profile): AsyncState<null> => profile.updateProfile
);

export const selectUpdateProfileStatus = createSelector(
  selectUpdateProfileState,
  (updateProfile): AsyncStatus => updateProfile.status
);

export const selectIsUpdateProfilePending = createSelector(
  selectUpdateProfileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
