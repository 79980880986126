import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";

export interface WebAnalyticsState {
  consumeAnalytics: AsyncState<[] | null>;
  ingestAnalytics: AsyncState<[] | null>;
  queueSize: AsyncState<[] | null>;
}

export const webAnalyticsState: WebAnalyticsState = {
  consumeAnalytics: {
    status: AsyncStatus.Void,
    data: null,
  },
  ingestAnalytics: {
    status: AsyncStatus.Void,
    data: null,
  },
  queueSize: {
    status: AsyncStatus.Void,
    data: null,
  },
};
