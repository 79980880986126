import React, { FC } from "react";
import { push } from "redux-first-history";
import { generatePath } from "react-router";
import { IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import {
  DataGrid,
  GridColDef,
  GridApi,
  GridCellValue,
  GridRenderCellParams,
  GridAlignment,
} from "@mui/x-data-grid";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { useShowForRoles } from "../../app/hooks/useShowForRoles";
import { getTenantIds } from "../../app/utils/get-tenant-ids";
import { TerminalDto } from "../../terminals/api/terminals.api.dto";
import { TERMINALS_EDIT, TERMINALS_EDIT_TENANT } from "../../app/const/routes";
import { UserRole } from "../../app/enum/UserRole";

interface ITerminalsTable {
  showEditActions?: boolean;
  showTenantColumn?: boolean;
  terminalsTableData: TerminalDto[];
}

export const TerminalsTable: FC<ITerminalsTable> = ({
  showEditActions,
  showTenantColumn,
  terminalsTableData,
}) => {
  const dispatch = useAppDispatch();
  const tenantIds = getTenantIds();
  const canSeeTenants =
    useShowForRoles([UserRole.SuperAdmin]) && tenantIds.length > 0;
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.id}>
          <span>{params.row.id}</span>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <span>{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.state}>
          <span>{params.row.state}</span>
        </Tooltip>
      ),
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.city}>
          <span>{params.row.city}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.address}>
          <span>{params.row.address}</span>
        </Tooltip>
      ),
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      minWidth: 150,
      flex: 1,
      filterable: false,
      headerClassName: "heading",
      renderCell: (params) => (
        <Tooltip title={params.row.timeZone}>
          <span>{params.row.timeZone}</span>
        </Tooltip>
      ),
    },
    ...(showTenantColumn
      ? [
          {
            field: "tenantId",
            headerName: "Tenant",
            minWidth: 150,
            flex: 1,
            filterable: false,
            headerClassName: "heading",
            renderCell: (params: GridRenderCellParams) =>
              params.row.tenantId ? (
                <Tooltip title={params.row.tenantId}>
                  <span>{params.row.tenantId}</span>
                </Tooltip>
              ) : (
                "N/A"
              ),
          },
        ]
      : []),
    ...(showEditActions
      ? [
          {
            field: "action",
            headerName: "",
            align: "right" as GridAlignment,
            width: 30,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
              const onClick = () => {
                const api: GridApi = params.api;
                const thisRow: Record<string, GridCellValue> = {};

                api
                  .getAllColumns()
                  .filter((c) => c.field !== "__check__" && !!c)
                  .forEach(
                    (c) =>
                      (thisRow[c.field] = params.getValue(params.id, c.field))
                  );

                if (thisRow.id) {
                  dispatch(
                    push(
                      canSeeTenants
                        ? generatePath(TERMINALS_EDIT_TENANT, {
                            id: thisRow.id as string,
                            tenantId: thisRow.tenantId as string,
                          })
                        : generatePath(TERMINALS_EDIT, {
                            id: thisRow.id as string,
                          })
                    )
                  );
                }
              };

              return (
                <IconButton
                  title="Edit this terminal"
                  aria-label="Edit this terminal"
                  size="small"
                  onClick={onClick}
                >
                  <EditIcon />
                </IconButton>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <DataGrid
      rows={terminalsTableData}
      columns={columns}
      getRowId={(row) => row.id + "|||" + row.tenantId}
      rowsPerPageOptions={[]}
      hideFooterPagination={true}
    />
  );
};
