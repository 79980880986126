import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Add as AddIcon } from "@mui/icons-material";

import { TENANT_ADD } from "../../../app/const/routes";
import { Button, IButton } from "../../../app/components/Button/Button";

type ITenantAddButton = IButton;

export const TenantAddButton: FC<ITenantAddButton> = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={() => navigate(TENANT_ADD)}
      {...props}
    >
      New Tenant
    </Button>
  );
};
