import { createSlice } from "@reduxjs/toolkit";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { profileState } from "./profile.store";
import { updateProfile } from "./profile.actions";

const slice = createSlice({
  name: "profile",
  initialState: profileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => ({
        ...state,
        updateProfile: {
          ...state.updateProfile,
          status: AsyncStatus.Pending,
        },
      }))
      .addCase(updateProfile.fulfilled, (state) => ({
        ...state,
        updateProfile: {
          ...state.updateProfile,
          status: AsyncStatus.Fulfilled,
        },
      }))
      .addCase(updateProfile.rejected, (state) => ({
        ...state,
        updateProfile: {
          ...state.updateProfile,
          status: AsyncStatus.Rejected,
        },
      }));
  },
});

export default slice.reducer;
