import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AsyncState } from "../../app/models/AsyncState";
import { AsyncStatus } from "../../app/enum/AsyncStatus";
import { VehiclesState } from "./vehicles.store";
import {
  VehicleDto,
  MeasurementDto,
  PagesDto,
  HistoryDto,
} from "../api/vehicles.api.dto";

export const selectSelf = (state: RootState): RootState => state;

export const selectVehiclesRootState = createSelector(
  selectSelf,
  (state: RootState): VehiclesState => state.vehicles
);

export const selectFetchAllVehiclesState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<VehicleDto[]> => vehicles.fetchAllVehicles
);

export const selectFetchAllVehiclesData = createSelector(
  selectFetchAllVehiclesState,
  (fetchAllVehicles): VehicleDto[] => fetchAllVehicles.data
);

export const selectFetchAllVehiclesStatus = createSelector(
  selectFetchAllVehiclesState,
  (fetchAllVehicles): AsyncStatus => fetchAllVehicles.status
);

export const selectIsFetchAllVehiclesPending = createSelector(
  selectFetchAllVehiclesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAllVehiclesStatsState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<VehicleDto[]> => vehicles.fetchAllVehiclesStats
);

export const selectFetchAllVehiclesStatsData = createSelector(
  selectFetchAllVehiclesStatsState,
  (fetchAllVehiclesStats): VehicleDto[] => fetchAllVehiclesStats.data
);

export const selectFetchVehicleState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<VehicleDto | null> => vehicles.fetchVehicle
);

export const selectFetchVehicleData = createSelector(
  selectFetchVehicleState,
  (fetchVehicle): VehicleDto | null => fetchVehicle.data
);

export const selectFetchVehicleStatus = createSelector(
  selectFetchVehicleState,
  (fetchVehicle): AsyncStatus => fetchVehicle.status
);

export const selectIsFetchVehiclePending = createSelector(
  selectFetchVehicleStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchVehicleCurrentStatsState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<MeasurementDto | null> =>
    vehicles.fetchVehicleCurrentStats
);

export const selectFetchVehicleCurrentStatsData = createSelector(
  selectFetchVehicleCurrentStatsState,
  (fetchVehicleCurrentStats): MeasurementDto | null =>
    fetchVehicleCurrentStats.data
);

export const selectFetchVehicleCurrentStatsStatus = createSelector(
  selectFetchVehicleCurrentStatsState,
  (fetchVehicleCurrentStats): AsyncStatus => fetchVehicleCurrentStats.status
);

export const selectIsFetchVehicleCurrentStatsPending = createSelector(
  selectFetchVehicleCurrentStatsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchVehicleHistoricalStatsState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<MeasurementDto | null> =>
    vehicles.fetchVehicleHistoricalStats
);

export const selectFetchVehicleHistoricalStatsData = createSelector(
  selectFetchVehicleHistoricalStatsState,
  (fetchVehicleHistoricalStats): MeasurementDto | null =>
    fetchVehicleHistoricalStats.data
);

export const selectFetchVehicleHistoricalStatsStatus = createSelector(
  selectFetchVehicleHistoricalStatsState,
  (fetchVehicleHistoricalStats): AsyncStatus =>
    fetchVehicleHistoricalStats.status
);

export const selectIsFetchVehicleHistoricalStatsPending = createSelector(
  selectFetchVehicleHistoricalStatsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectEditVehicleState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<null> => vehicles.editVehicle
);

export const selectEditVehicleStatus = createSelector(
  selectEditVehicleState,
  (editVehicle): AsyncStatus => editVehicle.status
);

export const selectIsEditVehiclePending = createSelector(
  selectEditVehicleStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchVehiclesPagesState = createSelector(
  selectVehiclesRootState,
  (pages): AsyncState<PagesDto> => pages.fetchVehiclesPages
);

export const selectFetchVehiclesPagesData = createSelector(
  selectFetchVehiclesPagesState,
  (fetchVehiclesPages): PagesDto => fetchVehiclesPages.data
);

export const selectFetchVehiclesPagesStatus = createSelector(
  selectFetchVehiclesPagesState,
  (fetchVehiclesPages): AsyncStatus => fetchVehiclesPages.status
);

export const selectIsFetchVehiclesPagesPending = createSelector(
  selectFetchVehiclesPagesStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchAllVehiclesCurrentStatsState = createSelector(
  selectVehiclesRootState,
  (vehicles): AsyncState<VehicleDto[] | null> =>
    vehicles.fetchAllVehiclesCurrentStats
);

export const selectFetchAllVehiclesCurrentStatsData = createSelector(
  selectFetchAllVehiclesCurrentStatsState,
  (fetchAllVehiclesCurrentStats): VehicleDto[] | null =>
    fetchAllVehiclesCurrentStats.data
);

export const selectFetchAllVehiclesCurrentStatsStatus = createSelector(
  selectFetchAllVehiclesCurrentStatsState,
  (fetchAllVehiclesCurrentStats): AsyncStatus =>
    fetchAllVehiclesCurrentStats.status
);

export const selectIsFetchAllVehiclesCurrentStatsPending = createSelector(
  selectFetchAllVehiclesCurrentStatsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchPressureHistoryState = createSelector(
  selectVehiclesRootState,
  (vehicle): AsyncState<HistoryDto[]> => vehicle.fetchPressureHistory
);

export const selectFetchPressureHistoryData = createSelector(
  selectFetchPressureHistoryState,
  (fetchPressureHistory): HistoryDto[] => fetchPressureHistory.data
);

export const selectFetchPressureHistoryStatus = createSelector(
  selectFetchPressureHistoryState,
  (fetchPressureHistory): AsyncStatus => fetchPressureHistory.status
);

export const selectIsFetchPressureHistoryPending = createSelector(
  selectFetchPressureHistoryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectFetchTemperatureHistoryState = createSelector(
  selectVehiclesRootState,
  (vehicle): AsyncState<HistoryDto[]> => vehicle.fetchTemperatureHistory
);

export const selectFetchTemperatureHistoryData = createSelector(
  selectFetchTemperatureHistoryState,
  (fetchTemperatureHistory): HistoryDto[] => fetchTemperatureHistory.data
);

export const selectFetchTemperatureHistoryStatus = createSelector(
  selectFetchTemperatureHistoryState,
  (fetchTemperatureHistory): AsyncStatus => fetchTemperatureHistory.status
);

export const selectIsFetchTemperatureHistoryPending = createSelector(
  selectFetchTemperatureHistoryStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
