import React, { FC, useEffect } from "react";
import { FormikProps } from "formik";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { convertTireCoordinatesToCountArray } from "../../../vehicles/utils/convert-tire-count-coordinates";
import { CreateEditTrailerDto } from "../../api/trailers.api.dto";
import { fetchAllTrailers, editTrailer } from "../../store/trailers.actions";
import { fetchAllVehicles } from "../../../vehicles/store/vehicles.actions";
import {
  selectFetchAllTrailersData,
  selectIsFetchAllTrailersPending,
} from "../../store/trailers.selector";
import { TrailerSetupForm } from "../../components/TrailerSetupForm/TrailerSetupForm";

export interface ITrailerSetupFormContainerProps {
  formikRef: React.Ref<FormikProps<CreateEditTrailerDto>>;
  selectedTrailerId: string | undefined;
  selectedTenantId: string | undefined;
  showTenantIds?: boolean;
  changeTrailer: (
    trailerId: string | undefined,
    tenantId: string | undefined
  ) => void;
  setTireCoordinatesError: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const TrailerSetupFormContainer: FC<ITrailerSetupFormContainerProps> = ({
  formikRef,
  selectedTrailerId,
  selectedTenantId,
  showTenantIds,
  changeTrailer,
  setTireCoordinatesError,
}) => {
  const dispatch = useAppDispatch();
  const trailers = useAppSelector(selectFetchAllTrailersData);
  const isTrailersPending = useAppSelector(selectIsFetchAllTrailersPending);

  const onSubmit = (trailerFormData: CreateEditTrailerDto) =>
    selectedTrailerId &&
    dispatch(
      editTrailer({
        trailerId: selectedTrailerId,
        tenantId: selectedTenantId,
        data: {
          ...trailerFormData,
          tiresCount: convertTireCoordinatesToCountArray(
            trailerFormData.tiresMap || []
          ),
        },
      })
    );

  useEffect(() => {
    dispatch(
      fetchAllTrailers({
        pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
      })
    );
    dispatch(
      fetchAllVehicles({
        pageInfo: { page: 0, rowsPerPage: Number.MAX_SAFE_INTEGER },
      })
    );
  }, [dispatch]);

  return (
    <TrailerSetupForm
      formikRef={formikRef}
      trailers={trailers}
      selectedTrailerId={selectedTrailerId}
      selectedTenantId={selectedTenantId}
      showTenantIds={showTenantIds}
      changeTrailer={changeTrailer}
      setTireCoordinatesError={setTireCoordinatesError}
      onSubmit={onSubmit}
      isPending={isTrailersPending}
    />
  );
};
