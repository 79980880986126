import { Typography } from "@mui/material";
import styled from "styled-components";
import {
  ASPHALT,
  ASPHALT_LIGHT,
  ASPHALT_LIGHTER,
  CAUTIONARY_ORANGE,
  SCHOOL_BUS_RED,
  TRUCKING_GREEN,
  BLACK,
  WHITE,
} from "../../../app/theme/palette";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 800px;
  margin: 64px auto;
`;

export const StyledTire = styled.div<{ alert: string; interactive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px !important;
  height: 28px;
  background-color: ${({ alert }) =>
    alert === "green"
      ? TRUCKING_GREEN
      : alert === "red"
      ? SCHOOL_BUS_RED
      : alert === "orange"
      ? CAUTIONARY_ORANGE
      : alert === "error"
      ? ASPHALT_LIGHTER
      : alert === "no-data"
      ? ASPHALT_LIGHTER
      : WHITE};
  margin: 3px;
  ${({ interactive }) =>
    interactive
      ? `
  cursor: pointer`
      : `pointer-events: none;
  cursor: default`};
  border-radius: 3px;
  &.borderSpacing {
    margin: 3px;
  }
`;

export const StyledBorderSpace = styled.div`
  background-color: transparent;
  border: 3px solid ${WHITE};
  padding: 0px;
  height: 40px;
  &:nth-child(1) {
    margin-left: -15px;
    margin-right: 5px;
  }
  &:nth-child(4) {
    margin-left: 5px;
  }
`;

export const StyledTireText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${WHITE};
`;

export const StyledTruck = styled.div`
  width: 222px;
  height: 112px;
  background: ${ASPHALT_LIGHT};
  border-radius: 4px;
  margin-right: 30px;
`;

export const StyledTrailer = styled.div`
  width: 554px;
  height: 112px;
  background: ${ASPHALT_LIGHT};
  border-radius: 4px;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledInnerTireRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 12px;
  margin: auto;
  width: 100%;
`;

export const StyledOuterTireRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

export const StyledTopText = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${ASPHALT};
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const StyledTypeText = styled(Typography)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: ${BLACK};
`;

export const StyledText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${BLACK};
  text-align: center;
`;
